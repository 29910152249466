export default {
    //BankAccountComponent
    payments_receipt: 'Receipt of payments',
    beneficiary: 'Beneficiary',
    bic: 'BIC',
    account_number: 'Account number',
    enter_beneficiary: 'Enter beneficiary',
    enter_bic: 'Enter BIC',
    enter_account_number: 'Enter account number',
    info_billing_msg: 'Enter this information to receive the income in your account.',
    info_billing_send: 'Send by mail to ',
    info_billing_dni_msg: ' color scanned copy of the DNI / NIE of the legal representative of your company and/or the CIF of your company.',

    //BasicDataComponent
    profile_info: 'Profile information',
    enter_name: 'Enter your name',
    enter_lastname: 'Enter your lastname',
    error_name: 'Must enter a name',
    error_lastname: 'Must enter your last name',

    //ChangePasswordComponent
    change_pass: 'Change your password',
    current_pass: 'Current password',
    new_pass: 'New password',
    confirm_pass: 'Repeat your new password',
    enter_pass: 'Enter current password',
    enter_new_pass: 'Enter your new password',
    repeat_new_pass: 'Repeat your new password',
    password_not_match: 'Passwords do not match',

    //UserAvatarComponent
    user_image: 'User image',
    upload_avatar: 'Upload your avatar',
    change: 'Change',
    insert: 'Insert'
}