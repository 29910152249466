
import { defineStore } from 'pinia'
import axios from "axios";
import AcademyService from "@/services/academy/AcademyService";
import {ApiClient} from "@/services/ApiService";
import {useAcademiesStore} from "@/stores/academies";
import router from "../router";

export const useAuthUserStore = defineStore('auth', {
    state: () => ({
        token: {
            token_type: null,
            expires_in: null,
            access_token: null,
            refresh_token: null
        },
        selected_academy_uuid: null,
        user: {
            academy: {
                branding: {
                    logo_url: null
                },
                uuid: null,
                saas_subscriptions: [{
                    status: '',
                    saas_plan: {
                        key: ''
                    }
                }]
            },
            avatar: "/images/avatar-bp.jpg",
            firstname: null,
            intercom_hash: null
        },
        isNewUser: true
    }),
    getters: {
        isLoggedIn:(state) =>  !!   state.token.accessToken,
        getApiClient: (state) => new ApiClient(state.token.accessToken),
        //getSelectedAcademyUuid: (state) => state.selected_academy_uuid
    },
    actions: {

        signup (credentials) {
            return axios
                .post(process.env.VUE_APP_API_URL + '/register', credentials)
                .then(({ data }) => {
                    console.log("Los datos recibidos son:", data)
                    this.setTokenData(data)
                })
                .then( () => {
                    this.getUser()
                })
        },
        login (credentials) {
            return axios
                .post(process.env.VUE_APP_API_URL + '/login', credentials)
                .then(({ data }) => {
                    console.log("Los datos recibidos son:", data)
                    this.setTokenData(data)
                })
        },
        logout () {
            localStorage.removeItem('authData')
            location.reload()
        },
        isNewUser (isNewUser) {
            this.isNewUser = isNewUser
        },
        changeAcademy() {
            AcademyService.index().then((result) => {
                console.log("Solicitud de cambio de academia", result)
                this.selected_academy_uuid = result.data.uuid
                localStorage.setItem('selectedAcademyUuid', result.data.uuid)
            })
        },
        getUserV2 () {
            return axios
                .get(process.env.VUE_APP_API_URL + '/me')
        },
        getUser () {
            return axios
                .get(process.env.VUE_APP_API_URL + '/me')
                .then( ({data}) => {
                    console.log("Los datos recibidos para el usuario son:", data)
                    if (data.is_owner)
                    {
                        this.user = data
                        useAcademiesStore().getAcademy()
                        if (window.Intercom)
                        {
                            console.log("intercom is loaded");
                            window.Intercom("boot", {
                                app_id: "ciiw9mpa",
                                "Company name": data.academy.title,
                                "Company ID": data.academy.uuid,
                                "Company website": 'https://' + data.academy.subdomain + '.mybrainspro.com',
                                user_id: data.uuid,
                                user_hash: data.intercom_hash,
                                name: data.firstname + ' ' + data.lastname,
                                email: data.email,
                                plan_type: data.academy.saas_subscriptions[0].saas_plan.title,
                                monthly_spend: data.academy.saas_subscriptions[0].total,
                                subscription_status: data.academy.saas_subscriptions[0].status
                            });
                        }
                    }
                    else
                    {
                        this.logout()
                    }

                    /*
                     * ---------------------
                     * Comprobará el estado de 'Saas Subscription'
                     * enviará a 'current-subscription'
                     * en caso de que el estado sea past_due.
                     * ---------------------
                     */
                    console.log("El estado de la suscriptión es: ", this.user.academy.saas_subscriptions[0].status)
                    if(this.user.academy.saas_subscriptions[0].status == 'past_due' || this.user.academy.saas_subscriptions[0].status == 'canceled')
                    {
                        router.push('/myaccount/current-subscription')
                    }
                })
                .catch( (error) => {
                    console.log("Se ha producido un error", error)
                })
        },
        rememberPassword (request) {
            return axios
                .post(process.env.VUE_APP_API_URL + '/remember', request)
                .then(({data}) => {
                    console.log("Result: ", data)
                });
        },
        resetPassword (request) {
            return axios
                .post(process.env.VUE_APP_API_URL + '/reset', request)
                .then(({data}) => {
                    console.log("Result: ", data)
                });
        },
        //-----------
        // Mutations!
        //-----------
        setTokenData (authData) {
            localStorage.setItem('authData', JSON.stringify(authData))
            axios.defaults.headers.common['Authorization'] = `Bearer ${
                authData.access_token
            }`
            this.token.accessToken = authData.access_token
        }
    }
})