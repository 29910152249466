<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm">
        <div class="text-center">
          <img style="height: 5rem" class=" mx-auto" src="/images/logo-bp-noclaim.png" alt="Brainspro">
          <h2 class="text-4xl leading-9 font-bold text-gray-800">
            {{ $t('signup.create_account') }}
          </h2>
          <p class="mt-2 text-sm leading-5 text-gray-600 max-w">
            O
            <a class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
               href="https://app.brainspro.com">
              {{ $t('signup.login') }}
            </a>
          </p>
        </div>

        <div class="mt-4">
          <div class="hidden">
            <div>
              <p class="text-sm leading-5 font-medium text-gray-700">
                {{ $t('signup.access_with') }}
              </p>

              <div class="mt-1 grid grid-cols-3 gap-3">
                <div>
                <span class="w-full inline-flex rounded-md shadow-sm">
                  <button aria-label="Sign in with Facebook"
                          class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                          type="button">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path clip-rule="evenodd"
                            d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                            fill-rule="evenodd"/>
                    </svg>
                  </button>
                </span>
                </div>

                <div>
                <span class="w-full inline-flex rounded-md shadow-sm">
                  <button aria-label="Sign in with Twitter"
                          class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                          type="button">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path
                          d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"/>
                    </svg>
                  </button>
                </span>
                </div>

                <div>
                <span class="w-full inline-flex rounded-md shadow-sm">
                  <button aria-label="Sign in with GitHub"
                          class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                          type="button">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path clip-rule="evenodd"
                            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                            fill-rule="evenodd"/>
                    </svg>
                  </button>
                </span>
                </div>
              </div>
            </div>

            <div class="mt-6 relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 bg-white text-gray-500">
                {{ $t('signup.continue_with') }}
              </span>
              </div>
            </div>
          </div>

          <div v-if="errors" class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: x-circle -->
                <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path clip-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        fill-rule="evenodd"/>
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm leading-5 font-medium text-red-800">
                  {{ $t('signup.error_text1') }} {{ errors.length }} {{ (errors.length === 1) ? "error" : "errores" }}
                  {{ $t('signup.error_text2') }}
                </h3>
                <div class="mt-2 text-sm leading-5 text-red-700">
                  <ul class="list-disc pl-5">
                    <li v-for="(error, index) in errors" :key="index">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <form action="#" method="GET" @submit.prevent="nextStep">
              <div>
                <label for="firstname" class="block text-sm font-medium text-gray-700">{{ $t('general.name') }}</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="text" v-model="v$.request.firstname.$model" id="firstname" required
                         class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <div v-if="v$.request.firstname.$error"
                       class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                    <!-- Heroicon name: solid/exclamation-circle -->
                    <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"/>
                    </svg>
                  </div>
                </div>
                <p v-if="v$.request.firstname.$error" class="mt-2 text-sm text-red-600" id="error-firstname">
                  {{ $t('signup.error_firstname_required') }}</p>
              </div>

              <div class="mt-6">
                <label for="lastname" class="block text-sm font-medium text-gray-700">{{
                    $t('general.lastname')
                  }}</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="text" v-model="v$.request.lastname.$model" id="lastname" required
                         class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <div v-if="v$.request.lastname.$error"
                       class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                    <!-- Heroicon name: solid/exclamation-circle -->
                    <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"/>
                    </svg>
                  </div>
                </div>
                <p v-if="v$.request.lastname.$error" class="mt-2 text-sm text-red-600" id="error-lastname">
                  {{ $t('signup.error_lastname_required') }}</p>
              </div>
              <div class="mt-6">
                <label for="email-address"
                       class="block text-sm font-medium text-gray-700">{{ $t('general.email_address') }}</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="text" v-model="v$.request.email.$model" id="email-address" required
                         class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <div v-if="v$.request.email.$error || !emailIsFree"
                       class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                    <!-- Heroicon name: solid/exclamation-circle -->
                    <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"/>
                    </svg>
                  </div>
                </div>
                <p v-if="v$.request.email.$error" class="mt-2 text-sm text-red-600" id="error-email-address">
                  {{ $t('signup.error_email_address_required') }}</p>
                <p v-if="!emailIsFree" class="mt-2 text-sm text-red-600" id="error-email-used">
                  {{ $t('signup.error_email_used') }}</p>
              </div>

              <div class="mt-6">
                <label for="subdomain" class="block text-sm font-medium text-gray-700">{{
                    $t('signup.subdomain')
                  }}</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="text" v-model="v$.request.subdomain.$model" id="subdomain" required
                         placeholder="mi-academia"
                         class="lowercase appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 sm:text-sm">
                    <span>.mybrainspro.com</span>
                  </div>
                </div>
                <p v-if="v$.request.subdomain.$error" class="mt-2 text-sm text-red-600" id="error-domain">
                  {{ $t('signup.error_academy_slug_required') }}</p>
                <p v-if="!subdomainIsFree" class="mt-2 text-sm text-red-600" id="error-domain-used">
                  {{ $t('signup.error_domain_used') }}</p>
              </div>

              <div class="mt-6">
                <label for="phone" class="block text-sm font-medium text-gray-700">{{ $t('general.phone') }}</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="tel" v-model="v$.request.phone.$model" id="phone" required
                         class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <div v-if="v$.request.phone.$error"
                       class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                    <!-- Heroicon name: solid/exclamation-circle -->
                    <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"/>
                    </svg>
                  </div>
                </div>
                <p v-if="v$.request.phone.$error" class="mt-2 text-sm text-red-600" id="error-phone">
                  {{ $t('signup.error_phone_required') }}</p>
              </div>

              <div class="mt-6">
                <label for="password" class="block text-sm font-medium text-gray-700">{{ $t('general.pass') }}</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input v-bind:type="viewPassType" v-model="v$.request.password.$model" id="password" required
                         class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <!-- Icono para mostrar contraseña o no -->
                  <div @click="clickOnEye" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                    <!-- Heroicon name: solid/question-mark-circle -->
                    <svg v-if="!viewPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                    </svg>
                  </div>
                  <div @click="clickOnEye" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                    <svg v-if="viewPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                    </svg>
                  </div>
                  <!-- fin de icono ojo -->
                </div>
                <p v-if="v$.request.password.$error" class="mt-2 text-sm text-red-600" id="error-password">
                  {{ $t('signup.error_pass_required') }}</p>
              </div>

              <div class="mt-6">
                <label for="confirmPassword"
                       class="block text-sm font-medium text-gray-700">{{ $t('general.pass_confirm') }}</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input v-bind:type="viewPassType" v-model="v$.request.password_confirmation.$model"
                         id="confirmPassword" required
                         class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                </div>
                <p v-if="v$.request.password_confirmation.$error" class="mt-2 text-sm text-red-600"
                   id="error-confirmPassword"> {{ $t('signup.error_confirm_pass_required') }}</p>
              </div>

              <div class="mt-6 flex items-center justify-between">
                <div class="flex items-center">
                  <input id="rgpd_acceptance" v-model="request.rgpd_acceptance"
                         class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out" required
                         type="checkbox">
                  <label class="ml-2 block text-sm leading-5 text-gray-900" for="rgpd_acceptance">
                    {{ $t('signup.privacy_text1') }}<a
                      class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      href="https://www.brainspro.com/politica-de-privacidad"
                      target="_blank">{{ $t('signup.privacy_text2') }}</a>
                  </label>
                </div>
              </div>


              <div class="mt-6 flex items-center justify-between">
                <div class="flex items-center">
                  <input id="commercial_acceptance" v-model="request.commercial_acceptance"
                         class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                         type="checkbox">
                  <label class="ml-2 block text-sm leading-5 text-gray-900" for="commercial_acceptance">
                    {{ $t('signup.marketing_text') }}
                  </label>
                </div>
              </div>

              <div class="mt-6">
              <span class="block w-full rounded-md shadow-sm">
                <button
                    class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                  {{ $t('signup.button_next') }}
                </button>
              </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
        class="hidden lg:block relative w-0 flex justify-center items-center flex-1 bg-gradient-to-br from-bppick to-bpblue text-white px-10 4xl:px-64 py-20">
      <img class="mb-20" src="/images/banners/banner-login-v2.png"/>
      <h2 class="text-4xl px-10 4xl:text-7xl mb-10 leading-20 font-light 4xl:px-40">{{ $t('signup.ad_text1') }}<span
          class="font-bold">{{ $t('signup.ad_text2') }}</span>{{ $t('signup.ad_text3') }}</h2>
      <p class="text-2xl px-10 4xl:px-40 font-extralight">{{ $t('signup.ad_text4') }}</p>
      <img alt=""
           class="hidden absolute inset-0 h-full w-full object-cover object-right"
           src="/images/banners/banner-login.jpeg">
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required, email, minLength, sameAs, maxLength, helpers} from "@vuelidate/validators";

import axios from "axios";

const validSlug = helpers.regex(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/);

export default {
  name: "SingUpComponent",

  data() {
    return {
      request: {
        firstname: "",
        lastname: "",
        email: "",
        subdomain: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
      errors: null,
      viewPass: false,
      subdomainIsFree: true,
      emailIsFree: true,

    }
  },
  computed: {
    viewPassType() {
      return (this.viewPass) ? 'text' : 'password'
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      request: {
        firstname: {
          required,
          maxLengthValue: maxLength(255)
        },
        lastname: {
          required,
          maxLengthValue: maxLength(255)
        },
        email: {
          required,
          email,
          maxLengthValue: maxLength(255)
        },
        subdomain: {
          required,
          validSlug,
          maxLengthValue: maxLength(255)
        },
        phone: {
          required,
          maxLengthValue: maxLength(255)
        },
        password: {
          required,
          minLength: minLength(8),
          maxLengthValue: maxLength(255)
        },
        password_confirmation: {
          required,
          minLength: minLength(8),
          sameAsPassword: sameAs(this.request.password),
          maxLengthValue: maxLength(255)
        }
      }
    }
  },
  methods: {
    nextStep() {
      let email = this.request.email
      let subdomain = this.request.subdomain

      axios.get(process.env.VUE_APP_API_URL + '/users/email/check', {
        params: {
          email: email
        }
      })
          .then((response) => {
            this.emailIsFree = response.data.is_free
            if (this.emailIsFree) {
              console.log("El email está libre: ", this.emailIsFree)
            } else {
              console.log("El email está en uso")
            }
          })
          .catch((error) => {
            console.log("Error con el email: ", error)
          })
      axios.get(process.env.VUE_APP_API_URL + '/academies/subdomain/check', {
        params: {
          subdomain: subdomain
        }
      })
          .then((response) => {
            this.subdomainIsFree = response.data.is_free
            if (this.subdomainIsFree && this.emailIsFree) {
              //Guardar los datos en store
              localStorage.setItem('userDataObject', JSON.stringify(this.request))
              //Se ha guardado el objeto userDataObject.
              let data = JSON.parse(localStorage.getItem('userDataObject'))
              console.log("Los datos del usuario son: ", data)
              //Enviar al segundo paso del signup
              this.$router.push({name: 'customer-data'})
            } else {
              console.log("El dominio está en uso.")
            }
          })
          .catch((error) => {
            console.log("Error: ", error)
          })
    },
    clickOnEye() {
      this.viewPass = !this.viewPass
    }
  }
}


</script>

<style scoped>

</style>