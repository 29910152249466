<template>
  <div class="relative pt-2 pb-6 md:py-6">
      <div v-if="showUploader" v-on:click="showUploader = !showUploader" class="absolute z-50 flex items-center justify-center top-0 right-0 bg-black backdrop-blur bg-opacity-30 left-0 h-screen py-32">
          <button type="button" class="absolute right-2 top-2 bg-transparent rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
              <span class="sr-only">{{$t('general.close')}}</span>
              <!-- Heroicon name: x -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
          </button>
        <div class="flex justify-between my-4">
          <Dashboard :uppy="uppy" id="uppy-dashboard" :plugins="[]" class=""/>
        </div>
      </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full"></div>
        </div>
        <div class="relative flex items-center justify-between">
            <span class="pr-3 pb-10 bg-gray-100 text-3xl font-medium text-gray-900">
              {{ $t("video.videos") }}
            </span>
        </div>
      </div>

      <div class="align-center">
        <default-card-component class="px-20 h-auto" :quantity="this.usedStorage" :loading="false">
          {{ $t("video.data_consumed") }}
        </default-card-component>
      </div>

      <div class="mt-5">
        <div v-show="this.filesData.length < 1" class="text-center mt-10 mb-8">
          <div class="mb-2 flex justify-center">
            <button v-on:click="showUploader = !showUploader" class="inline-flex items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-brainspro-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            Subir video
          </button>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto h-12 w-12 text-gray-300">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
          </svg>
          <label class="px-6 py-3 text-sm leading-4 font-bold text-gray-400 uppercase tracking-wider">
            {{ $t("video.no_file") }}
          </label>
          <p class="mt-1 text-sm text-gray-400">
            {{ $t('video.text_upload_file') }}
          </p>
        </div>
      </div>

      <div class="py-4 md:block">
        <div class="flex flex-col">
            <div v-show="this.filesData.length > 0"  class="mb-2 flex justify-end">
              <p class="font-normal text-sm text-gray-400 mt-1.5 mr-3">1 Giga por archivo</p>
              <button v-on:click="showUploader = !showUploader" class="inline-flex items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-brainspro-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                Subir video
              </button>
            </div>

          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div v-if="!loading && this.filesData.length > 0" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("video.size") }}
                  </th>
                  <th class="hidden px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Proyecto adjunto
                  </th>
                  <th class=" border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    <!-- Columna para completar el espacio -->
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("video.lms_link") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("video.video") }}
                  </th>

                  <!--
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Tipo
                  </th>
                  -->
                </tr>
                </thead>
                <tbody class="">
                <tr v-for="file in this.filesData" :key="file.uuid">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-400">
                    {{ (file.size_bytes /1024/1024).toFixed(2)}}
                  </td>
                  <td class="hidden px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-400">
                    file.projects_attach
                  </td>
                  <td class="whitespace-no-wrap border-b border-gray-200 text-sm text-gray-500 hover:text-gray-400">
                    <div v-on:click="copyToClipboard(file.path)">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cursor-pointer mx-3 w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                      </svg>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <div class="block flex flex-row">
                    <label :href="file.path" ref="myLink" class="overflow-hidden">
                      {{file.path}}
                    </label>
                    </div>
                  </td>

                  <td class="lg:px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <router-link :to="{ name: 'video-edit-general', params: { id : file.uuid }}">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-3 h-7 w-7 text-bpblue hover:text-blue-300 transition rounded ease-in-out duration-150">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <help-center-component :url="this.helpCenter.kn_video_url"></help-center-component>
      <video-list-component class="block md:hidden" :videos="filesData"></video-list-component>
    </div>
  </div>
</template>

<script>
import { Dashboard } from '@uppy/vue';
import Uppy from '@uppy/core';

// Don't forget the CSS: core and UI components + plugins you are using
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import 'filepond/dist/filepond.min.css';
import XHR from '@uppy/xhr-upload';
import Spanish from '@uppy/locales/lib/es_ES'

import DefaultCardComponent from "@/components/cards/DefaultCardComponent.vue";
import StorageService from "@/services/academy/StorageService";
import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";
import helpcenter from "@/services/helpcenter/links";
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import VideoListComponent from "@/views/videos/Mobile/VideoListComponent";
import NotificationService from "@/services/notification/NotificationService";
import HelpCenterComponent from "@/views/common/HelpCenterComponent";

export default {
  name: "VideoComponent",
  data: function () {
    return {
      loading: false,
      usedStorage: null,
      videoStorage: null,
      filesData: [],
      filePath: '',
      showUploader: false,
    };

  },
  components: {
    HelpCenterComponent,
    VideoListComponent,
    DefaultCardComponent,
      Dashboard
  },
    setup(){

    const authUserStore = useAuthUserStore();
    const academiesStore = useAcademiesStore()
    const helpCenter = helpcenter
    const navigationMenuStore = useNavigationMenuStore()

      const uppy = new Uppy({
          locale: Spanish,
          restrictions: {
              maxFileSize: 1024 * 1024 * 1024, // 1024 Mb in bytes
              maxNumberOfFiles: 1,
              allowedFileTypes: ['video/*']
          }
      })

      const headers = {
          authorization: `Bearer ` + authUserStore.token.accessToken,
          Accept: 'application/json'
      };
      uppy.use(XHR, {
          endpoint: process.env.VUE_APP_API_URL + '/storage/files',
          fieldName: "files",
          method: "post",
          headers: headers,
      })

        uppy.setMeta({
            academy_uuid: academiesStore.getSelectedAcademyUuid
        })

        uppy.on('complete', () => {console.log("Completada la carga del video con éxito.")});

      return {
      authUserStore,
      academiesStore,
      helpCenter,
      navigationMenuStore,
        uppy
    }
  },
  created() {
    this.getSize()
  },
  methods: {
    handleFilePondInit: function () {
      console.log('FilePond has initialized');

      this.$refs.pond.getFiles();
    },
    handleFilePondSubmit: function (){
      console.log("Submitting videos...", this.$refs.pond.getFiles())

      let academy_uuid = this.academiesStore.getSelectedAcademyUuid;
      StorageService.uploadFiles(academy_uuid, this.uppy.getFiles())
        this.getVideos()
    },
    getSize: function () {
      let academy_uuid = this.academiesStore.getSelectedAcademyUuid;

      StorageService.getSize(academy_uuid)
          .then((response) => {
            console.log("Storage service response", response)
            this.usedStorage = response.data.used_space
          })
          .finally(() => {
            this.getVideos()
          })
    },
    getVideos: function () {
      let academy_uuid = this.authUserStore.user.academy.uuid;

      StorageService.getVideos(academy_uuid)
          .then((response) => {
            this.filesData = response.data.data
            this.filesData.reverse()
            console.log("Files service response", this.filesData)
          })
    },

    copyToClipboard(link) {
      const copyVar = document.createElement("textarea");
      copyVar.value = link;
      document.body.appendChild(copyVar);
      copyVar.select();
      document.execCommand("copy");
      document.body.removeChild(copyVar);
      NotificationService.showNotification("Enlace copiado al portapapeles", "success")
    }
  },
}
</script>

<style scoped>

</style>