<template>
  <navigation-my-account-component></navigation-my-account-component>
</template>

<script>
import NavigationMyAccountComponent from "./NavigationMyAccountComponent";

export default {
  name: "MyAccountComponent",
  components: {NavigationMyAccountComponent},
}
</script>

<style scoped>

</style>