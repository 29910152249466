<template>
  <div class="">
    <section class="lg:mx-8 mx-4">
      <card-with-header-and-footer-component :loading="loading">
        <template v-slot:header>
          <div class="w-full block">
            <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.general') }}</h3>
            <div class="hidden inset-y-0 right-0 flex justify-end -mt-5 text-red-600">
              <button title="Delete" id="trash-delete" @click="this.showModalDeleteGroup = true" :disabled="loading" aria-label="Borrar Grupo">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>

          <!-- Modal Component para borrado de grupos -->
          <Teleport to="body">
            <modal-component id="modal-unregister" v-if="showModalDeleteGroup" :onProcess="confirmModalDeleteGroup" :onCancel="closeModalDeleteGroup">
              <template v-slot:header>{{ $t('products.warning_delete') }}</template>
              <p>Al eliminar el grupo perderás todas las asociaciones entre proyectos y usuarios y no se podrán recuperar.</p>
              <p class="font-bold mt-2">¿Seguro que quieres continuar?</p>
              <div class="mt-4">
                <input type="checkbox" id="checkbox-double-confirmation" v-model="doubleConfirmationDeleteGroup">
                <label class="ml-2">{{ $t('products.delete') }}</label>
                <div>
                  <p class="mt-2 text-sm text-gray-400" id="error-firstname">
                    (*) Esta casilla es obligatoria para eliminar el grupo</p>
                </div>
              </div>
            </modal-component>
          </Teleport>

        </template>

        <div class="col-span-6">
          <label for="group_title" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('general.title') }}</label>
          <input id="group_title" v-model="this.group.title" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <template v-slot:footer>
          <button type="button" v-on:click="getGroupGeneral" :disabled="loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.cancel') }}
          </button>

          <button type="button" v-on:click="editGroup(this.group)" :disabled="loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.save') }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>
  </div>
</template>

<script>
import GroupsService from "@/services/community/GroupsService";
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import ModalComponent from "@/components/modals/ModalComponent";
import {useAcademiesStore} from "@/stores/academies";
import NotificationService from "@/services/notification/NotificationService";

export default {
  name: "GroupGeneralComponent",
  components: { CardWithHeaderAndFooterComponent, ModalComponent },
  data: function () {
    return {
      loading: false,
      group: {
        title: '',
        uuid: '',
        academy_uuid: '',
        updated_at: '',
        created_at: '',

      },
      showModalDeleteGroup: false,
      doubleConfirmationDeleteGroup: false,
    }
  },
  setup() {
    const academyStore = useAcademiesStore()
    return {
      academyStore
    }
  },
  created() {
    this.getGroupGeneral()
  },
  methods: {
    getGroupGeneral()
    {
      let groupUuid = this.$route.params.id
      GroupsService.show(groupUuid)
          .then((response) => {
            this.group = response.data
            console.log("La respuesta al obtener el grupo es: ", this.group)
          })
          .catch((error) => {
            console.log("Error al obtener el grupo: ", error)
          })
          .finally(() => {
            //Silence is golden
          })
    },
    editGroup() {
      this.loading = true
      console.log("Se ha pulsado el botón guardar/editar")
      this.group.academy_uuid = this.academyStore.getSelectedAcademyUuid
      GroupsService.update(this.group)
          .then((response) => {
            console.log("Grupo actualizado: ", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Error al actualizar el grupo: ", error)
            NotificationService.showNotification(error, "error")
          })
          .finally(() => {
            this.loading = false
          })
    },
    confirmModalDeleteGroup ()
    {
      if(this.doubleConfirmationDeleteGroup)
      {
        this.group.academy_uuid = this.academyStore.getSelectedAcademyUuid
        this.loading = true
        console.log("Se procede a borrar el grupo: ", this.group.uuid)
        GroupsService.delete(this.group)
            .then((response) => {
              console.log("Respuesta al borrar grupo: ", response)
              this.$router.go(-1)
              NotificationService.showNotification("La operación se ha realizado correctamente", "success")
            })
            .catch((error) => {
              console.log("Error al borrar grupo: ", error)
              NotificationService.showNotification(error, "error")
            })
            .finally(() => {
              this.loading = false
            })
      }
    },
    closeModalDeleteGroup()
    {
      this.showModalDeleteGroup = false
    }
  }
}
</script>

<style scoped>

</style>