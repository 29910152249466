export default {
    //ProjectsList
    your_created_projects: "Ihre erstellten Kurse",
    new_project: "Neuer Kurs",
    search_course_placeholder: "Suchen Sie nach Ihrem Kurs...",
    projects_name: "KURS-NAME",
    update_at: 'Aktualisiert am ',
    coming_soon: 'Demnächst verfügbar',
    search_your_project: 'Suchen Sie nach Ihrem Kurs...',
    project_name: 'Name des Kurses',
    loading_projects: 'Projekte laden...',

    //ProjectEditComponent
    modify_project: 'Ändern Sie Ihren Kurs',
    general: 'Allgemein',
    access: 'Zugang',
    branding: 'Branding',
    product_page: 'Produkt Seite',
    sale: 'Verkauf',
    seo_text: 'SEO',
    product: 'Produkt',

    //CategoriesSlideOverComponent
    categories: 'Kategorien',
    text_category: 'Klassifizieren Sie Ihre Produkte nach Kategorien, um Ihren Studenten den Kauf Ihrer Kurse zu erleichtern.',
    select_category: 'Kategorie auswählen...',
    ebook: 'E-book',
    online_project: 'Online-Kurs',
    blended_learning: 'Blended Learning-Kurs',
    online_program: 'Online-Programm',
    webinar_consulting: 'Webinar / Online-Beratung',
    remove_category: 'Kategorie entfernen',
    href_help: 'Ich würde gerne mehr über die Kategorien erfahren',

    //ProjectEdit
    //CustomCategoriesComponent
    //GeneralComponent
    category: 'Kategorie',
    duration: 'Dauer',
    featured_product: 'Produkt-Spotlight',
    publish_project: 'Projekt veröffentlichen',
    delete_project: 'Projekt löschen',
    warning_delete: 'Warnung!',
    message_delete_project: 'Wenn Sie das Projekt löschen, gehen alle Daten unwiderruflich verloren.',
    question_delete_project: 'Sind Sie sicher, dass Sie dieses Projekt löschen wollen?',
    delete: 'Löschen',
    confirmation_delete_project: '(*) Dieses Feld ist erforderlich, um das Projekt zu löschen.',
    navigation: 'Navigation',
    navigation_free: 'Kostenlos',
    navigation_secuential: 'Sequentiell',
    navigation_without_restriction: 'Keine Einschränkungen. Der Nutzer kann frei durch den Kurs navigieren (ausgenommen Kurse mit Drip-Inhalten).',
    navigation_completed_restriction: 'Der Lernende muss jede Lerneinheit abschließen, bevor er mit der nächsten fortfahren kann. Der Abschluss von Lerneinheiten kann auf unterschiedliche Weise festgestellt werden.',
    quest_lesson_complete: 'Wann wird sie als abgeschlossene Lektion betrachtet?',
    lesson_complete_at_visit: 'Wenn besucht',
    lesson_at_visit_info: 'Wenn Sie eine Lektion eingeben, wird sie automatisch in den Gesamtfortschritt des Kurses eingefügt (Standardeinstellung).',
    lesson_complete_at_see: 'Bei vollständiger Anzeige des Inhalts',
    lesson_at_see_info: 'Sie wird als abgeschlossen markiert, wenn der Inhalt (Video, eBook, Podcast) vollständig angesehen wurde.',
    lesson_complete_on_time: 'Wenn Sie eine Weile bleiben',
    lesson_on_time_info: 'Auszufüllen durch Anwesenheit in jeder Unterrichtsstunde für die angegebene Zeit (HH:mm).',
    automatic_navigation: 'Automatische Navigation',
    automatic_navigation_disabled: 'Deaktiviert',
    automatic_navigation_enabled: 'Aktiviert',
    automatic_navigation_info: 'Wenn Sie den Inhalt einer Lektion abgeschlossen haben, gelangen Sie zur nächsten entsprechenden Lektion.',
    //PageComponent
    principal_page: 'Hauptseite',
    objects_presentation: 'Präsentation und Objekte',
    learning_objectives: 'Vergessen Sie nicht, die Lernziele kurz zu beschreiben.',
    teaching_staff: 'Methodik und Lehrpersonal',
    public: 'Öffentlich',
    content: 'Inhalt',
    sidebar: 'Seitenleiste',
    //ProductBrandingComponent
    banner: 'Banner',
    upload_banner: 'Laden Sie Ihr Banner hoch',
    banner_format: 'PNG, JPG, GIF bis zu 3 MB',
    promotional_video: 'Werbevideo',
    video_url: 'Video-URL',
    video_info: 'Wenn Sie ein Werbevideo haben, geben Sie hier die Url des Videos auf Youtube oder Vimeo an.',
    //SellComponent
    price_management: 'Preis-Management',
    sell_price: 'Verkaufspreis',
    tax: 'In €. (-3% Steuer)',
    price_offer: 'Angebotspreis',
    text_configuration: 'Text-Konfiguration',
    free_text_button: 'Freier Buy Button Titel',
    text_if_free: 'Dieser Titel erscheint, wenn Ihr Produkt kostenlos ist',
    on_sale: 'Zu verkaufen',
    sale_info: 'Wenn Ihr Produkt aktiviert ist, wird es zum Verkauf angeboten',

    //SeoComponent
    seo_configuration: 'SEO-Konfiguration',
    seo_title: 'SEO-Titel',
    slug: 'Slug',
    seo_description: 'SEO Beschreibung',

    //ProjectListComponent
    featured: 'Ausgewählt',
    classroom: 'Klassenzimmer',
    slug_text: 'slug',
    project: 'Projekt',
    projects: 'Projekte',
    preview: 'Vorschau',

    //ProjectAccessComponent
    access_project: 'Zugang zum Kurs',
    draft: 'Entwurf',
    draft_text: 'Dieses Projekt wird nicht in den Katalog aufgenommen und kann bearbeitet werden.',
    published: 'Veröffentlicht',
    published_text: 'Dieses Projekt ist veröffentlicht und die Benutzer können darauf zugreifen',
    private: 'Privat',
    private_text: 'Dieses Projekt ist privat, die Benutzer dieses Kurses werden sich manuell anmelden',
    coming_soon_access: 'Demnächst verfügbar',
    coming_soon_access_text: 'Dieses Projekt wird im Kurskatalog angezeigt, ist aber nicht zugänglich',
    enrollment_closed: 'Immatrikulation geschlossen',
    enrollment_closed_text: 'Nur eingeschriebene Benutzer können den Kurs betreten. Der Kurs wird im Katalog angezeigt, aber neue Benutzer können sich nicht einschreiben.',
    course_expiration: 'Ablauf des Kurses',
    project_expiration_info: 'Ihr Kurs ist während dieser Zeit zugänglich. Nach Ablauf dieses Zeitraums wird den angemeldeten Benutzern der Zugang zum Kurs verweigert.',
    init_date: 'Datum des Beginns',
    project_delete: 'Löschen',
    init_date_selected: 'Gewähltes Startdatum',
    end_date: 'Enddatum',
    date_delete: 'Löschen',
    end_date_selected: 'Ausgewähltes Enddatum',

    //ProjectContentComponent
    new_section: 'Neuer Abschnitt',
    section_title: 'Titel der Sektion: ',
    create_section: 'Abschnitt erstellen',
    import_section: 'Abschnitt "Importieren',
    lesson_title: 'Titel der Lektion:',
    new_lesson: 'Neue Lektion',

    //AttachProductSlideOverComponent
    attach_product_slide: 'Assoziiertes Produkt',
    selected_product: 'Ausgewähltes Produkt',
    quest_attach_product: 'Möchten Sie dieses Produkt anbringen?',

    //ProjectEditContentComponent
    back: 'Zurück',
    select_type_content: 'Wählen Sie die Art des Inhalts',
    type_lesson: 'Art der Lektion: ',
    html_editor: 'HTML-Editor',
    html_info_text: 'Fügen Sie den HTML-Code ein, den Sie in Ihrer Lektion anzeigen möchten',
    insert_video_url: 'Fügen Sie die URL des Videos ein',
    content_index: 'Inhaltsübersicht',
    student_list: 'Liste der Studenten',

    //ProjectLayoutContent
    image: 'Bild',
    classic_design: 'Klassischer Look'
}