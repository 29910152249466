export default {
    home: "Inici",
    projects: "Cursos",
    community: "Comunitat",
    customization: "Personalització",
    reports: "Informes",
    marketing: "Màrqueting",
    settings: "Ajustaments",
    my_account: 'El meu compte',
    annual_sales: "Vendes (Any)",
    sales: "Vendes",
    students: "Estudiants",
    teachers: "Docents",
    products: "Productes",
    project: "Projectes",
    categories: "Categories",
    category: 'Categoria',
    published: "Publicat",
    name: "Nom",
    lastname: 'Cognoms',
    email: "Email",
    phone: "Telèfon",
    title: "Títol",
    pass: 'Contrasenya',
    pass_confirm: 'Confirmació de contrasenya',
    coming_soon: "Próximamente",
    month: "Mes",
    year: "Any",
    date: "Data",
    plan: 'Pla',
    pvp: "PVP",
    net: "Net",
    total: 'Total',
    state: "Estat",
    delete: "Eliminar",
    profile: "Perfil",
    enrollments: "Matriculacions",
    cancel: "Cancel·la",
    save: "Desa",
    remove: 'Esborrar',
    email_address: 'Adreça de correu',
    image_format: 'PNG, JPG, GIF fins a 3MB',
    close: 'Tancar',
    back: 'Tornar',
    coming_soon_brainspro: 'Properament a Brainspro',
    and: 'i',
    product: "Producte",
    date_creation: 'Data de creació',
    type: 'tipus',

    //AppComponent
    my_profile: 'El meu perfil',
    adjust: 'Ajustaments',
    out: 'Sortir',
    search: 'Cerca',
    academy_preview: 'Previsualitzar acadèmia',
    my_academy: 'La meva Acadèmia',
    new_project: 'Nou Curs',
    text_current_period: 'El teu període de prova ',
    text_end_at: ' acaba a ',
    plans: 'Plans de Suscripció',
    billing: 'Facturació',

    //ThankYouPageComponent
    thankyou: 'Gràcies!',
    go_to_academy: "Anar a l'acadèmia",

    //NavigationDesktop - Mobile
    records: 'Enregistraments',
    videos: 'Videos'

}