<template>
  <div class="pt-2 pb-6 py-5 md:mx-8 mx-4">
    <!-- Próximamente -->
    <card-with-header-and-footer-component :loading="general.loading" class="hidden">
      <template v-slot:header>
        <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{$t('projects.access_project')}}</h3>
      </template>
      <div class="col-span-6 grid grid-cols-2 gap-4 ">
        <div class="form-check">
          <input value="1" checked id="access_draft" v-model="this.access.selected" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
          <label class=" inline-block text-gray-800" >
            {{$t('projects.draft')}}
          </label>
          <p class="text-gray-400 text-sm ml-6">{{$t('projects.draft_text')}}</p>
        </div>
        <div class="form-check">
          <input value="2" id="access_public" v-model="this.access.selected" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
          <label class=" inline-block text-gray-800" >
            {{$t('projects.published')}}
          </label>
          <p class="text-gray-400 text-sm ml-6">{{$t('projects.published_text')}}</p>
        </div>
        <div class="form-check">
          <input value="3" id="access_private" v-model="this.access.selected" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
          <label class=" inline-block text-gray-800" >
            {{$t('projects.private')}}
          </label>
          <p class="text-gray-400 text-sm ml-6">{{$t('projects.private_text')}}</p>
        </div>
        <div class="form-check">
          <input value="4" id="access_coming_soon" v-model="this.access.selected" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
          <label class=" inline-block text-gray-800" >
            {{$t('projects.coming_soon_access')}}
          </label>
          <p class="text-gray-400 text-sm ml-6">{{$t('projects.coming_soon_access_text')}}</p>
        </div>
        <div class="form-check">
          <input value="5" id="acces_enrollment_closed" v-model="this.access.selected" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
          <label class=" flex text-gray-800" >
            {{$t('projects.enrollment_closed')}}
          </label>
          <p class="text-gray-400 text-sm ml-6">{{$t('projects.enrollment_closed_text')}}</p>
        </div>
      </div>
      <template v-slot:footer>
        <button type="button" v-on:click="cancelClickOnAccess" :disabled="general.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="saveClickOnAccess" :disabled="general.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>

    <div class="bg-white shadow-inner rounded-lg shadow-md divide-y">
      <div class="p-6">
        <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">Configuración de matriculación</h3>
      </div>

      <div v-if="this.projectStore.project.enrol_configuration.time_start_value === 0 && this.projectStore.project.enrol_configuration.time_end_value === 0 && !showConfiguration" class="px-6">
        <alert-component class="mt-8">
          Haz clic en el botón para crear una configuración de matriculación. Esto hará que los alumnos inicien y finalicen la matriculación en el curso según la configuración.
        </alert-component>
        <div class="flex justify-center py-8">
          <button v-on:click="showConfiguration = true" class="inline-flex items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-brainspro-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            Configurar matriculación
          </button>
        </div>
      </div>

      <div v-else class="px-6">
        <alert-component class="mt-8">
          {{$t('projects.project_expiration_info')}}
        </alert-component>

        <div class="flex flex-col lg:flex-row w-full ">
          <div class="basis-1/2 pt-6 pb-4 sm:mr-8">
            <label class="block text-gray-600 text-sm font-bold mb-2" for="start_date">Tiempo Inicio</label>
            <div class="flex">
              <!-- Colocar inputs de inicio de la matrícula -->
              <input v-model="this.projectStore.project.enrol_configuration.time_start_value" type="number" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
              <select required
                      v-model="this.projectStore.project.enrol_configuration.time_start_unit"
                      class="ml-8 form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                <option disabled selected label="Unidad de tiempo"></option>
                <option value="hours">Horas</option>
                <option value="days">Días</option>
                <option value="weeks">Semanas</option>
                <option value="mounths">Meses</option>
                <option value="years">Años</option>
              </select>
          </div>
          <div class="basis-1/2 pt-6 pb-4">
            <label class="block text-gray-600 text-sm font-bold mb-2" for="end_date">Tiempo Fin</label>
            <div class="flex">
              <!-- Colocar inputs de inicio de la matrícula -->
              <input v-model="this.projectStore.project.enrol_configuration.time_end_value" type="number" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
              <select required
                      v-model="this.projectStore.project.enrol_configuration.time_end_unit"
                      class="ml-8 form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                <option disabled selected label="Unidad de tiempo"></option>
                <option value="hours">Horas</option>
                <option value="days">Días</option>
                <option value="weeks">Semanas</option>
                <option value="months">Meses</option>
                <option value="years">Años</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 my-8">
        <div class="py-4 flex justify-end">
            <button v-if="this.projectStore.project.enrol_configuration.time_start_value !== 0 || this.projectStore.project.enrol_configuration.time_end_value !== 0" type="button" v-on:click="deleteEnrolConfiguration" :disabled="general.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-red-500 bg-white hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              Eliminar
            </button>
            <button v-else type="button" v-on:click="cancelEnrolConfiguration" :disabled="general.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
              {{ $t('general.cancel') }}
            </button>
            <button type="button" v-on:click="saveEnrolConfiguration" :disabled="general.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
              {{ $t('general.save') }}
            </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import AlertComponent from "@/components/feedback/alerts/AlertComponent";
import {useProjectsStore} from "@/stores/projects";
import ProjectService from "@/services/project/ProjectService";

export default {
  name: "ProjectAccessComponent.vue",
  components: {CardWithHeaderAndFooterComponent, AlertComponent},
  data() {
    return {
      general: {
        loading: false,
      },
      access: {
        selected: false,
      },
      showConfiguration: false,
    }
  },
  setup(){
    const projectStore = useProjectsStore()

    return {
      projectStore
    }
  },
  methods: {
    cancelClickOnAccess() {
      console.log("Se ha pulsado el botón cancel")
      //Obtendrá el acceso guardado anteriormente para dicho curso.
    },
    saveClickOnAccess() {
      console.log("Se ha pulsado el botón save")
      //Guardará el tipo de acceso para el curso. Los cursos deben crearse con un tipo de acceso (draft)
    },
    cancelEnrolConfiguration() {
      console.log("Se ha pulsado el botón cancel")
      if (this.projectStore.project.enrol_configuration.time_start_value === 0 &&
          this.projectStore.project.enrol_configuration.time_end_value === 0 &&
          this.showConfiguration)
      {
        this.showConfiguration = false
      }
    },
    saveEnrolConfiguration() {
      //Cargar datos endpoint update.
      this.general.loading = true
      let projectUuid = this.$route.params.id
      let enrolConfiguration = this.projectStore.project.enrol_configuration
      ProjectService.postEnrolConfiguration(projectUuid, enrolConfiguration)
          .then((response) => {
            console.log("La respuesta de guardar la configuración de matriculación es: ", response)
          })
          .catch((error) =>{
            console.log("Error al guardar la configuración de matriculación: ", error)
          })
          .finally(() => {
            this.general.loading = false
          })
    },
    deleteEnrolConfiguration() {
      this.general.loading = true
      let projectUuid = this.$route.params.id
      ProjectService.deleteEnrolConfiguration(projectUuid)
          .then((response) => {
            console.log("Se ha borrado la configuración de matriculación correctamente", response)
            this.projectStore.project.enrol_configuration.time_start_value = 0
            this.projectStore.project.enrol_configuration.time_start_unit = 'hours'
            this.projectStore.project.enrol_configuration.time_end_value = 0
            this.projectStore.project.enrol_configuration.time_end_unit = 'hours'
            this.showConfiguration = false
          })
          .catch((error) => {
            console.log("Error al borrar la configuración de matriculación: ", error)
          })
          .finally(() => {
            this.general.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>