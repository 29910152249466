<template>
  <div class="pt-2 pb-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <section class="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-4">

      </section>

      <!-- Replace with your content -->
      <div class="py-4 hidden md:block">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div v-if="!loading" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.date") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("sales.buyer") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.product") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.state") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.pvp") }}
                  </th>
                  <th class="hidden px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.net") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="subscription in subscriptions" v-show="subscription.customer !== null" :key="subscription.uuid">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-500">{{ $moment(subscription.created_at).format('DD/MM/YYYY H:mm:ss') }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div v-if="subscription.customer !== null" class="text-sm leading-5 font-medium text-gray-900">{{subscription.customer.firstname + ' ' + subscription.customer.lastname}}</div>
                  </td>
                  <td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 text-gray-500">
                    {{ subscription.title }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <span v-if="subscription.status == 'active'" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800">{{ $t("sales.activated") }}</span>
                    <span v-if="subscription.status == 'canceled'" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-red-100 text-red-800">{{ $t("sales.canceled") }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ subscription.total }}
                  </td>
                  <td class="hidden px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500"></td>
                  <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <a href="#" class="hidden text-blue-600 hover:text-blue-900 hover:underline">{{ $t("sales.view_order") }}</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <loading-component v-else>{{ $t("sales.loading_subscriptions") }}</loading-component>
          </div>
        </div>
      </div>
      <!--<PaginationComponent></PaginationComponent>-->
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import ProductSubscriptionService from "../../services/report/ProductSubscriptionService";
import LoadingComponent from "@/views/common/LoadingComponent";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "ProductSubscriptionListComponent",
  components: {LoadingComponent},
  data() {
    return {
      loading: true,
      subscriptions: [],
      stats: {
        loading: true,
        SalesPVPCurrentMonth: 0,
        SalesNetCurrentMonth: 0,
        RefundsPVPCurrentMonth: 0,
        RefundsNetCurrentMonth: 0
      }
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  created() {
    this.getProductSubscriptions()
  },
  methods: {
    getProductSubscriptions() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      ProductSubscriptionService.index(academy_uuid).then((response) => {
        console.log("getSubscriptions OK", response)
        this.subscriptions = response.data.data
      })
      .catch((response) => {
        console.log("Ha ocurrido un error en getSubscriptions", response)
      })
      .finally(() => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>