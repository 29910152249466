<template>
  <div class="py-4">
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div v-if="!loading" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg justify-center">
          <div class="border border-gray-200 mb-4" v-for="order in orders" :key="order.uuid">
            <div class="px-6 py-4 whitespace-no-wrap">
              <div class="text-md leading-5 font-medium text-gray-900">{{ order.number }}</div>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('general.date')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap ">
              <div class="text-md leading-5 font-medium text-gray-900">{{ $moment(order.completed_at).format('DD/MM/YYYY H:mm:ss') }}</div>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('sales.buyer')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap">
              <div v-if="order.customer" class="text-md leading-5 font-medium text-gray-900">{{ order.customer.firstname + ' ' + order.customer.lastname }}</div>
              <div v-if="order.customer.email" class="text-md leading-3 font-light text-gray-900">{{ order.customer.email }}</div>
              <div v-if="order.customer.phone" class="text-md leading-3 font-light text-gray-900">{{ order.customer.phone }}</div>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('general.products')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-500">
                  <span v-for="item in order.items" :key="item.uuid">
                    {{item.title}}
                  </span>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('general.total')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-500">
              {{ order.total }}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('general.net')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-500">
              {{ order.net }}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('general.state')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-500">
              <span v-if="order.status == 'completed'" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-md font-medium leading-5 bg-green-100 text-green-800">{{$t('sales.complete')}}</span>
              <span v-if="order.status == 'refunded'" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-md font-medium leading-5 bg-red-100 text-red-800">{{$t('sales.reimbursed')}}</span>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-right text-md leading-5 font-medium">
              <a href="#" class="hidden text-blue-600 hover:text-blue-900 hover:underline">{{$t('sales.view_order')}}</a>
            </div>
          </div>
        </div>
        <loading-component v-else>{{$t('sales.loading_products')}}</loading-component>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/views/common/LoadingComponent";

export default {
  name: "OrdersComponent",
  components: {LoadingComponent},
  props: {
    orders: Array,
    loading: Boolean
  }
}
</script>

<style scoped>

</style>