<template>
  <card-with-header-and-footer-component class="bg-white" :loading="basicData.loading">
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('profile.profile_info') }}</h3>
    </template>

    <!--
    <div class="my-4">
      <label for="user-basicData-name" class="block text-sm font-medium text-gray-700">{{ $t('general.name') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input required id="user-basicData-name" type="text" name="user-basicData-name" v-model="basicData.request.name" class="focus:ring-bpblue focus:border-bpblue block w-full pr-12 sm:text-sm border-gray-300 rounded-md" :placeholder="$t('profile.enter_name')" aria-describedby="price-currency" :class="{'border-2 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500' : v$.coupon.code.$error}">
        <div v-if="basicData.request.name" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
          <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
      </div>
      error
    </div> -->
    <div class="mt-6">
      <label for="name" class="block text-sm font-medium text-gray-700">{{ $t('general.name') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input type="text" v-model="v$.basicData.request.name.$model" id="name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        <div v-if="v$.basicData.request.name.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none focus:border-red-300 focus:text-red-900 focus:placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
          <!-- Heroicon name: solid/exclamation-circle -->
          <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
      </div>
      <p v-if="v$.basicData.request.name.$error" class="mt-2 text-sm text-red-600" id="error-name"> {{$t('profile.error_name')}}</p>
    </div>

    <div class="mt-6">
      <label for="name" class="block text-sm font-medium text-gray-700">{{ $t('general.lastname') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input type="text" v-model="v$.basicData.request.lastname.$model" id="lastname" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        <div v-if="v$.basicData.request.lastname.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none focus:border-red-300 focus:text-red-900 focus:placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
          <!-- Heroicon name: solid/exclamation-circle -->
          <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
      </div>
      <p v-if="v$.basicData.request.lastname.$error" class="mt-2 text-sm text-red-600" id="error-lastname"> {{$t('profile.error_lastname')}}</p>
    </div>

    <div class="my-4">
      <label for="user-basicData-email" class="block text-sm font-medium text-gray-700">{{ $t('general.email') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input type="text" name="user-basicData-lastname" id="user-basicData-email" v-model="basicData.request.email" disabled class="focus:ring-bpblue bg-gray-50 text-gray-500 focus:border-bpblue block w-full pr-12 sm:text-sm border-gray-300 rounded-md" aria-describedby="price-currency">
      </div>
    </div>

    <template v-slot:footer>
      <button type="button" v-on:click="getBasicData" :disabled="basicData.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.cancel') }}
      </button>

      <button type="button" v-on:click="setBasicData" :disabled="basicData.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.save') }}
      </button>
    </template>
  </card-with-header-and-footer-component>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import BasicDataService from "@/services/user/BasicDataService";
import NotificationService from "@/services/notification/NotificationService";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";

export default {
name: "BasicDataComponent",
components: {CardWithHeaderAndFooterComponent},
data() {
  return {
    basicData: {
      loading: true,
      request: {
        name: '',
        lastname: '',
        email: '',
      }
    }
  }
},
  setup () {

    const authUserStore = useAuthUserStore();
    const academiesStore = useAcademiesStore()

    return {
      authUserStore,
      academiesStore,
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      basicData: {
        request: {
          name: {
            required
          },
          lastname: {
            required
          },
        }
      }
    }
  },

created() {
  this.getBasicData()
},
methods: {
  getBasicData() {
    this.basicData.loading = true;

    BasicDataService.index()
        .then((response) => {
          console.log("Se ha obtenido los datos de usuario correctamente", response)
          this.basicData.request = response.data
        })
        .catch((response) => {
          console.log("Error obteniendo los datos de usuario", response)
          NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
        })
        .finally(() => {
          this.basicData.loading = false
        })
  },
  setBasicData() {
    let academy_uuid = this.authUserStore.user.academy.uuid;
    this.basicData.loading = true;

    BasicDataService.update(academy_uuid, this.basicData.request)
        .then((response) => {
          console.log("Se ha almacenado los datos de usuario correctamente", response)
          NotificationService.showNotification("Se ha almacenado los datos de usuario correctamente", "success")

          this.academiesStore.getAcademy()
        })
        .catch((error) => {
          console.log("Error almacenando los datos de usuario", error.response)
          if (error.response.data.errors) {
            error.response.data.errors.forEach((error) => {
              NotificationService.showNotification(error, "error")
            })
          } else {
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          }
        })
        .finally(() => {
          this.basicData.loading = false
        })
  }
}

}
</script>

<style scoped>

</style>