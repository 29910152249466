<template>
  <section class="max-w-7xl mx-auto py-6 md:py-6 sm:px-8">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex overflow-hidden">
      <div class="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div class="flex-1 relative z-0 lg:flex lg:overflow-hidden">
          <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
            <!-- Start main area-->
            <div class="inset-0 px-4 sm:px-6 lg:px-8">
              <!-- class border-dashed border-2 border-gray-200 rounded-lg -->
              <div class="h-full">
                <div class="relative xl:flex xl:flex-col flex-shrink-0">
                  <section class="mb-10">
                    <card-with-header-and-footer-component :loading="false">
                      <template v-slot:header>
                        <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">Información de la aplicación</h3>
                      </template>

                      <div class="flex flex-row">
                        <label class="block text-gray-500">Nombre de la aplicación: </label>
                        <label class="font-semibold text-gray-700 ml-2">BrainsPro</label>
                      </div>

                      <div class="flex flex-row mt-4">
                        <label class="block text-gray-500">Fabricante: </label>
                        <label class="font-semibold text-gray-700 ml-2">Brainspro Enseña Online SL</label>
                      </div>

                      <div class="flex flex-row mt-4">
                        <label class="block text-gray-500">Versión: </label>
                        <label class="font-semibold text-gray-700 ml-2">2.1</label>
                      </div>

                        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-5">
                          <div v-if="this.updates.length > 0" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                            <table class="min-w-full">
                              <thead>
                              <tr>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Versión
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Fecha
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Recurso
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Descripción
                                </th>
                                <th class="border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  <!-- Columna para completar el espacio -->
                                </th>
                              </tr>
                              </thead>
                              <tbody v-if="updates" class="">
                              <tr v-for="update in this.updates" :key="update.uuid">
                                <td class="text-left px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-400">
                                  {{update.version}}
                                </td>
                                <td class="text-left px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-400">
                                  {{update.date}}
                                </td>
                                <td class="text-left px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-400">
                                  {{update.source}}
                                </td>
                                <td class="text-left px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-400">
                                  {{update.description}}
                                </td>
                                <td class="text-left px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-400">
                                  <div v-if="update === this.updates[0]" class="flex flex-row text-left">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-green-600 w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                    </svg>
                                    <label class="font-semibold text-green-700 mt-0.5">Activa</label>
                                  </div>
                                </td>

                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      <template v-slot:footer>
                        <label class="flex justify-center text-xs text-gray-400">Bienvenido a la versión 2.0 de BrainsPro. Este 2024 BrainsPro añade la versión 2.0, gracias por confiar en nosotros.</label>
                      </template>
                    </card-with-header-and-footer-component>
                  </section>

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";

export default {
  name: "InformationComponent",
  components: {CardWithHeaderAndFooterComponent},
  data() {
    return {
      updates: null,
      loading: false
    }
  },
  created() {
    this.updates = [
      {
        uuid: '45iuyh-dfg4-tyhju6-4566h',
        version: '2.1.0',
        date: '11/03/2024 17:42:33',
        source: 'Master',
        description: 'Inclusión de gestión de grupos de alumnos vía API.',
      },
      {
        uuid: '486b-dasd-tyhjaa1-kn56',
        version: '2.0.1',
        date: '24/02/2024 11:36:54',
        source: 'Master',
        description: 'Corrección de errores en componentes de video y mejora de rendimiento.'
      },
      {
        uuid: '4654hb-g422s-fg665-hkg21',
        version: '2.0.0',
        date: '5/02/2024 18:41:27',
        source: 'Master',
        description: 'Actualización masiva de librería y mejora de rendimiento general.'
      }
    ]

        console.log("El contenido de updates es: ", this.updates)
  }
}
</script>

<style scoped>

</style>