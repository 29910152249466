<template>
  <div class="max-w-7xl mx-auto py-3 md:py-6 sm:px-8 lg:pt-8">
    <header-component title="Marketing"></header-component>

    <nav class="mt-4 lg:ml-8 ml-4 mb-8">
      <div class="block">
        <div class="">
          <nav class="-mb-px flex justify-center lg:justify-start lg:space-x-8 flex-col md:flex md:flex-row" aria-label="Tabs">
            <router-link to="/marketing/" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="shopping-cart" size="lg" />
              <span>{{ $t("marketing.coupons") }}</span>
            </router-link>

            <router-link to="/marketing/scripts/" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="bolt" size="lg" />
              <span>{{ $t("marketing.scripts") }}</span>
            </router-link>

            <router-link to="/marketing/integrations/" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
              </svg>
              <span>{{$t('marketing.integrations')}}</span>
            </router-link>
          </nav>
        </div>
      </div>
    </nav>

    <router-view></router-view>

  </div>
</template>

<script>
import HeaderComponent from "@/views/common/HeaderComponent";

export default {
  name: "MarketingComponent",
  components: {
    HeaderComponent
  }
}
</script>

<style scoped>

</style>