<template>
  <div class="max-w-5xl mx-auto px-4 sm:px-6 mb-12">
    <div class="mt-4 lg:ml-8 ml-4 mb-8">
      <div class="block">
        <main class="" tabindex="0">
            <!-- Tabs -->
            <div class="mt-6 sm:mt-2 2xl:mt-5">
                <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <nav class="-mb-px flex mt-8 space-x-8" aria-label="Tabs">
                    <!-- Current: "border-pink-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                    <router-link :to="{ name: 'user-profile.data'}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                      <svg class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                           aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                      </svg>
                      {{ $t("general.profile")}}
                    </router-link>
                    <router-link :to="{ name: 'user-profile.billing-address'}" active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                      <svg class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                           aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
                      </svg>
                        {{ $t("community.billing_data") }}
                    </router-link>

                    <!--
                    <router-link :to="{ name:'user.groups' }"
                                 active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm"
                                 class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                                 aria-current="page">
                      <svg class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                      </svg>
                      <span class="truncate">
                          {{ $t("community.groups") }}
                        </span>
                    </router-link>
                    -->

                    <a href="#" class="hidden border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                      {{ $t("general.enrollments")}}
                    </a>

                    <a href="#" class="hidden border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                      {{ $t("community.orders")}}
                    </a>
                  </nav>
                </div>
            </div>
          <router-view></router-view>

        </main>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user/UserService";
import {useAuthUserStore} from "@/stores/auth";
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import notificationService from "../../services/notification/NotificationService";


export default {
name: "UserProfileComponent",
  components: {

  },
  data: function ()
  {
    return {
      showModalDeleteUser: false,
      doubleConfirmationDeleteUser: false,
      loading: false,
    }
  },
  computed: {
    nameComplete() {
      return this.user.firstname + ' ' + this.user.lastname
    }
  },
  setup(){
    const authUserStore = useAuthUserStore();
    const navigationMenuStore = useNavigationMenuStore()

    return {
      authUserStore,
      navigationMenuStore
    }
  },
  created() {

  },
  methods: {
    confirmModalDeleteUser() {
      if(this.doubleConfirmationDeleteUser)
      {
        this.showModalDeleteUser = false
        this.doubleConfirmationDeleteUser = false
        this.deleteUser()
      }
    },
    closeModalDeleteUser() {
      this.doubleConfirmationDeleteUser = false
      return this.showModalDeleteUser = false
    },
    deleteUser() {
      this.loading = true
      this.doubleConfirmationDeleteUser = false

      userService.delete()
          .then((response) => {
            console.log("Delete user devuelve: ", response)
            //Una vez borrado...
            notificationService.showNotification("El usuario se ha eliminado correctamente", "success")
            this.$router.go(-1)
          })
          .catch((error) => {
            console.log("Hubo un error: ", error)
          })
          .finally(() => {
            this.loading = false
          })

      //Notificar del éxito o error al borrar el usuario y enviar a la lista de usuarios
      notificationService.showNotification("El usuario se ha eliminado correctamente", "success")
      this.$router.go(-1)

    }
  }
}
</script>

<style scoped>

</style>