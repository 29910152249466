export default {
    your_users: "Comunitat",
    massive_increase: "Pujada massiva",
    new_user: "Nou usuari",
    placeholder_search: "Busca el teu usuari...",
    all: "Tots",
    project_name: "Nom del curs",
    updated_at: "Actualitzat el ",
    view_profile: "Veure perfil",
    write_email: "Escriure Mail",
    edit_user: "Editar usuari",
    call: "Trucar",
    orders: "Comandes",
    dni: "DNI / CIF / NIE",
    search_user: 'Cerca un usuari...',
    new_project: 'Nou Curs',
    delete_user: 'Eliminar Usuari',
    warning: 'Atenció!',
    message_delete: "En eliminar l'usuari perdràs totes les dades definitivament.",
    question_delete: "Segur que vols eliminar l'usuari ",
    delete: 'Eliminar',
    second_confirmation: "(*) Aquesta casella és obligatòria per eliminar l'usuari",
    loading_users: 'Carregant usuaris...',

    //MassUserImportComponent
    beta_version: 'Beta',
    mass_enrollment: "Matriculació Masiva",
    functionality: "Funcionalitat per a plans",
    advance: "Avançat",
    pro: "Pro",
    limitation_mass_enrollment: "Limitat a matriculacions de fins a 50 usuaris. La resta s'ignoraran.",
    download_template: "Descarrega la teva plantilla de matriculació",
    upload_students_list: "Puja la llista d'alumnes",
    drag_drop: "o arrossega-la i deixa-la anar aquí",
    format: "XLSX fins a 3MB",
    enroll_in: "Matricular a:",
    select_product: "Selecciona un curs...",
    upload_users: "Carregar usuaris",
    import_users: "Importar usuaris",
    href_massive_enroll: "Més sobre la importació massiva dusuaris.",

    //UserEnrollListComponent
    to_cancel: 'Donar de baixa',
    no_enrolls: 'Aquest usuari no està matriculat a cap curs',
    modal_title: 'Desmatricular',
    modal_warning: "Aquest procés no es pot revertir, l'alumne perdrà tot el progrés i no es podrà recuperar.",
    modal_question: 'Segur que vols desmatricular aquest usuari del curs ',
    loading_enrols: 'Carregant matriculacions...',
    processing: 'Processant...',
    access_denied: 'Accés denegat al curs',

    //UserEnrolSlideOverComponent
    select_project: 'Selecciona un projecte',
    loading_projects: 'Carregant projectes...',
    project_selected: 'Projecte seleccionat',
    send_users_notification: "Envieu a l'usuari una notificació de matriculació.",
    back: 'Tornar',
    enrol: 'Matricular',

    //BillingAddressComponent
    tax_data: 'Dades fiscals',
    company_name: 'Raó Social',
    vat_number_id: 'CIF / DNI',
    address_line_1: 'Direcció Línia 1',
    address_line_2: 'Direcció Línia 2',
    zip_code: 'Codi postal',
    city: 'Ciutat',
    province: 'Província',
    country: 'País',

    //UserProfileComponent
    save_edition: 'Desa edició',
    billing_data: 'Dades de Facturació',
    groups: 'Grups',
    in_progress: 'En progrés',
    completed: 'Completat',

    //Groups
    your_groups: 'Els teus grups',
    new_group: 'Nou grup',
    loading_groups: 'Carregant grups',
    name: 'Nom',
    projects: 'Projectes',
    users: 'Usuaris',
    last_update: 'Última actualització',
}