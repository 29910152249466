<template>
      <aside class="max-w-7xl mx-auto py-6 md:py-6 sm:px-8">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="flex overflow-hidden">
          <div class="flex flex-col min-w-0 flex-1 overflow-hidden">
            <div class="flex-1 relative z-0 lg:flex lg:overflow-hidden">
              <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
                <!-- Start main area-->
                <div class="inset-0 px-4 sm:px-6 lg:px-8">
                  <!-- class border-dashed border-2 border-gray-200 rounded-lg -->
                  <div class="h-full">
                    <div class="relative xl:flex xl:flex-col flex-shrink-0">
                      <section class="mb-10">
                        <basic-data-component></basic-data-component>
                      </section>
                      <section class="mb-10">
                        <change-password-component></change-password-component>
                      </section>
                      <section class="mb-10">
                        <bank-account-component></bank-account-component>
                      </section>

                    </div>
                  </div>
                </div>
                <!-- End main area -->
              </main>
              <aside class="relative xl:flex xl:flex-col flex-shrink-0 border-l border-gray-200">
                <!-- Start secondary column (hidden on smaller screens) -->
                <div class="inset-0 py-6 px-4 sm:px-6 lg:px-8">
                  <!-- class border-dashed border-2 border-gray-200 rounded-lg -->
                  <div class="h-full">
                    <user-avatar-component></user-avatar-component>
                  </div>
                </div>
                <!-- End secondary column -->
              </aside>
            </div>
          </div>
        </div>
        <router-view></router-view>
      </aside>
</template>

<script>
import UserAvatarComponent from "@/views/profile/avatar/UserAvatarComponent";
import BankAccountComponent from "@/views/profile/BankAccountComponent";
import ChangePasswordComponent from "@/views/profile/ChangePasswordComponent";
import BasicDataComponent from "@/views/profile/BasicDataComponent";

export default {
  name: "ProfileEditComponent",
  components: {
    BasicDataComponent,
    ChangePasswordComponent,
    BankAccountComponent,
    UserAvatarComponent,

  }
}
</script>

<style scoped>

</style>