<template>
  <div class="">
    <div class="flex flex-col min-w-0 flex-1 ">

      <div class="flex-1 relative z-0 flex ">
        <main class="flex-1 relative z-0 focus:outline-none xl:order-last" tabindex="0">
          <card-with-header-and-footer-component class="max-w-2xl mx-auto" :loading="general.loading" :blocked="general.blocked">
            <template v-slot:header>
              <div class="w-full block">
                <h3 class="text-lg font-semibold pt-1 leading-5 text-gray-900 sm:truncate">{{ $t('marketing.coupon_data') }}</h3>
                <div class="inset-y-0 right-0 flex justify-end -mt-5 text-red-600">
                  <button title="Delete" id="trash-delete" @click="this.general.showModalDeleteCoupon = true" :disabled="general.loading" aria-label="Borrar Cupón">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </template>
            <!-- Modal Component -->
            <Teleport to="body">
              <modal-component id="modal-coupon" v-if="general.showModalDeleteCoupon" :onProcess="confirmModalDeleteCoupon" :onCancel="closeModalDeleteCoupon" :loading="false">
                <template v-slot:header>{{$t('marketing.delete_coupon')}}</template>
                <p>{{$t('marketing.info_delete_coupon')}}</p>
                <p class="font-bold mt-2">{{$t('marketing.quest_delete_coupon')}}</p>
              </modal-component>
            </Teleport>

            <div class="col-span-6">
              <label for="coupon_code" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('marketing.code') }}</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input required id="coupon_code" autofocus v-model="v$.coupon.code.$model" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-transparent" v-if="{'border-2 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500' : v$.coupon.code.$error}">
                <div v-if="v$.coupon.code.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                  <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
              <p v-if="v$.coupon.code.$error" class="mt-2 text-sm text-red-600" id="error-code"> {{$t('marketing.error_code_required')}}</p>
            </div>

            <div class="mt-5">
              <label for="coupon_amount" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('marketing.quantity') }}</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input required id="coupon_amount" v-model="v$.coupon.amount.$model" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-transparent" v-if="{'border-2 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500' : v$.coupon.code.$error}">
                <div v-if="v$.coupon.amount.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                  <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
              <p v-if="v$.coupon.amount.$error" class="mt-2 text-sm text-red-600" id="error-amount"> {{$t('marketing.error_amount_required')}}</p>
            </div>

            <div class="mt-5">
              <label for="coupon_type" class="block text-sm font-medium text-gray-700">{{ $t('marketing.offer_type') }}</label>
              <select id="coupon_type" v-model="coupon.coupon_type.id" name="category" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-bpblue focus:border-bpblue sm:text-sm rounded-md">
                <option :value="''">{{ $t('marketing.offer_text') }}</option>
                <option :value="1">{{ $t('marketing.percent_offer') }}</option>
                <option :value="3">{{ $t('marketing.fixed_offer') }}</option>
              </select>
            </div>

            <div class=" hidden flex flex-col lg:flex-row w-full ">
              <form class="basis-1/2 pt-6 pb-4 sm:mr-8" @submit.prevent>
                <label class="block text-gray-600 text-sm font-bold mb-2" for="date">{{$t('projects.init_date')}}</label>
                <div class="flex w-full">
                  <v-date-picker mode="dateTime" v-model="coupon.starts_at" ref="startCalendar" class="flex-grow">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input id="dateStart" class="bg-white text-gray-700 w-full py-2 px-3 appearance-none border rounded-l focus:outline-none"
                             :class="{ 'border-red-600': errorMessageStart }"
                             :value="inputValue"
                             v-on="inputEvents"/>
                    </template>
                  </v-date-picker>
                  <button type="button" class="text-white font-bold py-2 px-4 rounded-r user-select-none focus:outline-none"
                          :class="coupon.starts_at ? 'bg-red-600' : 'bg-red-300'"
                          :disabled="!coupon.starts_at"
                          @click="coupon.starts_at = null">{{$t('projects.project_delete')}}</button>
                </div>
                <p class="text-red-600 text-xs italic mt-1" v-if="errorMessageStart">
                  {{ errorMessageStart }}
                </p>
                <p class="text-blue-500 text-xs font-bold mt-1" v-else>
                  {{$t('projects.init_date_selected')}}
                </p>
              </form>
              <form class="basis-1/2 pt-6 pb-4" @submit.prevent>
                <label class="block text-gray-600 text-sm font-bold mb-2" for="date">{{$t('projects.end_date')}}</label>
                <div class="flex w-full">
                  <v-date-picker mode="dateTime" :min-date='coupon.starts_at' v-model="coupon.expires_at" class="flex-grow">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input id="dateEnd" class="bg-white text-gray-700 w-full py-2 px-3 appearance-none border rounded-l focus:outline-none"
                             :class="{ 'border-red-600': errorMessageEnd }"
                             :value="inputValue"
                             v-on="inputEvents"/>
                    </template>
                  </v-date-picker>
                  <button type="button" class="text-white font-bold py-1 px-4 rounded-r user-select-none focus:outline-none"
                          :class="coupon.expires_at ? 'bg-red-600' : 'bg-red-300'"
                          :disabled="!coupon.expires_at"
                          @click="coupon.expires_at = null">{{$t('projects.date_delete')}}</button>
                </div>
                <p class="text-red-600 text-xs italic mt-1" v-if="errorMessageEnd">
                  {{ errorMessageEnd }}
                </p>
                <p class="text-blue-500 text-xs font-bold mt-1" v-else>
                  {{$t('projects.end_date_selected')}}
                </p>
              </form>
            </div>

            <div class="hidden col-span-6 mt-5">
              <label for="product_duration" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('marketing.duration') }}</label>
              <input id="product_duration"  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            </div>

            <template v-slot:footer>
              <button type="button" v-on:click="cancelButton" :disabled="general.loading || general.blocked" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brainspro-500">
                {{ $t('general.cancel') }}
              </button>

              <button type="button" v-on:click="setCoupon" :disabled="general.loading || general.blocked" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brainspro-600 hover:bg-brainspro-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brainspro-500">
                {{ $t('general.save') }}
              </button>
            </template>
          </card-with-header-and-footer-component>

          <CouponProductListComponent v-if="$route.params.id" :products="coupon.products" :key="coupon.products.length"></CouponProductListComponent>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import CouponProductListComponent from "@/views/marketing/products/CouponProductListComponent";
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import couponService from "@/services/marketing/CouponService";
import NotificationService from "@/services/notification/NotificationService";
import useVuelidate from "@vuelidate/core";
import {maxLength, numeric, required} from "@vuelidate/validators";
import {useAuthUserStore} from "@/stores/auth";
import ModalComponent from "@/components/modals/ModalComponent";
import CouponService from "../../services/marketing/CouponService";

export default {
  name: "CouponComponent",
  components: {CouponProductListComponent, CardWithHeaderAndFooterComponent, ModalComponent},
  data: function ()
  {
    return {
      general : {
        blocked: false,
        loading: true,
        showModalDeleteCoupon: false,
      },
      calendar: {
        dateStart: null,
        dateEnd: null,
      },
      coupon: {
        uuid: null,
        academy_uuid: null,
        code: null,
        amount: null,
        starts_at: null,
        expires_at: null,
        coupon_type: {

        },
        products: [],
        created_at: null,
        deleted_at: null,
        updated_at: null
      }
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore,
      v$: useVuelidate()
    }
  },
  computed: {
    errorMessageStart() {
      if (!this.coupon.starts_at) return 'Es necesario seleccionar una fecha de inicio.';
      return '';
    },
    errorMessageEnd() {
      if (!this.coupon.expires_at) return 'Es necesario seleccionar una fecha de fin.';
      return '';
    },
  },
  validations () {
    return {
      coupon: {
        code: {
          required,
          maxLengthValue: maxLength(255)
        },
        amount: {
          required,
          numeric,
        }
      }
    }
  },
  created() {
    console.log("Loading coupon")
    this.getCoupon()
  },
  mounted() {

  },
  methods: {
    getCoupon() {
      this.general.blocked =
          this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.key !== "plan_pro" &&
          this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.key !== "plan_avanzado"

      couponService.show(this.$route.params.id).
        then((response) => {
              console.log("Se han obtenido los datos del coupon", response)
              this.coupon = response.data
        })
        .catch(() => {

        })
        .finally(() => {
          this.general.loading = false
        })
    },
    closeModalDeleteCoupon() {
      this.general.showModalDeleteCoupon = false
    },
    confirmModalDeleteCoupon() {
      console.log("Procesando el borrado del cupón: ", this.coupon.uuid )
      this.coupon.academy_uuid = this.authUserStore.user.academy.uuid
      this.general.showModalDeleteCoupon = false
      //this.general.loading = true

      CouponService.delete(this.coupon)
        .then((response) => {
          console.log("La respuesta es: ", response.data)
          //Enviará a la página anterior
          this.$router.go(-1)
        })
        .catch((response) => {
          console.log("Error borrando el cupón: ", response)
          NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
        })
        .finally(() => {
          //Silence is golden
          this.general.loading = false
        })
    },
    setCoupon() {
      console.log("Saving coupon...")
      this.general.loading = true
      this.coupon.academy_uuid = this.authUserStore.user.academy.uuid

      if (this.$route.params.id)
      {
        couponService.update(this.coupon)
        .then((response) => {
          console.log("Se han guardado los datos del cupon", response)
          this.coupon = response.data

          NotificationService.showNotification("La operación se ha realizado correctamente", "success")
        })
            .catch((error) => {
              console.log("Ha ocurrido un error almacenando el producto", error)
              if (error.response.data.errors)
              {
                error.response.data.errors.forEach( (error) => {
                  NotificationService.showNotification(error, "error")
                })
              }
              else
              {
                NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
              }
            })
            .finally(() => {
              this.general.loading = false
              this.$router.go(-1)
            })
      }
      else
      {
        couponService.store(this.coupon).
        then((response) => {
          console.log("Se han guardado los datos del cupon", response)
          this.coupon = response.data

          NotificationService.showNotification("La operación se ha realizado correctamente", "success")
        })
            .catch((error) => {
              console.log("Ha ocurrido un error almacenando el producto", error)
              if (error.response.data.errors)
              {
                error.response.data.errors.forEach( (error) => {
                  NotificationService.showNotification(error, "error")
                })
              }
              else
              {
                NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
              }
            })
            .finally(() => {
              this.general.loading = false

              if (this.coupon)
              {
                this.$router.push({ name: 'marketing.coupon', params: { id : this.coupon.coupon_uuid }})
                this.getCoupon()
                this.$router.go(-1)
              }
            })
      }
    },
    cancelButton()
    {
      this.$router.go(-1)
    },
  },

}
</script>

<style scoped>

</style>