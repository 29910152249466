export default {
    //AcademyComponent
    customization: 'Personaliza tu academia',
    domain: "Dominio",
    brand_logo: "Logo y Marca",
    rrss_contact: "Contacto y RRSS",
    legal: "Legal",
    options: 'Opciones',

    //DomainComponent
    your_domain: "Tu dominio mybrainspro.com",
    example_url_placeholder: 'https://',
    example_2: '.mybrainspro.com',
    info_domain: "Escribe el dominio SIN símbolos (.,-¿?!·$%&/()...), ni acentos ni espacios en blanco. No hace falta que pongas www.",
    domain_input: "Dominio mybrainspro.com",
    custom_domain: "Dominio personalizado",
    domain_text: 'Dominio',
    info_domain2: "Introduce tu dominio (www.tudominio.com) para utilizarlo como página principal o como página secundaria (tuescuela.tudominio.com)",
    attention: "Atención",
    info_attention: "Sólo disponible para plan AVANZADO y plan PRO",
    activate_domain: "Para activar tu dominio en BrainsPro: ",
    info_activate_1line: "Visita la plataforma donde compraste tu dominio y busca la configuración de DNS.",
    info_activate_2line: "Añade el registro de tipo A a la IP: 76.223.11.227",
    info_activate_3line: "Ten en cuenta que pueden pasar 48 horas hasta que estos cambios se hagan efectivos y se propagen en tu DNS. Una vez listo, ya puedes comenzar a utilizar la nueva dirección de tu academia online en BrainsPro.",
    info_activate_4line: "El equipo de brainspro se pondrá en contacto contigo para enviarte unos registros adicionales que tendrás que colocar en tus DNS. Con ellos generaremos tu web segura (https://)",
    example_academy: 'academia-de-ejemplo',
    example_domain: 'miacademia.com',
    //LanguageSelector
    lang: 'Idioma',
    academy_lang: 'Idioma de la Academia',
    academy_lang_selector_text: 'Elige el idioma de tu academia',

    //BrandingComponent
    banner: 'Banner',
    brand_academy: "Marca de tu academia",
    academy_name: "Nombre de Academia",
    full_name: "Elige el nombre completo de tu academia",
    tagline: "TagLine de Academia",
    slogan: "Elige el eslogan de tu academia",
    search: 'Buscar',
    my_academy: 'Mi Academia en BrainsPro',
    change: 'Cambiar',
    insert: 'Insertar',
    href_custom_academy: 'Más sobre personalizar tu academia.',

    //ContactComponent
    description: "Descripción",
    web_rrss: "Web y Redes Sociales",
    web_blog: "Web o Blog",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    linkedin: "Linkedin",
    youtube: "Youtube",
    location: "Ubicación y datos Fiscales",
    address_line_1: "Dirección, línea 1",
    address_line_2: "Dirección, línea 2",
    postalcode: "Código Postal",
    city: "Ciudad",
    province: "Provincia",
    country: "País",
    company_name: "Denominación Social",
    nif_cif: "NIF/CIF",

    //LegalComponent
    privacy_policy: "Política de Privacidad",
    text_register: "Texto resumen para registro de usuario",
    user_view_text: "Este texto se mostrará en el regisro de usuario, a modo de resumen de la versión más extensa que se mostrará en la página de aviso legal de tu academia.",
    privacy_policy_page: "Página de Política de Privacidad",
    privacy_policy_academy_page: "Página de Política de Privacidad de la academia",
    legal_notice: "Aviso Legal",
    href_legal: 'Más sobre Aviso Legal y Política de Privacidad.',

    //Contact
    contact_bloq: 'Bloque de Contacto',
    html_bloq: 'Inserta un bloque html para acompañar a la información de contacto. Como un iframe de google maps.',

    //Branding
    //BannerBrandingComponent
    upload_banner: 'Sube tu banner',
    image_format: 'PNG, JPG, GIF hasta 3MB',

    //ColorsComponent
    desing_colors: 'Diseño y Colores',
    header_type: 'Tipo de encabezado',
    banner_complete: 'Banner a tamaño completo',
    banner_complete_no_text: 'Banner a tamaño completo sin texto',
    columns_text_image: '2 Columnas, Texto + Imagen',
    banner_info: 'Con esto cambiarás el tipo de imagen de portada que verán los usuarios en tu academia.',
    enterprise_color: 'Color corporativo',
    text_color: 'Color de texto',

    //FaviconComponent
    favicon: 'Favicon',
    upload_favicon: 'Sube tu favicon',
    favicon_format: 'PNG, JPG, GIF hasta 3MB',

    //LogoBrandingComponent
    logo: 'Logo',
    upload_logo: 'Sube tu logo',

    //OptionsComponent
    register_config: 'Configuración de Registro',
    info_button_register: 'Al activar este botón se desactivará la opción de registrarse en la academia. Para ello todos los usuarios deberán registrarse desde la App de Brainspro.',
    restricted_register: 'Restricción de registro',

}