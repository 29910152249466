export default {
    home: "Home",
    projects: "Cursos",
    community: "Comunidade",
    customization: "Personalização",
    reports: "Relatórios",
    marketing: "Marketing",
    settings: "Ajustamentos",
    my_account: 'A minha conta',
    annual_sales: "Vendas anuais",
    sales: "Vendas",
    students: "Estudantes",
    teachers: "Professores",
    products: "Produtos",
    project: "Projectos",
    categories: "Categorias",
    published: "Publicado em",
    name: "Nome",
    lastname: 'Apelido',
    email: "Email",
    phone: "Telefone",
    title: "Título",
    coming_soon: "Em breve",
    mount: "Mês",
    year: "Ano",
    date: "Data",
    plan: 'Plano',
    pvp: "RRP",
    net: "Net",
    state: "Estado",
    delete: "Eliminar",
    profile: "Perfil",
    enrollments: "Inscrições",
    cancel: "Anular",
    save: "Salvar",
    remove: 'eliminar',
    email_address: 'Endereço postal',
    image_format: 'PNG, JPG, GIF até 3MB',
    close: 'Fechar',
    back: 'Voltar a',
    coming_soon_brainspro: 'Brevemente a Brainspro',
    and: 'y',
    product: "Produto",
    date_creation: 'Data de criação',
    type: 'tipo',

    //AppComponent
    my_profile: 'O meu perfil',
    adjust: 'Ajustamentos',
    out: 'Saída',
    search: 'Pesquisa',
    academy_preview: 'Academia de pré-visualização',
    my_academy: 'A minha Academia',
    new_project: 'Novo Curso',
    text_current_period: 'O seu período experimental para ',
    text_end_at: ' termina em ',
    plans: 'Planos de assinatura',
    billing: 'Facturação',

    //ThankYouPageComponent
    thankyou: 'Obrigado',
    go_to_academy: 'Ir para a academia',

    //NavigationDesktop - Mobile
    records: 'Aufzeichnungen',
    videos: 'Videos'
}