import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid, year, month) {
        return useAuthUserStore().getApiClient.get('/reports/orders', {
            params: {
                academy_uuid: academyUuid,
                year: year,
                month: month
            }
        })
    }
}