export default {
    loading_content: "Estem carregant el teu contingut",
    wellcome: "Benvingut a BrainsPro!",
    redirect_academy: "En uns moments seràs redirigit a la teva acadèmia.",
    first: "Primera",
    last: "Última",
    next: "Següent",
    page: "Pàgina",
    previous: "Anterior",

}