export default {
    hello: "Hallo",
    create_a_project: "Einen Kurs erstellen",
    customize_your_academy: "Personalisierung",
    check_your_sales: "Prüfen Sie Ihr Einkommen",
    create_your_project: "Erstellen Sie Ihren Kurs",
    placeholder_name: "Mein Kurs bei Brainspro",
    text_describe: "Der Titel beschreibt in einem Satz, was Ihre Schüler lernen werden.",
    bttn_close: "Schließen Sie",
    bttn_create: "Kurs erstellen!",
    contacts: 'Weitere Fragen?, kontaktieren Sie uns!',
    client_attention: 'Kundenservice: ',
    mail_client_attention: 'hola@brainspro.com',
    support: 'Technische Unterstützung: ',
    email_support: 'soporte@brainspro.com',
    phone_whatsapp: 'Telefon und Whatsapp: ',
    phone_number: '+34 626 201 897',
    text_support: 'Buchen Sie online einen Termin mit einem unserer Experten',
    href_intercom: 'Besuchen Sie die Wissensdatenbank',
    href_custom_domain: 'Mehr über die Konfiguration von benutzerdefinierten Domains.',
    no_annual_data: "Keine jährlichen Daten",
    no_monthly_data: "Keine monatlichen Daten",
    no_students_data: "Keine Daten über eingeschriebene Studenten",
    no_projects_data: "Keine Daten über erstellte Projekte",

}