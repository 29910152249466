<template>
 <div class="pt-2 pb-6 py-5 md:mx-8 mx-4">
   <section class="">
     <!-- Se pintarán las secciones con las posibles lecciones -->
     <div id="sections" v-for="section in this.projectsStore.project.sections" v-bind:key="section.uuid">
       <section class="mb-8 py-2 bg-white shadow rounded-md">
         <div class="flex px-2 py-2 mb-2 mx-2">
             <input v-model="section.title" class="px-2 column-1 text-xl leading-6 font-bold text-stone-900 w-full">
              <div class="ml-2 px-2 mt-2">
                <button title="Guardar nombre" type="button" v-on:click="editSection(section)" class="font-medium text-bpblue hover:text-blue-300 transition-all focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </button>
              </div>
               <button title="Borrar sección" type="button" v-on:click="deleteSection(section)" :disabled="general.loading" class="px-2 font-medium text-gray-400 hover:text-gray-300 transition-all focus:outline-none">
                 <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                   <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                 </svg>
               </button>
         </div>
         <!-- Lección dentro de la sección -->
         <div class="pb-2">
           <div v-if="section.lessons.length === 0">
             <div class="flex justify-center">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
               </svg>
             </div>
           </div>
           <div class="lessons" v-else v-for="lesson in section.lessons" v-bind:key="lesson.uuid">
             <div class="relative w-full p-4 bg-stone-200 mb-2 shadow-inner border-b border-stone-300 flex flex-row items-center">
               <svg v-on:click="getLesson(lesson.uuid)" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 ml-2 mr-2 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
               </svg>
               <input v-model="lesson.title" type="text" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
               <div class="flex justify-center">
                 <div class="text-sm mx-2 px-2 py-1 text-stone-500 rounded">
                   <label>{{lesson.lesson_type.title}}</label>
                 </div>
                 <div type="button" title="Borrar" v-on:click="deleteLesson(lesson.uuid)" class="px-2 cursor-pointer rounded">
                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-600 hover:text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                   </svg>
                 </div>
                 <div class="px-2 cursor-pointer rounded text-blue-500 hover:text-blue-300">
                   <router-link title="Editar contenido de la lección" :to="{ name: 'projects-edit-content', params: { uuid: lesson.uuid }}" aria-current="page">
                     <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                       <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                     </svg>
                   </router-link>
                 </div>
               </div>
             </div>
           </div>
         </div>

         <!-- Creando una nueva lección -->
         <div v-show="general.createLesson && this.general.section.uuid === section.uuid" class="pb-8">
           <card-with-header-and-footer-component :loading="general.loading" class="bg-gray-50 -mt-4">
             <template v-slot:header>
               <h3 class="text-md font-semibold leading-5 text-gray-600 sm:truncate">{{ $t('projects.new_lesson') }}</h3>
             </template>
             <div class="flex flex-row my-3">
               <label class="mt-1 mr-4">{{ $t('projects.lesson_title') }}</label>
               <input v-model="this.general.lesson.title" type="text" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
             </div>
             <div class="flex flex-row my-3">
               <label class="mt-1 mr-6">{{$t('projects.type_lesson')}}</label>
               <select v-model="general.lessonTypeSelected" class="appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 rounded-md bg-white bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                 <option class="" v-for="lessonType in general.lessonTypes" v-bind:key="lessonType.key">
                   {{ lessonType.key }}
                 </option>
               </select>
             </div>

             <template v-slot:footer>
               <button type="button" v-on:click="cancelAddLesson" :disabled="general.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                 {{ $t('general.cancel') }}
               </button>
               <button type="button" v-on:click="saveLesson(section)" :disabled="general.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                 {{ $t('general.save') }}
               </button>
             </template>
           </card-with-header-and-footer-component>
         </div>

         <div class="flex flex-row items-center justify-center " v-bind:key="section.uuid">
           <button type="button" v-on:click="this.general.createLesson = true; this.general.section.uuid = section.uuid" :disabled="general.loading" class="inline-flex items-center mb-2 px-4 py-2 font-medium  shadow-sm hover:shadow-inner text-white bg-stone-600 rounded-full hover:bg-bpblue transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
             <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
               <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
             </svg>
           </button>
         </div>
       </section>
     </div>

     <!-- Creando una nueva sección -->
     <div v-show="general.createSection" class="pb-8">
       <card-with-header-and-footer-component :loading="general.loading" class="bg-gray-50">
         <template v-slot:header>
           <h3 class="text-md font-semibold leading-5 text-gray-600 sm:truncate">{{$t('projects.new_section')}}</h3>
         </template>
         <!-- Contenido -->
         <div class="flex flex-row">
           <label>{{$t('projects.section_title')}}</label>
           <input v-model="this.general.section.title" type="text" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
         </div>
         <template v-slot:footer>
           <button type="button" v-on:click="cancelAddSection" :disabled="general.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
             {{ $t('general.cancel') }}
           </button>
           <button type="button" v-on:click="saveSection" :disabled="general.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
             {{ $t('general.save') }}
           </button>
         </template>
       </card-with-header-and-footer-component>
     </div>
      <div class="flex flex-row justify-end">
        <div class="border-b-bpblue border-t-bpblue border-4 w-8 h-8 cursor-pointer" @click="this.general.createSection = !this.general.createSection">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-1 text-bpblue" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="mt-1">
          <label class="font-bold ml-2 cursor-pointer text-bpblue" @click="this.general.createSection = !this.general.createSection">{{$t('projects.create_section')}}</label>
        </div>
      </div>
   </section>
 </div>
</template>

<script>

import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import ProjectService from "@/services/project/ProjectService";
import {useProjectsStore} from "@/stores/projects";
import NotificationService from "@/services/notification/NotificationService";


export default {
  name: "BrandingComponent",
  components: {
    CardWithHeaderAndFooterComponent

  },
  setup(){
    const projectsStore = useProjectsStore()

    return {
      projectsStore
    }
  },
  created() {
    this.getLessonTypes()
  },
  data() {
    return {
      general: {
        loading: false,
        showMenuItems: false,
        createSection: false,
        createLesson: false,
        lessonTypes: null,
        lessonTypeSelected: null,
        sections: [],
        section: {
          title: "",
          uuid: '',
        },
        lesson: {
          title: "",
          uuid: '',
          content: null,
          lesson_type_id: '',
          lesson_type: {
            key: '',
            title: '',
          }
        }
      },
      request: {
        sections: [],
      },
    }
  },
  methods: {

    editSection(section) {
      section.project_uuid = this.$route.params.id
      console.log("Editing section", section)

      this.projectsStore.editSection(section)
          .then((response) => {
            console.log("La respueta es: ", response)
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando la sección", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.projectsStore.getProject(this.$route.params.id)
          })
    },
    deleteSection() {
      //TODO Comprobará en pinia la sección (uuid) y la borrará.
    },
    cancelAddSection() {
      this.general.createSection = false
    },
    saveSection() {
      //guarda la sección en el array
      this.general.sections.push(this.general.section)
      console.log("Array secciones: ", this.general.sections)
      let section = {
        uuid: this.$route.params.id,
        title: this.general.section.title
      }
      this.projectsStore.addSection(section)
          .then((response) => {
            console.log("La respueta es: ", response)
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando la sección", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.general.createSection = false
            this.projectsStore.getProject(this.$route.params.id)
          })
    },
    /*
    addNewLesson() {
      this.general.createLesson = true
    },
    */

    getLessonTypes() {
      //TODO ¿ENDPOINT para obtener los tipos de cursos?

      ProjectService.getLessonTypes()
          .then((response) => {
            this.general.lessonTypes = response.data.data
            console.log("TIPOS DE LECCIONES: ", this.general.lessonTypes)
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando la sección", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {

          })

    },
    cancelAddLesson() {
      this.general.createLesson = false
    },

    saveLesson(section) {
      this.general.loading = true
      console.log("La sección a la que pertenece es: ", section.uuid)
      console.log("El título de la lección es: ", this.general.lesson.title)
      console.log("El tipo de lección es: ", this.general.lessonTypeSelected)

      //section.lessons.push(Object.assign({}, section.new))
      //this.general.sections.push(this.general.section)

      this.projectsStore.addLesson(section.uuid, this.general.lesson.title, this.general.lessonTypeSelected)
          .then((response) =>{
            console.log("la respuesta es:", response)
            this.projectsStore.getProject(this.$route.params.id)
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando la sección", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.general.createLesson = false
            this.general.loading = false
          })
    },
    deleteLesson(lessonUuid) {
      console.log("Borrar lección", lessonUuid)
    },

    getLesson(lessonUuid) {
      let projectUuid = this.projectsStore.project.uuid

      ProjectService.getLesson(lessonUuid, projectUuid)
          .then((response) => {
            console.log("La lección obtenida es: ", response.data)
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando la sección", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.general.createSection = false
            this.projectsStore.getProject(this.$route.params.id)
          })
    }
  },
}
</script>

<style scoped>

</style>