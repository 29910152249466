<template>
  <div class="pt-4 pb-6">
    <div class=" mt-2 flex text-sm">
      <a href="#" class="group inline-flex items-center text-gray-500 hover:text-gray-900">
        <!-- Heroicon name: solid/question-mark-circle -->
        <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
        </svg>
        <span class="ml-2">
          <a :href="$props.url" target="_blank">{{ $t('dashboard.href_intercom') }}</a>
        </span>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: "HelpCenterComponent",
  props: {
    url: String,
  },
}
</script>

<style scoped>

</style>