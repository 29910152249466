<template>
  <div class="h-screen flex overflow-hidden bg-stone-100">
    <new-project-modal-component></new-project-modal-component>
    <slide-over-component></slide-over-component>
    <categories-slide-over-component></categories-slide-over-component>
    <NavigationMobile></NavigationMobile>
    <NavigationDesktop></NavigationDesktop>

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div class="flex">
        <button @click="openNavigationMenu"
                class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
                aria-label="Open sidebar">
          <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"/>
          </svg>
        </button>
        </div>

        <trialling-component></trialling-component>

        <div class="flex-1 px-4 flex justify-between">
          <div class="flex-1 flex items-center">
            <div class="hidden w-full flex md:ml-0">
              <label for="search_field" class="sr-only">{{ $t('general.search') }}</label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"/>
                  </svg>
                </div>
                <input id="search_field"
                       class="block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm"
                       placeholder="{{ $t('general.search') }}" type="search"/>
              </div>
            </div>
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            <button
                class="hidden p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
                aria-label="Notifications">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
              </svg>
            </button>

            <a v-if="academiesStore.academy.domain != '' || academiesStore.academy.subdomain != ''" :href="previewAcademy" target="_blank"
               :title="$t('general.academy_preview')"
               class="mr-2 p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
               :aria-label="$t('general.my_academy')">
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"/>
              </svg>
            </a>

            <button v-on:click="productsStore.toggleModal()" :title="$t('general.new_project')"
                    class="mr-2 p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
                    :aria-label="$t('general.new_project')">
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
            </button>

            <!-- Profile dropdown -->
            <div class="h-7 w-7 -mt-1 border-2 border-white hover:border-gray-100 hover:border-2 hover:text-gray-500 rounded-full">
              <div v-on:click="showUserMenu = !showUserMenu">
                <button class="rounded-full focus:outline-none focus:shadow-outline"
                        id="user-menu" aria-label="User menu" aria-haspopup="true">
                  <img class="h-7 w-7 rounded-full" :src="(authUserStore.user.avatar)? authUserStore.user.avatar : '/images/avatar-bp.jpg'"
                       alt=""/>
                </button>
              </div>

              <transition
                  name="display-user-menu"
                  enter-active-class="transition ease-out duration-100"
                  enter-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                  v-on:mouseleave="this.showUserMenu = false"
              >
                <div class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg" v-show="showUserMenu" >
                  <div class="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical"
                       aria-labelledby="user-menu">
                    <router-link to="/myaccount"
                                 class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                                 role="menuitem">{{ $t('general.my_profile') }}
                    </router-link>
                    <router-link to="/myaccount/current-subscription"
                                 class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                                 role="menuitem"> {{ $t('general.plans') }}
                    </router-link>
                    <a href="#"
                       class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                       role="menuitem" @click="logout">{{ $t('general.out') }}</a>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
        <router-view></router-view>
        <div class="text-center py-2 text-stone-500 text-sm place-self-end">Made with <font-awesome-icon icon="heart" class="text-red-600"></font-awesome-icon> in Málaga</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationDesktop from "@/views/common/NavigationDesktop";
import NavigationMobile from "@/views/common/NavigationMobile";
import NewProjectModalComponent from "@/views/products/NewProjectModalComponent";
import SlideOverComponent from "@/components/panels/SlideOverComponent";
import CategoriesSlideOverComponent from "@/views/products/CategoriesSlideOverComponent";
import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import {useProductsStore} from "@/stores/products";
import TriallingComponent from "@/views/common/trial/TrialingComponent";

export default {
  name: "AppComponent",
  components: {
    TriallingComponent,
    CategoriesSlideOverComponent,
    SlideOverComponent,
    NewProjectModalComponent,
    NavigationDesktop,
    NavigationMobile,
  },
  setup(){
    const authUserStore = useAuthUserStore();
    const academiesStore = useAcademiesStore()
    const navigationMenuStore = useNavigationMenuStore()
    const productsStore = useProductsStore()

    authUserStore.getUser()
    authUserStore.changeAcademy()
    return {
      authUserStore,
      academiesStore,
      navigationMenuStore,
      productsStore
    }
  },
  created() {
    console.log("Subdominio obtenido", this.academiesStore.academy.subdomain)
  },
  data: () => {
    return {
      showUserMenu: false,
    }
  },
  mounted() {
    console.log("Brainspro APP")
  },
  methods: {
    logout() {
      console.log("Se procede a salir de la aplicación. ¡Hasta Pronto!")

      // eslint-disable-next-line no-undef
      if (typeof Android !== "undefined") {
        // eslint-disable-next-line no-undef
        Android.logout();
      } else {
        //TODO PINIA AUTH
        this.authUserStore.logout()
        this.$router.push({name: 'login'})
      }
    },
    openNavigationMenu() {
      this.navigationMenuStore.showMobileNavigationMenu = true
    },
  },
  computed: {
    previewAcademy() {
      if (this.academiesStore.academy.domain !== '') {
        return 'https://' + this.academiesStore.academy.domain
      } else {
        return 'https://' + this.academiesStore.academy.subdomain + '.mybrainspro.com'
      }
    },
  }
}
</script>

<style scoped>

</style>