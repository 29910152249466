export default {
    home: "Home",
    projects: "Projects",
    community: "Community",
    customization: "Customisation",
    reports: "Reports",
    marketing: "Marketing",
    settings: "Settings",
    my_account: 'My account',
    annual_sales: "Annual Sales",
    sales: "Sales",
    students: "Students",
    teachers: "Teachers",
    products: "Products",
    project: "Projects",
    categories: "Categories",
    category: 'Category',
    published: "Published",
    name: "Name",
    lastname: 'Surname',
    email: "Email",
    phone: "Phone",
    title: "Title",
    pass: 'Password',
    pass_confirm: 'Password confirmation',
    coming_soon: "Coming soon",
    month: "Month",
    year: "Year",
    date: "Date",
    plan: 'Plan',
    pvp: "PVP",
    net: "Net",
    total: 'Total',
    state: "State",
    delete: "Delete",
    profile: "Profile",
    enrollments: "Enrolments",
    cancel: "Cancel",
    save: "Save",
    remove: 'Remove',
    email_address: 'Email address',
    image_format: 'PNG, JPG, GIF to 3MB',
    close: 'Close',
    back: 'Back',
    coming_soon_brainspro: 'Coming soon to Brainspro',
    and: 'and',
    product: "Product",
    date_creation: 'Date of creation',
    type: 'type',

    //AppComponent
    my_profile: 'My Profile',
    adjust: 'Adjustments',
    out: 'Exit',
    search: 'Search',
    academy_preview: 'Preview academy',
    my_academy: 'My Academy',
    new_project: 'New Project',
    text_current_period: 'Your trial period to ',
    text_end_at: ' ends in ',
    plans: 'Subscription Plans',
    billing: 'User',

    //ThankYouPageComponent
    thankyou: 'Thank you!',
    go_to_academy: 'Go to the academy',

    //NavigationDesktop - Mobile
    records: 'Records',
    videos: 'Videos'

}