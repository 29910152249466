export default {
    //EditUserSlideOverComponent
    edit_user: 'Editar usuario',
    firstname: 'Nombre',
    lastname: 'Apellidos',
    email: 'Email',
    identity_card: 'DNI / CIF / NIF',
    password: 'Password',
    close: 'Cerrar',

    //SlideOverComponent
    enroll_in: 'Matricular en:',
    select_project: 'Selecciona un curso...',
    user_role: 'Rol de usuario',
    student: 'Alumno',
    user_as_student: 'Matricula tu usuario como estudiante',
    teacher: 'Docente',
    user_as_teacher: 'Matricula tu usuario como docente en el curso',
    teacher_no_edition: 'Docente sin permisos de edición',
    user_as_teacher_no_edition: 'Matricula tu usuario como docente sin permisos de edición en el curso',
    inspector: 'Inspector',
    user_as_inspector: 'Matricula tu usuario como inspector FUNDAE',
    email_notification: 'Notificar por email',
    text_email_notification: 'Notifica a tu alumno de que se le ha matriculado',
    help_manual_user: 'Deseo saber más sobre la creación manual de usuarios',
    create_user: 'Crear usuario',
    create_info: 'Empieza rellenando la siguiente información para crear tu usuario.',
        //Vuelidate strings error
        error_firstname_required: 'Debes introducir un nombre',
        error_lastname_required: 'Debes introducir los apellidos',
        error_email_required: 'Debes introducir un email válido',
        error_password_required: 'Debes introducir una contraseña con 8 caracteres',
        error_dni_minLenght: 'Debe tener al menos 9 caracteres',

    //CardWithHeaderAndFooterComponent
    text_plan1: 'Esta funcionalidad pertenece a un plan superior, por favor',
    text_plan2: 'actualiza tu plan',
    loading_content: 'Estamos cargando tu contenido',
}