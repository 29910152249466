<template>
  <div class="pt-2 pb-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <!-- Replace with your content -->
      <div class="py-4 hidden md:block">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div v-if="!loading" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("sales.description") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("sales.payment_date") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("sales.quantity") }}
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="payout in payouts" :key="payout.uuid">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    {{ payout.concept }}
                    <div>{{ payout.uuid }}</div>
                    <div class="py-2 whitespace-no-wrap text-sm text-gray-500" v-html="payout.description"></div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ $moment(payout.payout_date).format('DD-MM-YYYY') }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ payout.quantity }}€
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <loading-component v-else>{{ $t("sales.loading_payments") }}</loading-component>
          </div>
        </div>
      </div>
      <mobile-payouts-component class="md:hidden" :loading="loading" :payouts="payouts"></mobile-payouts-component>
      <!--<PaginationComponent></PaginationComponent>-->
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/views/common/LoadingComponent";
import PayoutService from "@/services/report/PayoutService";
import MobilePayoutsComponent from "@/views/sales/Mobile/PayoutsComponent";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "PayoutsComponent",
  components: {LoadingComponent, MobilePayoutsComponent},
  data() {
    return {
      loading: true,
      payouts: [],
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  created() {
    this.getPayouts()
  },
  methods: {
    getPayouts() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      PayoutService.index(academy_uuid).then((response) => {
        console.log("getPayouts OK", response)
        this.payouts = response.data.payouts
      })
      .catch((response) => {
        console.log("Ha ocurrido un error en getPayouts", response)
      })
      .finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>