<template>
    <div class="flex bg-white items-center">
      <div class="flex-1 flex-col justify-self-auto py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm">
            <img class="max-h-14 w-auto mx-auto" src="/images/logo-bp-noclaim.png" alt="Brainspro">
            <h1 class="flex justify-center text-3xl leading-9 font-extrabold text-gray-700 mt-2 ">
              ¡Gracias!
            </h1>
          </div>
          <div class="flex justify-center mt-6 hidden">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 border-white border-2 border-x-bpblue rounded-full text-bppick" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z" />
            </svg>
          </div>
          <div class="flex justify-center hover:animate-pulse mt-14">
            <button class="bg-bpblue text-white p-2 rounded-md shadow-lg" @click="goToAcademy">
              Ir a la academia
            </button>
          </div>
        </div>

      <FireworksComponent class="lg:embed-responsive block relative flex justify-center items-center flex-1 bg-gradient-to-br from-bppick to-bpblue text-white px-10"
                          :box-height="'100%'" :box-width="'100%'"></FireworksComponent>
      </div>



</template>

<script>
import FireworksComponent from "./FireworksComponent";

export default {
  name: "ThankYouPageComponent",
  components: { FireworksComponent },
  methods: {
    goToAcademy() {
      this.$router.push({name: 'dashboard'})
    }
  }
}
</script>

<style scoped>

</style>