ç<template>
  <div class="pt-2 pb-6 md:py-6 max-w-3xl mx-auto">
    <card-with-header-and-footer-component class="bg-white" :loading="false" :blocked="this.massive.blocked">
      <template v-slot:header>
        <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">
          {{ $t("community.mass_enrollment") }}
          <span
              class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-yellow-100 text-yellow-800">
  {{ $t("community.beta_version") }}
</span>
        </h3>
      </template>

      <div class="mt-1 sm:mt-0">
        <alert-component class="mb-10">
          <ul>
            <li>{{ $t("community.functionality") }} <b>{{ $t("community.advance") }}</b> {{ $t("general.and") }}
              <b>{{ $t("community.pro") }}</b>.
            </li>
            <li>{{ $t("community.limitation_mass_enrollment") }}</li>
            <li><a href="/assets/subida_masiva_plantilla.xlsx"
                   class="font-medium underline">{{ $t("community.download_template") }}</a></li>
          </ul>
        </alert-component>

        <div v-if="uploadDisabled" v-cloak @drop.prevent="addFile" @dragover.prevent
             class="max-w-lg flex justify-center mx-auto px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div class="space-y-1 text-center">
            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                 aria-hidden="true">
              <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <div class="flex text-sm text-gray-600">
              <label for="file-upload"
                     class="relative cursor-pointer rounded-md font-medium text-bpblue hover:text-bpblue focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-bpblue">
                <span>{{ $t("community.upload_students_list") }}</span>
                <input id="file-upload" @change.prevent="addFileFromExplorer" name="file-upload" type="file"
                       class="sr-only">
              </label>
              <p class="pl-1">{{ $t("community.drag_drop") }}</p>
            </div>
            <p class="text-xs text-gray-500">
              {{ $t("community.format") }}
            </p>
          </div>
        </div>

        <ul v-else class="space-y-3">
          <li v-for="file in request.files" v-bind:key="file.name"
              class="bg-white shadow border border-gray-50 overflow-hidden rounded-md px-6 py-4">
            {{ file.name }} ({{ file.size / 1024 }} kb)
            <button @click="removeFile(file)" class="text-red-300 hover:underline hover:text-red-500 transition-all"
                    :title="$t('general.remove')">{{ $t("general.delete") }}
            </button>
          </li>
        </ul>

        <!-- Select con autocompletar -->
        <div class="mt-6">
          <label for="enrol" class="block text-sm font-medium text-gray-700">{{ $t("community.enroll_in") }}</label>
          <form>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-3 right-0 pr-3 text-gray-400">
                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
              </div>
            </div>
            <input type="text"
                   class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                   v-model="searchQuery" @focus="showAllProjects" placeholder="Nombre del proyecto">
          </form>
          <ul v-show="isFocused && filteredProjects.length > 0 && searchQuery" class="mt-3 list-item">
            <div class="">
              <li v-for="project in filteredProjects" :key="project.uuid" v-on:click="addProject(project)" @keyup.enter="addProject(project)"
                  class="flex p-4 cursor-pointer hover:bg-stone-100 hover:text-white border-2 my-2 rounded-md">
                <div class="flex-col hover:text-white">
                  <label class="font-extrabold text-gray-600 hover:text-white">{{ project.title }}</label>
                  <p class="text-gray-400 text-sm ">Actualizado el {{$moment(project.updated_at).format("LLL")}}</p>
                </div>
              </li>
            </div>
          </ul>
        </div>

        <div class="mt-5 flex flex-col -my-2">
          <div v-if="request.projectsSelected.length <= 0" class="">
            <label class="text-gray-500">
              No hay cursos seleccionados
            </label>
          </div>


            <div :id="project.uuid" v-for="project in request.projectsSelected" v-bind:key="project.uuid" class="border-2 p-3 rounded-md my-2">
              <label class="flex justify-between text-sm font-bold text-gray-500">
                {{project.title}}

                <div v-if="project.status == 'completed'" class="flex justify-end mt-4">
                  <span class="mr-2 text-green-600 font-normal">
                    Completado
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline mr-3 w-4 h-4 text-green-700">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                </div>

                <div v-else-if="this.massive.loading && project.status == 'pending'" class="flex justify-end mt-4">
                  <span class="mr-2 text-gray-400 font-normal">
                    {{ $t('community.processing') }}
                  </span>
                  <svg class="inline mr-3 w-4 h-4 text-blue-700 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                  </svg>
                </div>

                <div v-else class="cursor-pointer mt-4" v-on:click="deleteProject(project)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-red-600">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                  </svg>
                </div>

              </label>
              <p class="text-bpblue text-xs">
                {{project.students_count}} Estudiantes
              </p>
            </div>
        </div>
      </div>

      <template v-slot:footer>
        <!-- Link to intercoms helpcenter -->
        <div class="flex justify-start text-sm">
          <a href="#" class="group inline-flex items-center text-gray-500 hover:text-gray-900">
            <!-- Heroicon name: solid/question-mark-circle -->
            <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"/>
            </svg>
            <span class="ml-2">
                          <a href="https://helpcenter.brainspro.com/es/articles/5713373-como-importar-una-lista-de-estudiantes"
                             target="_blank">{{ $t('community.href_massive_enroll') }}</a>
                      </span>
          </a>
        </div>
        <button v-if="!uploadDisabled && this.request.projectsSelected.length > 0" type="button" v-on:click="upload"
                :hidden="uploadDisabled || massive.loading || massive.blocked || request.projects.length < 1"
                :disabled="this.massive.loading"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t("community.import_users") }}
        </button>
        <button v-else type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-700 bg-gray-300 focus:outline-none cursor-not-allowed">
          {{ $t("community.import_users") }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </div>
</template>

<script>
import MassiveUploadService from "@/services/user/MassiveUploadService";
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import AlertComponent from "@/components/feedback/alerts/AlertComponent";
import notificationService from '@/services/notification/NotificationService'
import {useAuthUserStore} from "@/stores/auth";
import projectService from "@/services/project/ProjectService";

export default {
  name: "MassiveUserImportComponent",
  components: {AlertComponent, CardWithHeaderAndFooterComponent},
  data: function () {
    return {
      massive: {
        loading: false,
        blocked: false
      },
      request: {
        files: [],
        project: null,
        projects: [],
        projectsSelected: []
      },

      searchQuery: '',
      isFocused: false,


      time: {
        entry: null,
        process: null,
        result: null
      },
    };

  },
  setup() {

    const authUserStore = useAuthUserStore();

    return {
      authUserStore
    }
  },
  created() {

    this.massive.blocked =
        this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.key !== "plan_pro" &&
        this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.key !== "plan_corporate"

    let academy_uuid = useAuthUserStore().user.academy.uuid

    projectService.index(academy_uuid)
        .then((response) => {
          this.request.projects = response.data.data
          this.loading = false
        })
  },
  computed: {
    uploadDisabled() {
      return this.request.files.length === 0;
    },
    filteredProjects() {
      if (!this.searchQuery) {
        return this.request.projects;
      }

      const query = this.searchQuery.toLowerCase();
      return this.request.projects.filter(project =>
          project.title.toLowerCase().includes(query)
      );
    }
  },
  methods: {
    addProject(projectSelected) {
      projectSelected.status = "pending";
      //Comprueba que el proyecto seleccionado no esté null y que no exista anteriormente en la lista.
      if (projectSelected && this.request.projectsSelected.findIndex(project => project.uuid === projectSelected.uuid) === -1)
      {
        this.request.projectsSelected.push(projectSelected)
        this.searchQuery = ""
      }
    },

    deleteProject(projectSelected) {
      let index = this.request.projectsSelected.findIndex(project => project.uuid === projectSelected.uuid)
      if (index !== -1) {
        this.request.projectsSelected.splice(index, 1)
      }
    },

    addFile(e) {
      let droppedFiles =
          e.dataTransfer.files;
      if (!droppedFiles) return;

      // this tip, convert FileList to array
      // credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...droppedFiles]).forEach(f => {
        this.request.files = [];
        this.request.files.push(f);
      });
    },
    addFileFromExplorer(e) {
      let changedFiles = e.target.files;
      if (!changedFiles) return;

      // this tip, convert FileList to array
      // credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...changedFiles]).forEach(f => {
        this.request.files = [];
        this.request.files.push(f);
      });
    },
    removeFile(file) {
      this.request.files = this.request.files.filter(f => {
        return f != file;
      });
    },
    upload() {
      this.time.entry = this.$moment.now()
      this.massive.loading = true;
      let academy_uuid = this.authUserStore.user.academy.uuid;

      this.request.projectsSelected.forEach((project) => {
        setTimeout(this.processProjectEnrol(academy_uuid, project), 1000)
      })
    },

    processProjectEnrol(academy_uuid, project) {
      console.log("Loading project enrol: ", project)

      let formData = new FormData();
      this.request.files.forEach((f, x) => {
        formData.append('massive_upload_' + (x + 1), f);
      });

      formData.append('academy_uuid', academy_uuid)
      formData.append('project_uuid', project.uuid)

      MassiveUploadService.v2_upload(formData)
          .then((response) => {
            console.log("Se han cargado el curso correctamente", response)
            project.status = "completed"

            this.massive.loading = false
          })
          .catch((error) => {
            console.log("Error en la matriculación masiva", error)
            project.status = "pending"

            if (error.response.data.errors) {
              error.response.data.errors.forEach((error) => {
                notificationService.showNotification(error, "error")
              })
            }
            else
            {
              notificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            //this.request.files = [];
          })
    },

    showAllProjects() {
      if (this.filteredProjects)
      {
        this.isFocused = true;
      }
    }

  },
}
</script>

<style scoped>

</style>