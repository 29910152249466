export default {
    log_account: 'Login to your account',
    create_account: 'create a free account',
    trial_info: 'start your 14-day trial',
    log_with: 'Login with',
    continue_with: 'Or continue with ',
    attention: 'Attention administrators only',
    welcome_text1: 'Welcome to Brainspro v2. ',
    admin_access: 'Access for administrators only',
    welcome_text2: 'If you are a student or teacher, access your course from your school\'s website.',
    email_address: 'Mailing address',
    remember_me: 'Remember me',
    forgot_pass: 'Forgot your password?',
    access: 'Login',
    ad_text1: 'Turn your online courses into',
    ad_text2: 'learning experiences',
    ad_text3: 'high impact',
    ad_text4: 'Your students will appreciate the quality of your services and you will have everything under control in a simple way.',
    //RememberPasswordComponent
    recovery_password: 'Recover your password',
    email_input_placeholder: 'Introduce tu email',
    information_email_send: 'A confirmation email will be sent to recover your password.',
    information_go_email_inbox: 'Please log in to your email and click on the link to reset your password. If you dont find the email, please check your SPAM folder.',
    error_email_invalid: 'You must enter a valid email address',
    button_text: 'Password recovery',
    //ResetPasswordComponent
    restore_password: 'Reset your password',
    new_password: 'New password',
    password_placeholder: 'Enter your new password',
    error_password: 'The password must have a minimum of 8 characters',
    confirm_password: 'Confirm password',
    password_confirm_placeholder: 'Enter your new password',
    error_password_confirm: 'Passwords do not match',
    button_reset_password: 'Reset password',
    success_password_change: "Password updated.",
}