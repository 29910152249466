import { defineStore } from 'pinia'
import {useAuthUserStore} from "@/stores/auth";
import ProjectService from "@/services/project/ProjectService";

export const useProjectsStore = defineStore('proyect', {
    state: () => ({
        project: {
            academy_id: null,
            created_at: '',
            delete_at: '',
            enrol_configuration: {
                time_start_value: 0,
                time_start_unit: '',
                time_end_value: 0,
                time_end_unit: ''
            },
            products: null,
            product_type_id: null,
            sections: null,
            status: '',
            title: '',
            updated_at: '',
            uuid: '',
        },
        navigation: {
            navigationMode: 2,
            completeLesson: 3,
            timeToComplete: '05:50',
            autoNavigation: 2
        },
        sections: {
            sectionTitle: "",
            sectionUuid: "",
            lessons: [
                {
                    lessonTitle: "",
                    lessonUuid: ""
                }
            ],
        },
        loading: false,
    }),
    actions: {
        //Getters
        getProjects() {
            return ProjectService.index()
                .then((response) => {
                  console.log("Resultado de projects: ", response)
                })
                .catch(error => {
                    console.log("Error al obtener los proyecto: ", error)
                })
        },
        getProject(projectUuid)
        {
            this.loading = true
            useAuthUserStore().getApiClient.get('/projects/' + projectUuid, {})
                .then((response) => {
                    console.log("El proyecto obtenido es: ", response)
                    this.setProjects(response.data)
                })
                .catch(error => {
                    console.log("Ha ocurrido un error obteniendo el proyecto", error)
                })
                .finally(() => {
                    console.log(useProjectsStore())
                    useProjectsStore().loading = false
                })
        },
        setProjectGeneral() {
            return ProjectService.put({
                uuid: this.project.uuid,
                title: this.project.title,
            }).then((response) => {
                console.log("Almacenando seccion general del producto", response)
            })
                .catch((error) => {
                    console.log("Ha ocurrido un error almacenando el producto", error)
                })
        },
        //=============================================================
        //                          SECTIONS
        //=============================================================
        addSection(section) {
            return ProjectService.postSection( {
                project_uuid: section.uuid,
                title: section.title
            })
        },
        editSection(section) {
            return ProjectService.putSection({
                project_uuid: section.project_uuid,
                title: section.title,
                section_uuid: section.section_uuid
            })
        },

        //=============================================================
        //                          LESSONS
        //=============================================================
        addLesson(section_uuid, lesson_title, lesson_type_key) {
            return ProjectService.postLesson(section_uuid, lesson_title, lesson_type_key)
        },

        //=============================================================
        //                          Mutations
        //=============================================================
        setProjects(project) {
            this.project = {
                academy_id: project.academy_id,
                created_at: project.created_at,
                delete_at: project.delete_at,
                enrol_configuration: {
                    time_start_value: (project.enrol_configuration && project.enrol_configuration.time_start_value)? project.enrol_configuration.time_start_value : 0,
                    time_start_unit: (project.enrol_configuration && project.enrol_configuration.time_start_unit)? project.enrol_configuration.time_start_unit : 'hours',
                    time_end_value: (project.enrol_configuration && project.enrol_configuration.time_end_value)? project.enrol_configuration.time_end_value : 0,
                    time_end_unit: (project.enrol_configuration && project.enrol_configuration.time_end_unit)? project.enrol_configuration.time_end_unit : 'hours',
                },
                products: project.products,
                product_type_id: project.product_type_id,
                sections: project.sections,
                status: project.status,
                title: project.title,
                updated_at: project.updated_at,
                uuid: project.uuid,
            }
        },
    }

})