<template>
  <div class="max-w-7xl mx-auto py-3 md:py-6 sm:px-8 lg:pt-8">
    <header-component :title="$t('customization.customization')"></header-component>

    <nav class="mt-4 lg:ml-8 ml-4">
      <div class="block">
        <div class="">
          <nav class="-mb-px flex md:space-x-8 sm:mx-auto flex flex-col md:flex md:flex-row" aria-label="Tabs">
            <router-link to="/academy/" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="globe" size="lg" />
              <span>{{ $t("customization.domain") }}</span>
            </router-link>
            <router-link to="/academy/branding" active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="swatchbook" size="lg" />
              <span>{{ $t("customization.brand_logo") }}</span>
            </router-link>
            <router-link to="/academy/contact" active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="id-card-alt" size="lg" />
              <span>{{ $t("customization.rrss_contact") }}</span>
            </router-link>
            <router-link to="/academy/legal" active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="file-contract" size="lg" />
              <span>{{ $t("customization.legal") }}</span>
            </router-link>
            <router-link to="/academy/options" active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="hidden text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span>{{ $t("customization.options") }}</span>
            </router-link>
          </nav>
        </div>
      </div>
    </nav>

    <router-view></router-view>
  </div>
</template>

<script>
import HeaderComponent from "@/views/common/HeaderComponent";

export default {
  name: "AcademyComponent",
  components: {
    HeaderComponent,
  }

}
</script>

<style scoped>

</style>