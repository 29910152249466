<template>
  <card-with-header-and-footer-component :loading="colors.loading">
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('customization.desing_colors') }}</h3>
    </template>

    <div>
      <label for="header-block" class="block text-sm font-medium text-gray-700">{{ $t('customization.header_type') }}</label>
      <select id="header-block" name="header-block" v-model="colors.request.theme_header_block" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-bpblue focus:border-bpblue sm:text-sm rounded-md">
        <option value="1c01-image">{{ $t('customization.banner_complete') }}</option>
        <option value="1c02-image">{{ $t('customization.banner_complete_no_text') }}</option>
        <option value="2c01-text-image">{{ $t('customization.columns_text_image') }}</option>
      </select>
      <p class="mt-2 text-sm text-gray-500" id="academy-header-block-description">{{ $t('customization.banner_info') }}</p>
    </div>

    <div class="mt-6">
      <label for="brand-color" class="block text-sm font-medium text-gray-700">{{ $t('customization.enterprise_color') }}</label>
      <div class="flex">
        <input type="text" v-model="corporateColor" name="academy-tagline" id="brand-color" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" aria-describedby="email-description">
        <div id="corporate-color" class="ml-4 w-10 h-10 border border-gray-300 rounded-md text-center"></div>
      </div>
    </div>

    <alert-component class="mt-4">
      Puedes buscar tu color personalizado en <a class="font-semibold" href="https://htmlcolorcodes.com/es/" target="_blank">https://htmlcolorcodes.com/es/</a> y copiar el valor hexadecimal.
    </alert-component>

    <div class="m-8 rounded-md">
      <div class="flex justify-center my-4">
        <input type="button" style="background-color: #090909" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#090909'">
        <input type="button" style="background-color: #FFFFFF" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#FFFFFF'">
        <input type="button" style="background-color: #ef4444" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#ef4444'">
        <input type="button" style="background-color: #fbbf24" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#fbbf24'">
        <input type="button" style="background-color: #facc15" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#facc15'">
        <input type="button" style="background-color: #4ade80" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#4ade80'">
        <input type="button" style="background-color: #2dd4bf" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#2dd4bf'">
        <input type="button" style="background-color: #60a5fa" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#60a5fa'">
        <input type="button" style="background-color: #a78bfa" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#a78bfa'">
        <input type="button" style="background-color: #e879f9" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#e879f9'">
        <input type="button" style="background-color: #f472b6" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#f472b6'">
        <input type="button" style="background-color: #fb7185" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="corporateColor = '#fb7185'">
      </div>
    </div>

    <div class="m-8 rounded-md">
      <div class="my-4 grid grid-cols-6 text-center">
        <div class="">
          <input type="button" class="bg-slate-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#94a3b8'">
          <input type="button" class="bg-slate-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#64748b'">
          <input type="button" class="bg-slate-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#475569'">
          <input type="button" class="bg-slate-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#334155'">
        </div>
        <div class="">
          <input type="button" class="bg-stone-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#a8a29e'">
          <input type="button" class="bg-stone-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#78716c'">
          <input type="button" class="bg-stone-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#57534e'">
          <input type="button" class="bg-stone-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#44403c'">
        </div>
        <div class="">
          <input type="button" class="bg-red-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#f87171'">
          <input type="button" class="bg-red-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#ef4444'">
          <input type="button" class="bg-red-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#dc2626'">
          <input type="button" class="bg-red-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#b91c1c'">
        </div>
        <div>
          <input type="button" class="bg-orange-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#fb923c'">
          <input type="button" class="bg-orange-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#f97316'">
          <input type="button" class="bg-orange-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#ea580c'">
          <input type="button" class="bg-orange-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#c2410c'">
        </div>
        <div class="">
          <input type="button" class="bg-yellow-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#facc15'">
          <input type="button" class="bg-yellow-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#eab308'">
          <input type="button" class="bg-yellow-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#ca8a04'">
          <input type="button" class="bg-yellow-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#a16207'">
        </div>
        <div class="">
          <input type="button" class="bg-green-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#4ade80'">
          <input type="button" class="bg-green-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#22c55e'">
          <input type="button" class="bg-green-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#16a34a'">
          <input type="button" class="bg-green-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#15803d'">
        </div>
        <div>
          <input type="button" class="bg-rose-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#fb7185'">
          <input type="button" class="bg-rose-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#f43f5e'">
          <input type="button" class="bg-rose-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#e11d48'">
          <input type="button" class="bg-rose-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#be123c'">
        </div>
        <div>
          <input type="button" class="bg-pink-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#f472b6'">
          <input type="button" class="bg-pink-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#ec4899'">
          <input type="button" class="bg-pink-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#db2777'">
          <input type="button" class="bg-pink-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#be185d'">
        </div>
        <div>
          <input type="button" class="bg-purple-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#c084fc'">
          <input type="button" class="bg-purple-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#a855f7'">
          <input type="button" class="bg-purple-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#9333ea'">
          <input type="button" class="bg-purple-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#7e22ce'">
        </div>
        <div>
          <input type="button" class="bg-indigo-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#818cf8'">
          <input type="button" class="bg-indigo-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#6366f1'">
          <input type="button" class="bg-indigo-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#4f46e5'">
          <input type="button" class="bg-indigo-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#4338ca'">
        </div>
        <div>
          <input type="button" class="bg-sky-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#38bdf8'">
          <input type="button" class="bg-sky-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#0ea5e9'">
          <input type="button" class="bg-sky-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#0284c7'">
          <input type="button" class="bg-sky-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#0369a1'">
        </div>
        <div>
          <input type="button" class="bg-teal-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#2dd4bf'">
          <input type="button" class="bg-teal-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#14b8a6'">
          <input type="button" class="bg-teal-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#0d9488'">
          <input type="button" class="bg-teal-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="corporateColor = '#0f766e'">
        </div>
      </div>
    </div>

    <div class="mt-6">
      <label for="text-color" class="block text-sm font-medium text-gray-700">{{ $t('customization.text_color') }}</label>
      <div class="flex">
        <input type="text" v-model="textColor" name="academy-tagline" id="brand-color" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" aria-describedby="email-description">
        <div id="text-color" class="ml-4 w-10 h-10 border border-gray-300 rounded-md text-center"></div>
      </div>
    </div>

    <alert-component class="mt-4">
      Puedes buscar tu color personalizado en <a class="font-semibold" href="https://htmlcolorcodes.com/es/" target="_blank">https://htmlcolorcodes.com/es/</a> y copiar el valor hexadecimal.
    </alert-component>

    <div class="m-8 rounded-md">
      <div class="flex justify-center my-4">
      <input type="button" style="background-color: #090909" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#090909'">
      <input type="button" style="background-color: #FFFFFF" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#FFFFFF'">
      <input type="button" style="background-color: #ef4444" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#ef4444'">
      <input type="button" style="background-color: #fbbf24" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#fbbf24'">
      <input type="button" style="background-color: #facc15" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#facc15'">
      <input type="button" style="background-color: #4ade80" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#4ade80'">
      <input type="button" style="background-color: #2dd4bf" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#2dd4bf'">
      <input type="button" style="background-color: #60a5fa" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#60a5fa'">
      <input type="button" style="background-color: #a78bfa" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#a78bfa'">
      <input type="button" style="background-color: #e879f9" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#e879f9'">
      <input type="button" style="background-color: #f472b6" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#f472b6'">
      <input type="button" style="background-color: #fb7185" class="w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 border hover:border-2 hover:border-white" v-on:click="textColor = '#fb7185'">
    </div>
    </div>

    <div class="m-8 rounded-md">
      <div class="my-4 grid grid-cols-6 text-center">
      <div class="">
        <input type="button" class="bg-slate-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#94a3b8'">
        <input type="button" class="bg-slate-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#64748b'">
        <input type="button" class="bg-slate-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#475569'">
        <input type="button" class="bg-slate-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#334155'">
      </div>
      <div class="">
        <input type="button" class="bg-stone-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#a8a29e'">
        <input type="button" class="bg-stone-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#78716c'">
        <input type="button" class="bg-stone-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#57534e'">
        <input type="button" class="bg-stone-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#44403c'">
      </div>
      <div class="">
        <input type="button" class="bg-red-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#f87171'">
        <input type="button" class="bg-red-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#ef4444'">
        <input type="button" class="bg-red-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#dc2626'">
        <input type="button" class="bg-red-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#b91c1c'">
      </div>
      <div>
        <input type="button" class="bg-orange-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#fb923c'">
        <input type="button" class="bg-orange-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#f97316'">
        <input type="button" class="bg-orange-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#ea580c'">
        <input type="button" class="bg-orange-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#c2410c'">
      </div>
      <div class="">
        <input type="button" class="bg-yellow-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#facc15'">
        <input type="button" class="bg-yellow-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#eab308'">
        <input type="button" class="bg-yellow-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#ca8a04'">
        <input type="button" class="bg-yellow-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#a16207'">
      </div>
      <div class="">
        <input type="button" class="bg-green-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#4ade80'">
        <input type="button" class="bg-green-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#22c55e'">
        <input type="button" class="bg-green-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#16a34a'">
        <input type="button" class="bg-green-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#15803d'">
      </div>
      <div>
        <input type="button" class="bg-rose-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#fb7185'">
        <input type="button" class="bg-rose-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#f43f5e'">
        <input type="button" class="bg-rose-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#e11d48'">
        <input type="button" class="bg-rose-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#be123c'">
      </div>
      <div>
        <input type="button" class="bg-pink-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#f472b6'">
        <input type="button" class="bg-pink-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#ec4899'">
        <input type="button" class="bg-pink-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#db2777'">
        <input type="button" class="bg-pink-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#be185d'">
      </div>
      <div>
        <input type="button" class="bg-purple-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#c084fc'">
        <input type="button" class="bg-purple-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#a855f7'">
        <input type="button" class="bg-purple-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#9333ea'">
        <input type="button" class="bg-purple-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#7e22ce'">
      </div>
      <div>
        <input type="button" class="bg-indigo-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#818cf8'">
        <input type="button" class="bg-indigo-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#6366f1'">
        <input type="button" class="bg-indigo-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#4f46e5'">
        <input type="button" class="bg-indigo-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#4338ca'">
      </div>
      <div>
        <input type="button" class="bg-sky-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#38bdf8'">
        <input type="button" class="bg-sky-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#0ea5e9'">
        <input type="button" class="bg-sky-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#0284c7'">
        <input type="button" class="bg-sky-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#0369a1'">
      </div>
      <div>
        <input type="button" class="bg-teal-400 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#2dd4bf'">
        <input type="button" class="bg-teal-500 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#14b8a6'">
        <input type="button" class="bg-teal-600 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#0d9488'">
        <input type="button" class="bg-teal-700 w-14 h-14 rounded-md hover:shadow-md cursor-pointer mr-2 hover:border-2 hover:border-white" v-on:click="textColor = '#0f766e'">
      </div>
      </div>
    </div>

    <template v-slot:footer>
      <button type="button" v-on:click="getColors" :disabled="colors.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.cancel') }}
      </button>

      <button type="button" v-on:click="saveColors" :disabled="colors.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.save') }}
      </button>
    </template>
  </card-with-header-and-footer-component>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import ColorsService from "@/services/academy/ColorsService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import AlertComponent from "@/components/feedback/alerts/AlertComponent";

export default {
  name: "ColorsComponent",
  components: {
    CardWithHeaderAndFooterComponent,
    AlertComponent
  },
  data () {
    return {
      colors: {
        loading: true,
        request: {
          brand_color: "",
          text_color: "",
          theme_header_block: ""
        }
      },
    }
  },
  mounted() {
    this.getColors()
  },
  setup(){

    const authUserStore = useAuthUserStore();

    return {
      authUserStore
    }
  },
  methods: {
    getColors() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.colors.loading = true;

      ColorsService.index(academy_uuid)
          .then((response) => {
            console.log("Se han obtenido los colores correctamente", response)
            this.colors.request = response.data
            document.getElementById('corporate-color').style.backgroundColor = this.corporateColor
            document.getElementById('text-color').style.backgroundColor = this.textColor
          })
          .catch((response) => {
            console.log("Error obteniendo los datos de colores", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.colors.loading = false
          })
    },
    saveColors() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.colors.loading = true;

      ColorsService.update(academy_uuid, this.colors.request)
          .then((response) => {
            console.log("Se han almacenado los colores correctamente", response)
            NotificationService.showNotification("Los datos de los colores se han almacenado correctamente", "success")

          })
          .catch((error) => {
            console.log("Error almacenando los colores", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.colors.loading = false
          })
    },
  },
  computed: {
    corporateColor:
    {
      get()
      {
        return this.colors.request.brand_color
      },
      set(color)
      {
        this.colors.request.brand_color = color
        document.getElementById('corporate-color').style.backgroundColor = color
      },
    },
    textColor:
    {
       get()
       {
         return this.colors.request.text_color
       },
       set(color)
       {
         this.colors.request.text_color = color
         document.getElementById('text-color').style.backgroundColor = color
       }
    }
  }
}
</script>

<style scoped>

</style>