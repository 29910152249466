
import { defineStore } from 'pinia'
import {useAuthUserStore} from "@/stores/auth";
import axios from "axios";
var moment = require('moment');
moment.locale('es_ES')

export const useAcademiesStore = defineStore('academies', {
    state: () => ({
        loading: true,
        academy: {
            uuid: "",
            title: "",
            tagline: "",
            description: "",
            subdomain: "",
            language_id: null,
            languageName: "",
            colors: {
                brand: "",
                text: "",
            },

            branding: {
                banner_handle: "",
                banner_url: "",
                brand_color: "",
                text_color: "",
                logo_handle: "",
                logo_url: ""
            },
            options: "",
            rrss: {
                website_url: "",
                email: "",
                phone: "",
                facebook_url: "",
                twitter_url: "",
                instagram_url: "",
                linkedin_url: "",
                youtube_url: ""
            },
            address: {
                address_line_1: "",
                address_line_2: "",
                postal_code: "",
                city: "",
                state: "",
                country: "",
                fiscal_fullname: "",
                vat_id: "",
            },
            url: "",
            saas_subscriptions: [],
            main_saas_subscription: {},
            remainingDaysOnFreeSession: null,
            is_main_saas_subscription_active: false,
        }
    }),

    //TODO: Hacer getters cuando alimente el state

    getters: {
        getSaasSubscriptionsStatus: (state) => {
            if (state.academy && state.academy.main_saas_subscription)
            {
                return state.academy.main_saas_subscription.status
            }
        },
        isTrialExpired(state) {
            // No molestamos al usuario si aun no tenemos el dato
            if (!state.academy.main_saas_subscription)
            {
                return false;
            }

            // Si no estamos en trial no calculamos nada
            if (this.getSaasSubscriptionsStatus != 'trialing')
            {
                return false;
            }

            let trialEndsAt = moment(state.academy.main_saas_subscription.trial_ends_at)
            let result = trialEndsAt.diff(moment(), 'days')

            if(result < 0)
            {
                return true
            }

            return false
        },
        isSaasSubscriptionCanceled: (state) => {
            if (state.academy.main_saas_subscription && state.academy.main_saas_subscription.status && state.academy.main_saas_subscription.status === 'canceled')
            {
                return true
            }
        },
        getSelectedAcademyUuid: () => localStorage.getItem('selectedAcademyUuid')
    },

    actions: {
        getAcademy() {
            let academy_uuid = useAuthUserStore().user.academy.uuid
            return axios
                .get(process.env.VUE_APP_API_URL + '/academies/' + academy_uuid)
                .then( (response) => {
                    console.log("Los datos de academia recibidos para son:", response.data.data)
                    this.setAcademyData(response.data.data)
                })
                .catch( (response) => {
                    console.log("Se ha producido un error", response)
                })
        },

        getGoalOptions () {
            return axios
                .get(process.env.VUE_APP_API_URL + '/academies/segment/goal')
        },

        getAreaOptions () {
            return axios
                .get(process.env.VUE_APP_API_URL + '/academies/segment/area')
        },

        getSectorOptions () {
            return axios
                .get(process.env.VUE_APP_API_URL + '/academies/segment/sector')
        },

        getVolumeOptions () {
            return axios
                .get(process.env.VUE_APP_API_URL + '/academies/segment/volume')
        },

        subdomainIsFree(subdomain) {
            console.log("El subdominio es: ", subdomain)
            return axios
                .get('/academies/subdomain/check')
        },
        getAcademyLanguage (academyUuid) {
            return useAuthUserStore().getApiClient.get('/academy/language', {
                params: {
                    academy_uuid: academyUuid
                }
            })
        },

        //Mutations
        setAcademyData(academyData) {
            this.academy = academyData
            this.setSelectedAcademyUuid(this.academy.uuid)
        },

        setSelectedAcademyUuid(uuid)
        {
            localStorage.setItem('selectedAcademyUuid', uuid)
        }

    }

})