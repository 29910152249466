<template>
  <!-- TODO: En el momento que sea necesario, este componente mostrará un submenú. Copiando arquitectura de otros componentes como productos.-->
  <router-view></router-view>
</template>

<script>
export default {
  name: "NavigationProjectComponent"
}
</script>

<style scoped>

</style>