export default {
    create_account: 'Crea un compte',
    login: 'inicia sessió',
    access_with: 'Accedeix amb',
    continue_with: 'O contúnia amb',
    error_text1: 'Upps! Hi ha',
    error_text2: 'a la teva sol·licitud.',
    privacy_text1: 'He llegit i accepto la Política de privadesa. Per a més informació, consulta la nostra ',
    privacy_text2: 'política de privadesa',
    marketing_text: 'Consenteixo de manera prèvia i expressa que BrainsPro m\'enviï comunicacions comercials sobre els seus serveis.',
    want_academy: 'Vull la meva acadèmia!',
    ad_text1: 'Converteix els teus cursos online a ',
    ad_text2: "experiències d'aprenentatge",
    ad_text3: " d'alt impacte",
    ad_text4: 'Els teus estudiants agrairan la qualitat dels teus serveis i ho tindràs tot sota control de forma senzilla',
    button_next: 'Següent',
    start: 'Crear compte',
    error_firstname_required: "Has d'introduir el teu nom",
    error_lastname_required: "Has d'introduir els teus Cognoms",
    error_email_address_required: "Heu d'introduir un correu electrònic vàlid",
    error_academy_slug_required: "Heu d'introduir un domini vàlid",
    error_phone_required: "Heu d'introduir un número telèfon vàlid",
    error_pass_required: "Heu d'introduir una contrasenya vàlida (mínim 8 caràcters)",
    error_confirm_pass_required: "Ha de ser la mateixa contrasenya que l'anterior",
    error_domain_used: 'El domini està sent usat, introduïu un altre.',
    error_email_used: 'Aquest email ja existeix. Si us plau inicia sessió o crea el teu compte amb un altre email',
    subdomain: 'Subdomini',

    //CustomerDataComponent
    title_create_your_academy: 'Ja gairebé estem...',
    question_goal: 'Quin és el teu principal objectiu a Brainspro?',
    select_goal: 'Selecciona un objectiu',
    question_volume: 'Quantes persones vols formar?',
    select_volume: 'Selecciona un rang aproximat',
    question_sector: "Quin és el vostre sector d'activitat?",
    select_sector: 'Selecciona un sector',
    question_area: 'Quina és la teva àrea professional?',
    select_area: 'Selecciona una àrea',
    back: 'Tornar',

}