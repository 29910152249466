<template>
  <div class="py-4">
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg">
          <div v-for="user in users" :key="user.uuid" class="bg-white border border-gray-200 mb-4 transition-colors ease-in">
            <div class="px-6 py-4 whitespace-no-wrap">
              <div class="flex items-center">
                <div class="flex-shrink-0 h-12 w-1/5">
                  <img v-if="user.avatar" class="h-12 rounded-full shadow" :src="user.avatar" alt="">
                  <div v-else class="h-12 w-12 bg-brainspro-400 rounded-full shadow">
                    <img src="/images/avatar-transparent.png">
                  </div>
                </div>
                <div class="flex-shrink-0 w-4/5">
                  <div class="text-md leading-5 font-medium text-gray-900 mb-1">{{user.firstname}} {{user.lastname}}</div>
                  <span class="text-md leading-5 text-brainspro-800 rounded mr-2 inline bg-brainspro-100 py-1 px-3">{{user.role}}</span>
                  <div class="text-md leading-5 text-gray-500 hidden">{{ $t("community.updated_at") }} {{$moment(user.updated_at).format("LLLL")}}</div>
                </div>
              </div>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap">
              <span class="text-md leading-5 text-gray-500">{{user.email}}</span>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
              <span class="text-md leading-5 text-gray-500">{{user.phone}}</span>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
              <div class="flex items-center justify-end" v-on:click="goToUserProfile(user.uuid)">
                <!-- Heroicon name: solid/chevron-right -->
                <div class="flex h-auto w-full justify-end">
                  <h3 class="text-md text-gray-400">{{ $t("community.view_profile") }}</h3>
                  <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserListComponent",
  props: {
    users: Array
  },
  methods: {
    goToUserProfile(user_uuid)
    {
      this.$router.push({ name: 'user-profile', params: { id: user_uuid } })
    },
  }
}
</script>

<style scoped>

</style>