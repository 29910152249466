import {useAuthUserStore} from "@/stores/auth";

export default {

    index(academyUuid) {
        return useAuthUserStore().getApiClient.get('/settings/languages', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },

}