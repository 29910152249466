<template>
  <section class="pt-2 pb-6 py-5 md:mx-8 mx-4">
    <card-with-header-and-footer-component :loading="general.loading">
      <template v-slot:header>
        <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('projects.general') }}</h3>
      </template>

      <div class="col-span-6">
        <label for="project_title" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('general.title') }}</label>
        <input id="project_uuid" v-model="title" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
      </div>
      <div class="flex flex-row justify-between">
        <div class="col-span-6 mt-5">
          <label for="product_duration" class="block text-sm font-medium leading-5 text-gray-700 mb-2">{{ $t('projects.publish_project') }}</label>
          <ToggleComponent v-bind:status="(projectsStore.project.status === 'publish')" :callback="changeProjectStatus" :product="projectsStore.project.uuid"></ToggleComponent>
        </div>
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="general.showModalDeleteProduct = true" :disabled="general.loading" class="mr-2 inline-flex float-left px-4 py-2 text-sm font-medium rounded-md text-red-600 hover:underline">
          <label class="ml-1 cursor-pointer">{{ $t('projects.delete_project') }}</label>
        </button>

        <!-- Modal Component para borrado de cursos -->
        <Teleport to="body">
          <modal-component id="modal-unregister" v-if="general.showModalDeleteProduct" :onProcess="confirmModalDeleteProduct" :onCancel="closeModalDeleteProduct">
            <template v-slot:header>{{ $t('projects.warning_delete') }}</template>
            <p>{{ $t('projects.message_delete_project') }}</p>
            <p class="font-bold mt-2">{{ $t('projects.question_delete_project') }}</p>
            <div class="mt-4">
              <input type="checkbox" id="checkbox-double-confirmation" v-model="general.doubleConfirmationDeleteProduct">
              <label class="ml-2">{{ $t('projects.delete') }}</label>
              <div>
                <p class="mt-2 text-sm text-gray-400" id="error-firstname">
                  {{ $t('projects.confirmation_delete_project') }}</p>
              </div>
            </div>
          </modal-component>
        </Teleport>

        <button type="button" v-on:click="getProjectGeneral" :disabled="general.loading" class="hidden mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="setProjectGeneral" :disabled="general.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>

    <card-with-header-and-footer-component :loading="general.loading" class="mt-8">
      <template v-slot:header>
        <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{$t('projects.navigation')}}</h3>
      </template>


        <div class="col-span-6 grid grid-cols-2 gap-4 ">
          <div class="form-check">
            <input value="1" id="access_draft" v-model="this.projectsStore.navigation.navigationMode" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
            <label class=" inline-block text-gray-800" >
              {{$t('projects.navigation_free')}}
            </label>
            <p class="text-gray-400 text-sm ml-6 bg-bp">{{$t('projects.navigation_without_restriction')}}</p>
          </div>
          <div class="form-check">
            <input value="2" id="access_public" v-model="this.projectsStore.navigation.navigationMode" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
            <label class=" inline-block text-gray-800" >
              {{$t('projects.navigation_secuential')}}
            </label>
            <p class="text-gray-400 text-sm ml-6">{{$t('projects.navigation_completed_restriction')}}</p>
          </div>
        </div>

      <div class="mt-8">
        <label class="mb-4 block text-sm font-medium leading-5 text-gray-700">{{$t('projects.quest_lesson_complete')}}</label>
        <div class="flex flex-row">
          <div class="form-check">
            <input id="complete_visit" v-model="this.projectsStore.navigation.completeLesson" v-bind:value="1" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
            <label class=" text-gray-800" >
              {{$t('projects.lesson_complete_at_visit')}}
            </label>
            <p class="text-gray-400 text-sm ml-6 bg-bp">{{$t('projects.lesson_at_visit_info')}}</p>
          </div>
        </div>
        <div class="flex flex-row mt-4">
          <div class="form-check">
            <input id="complete_time" v-model="this.projectsStore.navigation.completeLesson" v-bind:value="2" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
            <label class="flex text-gray-800">
              {{$t('projects.lesson_complete_at_see')}}
            </label>
            <p class="text-gray-400 text-sm ml-6 bg-bp">{{$t('projects.lesson_at_see_info')}}</p>
          </div>
        </div>
        <div class="flex flex-row mt-4">
          <div class="form-check">
            <div class="flex lg:flex-row">
              <input id="complete_time" v-model="this.projectsStore.navigation.completeLesson" v-bind:value="3" class="h-4 w-4 border border-gray-300 bg-white focus:ring-bpblue checked:text-bpblue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
              <label class="text-gray-800">
                {{$t('projects.lesson_complete_on_time')}}
              </label>
              <div class="mt-2">
                <input type="time" min="00:00" max="23:59" name="time_to_complete" id="time_to_complete" v-model="this.projectsStore.navigation.timeToComplete" class="ml-4 -mt-1 lg:-mt-1 lg:mx-4 lg:px-2 py-1 form-input border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
              </div>
            </div>
            <p class="mt-1 text-gray-400 text-sm ml-6 bg-bp">{{$t('projects.lesson_on_time_info')}}</p>
          </div>
        </div>
      </div>


      <div class="col-span-6 mt-6">
        <label for="auto_navigation" class="block text-sm font-medium leading-5 text-gray-700">{{$t('projects.automatic_navigation')}}</label>
        <div class="flex flex-row mt-1">
          <select v-model="this.projectsStore.navigation.autoNavigation" class="rounded mt-1 form-input border border-gray-300 text-sm font-medium focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            <option v-bind:value="1">{{$t('projects.automatic_navigation_disabled')}}</option>
            <option v-bind:value="2">{{$t('projects.automatic_navigation_enabled')}}</option>
          </select>
        </div>
        <label class="flex flex-row text-gray-400 mt-1 text-sm font-normal" >
          {{$t('projects.automatic_navigation_info')}}
        </label>
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="general.showModalDeleteProduct = true" :disabled="general.loading" class="hidden mr-2 inline-flex float-left px-4 py-2 text-sm font-medium rounded-md text-red-600 hover:underline">
          <label class="ml-1 cursor-pointer">{{ $t('projects.delete_project') }}</label>
        </button>
      <button type="button" v-on:click="getProjectNavigation" :disabled="general.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.cancel') }}
      </button>

      <button type="button" v-on:click="setProjectNavigation" :disabled="general.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.save') }}
      </button>
      </template>
    </card-with-header-and-footer-component>
  </section>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import NotificationService from "@/services/notification/NotificationService";
import productService from "../../../services/product/ProductService";
import notificationService from "../../../services/notification/NotificationService";
import ModalComponent from "../../../components/modals/ModalComponent";
import {useProjectsStore} from "@/stores/projects";
import ToggleComponent from "@/components/forms/ToggleComponent";

export default {
  name: "ProjectGeneralComponent",
  components: {CardWithHeaderAndFooterComponent, ModalComponent, ToggleComponent},
  setup()
  {
    const projectsStore = useProjectsStore()

    return {
      projectsStore
    }
  },
  data() {
    return {
      general: {
        loading: true,
        showModalDeleteProject: false,
        doubleConfirmationDeleteProduct: false,
        autoNavigation: 'Desactivado',
        navigationSelect: false,
        completeSelect: false,
        time: '',
      },
      request: {
        product: ''
      }
    }
  },
  mounted() {
    this.general.loading = false;
  },
  methods: {
    setProjectGeneral() {
      this.projectsStore.loading = true
      console.log("Guardando proyecto")
      this.projectsStore.setProjectGeneral()
          .then((response) => {
            console.log("Almacenando sección General del proyecto", response)
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el proyecto", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.projectsStore.loading = false
          })
    },
    closeModalDeleteProduct() {
      this.general.showModalDeleteProduct = false
      this.general.doubleConfirmationDeleteProduct = false
    },
    confirmModalDeleteProduct() {
      if (this.general.doubleConfirmationDeleteProduct)
      {
        console.log("Borrando proyecto...")
        //this.general.loading = true
        this.general.showModalDeleteProduct = false
        this.general.doubleConfirmationDeleteProduct = false
        //Llamar a servicio
        productService.delete()
            .then((response) =>{
              console.log("Delete product devuelve: ", response)
              //Una vez borrado...
              notificationService.showNotification("El proyecto se ha eliminado correctamente", "success")
              this.$router.go(-1)
            })
            .catch((error) => {
              console.log("Se ha producido un error: ", error)
            })
            .finally(() => {
              this.general.loading = false
            })
      }
    },
    changeProjectStatus(project_uuid, status) {
      console.log("El uuid del projecto para cambiar el estado es: ", project_uuid)
      console.log("El estado del project es: ", status)

      /*
      let academy_uuid = this.authUserStore.user.academy.uuid;
      console.log("Cambiando el estado del producto", product_uuid, status)
      productService.changeProductStatus(academy_uuid, product_uuid, status)
          .then((response) => {
            console.log("Se ha cambiado  correctamente", response)
            var message = (status) ? "Se ha publicado el curso" : "Se ha despublicado el curso"
            NotificationService.showNotification(message, "success")

          })
          .catch((response) => {
            console.log("Error solicitando el cambio de estado del producto", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")

          })
          .finally(() => {
            //Silence is golden
          })

       */

    },
    getProjectNavigation() {
      this.general.navigationSelect = this.projectsStore.navigation.navigationMode
      this.general.time = this.projectsStore.navigation.completeLesson.onTime
    },
    setProjectNavigation() {
      //TODO seteará los datos en pinia
    }
  },
  computed: {
    title: {
      get() {
        return this.projectsStore.project.title
      },
      set(value) {
        this.projectsStore.project.title = value
      }
    },
  }
}
</script>

<style scoped>

</style>