<template>
  <div class="max-w-7xl mx-auto py-2 md:py-6 sm:px-6 lg:px-4 grid grid-cols-2 gap-4">
    <section class="p-4 col-span-2">
      <card-with-header-and-footer-component :loading="brandname.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.brand_academy") }}</h3>
        </template>

        <div>
          <label for="academy-name" class="block text-sm font-medium text-gray-700">{{ $t("customization.academy_name") }}</label>
          <div class="mt-1">
            <input type="text" v-model="brandname.request.title" name="academy-name" id="academy-name" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="$t('customization.my_academy')" aria-describedby="email-description">
          </div>
          <p class="mt-2 text-sm text-gray-500" id="academy-name-description">{{ $t("customization.full_name") }}</p>
        </div>

        <div class="mt-6">
          <label for="academy-tagline" class="block text-sm font-medium text-gray-700">{{ $t("customization.tagline") }}</label>
          <div class="mt-1">
            <input type="text" v-model="brandname.request.tagline" name="academy-tagline" id="academy-tagline" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="$t('customization.my_academy')" aria-describedby="email-description">
          </div>
          <p class="mt-2 text-sm text-gray-500" id="academy-tagline-description">{{ $t("customization.slogan") }}</p>
        </div>

        <template v-slot:footer>
          <button type="button" v-on:click="getBrandname" :disabled="brandname.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setBrandname" :disabled="brandname.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>
    <section class="p-4 mt-6 col-span-2 md:col-span-1">
        <logo-branding-component></logo-branding-component>
    </section>
    <section class="p-4 mt-6 col-span-2 md:col-span-1">
      <favicon-component></favicon-component>
    </section>
    <section class="p-4 mt-6 col-span-2">
      <banner-branding-component></banner-branding-component>
    </section>
    <section class="p-4 mt-6 col-span-2">
      <colors-component></colors-component>
    </section>
    <!-- Link to intercoms helpcenter manual -->
    <help-center-component :url="this.helpCenter.kn_branding_url" class="pl-3"></help-center-component>

  </div>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import BrandnameService from "@/services/academy/BrandnameService";
import LogoBrandingComponent from "@/views/customization/branding/LogoBrandingComponent";
import FaviconComponent from "@/views/customization/branding/FaviconComponent";
import BannerBrandingComponent from "@/views/customization/branding/BannerBrandingComponent";
import ColorsComponent from "@/views/customization/branding/ColorsComponent";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import HelpCenterComponent from "@/views/common/HelpCenterComponent";
import helpcenter from "@/services/helpcenter/links";

export default {
name: "BrandingComponent",
  components: {
    HelpCenterComponent,
    ColorsComponent,
    BannerBrandingComponent,
    LogoBrandingComponent,
    FaviconComponent,
    CardWithHeaderAndFooterComponent,
  },
  setup(){

    const authUserStore = useAuthUserStore();
    const helpCenter = helpcenter

    return {
      authUserStore,
      helpCenter
    }
  },
  mounted() {
    this.getBrandname()
  },
  data() {
    return {
      brandname: {
        loading: true,
        request: {
          title: "",
          tagline: "",
        }
      },
    }
  },
  methods: {
    getBrandname() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.brandname.loading = true;

      BrandnameService.index(academy_uuid)
          .then((response) => {
            console.log("Se ha obtenido el brandname correctamente", response)
            this.brandname.request = response.data
          })
          .catch((response) => {
            console.log("Error obteniendo los datos de marca", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.brandname.loading = false
          })
    },
    setBrandname() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.brandname.loading = true;

      BrandnameService.update(academy_uuid, this.brandname.request)
          .then((response) => {
            console.log("Se ha almacenado la marca correctamente", response)
            NotificationService.showNotification("Los datos de la academia se han almacenado correctamente", "success")
          })
          .catch((error) => {
            console.log("Error almacenando el brandname", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.brandname.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>