<template>
  <div>
    <section class="pt-2 pb-6 md:py-5">
      <card-with-header-and-footer-component :loading="productsStore.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.principal_page') }}</h3>
        </template>

        <div class="">
          <h4 class="font-medium leading-5 text-gray-600 sm:truncate mb-3">{{ $t('products.objectives_presentation') }}</h4>
          <vue-editor id="header-editor" v-model="header"></vue-editor>
          <p class="font-light leading-5 text-gray-500 sm:truncate mt-2">{{ $t('products.learning_objectives') }}</p>
        </div>

        <div class="mt-10">
          <h4 class="font-medium leading-5 text-gray-600 sm:truncate mb-3">{{ $t('products.teaching_staff') }}</h4>
          <vue-editor id="why-editor" v-model="why"></vue-editor>
        </div>

        <div class="mt-10">
          <h4 class="font-medium leading-5 text-gray-600 sm:truncate mb-3">{{ $t('products.public') }}</h4>
          <vue-editor id="what-editor" v-model="what"></vue-editor>
        </div>

        <div class="mt-10">
          <h4 class="font-medium leading-5 text-gray-600 sm:truncate mb-3">{{ $t('products.content') }}</h4>
          <vue-editor id="toc-editor" v-model="toc"></vue-editor>
        </div>

        <div class="mt-10">
          <h4 class="font-medium leading-5 text-gray-600 sm:truncate mb-3">{{ $t('products.sidebar') }}</h4>
          <!--<vue-editor id="aside-editor" v-model="aside"></vue-editor>-->
          <article-editor-component v-model="aside"></article-editor-component>
        </div>

        <template v-slot:footer>
          <button type="button" v-on:click="reloadProduct" :disabled="productsStore.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.cancel') }}
          </button>

          <button type="button" v-on:click="setProductPage" :disabled="productsStore.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.save') }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>
  </div>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import { VueEditor } from "vue3-editor";
import NotificationService from "@/services/notification/NotificationService";
import {useProductsStore} from "@/stores/products";
import ArticleEditorComponent from "@/components/editor/ArticleEditorComponent";

export default {
  name: "PageComponent",
  components: {
    ArticleEditorComponent,
    CardWithHeaderAndFooterComponent,
    VueEditor,
  },
  setup(){

    const productsStore = useProductsStore()

    return {
     productsStore
    }
  },
  computed: {
    header: {
      get() {
        return this.productsStore.product.content.header
      },
      set(value) {
        this.productsStore.product.content.header = value
      }
    },
    why: {
      get() {
        return this.productsStore.product.content.why
      },
      set(value) {
        this.productsStore.product.content.why = value
      }
    },
    what: {
      get() {
        return this.productsStore.product.content.what
      },
      set(value) {
        this.productsStore.product.content.what = value
      }
    },
    toc: {
      get() {
        return this.productsStore.product.content.toc
      },
      set(value) {
        this.productsStore.product.content.toc = value
      }
    },
    aside: {
      get() {
        return this.productsStore.product.content.aside
      },
      set(value) {
        this.productsStore.product.content.aside = value
      }
    },
  },
  methods: {
    reloadProduct() {
      this.productsStore.getProduct(this.$route.params.id)
    },
    setProductPage() {
      this.productsStore.setProductPage()
          .then((response) => {
            console.log("Almacenando seccion page del producto", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el producto", error)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.productsStore.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>