<template>
  <card-with-header-and-footer-component class="bg-white" :loading="change_password.loading">
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('profile.change_pass') }}</h3>
    </template>

    <div class="my-4">
      <label for="user-current-pass" class="block text-sm font-medium text-gray-700">{{
          $t('profile.current_pass')
        }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input v-bind:type="viewOldPassType" name="user-current-pass" id="user-current-pass"
               v-model="change_password.request.current_password"
               class="focus:ring-bpblue focus:border-bpblue block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
               :placeholder="$t('profile.enter_pass')" aria-describedby="price-currency">
        <!-- Icono para mostrar contraseña o no -->
        <div @click="clickOnEyeOld" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
          <!-- Heroicon name: solid/question-mark-circle -->
          <svg v-if="!viewOldPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
          </svg>
        </div>
        <div @click="clickOnEyeOld" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
          <svg v-if="viewOldPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
          </svg>
        </div>
        <!-- fin de icono ojo -->
      </div>
    </div>

    <div class="my-4" v-if="!change_password.request.change">
      <label for="user-new-password" class="block text-sm font-medium text-gray-700">{{$t('profile.new_pass') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input name="user-new-password" id="user-new-password"
               v-model="v$.change_password.request.new_password.$model"
               v-bind:type="viewPassType"
               class="focus:ring-bpblue focus:border-bpblue block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
               :placeholder="$t('profile.enter_new_pass')" aria-describedby="price-currency">
        <!-- Icono para mostrar contraseña o no -->
        <div @click="clickOnEye" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
          <!-- Heroicon name: solid/question-mark-circle -->
          <svg v-if="!viewPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
          </svg>
        </div>
        <div @click="clickOnEye" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
          <svg v-if="viewPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
          </svg>
        </div>
        <!-- fin de icono ojo -->
      </div>
    </div>

    <div class="my-4" v-if="!change_password.request.change">
      <label for="user-new-password-confirmation"
             class="block text-sm font-medium text-gray-700">{{ $t('profile.confirm_pass') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input name="user-new-password-confirmation" id="user-new-password-confirmation"
               v-model="v$.change_password.request.new_password_confirmation.$model"
               v-bind:type="viewPassType"
               class="focus:ring-bpblue focus:border-bpblue block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
               placeholder="Confirma la nueva contraseña" aria-describedby="price-currency">
      </div>
      <p v-if="v$.change_password.request.new_password_confirmation.$error" class="mt-2 text-sm text-red-600"
         id="error-password_confirmation">{{ $t('profile.password_not_match') }}</p>
    </div>

    <template v-slot:footer>
      <button type="button" v-on:click="setNewPassword" :disabled="change_password.loading"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpbluedeep">
        {{ $t('general.save') }}
      </button>
    </template>
  </card-with-header-and-footer-component>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import PasswordService from "@/services/user/PasswordService";
import NotificationService from "@/services/notification/NotificationService";
import {maxLength, minLength, required, sameAs} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "ChangePasswordComponent",
  components: {CardWithHeaderAndFooterComponent},
  data() {
    return {
      change_password: {
        loading: false,
        request: {
          current_password: '',
          new_password: '',
          new_password_confirmation: ''
        }
      },
      viewOldPass: false,
      viewPass: false
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      change_password: {
        request: {
          current_password: {
            required,
            minLength: minLength(8),
            maxLengthValue: maxLength(255)
          },
          new_password: {
            required,
            minLength: minLength(8),
            maxLengthValue: maxLength(255)
          },
          new_password_confirmation: {
            required,
            minLength: minLength(8),
            maxLengthValue: maxLength(255),
            sameAsPassword: sameAs(this.change_password.request.new_password_confirmation)
          }
        }
      }
    }
  },
  computed: {
    viewOldPassType() {
      return (this.viewOldPass) ? 'text' : 'password'
    },
    viewPassType() {
      return (this.viewPass) ? 'text' : 'password'
    }
  },
  methods: {
    clickOnEye() {
      this.viewPass = !this.viewPass
    },
    clickOnEyeOld() {
      this.viewOldPass = !this.viewOldPass
    },
    setNewPassword() {
      this.change_password.loading = true;

      PasswordService.update(this.change_password.request)
          .then((response) => {
            console.log("Se ha modificado la contraseña correctamente", response)
            NotificationService.showNotification("Tu contraseña se ha cambiado correctamente", "success")
          })
          .catch((error) => {
            console.log("Error almacenando la nueva contraseña", error.response)
            if (error.response.data.errors) {
              error.response.data.errors.forEach((error) => {
                NotificationService.showNotification(error, "error")
              })
            } else {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.change_password.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>