<template>
  <section class="pt-2 pb-6 md:py-5">
    <card-with-header-and-footer-component :loading="productsStore.loading">
      <template v-slot:header>
        <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.seo_configuration') }}</h3>
      </template>

      <div class="col-span-6">
        <div>
          <label for="seo_title" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('products.seo_title') }}</label>
          <input id="seo_title" v-model="seo_title" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
      </div>

      <div class="col-span-6 mt-5">
        <div>
          <label for="slug" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('products.slug') }}</label>
          <input id="slug" v-model="slug" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
      </div>

      <div class="mt-5">
        <h4 class="font-medium leading-5 text-gray-600 sm:truncate mb-3">{{ $t('products.seo_description') }}</h4>
        <textarea v-model="seo_description" class="mt-1 h-24 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="getProductGeneral" :disabled="productsStore.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="setProductSEO" :disabled="productsStore.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </section>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import NotificationService from "@/services/notification/NotificationService";
import {useProductsStore} from "@/stores/products";

export default {
  name: "SeoComponent",
  components: {CardWithHeaderAndFooterComponent},
  setup(){

    const productsStore = useProductsStore()

    return {
      productsStore
    }

  },
  data() {
    return {
      general: {
        loading: true,
        request: {
          title: "",
          category: "",
          duration: ""
        }
      }
    }
  },
  methods: {
    getProductGeneral() {
      this.productsStore.getProduct(this.$route.params.id)
    },
    setProductSEO() {
      console.log("Guardando producto")
      this.productsStore.setProductSEO()
          .then((response) => {
            console.log("Almacenando seccion branding del producto", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el producto", error)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.productsStore.loading = false
          })
    }
  },
  computed: {
    seo_description: {
      get() {
        return this.productsStore.product.seo.seo_description
      },
      set(value) {
        this.productsStore.product.seo.seo_description = value
      }
    },
    slug: {
      get() {
        return this.productsStore.product.slug
      },
      set(value) {
        this.productsStore.product.slug = value
      }
    },
    seo_title: {
      get() {
        return this.productsStore.product.seo.seo_title
      },
      set(value) {
        this.productsStore.product.seo.seo_title = value
      }
    }
  }
}
</script>

<style scoped>

</style>