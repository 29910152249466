export default {
    //BankAccountComponent
    payments_receipt: 'Recibo de pagamentos',
    beneficiary: 'Beneficiário',
    bic: 'BIC',
    account_number: 'Número de conta',
    enter_beneficiary: 'Entrar beneficiário',
    enter_bic: 'Entre no BIC',
    enter_account_number: 'Introduza o número da conta',
    info_billing_msg: 'Introduza esta informação para receber o rendimento na sua conta',
    info_billing_send: 'Enviar por correio para ',
    info_billing_dni_msg: ' cópia digitalizada a cores do DNI / NIE do representante legal da sua empresa e/ou do CIF da sua empresa.',

    //BasicDataComponent
    profile_info: 'Informação de perfil',
    enter_name: 'Introduza o seu nome',
    enter_lastname: 'Introduza o seu apelido',
    error_name: 'Deve introduzir um nome',
    error_lastname: 'Deve introduzir o seu apelido',

    //ChangePasswordComponent
    change_pass: 'Altere a sua palavra-passe',
    current_pass: 'Senha actual',
    new_pass: 'Nova palavra-passe',
    confirm_pass: 'Repita a sua nova palavra-passe',
    enter_pass: 'Introduza a palavra-passe actual',
    enter_new_pass: 'Introduza a sua nova palavra-passe',
    repeat_new_pass: 'Repita a sua nova palavra-passe',
    password_not_match: 'As palavras-passe não coincidem',

    //UserAvatarComponent
    user_image: 'Imagem do utilizador',
    upload_avatar: 'Carregue o seu avatar',
    change: 'Alterar',
    insert: 'Inserir'
}