<template>
    <div class="pt-2 pb-6 md:py-6">
        <header-component :title="this.projectStore.project.title"></header-component>
        <div class="max-w-7xl mx-auto px-4 sm:px-4 md:px-8">
          <div v-show="$route.name !== 'projects-edit-content'" class="py-4">
            <nav class="py-4">
              <div class="">
                <div class="">
                  <nav class="flex flex-col lg:flex-row justify-start" aria-label="Tabs">
                    <router-link :to="{ name: 'projects-edit', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="lg:mr-4 text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                      <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                      <span>{{ $t('projects.general') }}</span>
                    </router-link>
                    <!-- Current: "border-bpblue text-bpbluedeep", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                    <router-link :to="{ name: 'projects-content'}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" active-class="border-bpblue text-bpblue" class="lg:mr-4 text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium" aria-current="page">
                      <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                      </svg>
                      <span>{{ $t('projects.content') }}</span>
                    </router-link>
                    <router-link :to="{ name: 'projects-edit-access', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="lg:mr-4 text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                      <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                      </svg>
                      <span>{{ $t('projects.access') }}</span>
                    </router-link>
                    <router-link :to="{ name: 'projects-edit-attach-product', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="lg:mr-4 text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                      <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span>{{ $t('projects.product') }}</span>
                    </router-link>
                    <!--
                    <router-link :to="{ name: 'users-enrol-project-list', params: { id : $route.params.id, page : '1' }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="lg:mr-4 text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="-ml-0.5 mr-2 h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                      </svg>
                      <span>Usuarios</span>
                    </router-link>
                    -->
                  </nav>
                </div>
              </div>
            </nav>
          </div>
        </div>
      <section class="mx-auto max-w-7xl">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <router-view class="md:col-span-2"></router-view>
        </div>
      </section>
    </div>
</template>

<script>
    import HeaderComponent from "@/views/common/HeaderComponent";
    //import ProjectCardPreview from "@/views/projects/ProjectEdit/ProjectCardPreview";
    import {useProjectsStore} from "@/stores/projects";

    export default {
        name: "ProjectEditComponent",
        components: {
          HeaderComponent},
        data() {
            return {
              project: null
            }
        },
        setup(){
          const projectStore = useProjectsStore()

          return {
           projectStore
          }
        },
      mounted() {
        this.projectStore.getProject(this.$route.params.id)
      },
      methods: {

      }
    }
</script>

<style scoped>

</style>