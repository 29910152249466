<template>
  <div class="py-4">
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div v-if="!loading" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg">
          <div class="border border-gray-200 mb-4" v-for="payout in payouts" :key="payout.uuid">
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('sales.description')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap">
              {{ payout.concept }}
              <div>{{ payout.uuid }}</div>
              <div class="py-2 whitespace-no-wrap text-md text-gray-500" v-html="payout.description"></div>
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('sales.payment_date')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-500">
              {{ $moment(payout.payout_date).format('DD-MM-YYYY') }}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-900">
              {{$t('sales.quantity')}}
            </div>
            <div class="px-6 py-4 whitespace-no-wrap text-md leading-5 text-gray-500">
              {{ payout.quantity }}€
            </div>
          </div>
        </div>
        <loading-component v-else>{{$t('sales.loading_payments')}}</loading-component>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/views/common/LoadingComponent";

export default {
  name: "PayoutsComponent",
  components: {LoadingComponent},
  props: {
    payouts: Array,
    loading: Boolean
  }
}
</script>

<style scoped>

</style>