export default {

    //EditUserSlideOverComponent
    edit_user: 'Editar utilizador',
    name: 'Nome',
    lastname: 'Apelido',
    email: 'Email',
    password: 'Password',
    close: 'Fechar',

    //SlideOverComponent
    enroll_in: 'Inscrição em:',
    select_project: 'Seleccione um curso...',
    user_role: 'Papel do utilizador',
    student: 'Estudante',
    user_as_student: 'Registe o seu utilizador como estudante',
    teacher: 'Professor',
    user_as_teacher: 'Registe o seu utilizador como professor no curso',
    teacher_no_edition: 'Professor sem permissão de edição',
    user_as_teacher_no_edition: 'Inscreva o seu utilizador como professor sem permissões de edição no curso',
    inspector: 'Inspector',
    user_as_inspector: 'Registe o seu utilizador como inspector da FUNDAE',
    email_notification: 'Notificar por email',
    text_email_notification: 'Notifique o seu aluno de que ele/ela foi inscrito.',
    help_manual_user: 'Quero saber mais sobre a criação manual de utilizadores',

    //CardWithHeaderAndFooterComponent
    text_plan1: 'Esta funcionalidade pertence a um plano superior, por favor',
    text_plan2: 'actualize o seu plano',
    loading_content: 'Estamos a fazer o upload do seu conteúdo',

}