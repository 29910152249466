import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid) {
        return useAuthUserStore().getApiClient.get('/reports/users', {
            params: {
                academy_uuid: academyUuid,
            }
        })
    }
}