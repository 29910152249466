<template>
  <section class="pb-6">
      <project-list-component></project-list-component>
  </section>

</template>

<script>
import ProjectListComponent from "@/views/products/ProductEdit/ProductAttachProject/ProjectAttachedListComponent";

export default {
  name: "ProductAttachProjectComponent",
  components: {ProjectListComponent},
  data: () => {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
