<template>
  <div class="pt-4 pb-6 md:py-6">
    <header-component :title="'¡'+ $t('dashboard.hello') + ' ' + authUserStore.user.firstname +'!'"></header-component>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <!-- Replace with your content -->
      <div class="py-4">
        <section class="pt-1 pb-4 grid gap-4 grid-cols-2">

          <div v-if="annualReport.total === '0.00'" class="bg-white rounded-lg shadow-inner shadow-stone-200 border border-stone-300 h-32 relative">
            <div class="flex flex-col md:p-10 p-6 text-center">
              <dd class="text-stone-400 order-2 mt-2 text-sm md:text-base lg:text-2xl leading-6 font-semibold">
                {{ $t('dashboard.no_annual_data') }}
              </dd>
            </div>
          </div>
          <div v-else>
            <default-card-component :quantity="annualReport.total + '€'" :loading="annualReport.loading">
              {{ $t('general.annual_sales') + ' ' +  annualReport.year }}
            </default-card-component>
          </div>

          <div v-if="monthlyReport.total === '0.00'" class="bg-white rounded-lg shadow-inner shadow-stone-200 border border-stone-300 h-32 relative">
            <div class="flex flex-col md:p-10 p-6 text-center">
              <dd class="text-stone-400 order-2 mt-2 text-sm md:text-base lg:text-2xl leading-6 font-semibold">
                {{ $t('dashboard.no_monthly_data') }}
              </dd>
            </div>
          </div>
          <div v-else>
            <default-card-component :quantity="monthlyReport.total + '€'" :loading="monthlyReport.loading">
              {{ $t('general.sales') + ' ' +  monthlyReport.month }}
            </default-card-component>
          </div>

          <div v-if="studentsReport.total === '0'" class="bg-white rounded-lg shadow-inner shadow-stone-200 border border-stone-300 h-32 relative">
            <div class="flex flex-col p-12 text-center">
              <dd class="text-stone-400 order-2 mt-2 text-sm md:text-base lg:text-2xl leading-6 font-semibold">
                {{ $t('dashboard.no_students_data') }}
              </dd>
            </div>
          </div>
          <div v-else>
            <default-card-component :quantity="studentsReport.total" :loading="studentsReport.loading">
              {{ $t('general.students') }}
            </default-card-component>
          </div>

          <div v-if="productsReport.total === '0'" class="bg-white rounded-lg shadow-inner shadow-stone-200 border border-stone-300 h-32 relative">
            <div class="flex flex-col p-12 text-center">
              <dd class="text-stone-400 order-2 mt-2 text-sm md:text-base lg:text-2xl leading-6 font-semibold">
                {{ $t('dashboard.no_projects_data') }}
              </dd>
            </div>
          </div>
          <div v-else>
            <default-card-component :quantity="productsReport.total" :loading="productsReport.loading">
              {{ $t('general.products') }}
            </default-card-component>
          </div>



        </section>
        <div class="mt-4 grid gap-8 md:grid-cols-2 sm:grid-cols-1">
          <section>
            <nav class="mb-6">
              <ul>
                <li v-on:click="productsStore.toggleModal"
                    class="mb-4 p-2 bg-white rounded-md shadow-sm hover:shadow-lg hover:bg-brainspro-50 transition-all ease-in">
                  <div>
                    <span class="mr-4"><img class="inline-block h-14 w-14 rounded-md" src="/images/icons/cursos.png"
                                            alt=""/></span>
                    <span class="text-xl leading-10 inline-block align-middle">{{ $t('dashboard.create_a_project') }}</span>
                  </div>
                </li>
                <li class="mb-4 p-2 bg-white rounded-md shadow-sm hover:shadow-lg hover:bg-brainspro-50 transition-all ease-in">
                  <router-link class="block" to="/academy">
                    <span class="mr-4"><img class="inline-block h-14 w-14 rounded-md" src="/images/icons/contents.png"
                                            alt=""/></span>
                    <span class="text-xl leading-10 inline-block align-middle">{{ $t('dashboard.customize_your_academy') }}</span>
                  </router-link>
                </li>
                <li class="mb-4 p-2 bg-white rounded-md shadow-sm hover:shadow-lg hover:bg-brainspro-50 transition-all ease-in">
                  <router-link class="block" to="/reports">
                    <span class="mr-4"><img class="inline-block h-14 w-14 rounded-md" src="/images/icons/sales-clic.png"
                                            alt=""/></span>
                    <span class="text-xl leading-10 inline-block align-middle">{{ $t('dashboard.check_your_sales') }}</span>
                  </router-link>
                </li>
              </ul>
            </nav>

            <div class="hidden bg-white p-8 mb-6 rounded-md shadow-md">
              <h2 class="text-2xl mb-2">{{ $t("dashboard.contacts") }}</h2>
              <ul class="text-base">
                <li>
                  <b>{{ $t("dashboard.client_attention") }}</b> <a href="mailto:hola@brainspro.com">hello@brainspro.com</a>
                </li>
                <li>
                  <b>{{ $t("dashboard.support") }}</b> <a href="mailto:soporte@brainspro.com">soporte@brainspro.com</a>
                </li>
                <li>
                  <b>{{ $t("dashboard.phone_whatsapp") }}</b>{{ $t("dashboard.phone_number") }}
                </li>

              </ul>
            </div>

            <div class="hidden flex justify-center">
              <a href="https://meetings.hubspot.com/silvia-montoya/soporte-tecnico" target="_blank"
                 class="px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-50 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-green-200 transition ease-in-out duration-150">
                {{ $t("dashboard.text_support") }}
              </a>
            </div>
          </section>
          <section class="grid-cols-1 md:grid-cols-2">
            <div class="bg-white rounded-lg shadow-lg md:col-span-2 p-2 md:p-4">
              <vue-apex-charts type="bar" :options="options"  :series="series"></vue-apex-charts>
            </div>
            <div class="hidden"><a href="https://helpcenter.brainspro.com/es/">{{ $t('dashboard.href_intercom') }}</a></div>
            <help-center-component :url="this.helpCenter.kn_dashboard_url"></help-center-component>
          </section>
        </div>
      </div>
      <!-- /End replace -->
    </div>
  </div>

</template>

<script>
import HeaderComponent from "@/views/common/HeaderComponent";
import AnnualSalesService from "@/services/report/AnnualSalesService";
import MonthlySalesService from "@/services/report/MonthlySalesService";
import UsersReportService from "@/services/report/UsersReportService";
import moment from 'moment';
import DefaultCardComponent from "@/components/cards/DefaultCardComponent";
import ProducsReportService from "@/services/report/ProducsReportService";
import VueApexCharts from 'vue3-apexcharts';
import {useAuthUserStore} from "@/stores/auth";
import {useProductsStore} from "@/stores/products";
import HelpCenterComponent from "@/views/common/HelpCenterComponent";
import helpcenter from "@/services/helpcenter/links"

export default {
  name: "DashboardHelper",
  components: {DefaultCardComponent, HeaderComponent, VueApexCharts, HelpCenterComponent},
  data() {
    return {
      options: {
        chart: {
          id: 'monthly-sales',
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: moment.monthsShort()
        }
      },
      series: [{
        name: 'Ventas mensuales (EUR)',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      annualReport: {
        loading: true,
        year: "",
        total: 0
      },
      monthlyReport: {
        loading: true,
        current_year: [],
        month: "",
        total: 0
      },
      studentsReport: {
        loading: true,
        total: 0
      },
      productsReport: {
        loading: true,
        total: 0
      },
      barChartData: {
        labels: moment.monthsShort(),
        datasets: [
          {
            label: 'Ventas mensuales',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: '#3297cc'
          }
        ]
      },
      barChartOptions: {
        responsive: true,
        scales: {
          x: {
            ticks: {
              color: '#1F2937'
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              color: '#f3f8f8'
            }
          }
        }
      }
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()
    const productsStore = useProductsStore()
    const helpCenter = helpcenter

    return {
      authUserStore,
      productsStore,
      helpCenter
    }
  },
  mounted() {
    this.authUserStore.getUser().then(() => {
      this.getAnnualReport();
      this.getMonthlyReport();
      this.getUsersReport();
      this.getProductssReport();
    })
  },
  methods: {
    getAnnualReport() {
      this.annualReport.loading = true;
      var academy_uuid = this.authUserStore.user.academy.uuid;
      AnnualSalesService.index(academy_uuid).then((response) => {
        console.log("Reporte anual de ventas", response)
        this.annualReport = response.data
        this.annualReport.total = parseFloat(this.annualReport.total).toFixed(2)
      }).catch((response) => {
        console.log("Error", response)
      })
          .finally(() => {
            this.annualReport.loading = false
          })
    },
    getMonthlyReport() {
      this.monthlyReport.loading = true;
      var academy_uuid = this.authUserStore.user.academy.uuid;
      MonthlySalesService.index(academy_uuid).then((response) => {
        console.log("Reporte mensual de ventas", response)
        moment.locale("es");
        this.monthlyReport.month = moment(response.data.month, 'M').format('MMMM')
        this.monthlyReport.total = parseFloat(response.data.total).toFixed(2)
        this.monthlyReport.current_year = response.data.current_year

        this.monthlyReport.current_year.forEach((month) => {
            this.barChartData.datasets[0].data[parseInt(month.month) - 1] = parseFloat(month.sum).toFixed(2)
            this.series[0].data[parseInt(month.month) - 1] = parseFloat(month.sum).toFixed(2)
        })
      }).catch((response) => {
        console.log("Error", response)
      })
          .finally(() => {
            this.monthlyReport.loading = false
          })
    },
    getUsersReport() {
      this.studentsReport.loading = true;
      var academy_uuid = this.authUserStore.user.academy.uuid;

      UsersReportService.index(academy_uuid).then((response) => {
        console.log("Reporte estudiantes", response)
        this.studentsReport.total = response.data.students.total
      })
          .catch((response) => {
            console.log("Error", response)
          })
          .finally(() => {
            this.studentsReport.loading = false
          })
    },
    getProductssReport() {
      this.productsReport.loading = true;
      var academy_uuid = this.authUserStore.user.academy.uuid;

      ProducsReportService.index(academy_uuid).then((response) => {
        console.log("Reporte productos", response)
        this.productsReport.total = response.data.products.total
      })
          .catch((response) => {
            console.log("Error", response)
          })
          .finally(() => {
            this.productsReport.loading = false
          })
    }

  }
}
</script>

<style scoped>

</style>