<template>
    <div class="pt-2 pb-6 md:py-6">
        <header-component :title="$t('settings.subscription_plan')"></header-component>

        <div class="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
            <div class="relative">
                <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="mx-auto lg:grid lg:grid-cols-3 lg:gap-5">
                        <div class="rounded-lg shadow-lg overflow-hidden">
                            <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                <div>
                                    <h3 class="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-bpblue" id="tier-standard">
                                      {{ $t('settings.basic') }}
                                    </h3>
                                </div>
                                <div class="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                                  {{ $t('settings.basic_price') }}
                                    <span class="ml-1 text-2xl leading-8 font-medium text-gray-500">
                                      {{ $t('settings.per_month') }}
                                    </span>
                                </div>
                                <p class="mt-5 text-lg leading-7 text-gray-500">
                                  {{ $t('settings.basic_info') }}
                                </p>
                            </div>
                            <div class="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                                <ul>
                                    <li class="flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.unlimited_projects') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.unlimited_students') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.to_3_teachers') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.subdomain') }}
                                        </p>
                                    </li>
                                </ul>
                                <div class="mt-6 rounded-md shadow">
                                    <a href="#" class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="tier-standard">
                                      {{ $t('settings.take_plan') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 rounded-lg shadow-lg overflow-hidden lg:mt-0">
                            <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                <div>
                                    <h3 class="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-enterprise">
                                      {{ $t('settings.advance') }}
                                    </h3>
                                </div>
                                <div class="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                                  {{ $t('settings.advance_price') }}
                                    <span class="ml-1 text-2xl leading-8 font-medium text-gray-500">
                  {{ $t('settings.per_month') }}
                </span>
                                </div>
                                <p class="mt-5 text-lg leading-7 text-gray-500">
                                  {{ $t('settings.advance_info') }}
                                </p>
                            </div>
                            <div class="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                                <ul>
                                    <li class="flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.own_domain') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.private_label') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.to_10_teachers') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.report_gen') }}
                                        </p>
                                    </li>
                                </ul>
                                <div class="mt-6 rounded-md shadow">
                                    <a href="#" class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="tier-enterprise">
                                      {{ $t('settings.take_plan') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 rounded-lg shadow-lg overflow-hidden lg:mt-0">
                            <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                <div>
                                    <h3 class="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-enterprise">
                                      {{ $t('settings.pro') }}
                                    </h3>
                                </div>
                                <div class="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                                  {{ $t('settings.pro_price') }}
                                    <span class="ml-1 text-2xl leading-8 font-medium text-gray-500">
                  {{ $t('settings.per_month') }}
                </span>
                                </div>
                                <p class="mt-5 text-lg leading-7 text-gray-500">
                                  {{ $t('settings.pro_info') }}
                                </p>
                            </div>
                            <div class="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                                <ul>
                                    <li class="flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.no_comissions') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.api_access') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.app_integrations') }}
                                        </p>
                                    </li>
                                    <li class="mt-4 flex items-start">
                                        <div class="flex-shrink-0">
                                            <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p class="ml-3 text-base leading-6 text-gray-700">
                                          {{ $t('settings.support_prior') }}
                                        </p>
                                    </li>
                                </ul>
                                <div class="mt-6 rounded-md shadow">
                                    <a href="#" class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="tier-enterprise">
                                      {{ $t('settings.take_plan') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderComponent from "@/views/common/HeaderComponent";

    export default {
        name: "SubscriptionComponent",
        components: { HeaderComponent}
    }
</script>

<style scoped>

</style>