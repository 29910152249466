import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academy_uuid) {
        return useAuthUserStore().getApiClient.get('/groups', {
            params: {
                academy_uuid: academy_uuid
            }
        })
    },
    show(uuid) {
      return useAuthUserStore().getApiClient.get('/groups/' + uuid)
    },
    store(group) {
        return useAuthUserStore().getApiClient.post('/groups', group)
    },
    attachProject(group_uuid, project_uuid) {
      return   useAuthUserStore().getApiClient.put('/groups/project/attach', {
          group_uuid: group_uuid,
          project_uuid: project_uuid
      })
    },
    detachProject(group_uuid, project_uuid) {
        return   useAuthUserStore().getApiClient.delete('/groups/project/detach', {
            params: {
                group_uuid: group_uuid,
                project_uuid: project_uuid
            }
        })
    },
    update(group) {
      return useAuthUserStore().getApiClient.put('/groups/' + group.uuid, group)
    },
    delete(group) {
        return useAuthUserStore().getApiClient.delete('/groups/' + group.uuid, {
            params: {
                academy_uuid: group.academy_uuid,
                group_uuid: group.uuid,
            }
        })
    },

}