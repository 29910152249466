import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid, page = 1, type, search) {
        return useAuthUserStore().getApiClient.get('/users', {
            params: {
                academy_uuid: academyUuid,
                page: page,
                user_type: type,
                search: search
            }
        })
    },

    show(academyUuid, userUuid) {
        return useAuthUserStore().getApiClient
            .get('/users/' + userUuid, {
                params: {
                    academy_uuid: academyUuid
                }
            })
    },

    post(academyUuid, user) {
        return useAuthUserStore().getApiClient
            .post('/academies/user', {
                academy_uuid: academyUuid,
                uuid: user.uuid,
                firstname: user.firstname,
                lastname: user.lastname,
                phone: user.phone,
                card_id: user.identity_card
            })
    },

    put(academyUuid, user) {
        return useAuthUserStore().getApiClient
            .put('/users/', {
                academy_uuid: academyUuid,
                uuid: user.uuid,
                firstname: user.firstname
            })
    },

    download(academyUuid) {
        return useAuthUserStore().getApiClient
            .get('/export/users', {
                responseType: 'blob',
                params: {
                    academy_uuid: academyUuid
                }
            })
    },
    downloadUserOnProject(academyUuid, projectUuid)
    {
        return useAuthUserStore().getApiClient
            .get('/export/users', {
                responseType: 'blob',
                params: {
                    academy_uuid: academyUuid,
                    project_uuid: projectUuid
                }
            })
    },
    getEnrolments(academy_user_uuid)
    {
        return useAuthUserStore().getApiClient
            .get('/v2/academy/user/enrolments', {
                params: {
                    academy_user_uuid: academy_user_uuid
                }
            })
    },

    delete() {
        return true
    }
}