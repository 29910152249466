import * as filestack from "filestack-js";
import {useAuthUserStore} from "@/stores/auth";

let filestackClient = filestack.init('AUawhu5CTLCM9Wjbqlmr9z');

export default {
    index(filters = {} ) {
        console.log("Products filters", filters);
        return useAuthUserStore().getApiClient.get('/products', {
            params: filters
        })
    },

    get(productUuid) {
        return useAuthUserStore().getApiClient.post('/product/' + productUuid)
    },

    post(academy_uuid, product) {
        return useAuthUserStore().getApiClient.post('/products', {
            'academy_uuid': academy_uuid,
            'title': product.title,
            'category': product.category
        })
    },

    put(product) {
        return useAuthUserStore().getApiClient.put('/products', product)
    },

    changeProductStatus(academyUuid, productUuid, status) {
        return useAuthUserStore().getApiClient.post('/products/status', {
            academy_uuid: academyUuid,
            product_uuid: productUuid,
            status: status
        })
    },

    delete() {

      return true
    },

    openPicker(callback)
    {
        /*
        * Opciones predefinidas para FileStack Logo
        */
        const options = {
            accept: ['image/*'],
            fromSources: ["local_file_system", "imagesearch", "facebook", "instagram", "googledrive", "dropbox"],
            lang: 'es',
            maxSize: 1*1024*1024,
            maxFiles: 1,
            minFiles: 1,
            imageMin: [360, 180],
            transformations: {
                crop: {
                    aspectRatio: 2/1,
                    force: true
                }
            },
            //onOpen: () => console.log('opened!'),
            onUploadDone: callback
        };

        return filestackClient.picker(options).open()
    }
}