<template>
  <div class="max-w-7xl mx-auto py-3 md:py-6 sm:px-8 lg:pt-8">
    <card-with-header-and-footer-component :loading="loading">
      <template v-slot:header>
        <div class="flex flex-row justify-between">
          <div class="flex flex-row">
            <img v-if="user.avatar != ''" class="h-12 w-12 rounded-full ring-2 ring-stone-100 sm:h-16 sm:w-16" :src="user.avatar">
            <img v-else class="h-12 w-12 rounded-full ring-2 ring-stone-100 sm:h-16 sm:w-16 bg-brainspro" src="/images/avatar-transparent.png" :alt="user.firstname + ' ' + user.lastname">
            <label class="ml-4 mt-5 text-lg font-semibold leading-5 text-gray-900">{{
                user.firstname + ' ' + user.lastname
              }}</label>
          </div>
        </div>

      </template>
      <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="">
          <label for="user_firstname"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t("general.name") }}</label>
          <input id="user_firstname" v-model="this.user.firstname"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="user_lastname"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t("general.lastname") }}</label>
          <input id="user_lastname" v-model="this.user.lastname"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="user_email"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t("general.email") }}</label>
          <input disabled id="user_email" v-model="this.user.email"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="user_phone"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t("general.phone") }}</label>
          <input id="user_phone" v-model="this.user.phone"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="user_identity_card"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t("community.dni") }}</label>
          <input id="user_identity_card" v-model="this.user.identity_card"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div v-show="this.user.external_id" class="">
          <label for="user_identity_card"
                 class="block text-sm font-medium leading-5 text-gray-700">Identificador externo</label>
          <input disabled id="user_identity_card" v-model="this.user.external_id"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

      </div>

      <template v-slot:footer>

        <button type="button" v-on:click="getUser" :disabled="loading"
                class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="editUserData" :disabled="loading"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>

    <UserEnrolListComponent></UserEnrolListComponent>
  </div>
</template>

<script>
import UserEnrolListComponent from "@/views/community/enrol/UserEnrolListComponent";
import userService from "@/services/user/UserService";
import {useAuthUserStore} from "@/stores/auth";
import notificationService from "@/services/notification/NotificationService";
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";

export default {
  name: "DataComponent",
  components: {
    UserEnrolListComponent,
    CardWithHeaderAndFooterComponent
  },
  data: function (){
    return {
      loading: true,
      user: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        identity_card: '',
        external_id: ''
      }
    }
  },
  setup(){
    const authUserStore = useAuthUserStore();

    return {
      authUserStore
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser(){
      let academy_uuid = this.authUserStore.user.academy.uuid;
      let user_uuid = this.$route.params.id;
      userService.show(academy_uuid, user_uuid)
          .then((response) => {
            console.log("Usuario", response)
            this.user = response.data
          })
          .catch((error) => {
            console.log("Error al obtener usuario: ", error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    editUserData()
    {
      this.loading = true;
      let academy_uuid = this.authUserStore.user.academy.uuid

      console.log("User id", this.$route.params.id)

      userService.post(academy_uuid, this.user)
          .then((result) => {
            console.log("Resultado edición del usuario", result)
            notificationService.showNotification("El usuario editado correctamente", "success")
          })
          .catch((error) => {
            console.log("Error en la edición del usuario", error)
            notificationService.showNotification(error, "error")
          })
          .finally(() => {
            this.loading = false;
          })
    },
  }

}
</script>

<style scoped>

</style>