export default {
    create_account: 'Criar uma conta',
    loggin: 'iniciar sessão',
    access_with: 'Acesso com',
    continue_with: 'O contunia com',
    error_text1: 'Oops! Há',
    error_text2: 'na sua candidatura.',
    privacy_text1: 'Li e aceito a Política de Privacidade. Para mais informações, consulte por favor o nosso ',
    privacy_text2: 'política de privacidade',
    marketing_text: 'Autorizo expressamente antecipadamente que BrainsPro me envie comunicações comerciais sobre os seus serviços.',
    want_academy: 'Eu quero a minha academia!',
    ad_text1: 'Transforme os seus cursos online em ',
    ad_text2: 'experiências de aprendizagem',
    ad_text3: ' alto impacto',
    ad_text4: 'Os seus alunos apreciarão a qualidade dos seus serviços e terá tudo sob controlo de uma forma simples.',
    button_next: 'Próximo',
    start: 'Criar uma conta',
    error_firstname_required: 'Deve introduzir o seu nome',
    error_lastname_required: 'Deve introduzir o seu Apelido',
    error_email_address_required: 'Deve introduzir um endereço de correio electrónico válido',
    error_phone_required: 'Deve introduzir um número de telefone válido',
    error_pass_required: 'Deve introduzir uma palavra-passe válida (mínimo 8 caracteres)',
    error_confirm_pass_required: 'Deve ser a mesma palavra-passe que acima',
    error_email_used: 'Este e-mail já existe. Por favor inicie sessão ou crie a sua conta com outro endereço de correio electrónico',
    subdomain: 'Subdomínio',

    //CustomerDataComponent
    title_create_your_academy: 'Estamos quase lá...',
    question_goal: 'Qual é o seu principal objectivo na Brainspro?',
    select_goal: 'Seleccione um alvo',
    question_volume: 'Quantas pessoas quer treinar?',
    select_volume: 'Seleccione um intervalo aproximado',
    question_sector: 'Qual é o seu sector de actividade?',
    select_sector: 'Seleccione um sector',
    question_area: 'Qual é a sua área profissional?',
    select_area: 'Seleccione uma área',
    back: 'Voltar',
}