<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
import userService from "@/services/user/UserService";
import {useAuthUserStore} from "@/stores/auth";

export default {
name: "UsersComponent",
  data: function ()
  {
    return {
      request: {
        files: [],
        project: ""
      },
      users: [],
      pagination: null
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  created() {
    console.log("Obteniendo el listado de usuarios")
    let academy_uuid = this.authUserStore.user.academy.uuid;
    let page = this.$route.params.page;

    userService.index(academy_uuid, page)
        .then((response) => {
          console.log("Usuarios", response)
          this.users = response.data.data
          this.pagination = response.data.meta
          this.loading = false
        })
  },
  computed: {

  }
}
</script>

<style scoped>

</style>