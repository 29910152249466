<template>
  <div class="flex justify-center">
    <button type="button" v-on:click="getStripeNumber" :disabled="loading" class="mb-8 mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
      {{$t('myaccount.change_pay_method')}}
    </button>
  </div>

</template>

<script>
import CreditCardService from "@/services/user/CreditCardService"
import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";

export default {
  name: "StripeNumberComponen",
  components: {},
  data() {
    return {
        loading: false,
        request: {
          stripeNumber: ''
        }
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();
    const academiesStore = useAcademiesStore()

    return {
      authUserStore,
      academiesStore
    }
  },
  created() {

  },
  methods: {
    getStripeNumber() {
      CreditCardService.index()
          .then((response) => {
            console.log("Se ha llamado al endpoint de stripe number", response)
            window.location = response.data.url
          })
          .catch((error) => {
            console.log("Error al obtener el Número de Stripe: ", error)
          })

    },
  }

}
</script>

<style scoped>

</style>