import {useNotificationsStore} from "@/stores/notifications";

export default {

    showNotification(message, notificationType){
        let notification = [
            {
                'message': message,
                'type': notificationType
            }
        ]

        console.log("ErrorMessage", message)
        useNotificationsStore().sendNotification(notification)
        useNotificationsStore().removeNotificationFromTimeout()
    }

}