<template>
  <div class="">
    <div class="sm:flex sm:flex-col">
      <h1 class="text-2xl font-extrabold text-stone-900 text-center">{{$t('settings.update_your_plan')}}</h1>
      <p class="hidden mt-5 text-xl text-gray-500 sm:text-center"></p>
      <div class="relative mt-6 bg-gray-100 rounded-lg p-0.5 flex self-center sm:mt-8">
        <button v-on:click="changeInterval('month')" type="button" :class="{'bg-white shadow' : interval == 'month'}" class="relative border-gray-200 rounded-md shadow-sm py-2 w-1/2 text-sm font-medium text-gray-900 whitespace-nowrap  focus:z-10 sm:w-auto sm:px-8">{{$t('settings.monthly_billing')}}</button>
        <button v-on:click="changeInterval('year')" type="button" :class="{'bg-white shadow' : interval == 'year'}" class="ml-1 relative border shadow-inner border-transparent rounded-md py-2 w-1/2 text-sm font-medium text-gray-700 whitespace-nowrap focus:z-10 sm:w-auto sm:px-8">{{$t('settings.yearly_billing')}}</button>
      </div>
    </div>
    <div class="max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8">
      <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
        <div v-for="plan in plans" :key="plan.uuid" class="border border-gray-200 rounded-lg divide-y divide-gray-200 bg-white shadow-lg">
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">{{ plan.title }}</h2>
            <p class="hidden mt-4 text-sm text-gray-500">{{$t('settings.text_all_the_basics')}}</p>
            <p class="mt-8">
              <span class="text-2xl font-bold text-gray-900">{{ getPlanPrice(plan.prices).total }} €</span>
              <span v-if="interval=='month'" class="text-base font-medium text-gray-500"> / {{$t('settings.month')}}</span>
              <span v-if="interval=='year'" class="text-base font-medium text-gray-500"> / {{$t('settings.year')}}</span>
            </p>
            <button v-if="plan.key != this.userStateBlockButton" v-on:click="changeSubscription(getPlanPrice(plan.prices).uuid)" class="mt-8 block w-full bg-bpblue border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-bppick">{{$t('settings.button_activate')}}</button>
            <button v-else class="mt-8 block w-full bg-gray-300 border border-transparent rounded-md py-2 text-sm font-semibold text-gray-500 text-center">{{$t('settings.button_current_plan')}}</button>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">{{$t('settings.what_include')}}</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li v-for="feature in plan.features" :key="feature.uuid" class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">{{getTranslatedFeatureTitle(feature.translations)}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-10 py-4 text-center text-gray-400 text-lg">{{$t('settings.text_tax_not_include')}}</div>
    </div>
  </div>
</template>

<script>
import SaasPlanService from "../../../services/saas/SaasPlanService";
import saasSubscriptionService from "../../../services/saas/SaasSubscriptionService";
import {useAuthUserStore} from "../../../stores/auth";

export default {
  name: "ChangeSubscriptionComponent",
  data() {
    return {
      loading: false,
      interval: "month",
      plans: [],
      userStateBlockButton: '',
      statusSaasPlan: ''
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  mounted() {
    this.getSubscriptionsPlans()
  },
  methods: {
    getSubscriptionsPlans() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      SaasPlanService.index(academy_uuid)
        .then((response) => {
          this.plans = response.data
        })
        .finally(() => {
          this.statusSaasPlan = this.authUserStore.user.academy.saas_subscriptions[0].status
          this.planKey = this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.key
          //Comparará userStateBlockButton con la key del plan (plan_starter / plan_PRO / plan_corporate)
          //Si no se modifica desactivará el botón. Si se modifica activará el botón para que el usuario pueda comprar el plan.
          switch (this.statusSaasPlan)
          {
            case 'trialing':
              this.userStateBlockButton = 'trialing'
              break
            case 'manual':
              this.userStateBlockButton = 'manual'
              break
            case 'active':
              this.userStateBlockButton = this.planKey
              break
            case 'incomplete':
              this.userStateBlockButton = 'incomplete'
              break
            case 'past_due':
              this.userStateBlockButton = 'past_due'
              break
            case 'canceled':
              this.userStateBlockButton = 'canceled'
              break
            case 'unpaid':
              this.userStateBlockButton = 'unpaid'
              break
            case 'incomplete_expired':
              this.userStateBlockButton = 'incomplete_expired'
              break
          }
        })
    },
    getPlanPrice(prices){
      let result = prices.find((price) => {
        return price.interval == this.interval
      })

      return result
    },
    changeInterval(interval) {
      this.interval = interval
    },
    changeSubscription(price_id) {
      console.log("I want to change my subscription with", price_id)
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      saasSubscriptionService.post(academy_uuid, price_id)
      .then((response) => {
        console.log("Response from saas subscription service:", response)
        window.location = response.data.stripe.url
      })
      .catch((result) => {
        console.log("Error", result)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getTranslatedFeatureTitle(translations) {
      let locale = this.$i18n.locale
      if(typeof translations[locale] == "undefined") {
        return ''
      } else {
        return translations[locale]
      }
    }
  }
}
</script>

<style scoped>

</style>