export default {
    //BankAccountComponent
    payments_receipt: 'Eingang der Zahlungen',
    beneficiary: 'Begünstigter',
    bic: 'BIC',
    account_number: 'Kontonummer',
    enter_beneficiary: 'Begünstigten eingeben',
    enter_bic: 'BIC eingeben',
    enter_account_number: 'Kontonummer eingeben',
    info_billing_msg: 'Geben Sie diese Informationen ein, um das Einkommen auf Ihrem Konto zu erhalten.',
    info_billing_send: 'Per Post senden an ',
    info_billing_dni_msg: ' eingescannte Farbkopie der DNI / NIE des gesetzlichen Vertreters Ihres Unternehmens und/oder der CIF Ihres Unternehmens.',

    //BasicDataComponent
    profile_info: 'Profil Informationen',
    enter_name: 'Geben Sie Ihren Namen ein',
    enter_lastname: 'Geben Sie Ihren Nachnamen ein',
    error_name: 'Muss einen Namen eingeben',
    error_lastname: 'Sie müssen Ihren Nachnamen eingeben',

    //ChangePasswordComponent
    change_pass: 'Ändern Sie Ihr Passwort',
    current_pass: 'Aktuelles Passwort',
    new_pass: 'Neues Passwort',
    confirm_pass: 'Wiederholen Sie Ihr neues Passwort',
    enter_pass: 'Aktuelles Passwort eingeben',
    enter_new_pass: 'Geben Sie Ihr neues Passwort ein',
    repeat_new_pass: 'Wiederholen Sie Ihr neues Passwort',
    password_not_match: 'Passwörter stimmen nicht überein',

    //UserAvatarComponent
    user_image: 'Imagen de usuario',
    upload_avatar: 'Sube tu avatar',
    change: 'Cambiar',
    insert: 'Insertar'
}