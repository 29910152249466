import {useAuthUserStore} from "@/stores/auth";

export default {
    post(product_uuid, project_uuid) {
        return useAuthUserStore().getApiClient.post('/projects/product', {
            'product_uuid': product_uuid,
            'project_uuid': project_uuid,
        })
    },

    delete(project_uuid, product_uuid) {
        return useAuthUserStore().getApiClient.delete('/projects/product', {
            params: {
                'product_uuid': product_uuid,
                'project_uuid': project_uuid,
            }
        })
    }

}