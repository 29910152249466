import {useAuthUserStore} from "@/stores/auth";

export default {
    getMoodleCourseURL(projectUUID)
    {
        return useAuthUserStore().getApiClient
            .get('/project/' + projectUUID + '/lms/auth')
            .then(({ data }) => {
                console.log("Link de acceso al curso", data)
                return data;
            })
            .catch(error => {

                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    //errorReporting.showError(error.response.data.message)
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log("No response", error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }

            })
    },

    delete(projectUUID, userUUID)
    {
        return useAuthUserStore().getApiClient.delete('/enrol', {
            params: {
                project_uuid: projectUUID,
                user_uuid: userUUID
            }
        })
    },
    post(enrolRequest) {
        return useAuthUserStore().getApiClient.post('/project/enrol', {
            'project_uuid': enrolRequest.project_uuid,
            'academy_user_uuid': enrolRequest.academy_user_uuid,
            'notify': enrolRequest.notify,
            'rol':  enrolRequest.rol
        })
    },
}