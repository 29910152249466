export default {
    //ProjectsList
    your_created_projects: "Tus proyectos creados",
    new_project: "Nuevo Curso",
    search_course_placeholder: "Busca tu projecto",
    projects_name: "NOMBRE DEL CURSO",
    update_at: 'Actualizado el ',
    coming_soon: 'Próximamente',
    search_your_project: 'Busca tu curso...',
    project_name: 'Nombre del curso',
    loading_projects: 'Cargando proyectos...',

    //ProjectEditComponent
    modify_project: 'Modificando tu proyecto',
    general: 'General',
    layout: 'Plantilla',
    access: 'Acceso',
    branding: 'Branding',
    product_page: 'Página de Producto',
    sale: 'Venta',
    seo_text: 'SEO',
    product: 'Producto',

    //CategoriesSlideOverComponent
    categories: 'Categorías',
    text_category: 'Clasifica tus productos por categorías, así facilitarás a tus alumnos comprar tus cursos.',
    select_category: 'Selecciona categoría...',
    ebook: 'E-book',
    online_project: 'Curso online',
    blended_learning: 'Curso semi-presencial',
    online_program: 'Programa Online',
    webinar_consulting: 'Webinar / Consultoría online',
    remove_category: 'Eliminar categoría',
    href_help: 'Deseo saber más sobre las categorías',

    //ProjectEdit
        //CustomCategoriesComponent
        //ProjectGeneralComponent
        category: 'Categoría',
        duration: 'Duración',
        featured_product: 'Producto destacado',
        publish_project: 'Publicar Proyecto',
        delete_project: 'Borrar Proyecto',
        warning_delete: '¡Atención!',
        message_delete_project: 'Al eliminar el curso perderás todos sus datos definitivamente.',
        question_delete_project: '¿Seguro que quieres eliminar este curso?',
        delete: 'Eliminar',
        confirmation_delete_project: '(*) Esta casilla es obligatoria para eliminar el curso',
        navigation: 'Navegación',
        navigation_free: 'Libre',
        navigation_secuential: 'Secuencial',
        navigation_without_restriction: 'Sin restricciones. El usuario podrá navegar libremente por el curso (excluyendo los cursos con contenido por goteo)',
        navigation_completed_restriction: 'El alumno tiene que completar cada unidad de aprendizaje antes de continuar con la siguiente. La finalización de las unidades de aprendizaje puede establecerse de diferentes maneras.',
        quest_lesson_complete: '¿Cuando será considerada una lección completada?',
        lesson_complete_at_visit: 'Al ser visitada',
        lesson_at_visit_info: 'Al entrar en una lección se completará automáticamente en el progreso total del curso. (Por defecto)',
        lesson_complete_at_see: 'Al visualizar el contenido completamente',
        lesson_at_see_info: 'Se marcará como completado al visualizar el contenido completamente (video, ebook, podcast)',
        lesson_complete_on_time: 'Al permanecer un tiempo',
        lesson_on_time_info: 'Se completará al estar en cada lección el tiempo indicado (HH:mm)',
        automatic_navigation: 'Navegación automática',
        automatic_navigation_disabled: 'Desactivado',
        automatic_navigation_enabled: 'Activado',
        automatic_navigation_info: 'Al completar el contenido de una lección pasará a la siguiente lección correspondiente.',
        //PageComponent
        principal_page: 'Página principal',
        objects_presentation: 'Presentación y objetos',
        learning_objectives: 'No te olvides de describir brevemente los objetivos de aprendizaje.',
        teaching_staff: 'Metodología y profesorado',
        public: 'Público',
        content: 'Contenido',
        sidebar: 'Barra Lateral',
        //ProductBrandingComponent
        banner: 'Banner',
        upload_banner: 'Sube tu banner',
        banner_format: 'PNG, JPG, GIF hasta 3MB',
        promotional_video: 'Video Promocional',
        video_url: 'URL del video',
        video_info: 'Si tienes un video promocional, coloca aquí la url del video en Youtube o Vimeo.',
        //SellComponent
        price_management: 'Gestión de Precios',
        sell_price: 'Precio de venta',
        tax: 'En €. (-3% tasas)',
        price_offer: 'Precio de Oferta',
        text_configuration: 'Configuración de textos',
        free_text_button: 'Título de botón de compra gratuito',
        text_if_free: 'Este título aparecerá si tu producto es gratuito',
        on_sale: 'En venta',
        sale_info: 'Si está activado tu producto estará a la venta',

        //SeoComponent
        seo_configuration: 'Configuración SEO',
        seo_title: 'Título SEO',
        slug: 'Slug',
        seo_description: 'Descripción SEO',

    //ProjectListComponent
    featured: 'Destacado',
    classroom: 'Aula',
    slug_text: 'slug',
    project: 'proyecto',
    projects: 'proyectos',
    preview: 'Previsualizar',

    //ProjectAccessComponent
    access_project: 'Acceso al curso',
    draft: 'Borrador',
    draft_text: 'Este proyecto no se mostrará en el catálogo y podrá ser editado',
    published: 'Publicado',
    published_text: 'Este proyecto está publicado y los usuarios podrán acceder a él',
    private: 'Privado',
    private_text: 'Este proyecto es privado, los usuarios de este curso se darán de alta manualmente',
    coming_soon_access: 'Próximamente',
    coming_soon_access_text: 'Este proyecto se mostrará en el catálofo de cursos pero no se podrá acceder',
    enrollment_closed: 'Matriculación cerrada',
    enrollment_closed_text: 'Solamente los usuarios matriculados podrán entrar al curso. El curso aparecerá en el catálogo pero no se podrán matricular nuevos usuarios',
    course_expiration: 'Caducidad del curso',
    project_expiration_info: 'Tu curso será accesible en este período de tiempo. Al finalizar se denegará el acceso al curso a los usuarios matriculados.',
    init_date: 'Fecha de Inicio',
    project_delete: 'Borrar',
    init_date_selected: 'Fecha de inicio seleccionada',
    end_date: 'Fecha de Fin',
    date_delete: 'Borrar',
    end_date_selected: 'Fecha de finalización seleccionada',

    //ProjectContentComponent
    new_section: 'Nueva Sección',
    section_title: 'Título de la sección: ',
    create_section: 'Crear sección',
    import_section: 'Importar sección',
    lesson_title: 'Título:',
    new_lesson: 'Nueva lección',

    //AttachProductListComponent
    attached_products: 'Productos Asociados',
    attach_product: 'Adjuntar Producto',
    loading_attached_products: 'Cargando productos asociados...',
    unattach_product: 'Desasociar producto',
    info_unattach_product: 'Al desasociar el producto al proyecto, los alumnos que compren dicho producto no podrán acceder al proyecto.',
    quest_unattach_product: '¿Seguro que quieres desasociar el producto ',
    no_product_attached: 'NO HAY PRODUCTOS ASOCIADOS',

    //AttachProductSlideOverComponent
    attach_product_slide: 'Asociar Producto',
    selected_product: 'Producto seleccionado',
    quest_attach_product: '¿Quieres adjuntar este producto?',

    //ProjectEditContentComponent
    back: 'Volver',
    select_type_content: 'Selecciona el tipo de contenido',
    type_lesson: 'Tipo: ',
    html_editor: 'Editor HTML',
    html_info_text: 'Inserta el código HTML que quieras mostrar en tu lección',
    insert_video_url: 'Inserta la URL del video',
    content_index: 'Índice de contenidos',
    student_list: 'Lista de estudiantes',

    //ProjectLayoutContent
    image: 'Imagen',
    classic_design: 'Aspecto Clásico'
}