<template>
  <div class="pt-2 pb-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <!-- Replace with your content -->
      <div class="py-4 hidden md:block">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div v-if="!loading" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('sales.state') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('sales.gross') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('sales.net') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('sales.commission') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('sales.created_at') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('sales.available_on') }}
                  </th>
                  <!--
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Tipo
                  </th>
                  -->
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="balance in balances" :key="balance.id">
                  <td v-if="balance.status == 'pending'" class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5">
                    <span class="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">{{balance.status}}</span>
                  </td>
                  <td v-else class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5">
                    <span class="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">{{balance.status}}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ balance.amount / 100 }} {{balance.currency}}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ balance.net / 100 }} {{balance.currency}}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ balance.fee / 100 }} {{balance.currency}}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ $moment.unix(balance.created).format('DD-MM-YYYY H:mm') }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ $moment.unix(balance.available_on).format('DD-MM-YYYY') }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <loading-component v-else>{{ $t("sales.loading_payments") }}</loading-component>
          </div>
        </div>
      </div>
      <!--<PaginationComponent></PaginationComponent>-->
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/views/common/LoadingComponent";
//import MobilePayoutsComponent from "@/views/reports/Mobile/PayoutsComponent";
import {useAuthUserStore} from "@/stores/auth";
import BalanceService from "@/services/report/BalanceService";

export default {
  name: "BalanceComponent",
  components: {LoadingComponent},
  data() {
    return {
      loading: true,
      balances: null,
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  created() {
    this.getBalance()
  },
  methods: {
    getBalance() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      BalanceService.index(academy_uuid)
          .then((response) => {
            console.log("getBalance OK", response)
            this.balances = response.data.data
          })
          .catch((error) => {
            console.log("Ha ocurrido un error en getBalance", error)
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>