export default {
    permits: 'Licenças',
    notifications: 'Notificações',
    plans_billing: 'Planos e Facturação',
    integrations: 'Integrações',
    update_info: 'Por favor, actualize os seus dados de facturação. Tenha em atenção que as suas definições de localização podem afectar as taxas de imposto.',
    payment_details: 'Detalhes de pagamento',
    expiration_date: 'Data de expiração',
    country: 'País / Região',
    postal: 'Código Postal',
    current_plan: 'Plano actual',
    business: 'Empresas',
    postings_job_25: 'Até 25 ofertas de emprego activas',
    enterprise: 'Empresa',
    unlimited_postings_job: 'Ofertas de emprego activas ilimitadas',
    pricing_plans: 'Fixação dos preços dos planos',
    startup: 'Startup',
    postings_job_5: 'Até 5 ofertas de emprego activas',
    user_settings: 'Configurações do utilizador',
    annual_billing: 'Volume de negócios anual',
    save_10: '(Poupe 10%)',
    billing_history: 'Histórico de facturação',
    save: 'Guardar',
    date: 'Data',
    description: 'Descrição',
    amount: 'Quantidade',
    format_date: 'MM / YY',
    spain: 'Espanha',
    canada: 'Canadá',
    mexico: 'México',

    //SubscriptionComponent
    subscription_plan: 'Plano de assinatura',
    basic: 'Básico',
    basic_price: '26€',
    per_month: '/mês',
    basic_info: 'A opção perfeita para começar no mundo do ensino online.',
    unlimited_projects: 'Cursos Ilimitados',
    unlimited_students: 'Estudantes Ilimitados',
    to_3_teachers: 'Até 3 professores',
    subdomain: 'Subdomínio',
    take_plan: 'Eu quero este plano',
    advance: 'Avançado',
    advance_price: '89€',
    advance_info: 'Impulsione a sua academia online, tudo desde o Plano Básico e muito mais.',
    own_domain: 'Domínio próprio',
    private_label: 'White Label',
    to_10_teachers: 'Até 10 professores',
    report_gen: 'Geração de relatórios',
    pro: 'Pro',
    pro_price: '152€',
    pro_info: 'Escala a sua academia online. Tudo de avançado e muito mais.',
    no_comissions: '0% comissão de vendas',
    api_access: 'Acesso API',
    app_integrations: 'Integração com APPs',
    support_prior: 'Apoio prioritário',
    //CurrentSubscriptionsComponent
    actual_subscriptions: 'Assinaturas actuais',
    attention: 'Atenção',
    att_info1: 'Se desejar alterar o seu plano, por favor contacte ',
    contact_email: 'billing@brainspro.com',
    att_info2: 'Se deseja subscrever, por favor preencha as informações disponíveis',
    here: 'aqui',
    ends_on: 'Termina em ',
    trial_ends_on: 'O período probatório termina: ',
    view_receipt: 'Ver Recibo',
    cancel_subscription: 'Anular a subscrição',
    modal_cancel_sub_text: 'Esta conta entrará num estado suspenso. A funcionalidade será restringida para utilizadores da academia, professores e proprietários.',
    modal_cancel_sub_question: 'Tem a certeza de que o quer fazer?',

    //ChangeSubscriptionComponent
    message_subscription_expire: 'A sua conta BrainsPro expirou. Activar um plano para voltar a ter acesso.',
    update_your_plan: 'Actualize o seu plano',
    monthly_billing: 'Mensal',
    yearly_billing: 'Anual',
    text_all_the_basics: 'Todas as noções básicas para iniciar um novo negócio',
    button_activate: 'Activar',
    button_current_plan: 'Plano actual',
    what_include: 'Incluindo:',
    text_tax_not_include: 'IVA não incluído',
    month: 'Mês',
    year: 'Ano',
}