export default {
    //ProjectsList
    your_created_projects: "Your created courses",
    new_project: "New Course",
    search_course_placeholder: "Search for your course...",
    projects_name: "COURSE NAME",
    update_at: 'Updated on ',
    coming_soon: 'Coming soon',
    search_your_project: 'Search for your course...',
    project_name: 'Course name',
    loading_projects: 'Loading proyects...',

    //ProjectEditComponent
    modify_project: 'Modifying your projects',
    general: 'General',
    layout: 'Template',
    access: 'Access',
    branding: 'Branding',
    product_page: 'Product Page',
    sale: 'Sale',
    seo_text: 'SEO',
    product: 'Product',

    //CategoriesSlideOverComponent
    categories: 'Categories',
    text_category: 'Classify your products by category to make it easier for your students to buy your courses.',
    select_category: 'Select category...',
    ebook: 'E-book',
    online_project: 'Online projects',
    blended_learning: 'Blended learning projects',
    online_program: 'Online Programme',
    webinar_consulting: 'Webinar / Online consulting',
    remove_category: 'Remove category',
    href_help: 'More about the categories',

    //Projectdit
    //CustomCategoriesComponent
    //GeneralComponent
    category: 'Category',
    duration: 'Duration',
    featured_product: 'Product Spotlight',
    publish_project: 'Publish Project',
    delete_project: 'Delete project',
    warning_delete: 'Warning!',
    message_delete_project: 'By deleting the projects you will permanently lose all your data.',
    question_delete_project: 'Are you sure you want to delete this projects?',
    delete: 'Delete',
    confirmation_delete_project: '(*) This box is required to delete the projects.',
    navigation: 'Navigation',
    navigation_free: 'Free',
    navigation_secuential: 'Sequential',
    navigation_without_restriction: 'No restrictions. The user will be able to navigate freely through the course (excluding courses with drip content).',
    navigation_completed_restriction: 'The learner has to complete each learning unit before continuing to the next one. The completion of the learning units can be established in different ways.',
    quest_lesson_complete: 'When will it be considered a completed lesson?',
    lesson_complete_at_visit: 'When visited',
    lesson_at_visit_info: 'When entering a lesson it will be automatically completed in the total progress of the course (Default).',
    lesson_complete_at_see: 'When viewing the content completely',
    lesson_at_see_info: 'It will be marked as completed when the content (video, ebook, podcast) is fully displayed.',
    lesson_complete_on_time: 'By remaining for a period of time',
    lesson_on_time_info: 'It will be completed when the indicated time (HH:mm) is in each lesson.',
    automatic_navigation: 'Automatic navigation',
    automatic_navigation_disabled: 'Disabled',
    automatic_navigation_enabled: 'Enabled',
    automatic_navigation_info: 'Completing the content of a lesson will take you to the next corresponding lesson.',
    //PageComponent
    principal_page: 'Home Page',
    objects_presentation: 'Presentation and objects',
    learning_objectives: 'Don\'t forget to briefly describe the learning objectives.',
    teaching_staff: 'Methodology and teaching staff',
    public: 'Public',
    content: 'Content',
    sidebar: 'Side Bar',
    //ProductBrandingComponent
    banner: 'Banner',
    upload_banner: 'Upload your banner',
    banner_format: 'PNG, JPG, GIF up to 3MB',
    promotional_video: 'Promotional Video',
    video_url: 'Video URL',
    video_info: 'If you have a promotional video, place here the url of the video on Youtube or Vimeo.',
    //SellComponent
    price_management: 'Price Management',
    sell_price: 'Selling price',
    tax: 'In €. (-3% tax)',
    price_offer: 'Offer Price',
    text_configuration: 'Text configuration',
    free_text_button: 'Free buy button title',
    text_if_free: 'This title will appear if your product is free',
    on_sale: 'For sale',
    sale_info: 'If activated your product will be for sale',

    //SeoComponent
    seo_configuration: 'SEO Configuration',
    seo_title: 'SEO Title',
    slug: 'Slug',
    seo_description: 'SEO Description',

    //ProjectListComponent
    featured: 'Featured',
    classroom: 'Classroom',
    slug_text: 'slug',
    project: 'proyect',
    projects: 'proyects',
    preview: 'Preview',

    //ProjectAccessComponent
    access_project: 'Access to the course',
    draft: 'Draft',
    draft_text: 'This project will not be shown in the catalog and may be edited.',
    published: 'Published',
    published_text: 'This project is published and users will be able to access it.',
    private: 'Private',
    private_text: 'This project is private, the users of this course will register manually',
    coming_soon_access: 'Coming soon',
    coming_soon_access_text: 'This project will be displayed in the course catalog, but will not be accessible',
    enrollment_closed: 'Enrollment closed',
    enrollment_closed_text: 'Only enrolled users will be able to enter the course. The course will appear in the catalog but new users will not be able to enroll.',
    course_expiration: 'Course expiration',
    project_expiration_info: 'Your course will be accessible during this period of time. At the end of this period, enrolled users will be denied access to the course.',
    init_date: 'Start Date',
    project_delete: 'Delete',
    init_date_selected: 'Selected start date',
    end_date: 'End Date',
    date_delete: 'Delete',
    end_date_selected: 'Selected end date',

    //ProjectContentComponent
    new_section: 'New Section',
    section_title: 'Section title: ',
    create_section: 'Create section',
    import_section: 'Import section',
    lesson_title: 'Lesson title:',
    new_lesson: 'New lesson',

    //AttachProductSlideOverComponent
    attach_product_slide: 'Associate Product',
    selected_product: 'Selected product',
    quest_attach_product: 'Do you want to attach this product?',

    //ProjectEditContentComponent
    back: 'Return',
    select_type_content: 'Select the type of content',
    type_lesson: 'Type of lesson: ',
    html_editor: 'HTML Editor',
    html_info_text: 'Insert the HTML code you want to display in your lesson',
    insert_video_url: 'Insert the URL of the video',
    content_index: 'Table of Contents',
    student_list: 'Students List',

    //ProjectLayoutContent
    image: 'Image',
    classic_design: 'Classic Appearance'
}