export default {
    hello: "Hola",
    create_a_project: "Crea un curso",
    customize_your_academy: "Personalización",
    check_your_sales: "Consulta tus ingresos",
    create_your_project: "Crea tu curso",
    placeholder_name: "Mi curso en Brainspro",
    text_describe: "El título describe en una sola frase lo que sus alumnos aprenderán.",
    bttn_close: "Cerrar",
    bttn_create: "¡Crear curso!",
    contacts: '¿Más dudas?, ¡Contáctanos!',
    client_attention: 'Atención al cliente: ',
    mail_client_attention: 'hola@brainspro.com',
    support: 'Soporte técnico: ',
    email_support: 'soporte@brainspro.com',
    phone_whatsapp: 'Teléfono y Whatsapp: ',
    phone_number: '+34 626 201 897',
    text_support: 'Reserva una cita online con uno de nuestros expertos',
    href_intercom: 'Ir a la base de conocimiento',
    href_custom_domain: 'Más sobre la configuración de dominios personalizados.',
    no_annual_data: "No hay datos anuales",
    no_monthly_data: "No hay datos mensuales",
    no_students_data: "No hay datos de estudiantes registrados",
    no_projects_data: "No hay datos de proyectos creados",

}