export default {
    //ProjectsList
    your_created_projects: "Os seus cursos criados",
    new_project: "Novo Curso",
    search_course_placeholder: "Procure o seu curso...",
    projects_name: "NOME DO CURSO",
    update_at: 'Actualizado em ',
    coming_soon: 'Em breve',
    search_your_project: 'Procure o seu curso...',
    project_name: 'Nome do curso',
    loading_projects: 'Carregamento de proyectos...',

    //ProjectEditComponent
    modify_project: 'Modificar o seu curso',
    general: 'Geral',
    layout: 'Template',
    access: 'Acesso',
    branding: 'Branding',
    product_page: 'Página do produto',
    sale: 'Venda',
    seo_text: 'SEO',
    product: 'Produto',

    //CategoriesSlideOverComponent
    categories: 'Categorias',
    text_category: 'Classifique os seus produtos por categoria para facilitar aos seus alunos a compra dos seus cursos.',
    select_category: 'Seleccione a categoria...',
    ebook: 'E-book',
    online_project: 'Curso online',
    blended_learning: 'Curso de aprendizagem mista',
    online_program: 'Programa Online',
    webinar_consulting: 'Webinar / Consultoria online',
    remove_category: 'Remover categoria',
    href_help: 'Gostaria de saber mais sobre as categorias',

    //ProjectEdit
        //CustomCategoriesComponent
        //GeneralComponent
        category: 'Categoria',
        duration: 'Duração',
        featured_product: 'Produto em Destaque',
        publish_project: 'Publicar Projeto',
        delete_project: 'Eliminar projecto',
        warning_delete: 'Advertência!',
        message_delete_project: 'Ao apagar o projecto, perderá permanentemente todos os seus dados.',
        question_delete_project: 'Tem a certeza de que quer eliminar este projecto?',
        delete: 'Eliminar',
        confirmation_delete_project: '(*) Esta caixa é necessária para apagar o projecto.',
        navigation: 'Navegação',
        navigation_free: 'Grátis',
        navigation_secuential: 'Sequencial',
        navigation_without_restriction: 'Sem restrições. O utilizador poderá navegar livremente pelo curso (excluindo cursos com conteúdo gota-a-gota).',
        navigation_completed_restriction: 'O aprendente tem de completar cada unidade de aprendizagem antes de continuar para a unidade seguinte. A conclusão de unidades de aprendizagem pode ser estabelecida de diferentes maneiras.',
        quest_lesson_complete: 'Quando é que será considerada uma lição concluída?',
        lesson_complete_at_visit: 'Quando visitados',
        lesson_at_visit_info: 'Ao entrar numa aula será automaticamente completada no progresso total do curso (por defeito).',
        lesson_complete_at_see: 'Ao visualizar o conteúdo na íntegra',
        lesson_at_see_info: 'Será marcado como concluído quando o conteúdo (vídeo, ebook, podcast) for totalmente visualizado.',
        lesson_complete_on_time: 'Ficando algum tempo',
        lesson_on_time_info: 'A completar em cada aula pelo tempo indicado (HH:mm).',
        automatic_navigation: 'Navegação automática',
        automatic_navigation_disabled: 'Desactivado',
        automatic_navigation_enabled: 'Activado',
        automatic_navigation_info: 'A conclusão do conteúdo de uma lição irá levá-lo para a próxima lição correspondente.',
        //PageComponent
        principal_page: 'Página inicial',
        objects_presentation: 'Apresentação e objectos',
        learning_objectives: 'Não se esqueça de descrever brevemente os objectivos de aprendizagem.',
        teaching_staff: 'Metodologia e pessoal docente',
        public: 'Público',
        content: 'Conteúdo',
        sidebar: 'Barra Lateral',
        //ProductBrandingComponent
        banner: 'Banner',
        upload_banner: 'Carregue o seu banner',
        banner_format: 'PNG, JPG, GIF para 3MB',
        promotional_video: 'Vídeo Promocional',
        video_url: 'URL do vídeo',
        video_info: 'Se tiver um vídeo promocional, coloque aqui a url do vídeo no Youtube ou no Vimeo.',
        //SellComponent
        price_management: 'Gestão de preços',
        sell_price: 'Preço de venda',
        tax: 'Em euros. (-3% de imposto)',
        price_offer: 'Preço da Oferta',
        text_configuration: 'Configuração de texto',
        free_text_button: 'Título do botão de compra livre',
        text_if_free: 'Este título aparecerá se o seu produto for gratuito',
        on_sale: 'Para venda',
        sale_info: 'Se activado, o seu produto estará à venda',

    //SeoComponent
    seo_configuration: 'Configuração SEO',
    seo_title: 'Título SEO',
    slug: 'Slug',
    seo_description: 'Descrição SEO',

    //ProjectListComponent
    featured: 'Destaque',
    classroom: 'Sala de aula',
    slug_text: 'slug',
    project: 'Proyecto',
    projects: 'Proyectos',
    preview: 'Pré-visualização',

    //ProjectAccessComponent
    access_project: 'Acesso ao curso',
    draft: 'Projecto',
    draft_text: 'Este projecto não será apresentado no catálogo e poderá ser editado',
    published: 'Publicado',
    published_text: 'Este projecto é publicado e os utilizadores poderão aceder ao mesmo',
    private: 'Particular',
    private_text: 'Este projecto é privado, os utilizadores deste curso irão inscrever-se manualmente',
    coming_soon_access: 'Em breve',
    coming_soon_access_text: 'Este projecto será apresentado no catálogo do curso, mas não será acessível',
    enrollment_closed: 'Inscrição encerrada',
    enrollment_closed_text: 'Apenas os utilizadores inscritos poderão entrar no curso. O curso irá aparecer no catálogo, mas os novos utilizadores não poderão inscrever-se',
    course_expiration: 'Expiração do curso',
    project_expiration_info: 'O seu curso será acessível durante este período de tempo. No final deste período, aos utilizadores inscritos será negado o acesso ao curso.',
    init_date: 'Data de início',
    project_delete: 'Eliminar',
    init_date_selected: 'Data de início seleccionada',
    end_date: 'Data final',
    date_delete: 'Eliminar',
    end_date_selected: 'Data final seleccionada',

    //ProjectContentComponent
    new_section: 'Nova Secção',
    section_title: 'Título da secção: ',
    create_section: 'Criar secção',
    import_section: 'Secção de importação',
    lesson_title: 'Título da lição:',
    new_lesson: 'Nova lição',

    //AttachProductSlideOverComponent
    attach_product_slide: 'Produto Associado',
    selected_product: 'Produto seleccionado',
    quest_attach_product: 'Quer anexar este produto?',

    //ProjectEditContentComponent
    back: 'Voltar',
    select_type_content: 'Seleccione o tipo de conteúdo',
    type_lesson: 'Tipo de lição: ',
    html_editor: 'Editor de HTML',
    html_info_text: 'Insira o código HTML que pretende exibir na sua lição',
    insert_video_url: 'Inserir o URL do vídeo',
    content_index: 'Índice',
    student_list: 'Liste der Studenten',

    //ProjectLayoutContent
    image: 'Imagem',
    classic_design: 'Olhar clássico'
}