<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm">
        <div class="text-center">
          <img class="h-12 w-auto mx-auto" src="/images/logo-bp.png" alt="Brainspro">
          <h1 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
            {{ $t('login.restore_password') }}
          </h1>
        </div>
        <div class="mt-6">
          <form @submit.prevent="resetPassword">
            <div class="mt-6">
              <label for="email" class="block text-sm font-medium text-gray-700">{{ $t('general.email_address') }}</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input type="email" v-model="v$.request.email.$model" id="email" required
                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                       :placeholder="$t('login.email_input_placeholder')">
                <div v-if="v$.request.email.$error"
                     class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"/>
                  </svg>
                </div>
              </div>
              <p v-if="v$.request.email.$error" class="mt-2 text-sm text-red-600" id="error-email">{{ $t('login.error_email_invalid') }}</p>
            </div>
            <div class="mt-6" v-if="!request.change">
              <label for="password" class="block text-sm font-medium text-gray-700">{{ $t('login.new_password') }}</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input v-bind:type="viewPassType" v-model="v$.request.password.$model" id="password" required
                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                       :placeholder="$t('login.password_placeholder')">

                <!-- Icono para mostrar contraseña o no -->
                <div @click="clickOnEye" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                  <!-- Heroicon name: solid/question-mark-circle -->
                  <svg v-if="!viewPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                </div>
                <div @click="clickOnEye" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                  <svg v-if="viewPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                </div>
                <!-- fin de icono ojo -->

              </div>
              <p v-if="v$.request.password.$error" class="mt-2 text-sm text-red-600" id="error-password">{{ $t('login.error_password') }}</p>
            </div>
            <div class="mt-6" v-if="!request.change">
              <label for="password_confirmation" class="block text-sm font-medium text-gray-700">{{ $t('login.confirm_password') }}</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input v-bind:type="viewPassType" v-model="v$.request.password_confirmation.$model" id="password_confirmation" required
                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                       :placeholder="$t('login.password_confirm_placeholder')">
                <div v-if="v$.request.password_confirmation.$error"
                     class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"/>
                  </svg>
                </div>
              </div>
              <p v-if="v$.request.password_confirmation.$error" class="mt-2 text-sm text-red-600"
                 id="error-password_confirmation">{{$t('login.error_password_confirm')}}</p>
            </div>
            <div class="mt-6">
              <span class="block w-full rounded-md shadow-sm">
                <button type="submit"
                        class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                  {{$t('login.button_reset_password')}}
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {email, maxLength, minLength, required, sameAs} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "ResetPasswordComponent.vue",

  data() {
    return {
      request: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      viewPass: false,
    }
  },
  setup() {

    const authUserStore = useAuthUserStore();

    return {
      authUserStore,
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      request: {
        email: {
          required,
          email
        },
        password: {
          required,
          minLength: minLength(8),
          maxLengthValue: maxLength(255)
        },
        password_confirmation: {
          required,
          minLength: minLength(8),
          sameAsPassword: sameAs(this.request.password),
          maxLengthValue: maxLength(255)
        }
      }
    }
  },
  computed: {
    viewPassType() {
      return (this.viewPass) ? 'text' : 'password'
    }
  },
  methods: {
    clickOnEye() {
      this.viewPass = !this.viewPass
    },

    resetPassword() {
      //Obteniendo los valores de URL
      this.request.token = new URLSearchParams(window.location.search).get('token');
      //Se envía a la api
      this.authUserStore.resetPassword(this.request)
          .then(() => {
            //Tras éxito mostrar mensaje del cambio de contraseña
            NotificationService.showNotification("Contraseña actualizada.", "success")
          })
          .catch((error) => {
            console.log("Ha fallado el cambio de contraseña...", error.response.data)
            if (error.response.data.message) {
              NotificationService.showNotification(error.response.data.message, "error")
            } else {
              NotificationService.showNotification("Upss! Parece que ha ocurrido un error, si pasados unos minutos el problema persiste. Ponte en contacto con el equipo de soporte", "error")
            }
          })
          .finally(() => {
            this.$router.push({name: 'login'})
          })
    },
  }
}
</script>

<style scoped>

</style>