export default {
    loading_content: "Wir laden Ihre Inhalte hoch",
    wellcome: "Willkommen bei BrainsPro!",
    redirect_academy: "In wenigen Augenblicken werden Sie zu Ihrer Akademie weitergeleitet.",
    first: "Erste",
    last: "Zuletzt",
    next: "Weiter",
    page: "Seite",
    previous: "Vorherige",

}