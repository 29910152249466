export default {
    //AcademyComponent
    customization: 'Personalise your academy',
    domain: "Domain",
    brand_logo: "Logo and Brand",
    rrss_contact: "Contact and RRSS",
    legal: "Legal",
    options: 'Options',

    //DomainComponent
    your_domain: "Your domain mybrainspro.com",
    example_1: 'https://',
    example_2: '.mybrainspro.com',
    info_domain: "Type the domain WITHOUT symbols (.,-¿?!-$%&/()...), accents or spaces. You do not need to put www.",
    domain_input: "Domain mybrainspro.com",
    custom_domain: "Custom domain",
    domain_text: 'Domain',
    info_domain2: "Enter your domain (www.yourdomain.com) to use it as your homepage or as a secondary page (youracademy.yourdomain.com).",
    attention: "Attention",
    info_attention: "Only available for ADVANCED and PRO plan.",
    activate_domain: "To activate your domain at BrainsPro: ",
    info_activate_1line: "Visit the platform where you purchased your domain and look for the DNS settings.",
    info_activate_2line: "Adds record type A to IP: 76.223.11.227",
    info_activate_3line: "Please note that it can take up to 48 hours for these changes to take effect and propagate in your DNS. Once ready, you can start using the new address of your BrainsPro online academy.",
    info_activate_4line: "The brainspro team will contact you to send you some additional records that you will have to place in your DNS. With them we will generate your secure website (https://).",
    example_academy: 'academy-example',
    example_domain: 'myacademy.com',
    //LanguageSelector
    lang: 'Language',
    academy_lang: 'Academy Language',
    academy_lang_selector_text: 'Choose the language of your academy',

    //BrandingComponent
    banner: 'Banner',
    brand_academy: "Branding your academy",
    academy_name: "Name of Academy",
    full_name: "Choose the full name of your academy",
    tagline: "Academy TagLine",
    slogan: "Choose your academy's slogan",
    search: 'Search',
    my_academy: 'My Academy at BrainsPro',
    change: 'Change',
    insert: 'Insert',

    //ContactComponent
    description: "Description",
    web_rrss: "Web and Social Networks",
    web_blog: "Web or Blog",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    linkedin: "Linkedin",
    youtube: "Youtube",
    location: "Location and Fiscal Data",
    address_line_1: "Address, line 1",
    address_line_2: "Address, line 2",
    postalcode: "Postcode",
    city: "City",
    province: "Province",
    country: "Country",
    company_name: "Company name",
    nif_cif: "TAX ID/CIF",

    //LegalComponent
    privacy_policy: "Privacy Policy",
    text_register: "Summary text for user registration",
    user_view_text: "This text will be displayed in the user registration, as a summary of the longer version that will be displayed on your school's legal notice page.",
    privacy_policy_page: "Privacy Policy Page",
    privacy_policy_academy_page: "Academy Privacy Policy Page",
    legal_notice: "Legal Notice",

    //Contact
    contact_bloq: 'Contact Block',
    html_bloq: 'Inserts an html block to accompany the contact information. Like a google maps iframe.',

    //Branding
    //BannerBrandingComponent
    upload_banner: 'Upload your banner',
    image_format: 'PNG, JPG, GIF up to 3MB',

    //ColorsComponent
    desing_colors: 'Design and Colours',
    header_type: 'Header type',
    banner_complete: 'Full size banner',
    banner_complete_no_text: 'Full size banner without text',
    columns_text_image: '2 Columns, Text + Image',
    banner_info: 'This will change the type of cover image that users will see in your academy.',
    enterprise_color: 'Corporate colour',
    text_color: 'Text colour',

    //FaviconComponent
    favicon: 'Favicon',
    upload_favicon: 'Upload your favicon',
    favicon_format: 'PNG, JPG, GIF up to 3MB',

    //LogoBrandingComponent
    logo: 'Logo',
    upload_logo: 'Upload your logo',

    //OptionsComponent
    register_config: 'Registration Configuration',
    info_button_register: 'Activating this button will deactivate the option to register in the academy. To do so, all users must register from the Brainspro App.',
    restricted_register: 'Registration restriction',


}