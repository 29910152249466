<template>
  <section class="pt-2 pb-6 md:py-5">
    <card-with-header-and-footer-component :loading="general.loading">
      <template v-slot:header>
        <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.price_management') }}</h3>
      </template>

      <div class="col-span-6 grid grid-cols-2 gap-4">
        <h4 class="col-span-2 font-medium leading-5 text-gray-500">{{ $t('products.sell_price') }}</h4>
        <div>
          <label for="regular_price_pvp" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('general.pvp') }}</label>
          <input id="regular_price_pvp" v-model="regular_price" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="hidden">
          <label for="regular_price_net" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('general.net') }}</label>
          <input id="regular_price_net" :value="regular_price_net" disabled class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          <p class="text-gray-500 mt-2 pl-2 text-sm">{{ $t('products.tax') }}</p>
        </div>
      </div>

      <div class="col-span-6 grid grid-cols-2 gap-4 mt-6">
        <h4 class="col-span-2 font-medium leading-5 text-gray-500">{{ $t('products.price_offer') }}</h4>
        <div>
          <label for="sale_price_pvp" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('general.pvp') }}</label>
          <input id="sale_price_pvp" v-model="sale_price" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="hidden">
          <label for="sale_price_net" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('general.net') }}</label>
          <input id="sale_price_net" :value="sale_price_net"  disabled class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          <p class="text-gray-500 mt-2 pl-2 text-sm">{{ $t('products.tax') }}</p>
        </div>
      </div>

      <div v-if="sale_price == 0 || regular_price == 0" class="col-span-6 grid grid-cols-2 gap-4 mt-6">
        <h4 class="col-span-2 font-medium leading-5 text-gray-500">{{ $t('products.text_configuration') }}</h4>
        <div>
          <label for="button_checkout_text" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('products.free_text_button') }}</label>
          <input id="button_checkout_text" v-model="button_checkout_text" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          <p class="text-gray-500 mt-2 pl-2 text-sm">{{ $t('products.text_if_free') }}</p>
        </div>
      </div>

      <div v-show="regular_price" class="col-span-6 mt-5">
        <label for="product_is_selling" class="block text-sm font-medium leading-5 text-gray-700 mb-2">{{ $t('products.on_sale') }}</label>
          <ToggleComponent  v-bind:status="productIsSelling" :callback="toggleIsSelling" :product="$route.params.id" :key="productsStore.product.uuid"></ToggleComponent>
          <p v-show="regular_price" class="text-gray-500 mt-2 pl-2 text-sm">{{ $t('products.sale_info') }}</p>
      </div>
      <p v-show="!regular_price" class="mt-4 text-sm text-red-600">Debes introducir un Precio de Venta para poder vender tu curso</p>

      <template v-slot:footer>
        <button type="button" v-on:click="getProductGeneral" :disabled="general.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="setProductPrice" :disabled="general.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </section>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import ToggleComponent from "@/components/forms/ToggleComponent";
import NotificationService from "@/services/notification/NotificationService";
import {useProductsStore} from "@/stores/products";

export default {
  name: "SellComponent",
  components: {CardWithHeaderAndFooterComponent, ToggleComponent},
  setup(){

    const productsStore = useProductsStore()

    return{
      productsStore
    }
  },
  data() {
    return {
      general: {
        loading: true,
        regular_price: '',
        sale_price: '',
      }
    }
  },
  mounted() {
    this.general.loading = false;
    /*
    1. Checkear si el precio contiene algún valor.
    2. Si no lo contiene productIsSelling -> false
    3. Bloquear clic en el toogle component. ¿?¿?¿?
     */
    this.checkPriceToSell()
  },
  methods: {
    checkPriceToSell() {
    },
    getProductGeneral() {
      this.productsStore.getProduct(this.$route.params.id)
    },
    setProductPrice() {
      console.log("Guardando producto")
      this.productsStore.setProductPrice()
          .then((response) => {
            console.log("Almacenando seccion branding del producto", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el producto", error)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.productsStore.loading = false
          })
    },
    toggleIsSelling(uuid, value) {
      if(this.regular_price)
      {
        this.productsStore.product.is_selling = value
      }
      else
      {
        this.productsStore.product.is_selling = false
      }
      console.log(this.productsStore.product.is_selling)
    }
  },
  computed: {
    productIsSelling(){
        return this.productsStore.product.is_selling === 1
    },
    button_checkout_text: {
      get() {
        return this.productsStore.product.metadata.button_checkout_text
      },
      set(value) {
        this.productsStore.product.metadata.button_checkout_text = value
      }
    },
    regular_price: {
      get() {
        return this.productsStore.product.regular_price
      },
      set(value) {
        //Cambiamos el formato del precio:
        if(value.indexOf('\'') !== -1)
        {
          value = value.replace('\'', ".")
        }
        else if (value.indexOf(","))
        {
          value = value.replace(",", ".")
        }
        this.productsStore.product.regular_price = value
      }
    },
    regular_price_net() {
      return this.productsStore.product.regular_price * 100 * 97 / 100 / 100
    },
    sale_price: {
      get() {
        return this.productsStore.product.sale_price
      },
      set(value) {
        //Cambiamos el formato del precio:
        if(value.indexOf('\'') !== -1)
        {
          value = value.replace('\'', ".")
        }
        else if (value.indexOf(","))
        {
          value = value.replace(",", ".")
        }
        this.productsStore.product.sale_price = value
      }
    },
    sale_price_net() {
      return this.productsStore.product.sale_price * 100 * 97 / 100 / 100
    },
  }
}
</script>

<style scoped>

</style>