<template>
  <div class="pt-2 pb-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div v-if="coupons.length > 0">
        <router-link :to="{name: 'marketing.coupon.create'}" type="button" class="mb-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brainspro-600 hover:bg-brainspro-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brainspro-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
          </svg>
          {{ $t("marketing.create_coupon")}}
        </router-link>
        <div class="bg-white shadow overflow-hidden sm:rounded-md">
          <ul class="divide-y divide-gray-200">
            <li v-for="coupon in coupons" :key="coupon.uuid">
              <router-link :to="{ name: 'marketing.coupon', params: { id : coupon.uuid }}" class="block hover:bg-gray-50">
                <div class="px-4 py-4 flex items-center sm:px-6">
                  <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div class="truncate">
                      <div class="flex text-sm">
                        <p class="text-sm font-medium text-brainspro-700 truncate">
                          {{ coupon.code }}
                        </p>
                        <div class="ml-2 flex-shrink-0 flex">
                          <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {{ $t("marketing.activated") }}
                          </p>
                        </div>
                      </div>
                      <div class="mt-2 flex">
                        <div class="flex items-center text-sm text-gray-500">
                          <!-- Heroicon name: solid/calendar -->
                          <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                          </svg>
                          <p>
                            {{ $t("marketing.update_at") }}
                            <time :datetime="$moment(coupon.updated_at)">{{$moment(coupon.updated_at).format("LLLL")}}</time>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="hidden mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                      <div class="flex overflow-hidden -space-x-1">
                        <img class="inline-block h-6 w-6 rounded-full ring-2 ring-white" src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Emily Selman">

                        <img class="inline-block h-6 w-6 rounded-full ring-2 ring-white" src="https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Kristin Watson">

                        <img class="inline-block h-6 w-6 rounded-full ring-2 ring-white" src="https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Emma Dorsey">
                      </div>
                    </div>
                  </div>
                  <div class="ml-5 flex-shrink-0">
                    <!-- Heroicon name: solid/chevron-right -->
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <PaginationComponent class="hidden"></PaginationComponent>
      </div>
      <div v-else>
        <empty-state-simple>

        </empty-state-simple>
      </div>
    </div>
  </div>
</template>

<script>
import PaginationComponent from "@/views/common/PaginationComponent";
import couponService from "@/services/marketing/CouponService";
import EmptyStateSimple from "@/components/empty-states/empty-state-simple";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "CouponsComponent",
  components: {EmptyStateSimple, PaginationComponent},
  data: () => {
    return {
      loading: true,
      coupons: [],
      loadingLMS: false
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  created() {
    console.log("Se ha creado el listado de cupones correctamente")
    let academy_uuid = this.authUserStore.user.academy.uuid;

    couponService.index(academy_uuid)
        .then((response) => {
          console.log("Coupons", response.data.data)
          this.coupons = response.data.data
          console.log("Coupons length", this.coupons.length)
        })
        .catch((error) => {
          console.log("Error en la obtencion de cupones", error)
        })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style scoped>

</style>