import {useAuthUserStore} from "@/stores/auth";

export default {
    getFiles(academyUuid) {
        return useAuthUserStore().getApiClient.get('/storage/files', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },
    getSize(academyUuid) {
        return useAuthUserStore().getApiClient.get('/storage/status', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },
    getVideos(academyUuid) {
        return useAuthUserStore().getApiClient.get('/storage/files', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },
    getVideo(fileUuid) {
        return useAuthUserStore().getApiClient.get('/storage/files/' + fileUuid)
    },
    uploadFiles(academyUuid, files) {
        let formData = new FormData();

        files.forEach(file => formData.append('files[]', file.file))
        formData.append('academy_uuid', academyUuid)

        return useAuthUserStore().getApiClient.post('/storage/files', formData)
    }
}