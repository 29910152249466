<template>
  <div>
    <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div class="w-0 flex-1 flex">
        <router-link v-if="pagination.current_page > 1" :to="{ path: '/users/' + (parseInt($route.params.page) - 1)}" class="-mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150">
          <svg class="mr-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
          {{ $t("common.previous") }}
        </router-link>
        <router-link v-if="pagination.current_page != 1" :to="{ path: '/users/' + 1}" exact-active-class="border-green-500 text-green-600" class="-mt-px border-t-2 border-transparent pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150">{{ $t("common.first") }}</router-link>
      </div>
      <div class="hidden md:flex" v-if="pagination.last_page < 11">
        <router-link v-for="i in pagination.last_page" :to="{ path: '/users/' + i}" v-bind:key="i" exact-active-class="border-green-500 text-green-600" class="-mt-px border-t-2 border-transparent pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150">
          {{ i }}
        </router-link>
      </div>
      <div class="hidden md:flex items-center justify-center" v-else>
        <input type="text" v-model="propModel" class="shadow-sm focus:ring-bpblue focus:border-bpblue mt-4 text-center w-1/4 sm:text-sm border-gray-300 rounded-md">
      </div>
      <div class="w-0 flex-1 flex justify-end">
        <router-link v-if="pagination.current_page != pagination.last_page" :to="{ path: '/users/' + pagination.last_page}" class="-mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150">
          {{ $t("common.last") }} ({{pagination.last_page}})
        </router-link>
        <router-link v-if="pagination.current_page < pagination.last_page" :to="{ path: '/users/' + (parseInt($route.params.page) + 1)}" class="-mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150">
          {{ $t("common.next") }}
          <svg class="ml-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </router-link>
      </div>
    </nav>
    <div class="w-full mt-5 text-center text-sm leading-5 font-medium text-gray-500">{{ $t("common.page") }} {{pagination.current_page}} de {{pagination.last_page}}</div>
  </div>
</template>

<script>
    export default {
        name: "PaginationComponent",
        props: {
          pagination: {
            type: Object,
            default: function (){
              return {
                current_page: 1,
                from: 1,
                last_page: 1
              }
            }
          }
        },
      computed: {
        propModel: {
          get () { return this.pagination.current_page },
          set (value) {
            this.$router.push({ path: '/users/' + value})
          },
        },
      },
    }
</script>

<style scoped>

</style>