<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="text-center">
    <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
      <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
    </svg>
    <h3 class="mt-2 text-sm font-medium text-gray-900">{{ $t('empty_state.no_coupons') }}</h3>
    <p class="mt-1 text-sm text-gray-500">
      {{ $t('empty_state.text_coupon') }}
    </p>
    <div class="mt-6">
      <button v-on:click="navigateOnClick" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brainspro-600 hover:bg-brainspro-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brainspro-500">
        <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
        </svg>
        {{ $t('empty_state.new_coupon') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "empty-state-simple",

  methods: {
    navigateOnClick() {
      this.$router.push({name: "marketing.coupon.create"})
    }
  }
}
</script>