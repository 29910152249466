export default {
    reports: "Vendas",
    //Orders view
    orders: "Encomendas",
    payments: "Pagamentos",
    pvp_sales: "Vendas",
    net_sales: "Receitas",
    pvp_returns: "Devolve PVP",
    net_returns: "Rede de devoluções",
    buyer: "Comprador",
    view_order: "Ver Ordem",
    loading_orders: "Estamos a carregar as suas encomendas",
    balance: 'Balanço',

    //Payments view
    description: "Conceito/Descrição",
    from: "A partir de",
    to: "Para",
    payment_date: "Data de pagamento",
    quantity: "Quantidade",
    loading_payments: "Estamos a fazer o upload dos seus pagamentos",
    //OrdersComponent
    loading_products: 'Estamos a carregar as suas encomendas',
    complete: 'Concluído',
    reimbursed: 'Reembolsado',
    obtain_text_1: 'Foram obtidos os seguintes',
    obtain_text_2: 'encomendas.',
    //ProductSubscriptionListComponent
    loading_subscriptions: 'Estamos a carregar as suas subscrições',
    subscriptions: "Subscrições",
    activated: "Activada",
    canceled: "Cancelada",

    //BalanceComponent
    state: 'Estado',
    gross: 'Bruto',
    net: 'Net',
    commission: 'Comissão',
    created_at: 'Criado em',
    available_on: 'Disponível em'
}