export default {
    //EditUserSlideOverComponent
    edit_user: 'Benutzer bearbeiten',
    name: 'Name',
    lastname: 'Nachname',
    email: 'Email',
    password: 'Passwort',
    close: 'Schließen Sie',

    //SlideOverComponent
    enroll_in: 'Einschreiben:',
    select_project: 'Wählen Sie einen Kurs...',
    user_role: 'Rolle des Benutzers',
    student: 'Studenten',
    user_as_student: 'Registrieren Sie Ihren Benutzer als Student',
    teacher: 'Professor',
    user_as_teacher: 'Registrieren Sie Ihren Benutzer als Lehrer für den Kurs',
    teacher_no_edition: 'Lehrer ohne Bearbeitungsberechtigung',
    user_as_teacher_no_edition: 'Ihren Benutzer als Lehrer ohne Bearbeitungsrechte für den Kurs einschreiben',
    inspector: 'Inspektor',
    user_as_inspector: 'Registrieren Sie Ihren Benutzer als FUNDAE-Inspektor',
    email_notification: 'Per EMail benachrichtigen',
    text_email_notification: 'Benachrichtigen Sie Ihren Schüler, dass er/sie eingeschrieben wurde.',
    help_manual_user: 'Ich möchte mehr über die manuelle Benutzererstellung erfahren',
    create_user: 'Benutzer erstellen',
    create_info: 'Geben Sie zunächst die folgenden Informationen ein, um Ihren Benutzernamen zu erstellen.',

    //CardWithHeaderAndFooterComponent
    text_plan1: 'Diese Funktionalität gehört zu einem höheren Plan, bitte',
    text_plan2: 'Ihren Plan aktualisieren',
    loading_content: 'Wir laden Ihre Inhalte hoch',
}