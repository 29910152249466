export default {
    //ProductList
    your_created_projects: "Os seus cursos criados",
    new_project: "Novo Curso",
    projects_name: "NOME DO CURSO",
    update_at: 'Actualizado em ',
    coming_soon: 'Próximamente',
    search_your_project: 'Pesquise o seu produto...',
    project_name: 'NNome do produto',
    loading_products: 'Carregamento de produtos...',

    //ProductEditComponent
    modify_project: 'Modificar o seu produto',
    general: 'Geral',
    branding: 'Marca',
    product_page: 'Página do produto',
    sale: 'Venda',
    seo_text: 'SEO',
    projects: 'Projectos',

    //CategoriesSlideOverComponent
    categories: 'Categorias',
    text_category: 'Classifique os seus produtos por categoria para facilitar aos seus alunos a compra dos seus cursos.',
    select_category: 'Seleccione a categoria...',
    ebook: 'E-book',
    online_project: 'Curso online',
    blended_learning: 'Curso de aprendizagem mista',
    online_program: 'Programa Online',
    webinar_consulting: 'Webinar / Consultoria online',
    remove_category: 'Remover categoria',
    href_help: 'Gostaria de saber mais sobre as categorias',

    //ProductEdit
    //CustomCategoriesComponent
    //GeneralComponent
    category: 'Categoria',
    duration: 'Duração',
    featured_product: 'Produto em Destaque',
    delete_project: 'Eliminar Curso',
    warning_delete: 'Atenção!',
    message_delete_project: 'Ao apagar o curso, perderá permanentemente todos os seus dados.',
    question_delete_project: 'Tem a certeza de que quer apagar este curso?',
    delete: 'Eliminar',
    confirmation_delete_project: '(*) Esta caixa é obrigatória para apagar o curso.',
    //PageComponent
    principal_page: 'Página inicial',
    objects_presentation: 'Apresentação e objectos',
    learning_objectives: 'Não se esqueça de descrever brevemente os objectivos de aprendizagem.',
    teaching_staff: 'Metodologia e pessoal docente',
    public: 'Público',
    content: 'Conteúdo',
    sidebar: 'Barra Lateral',
    //ProductBrandingComponent
    banner: 'Banner',
    upload_banner: 'Sube tu banner',
    banner_format: 'PNG, JPG, GIF para 3MB',
    promotional_video: 'Vídeo Promocional',
    video_url: 'URL do vídeo',
    video_info: 'Se tiver um vídeo promocional, coloque aqui a url do vídeo no Youtube ou no Vimeo.',
    //SellComponent
    price_management: 'Gestão de Preciosa',
    sell_price: 'Preço de venda',
    tax: 'Em euros (-3% de imposto)',
    price_offer: 'Preço da Oferta',
    text_configuration: 'Configuração de texto',
    free_text_button: 'Título do botão de compra livre',
    text_if_free: 'Este título aparecerá se o seu produto for gratuito',
    on_sale: 'Para venda',
    sale_info: 'Se activado, o seu produto estará à venda',

    //SeoComponent
    seo_configuration: 'Configuração SEO',
    seo_title: 'Título SEO',
    slug: 'Slug',
    seo_description: 'Descrição SEO',

    //ProductListComponent
    featured: 'Destaque',
    classroom: 'Sala de aula',
    slug_text: 'slug',
    product: 'produto',
    products: 'produtos',
    preview: 'Pré-visualizaçãoVin',

    //AttachProjectSlideOverComponent
    attach_project: 'Projecto Associado',
    loading_projects: 'Carregamento de projectos associados...',
    project_selected: 'Projecto seleccionado',
    quest_attach_project: 'Quer anexar este projecto?',
    back: 'Volver',
    add_project: 'Anexar Projecto',
    close_panel: 'Fechar painel',

    //ProjectAttachedListComponent
    attached_projects: 'Projectos Associados',
    loading_products_attached: 'Carregamento de produtos associados...',
    unattach_project: 'Projecto Desassociar',
    info_unattach_project: 'Ao dissociar o projecto do produto, os estudantes que comprarem o produto não poderão ter acesso ao projecto.',
    quest_unattach_project: 'Tem a certeza de querer dissociar o projecto ',
}