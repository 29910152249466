export default {
    create_account: 'Ein Konto erstellen',
    login: 'sich anmelden',
    access_with: 'Zugang mit',
    continue_with: 'O contunia mit',
    error_text1: 'Ups! Es gibt',
    error_text2: 'in Ihrer Bewerbung.',
    privacy_text1: 'Ich habe die Datenschutzbestimmungen gelesen und akzeptiere sie. Weitere Informationen finden Sie in unserem ',
    privacy_text2: 'Datenschutzbestimmungen',
    marketing_text: 'Ich erkläre mich im Voraus ausdrücklich damit einverstanden, dass BrainsPro mir kommerzielle Mitteilungen über seine Dienstleistungen zusendet.',
    want_academy: 'Ich will meine Akademie!',
    ad_text1: 'Verwandeln Sie Ihre Online-Kurse in ',
    ad_text2: 'Lernerfahrungen',
    ad_text3: ' hohe Auswirkung',
    ad_text4: 'Ihre Schüler werden die Qualität Ihrer Dienstleistungen zu schätzen wissen, und Sie haben alles auf einfache Weise im Griff.',
    button_next: 'Weiter',
    start: 'Ein Konto erstellen',
    error_firstname_required: 'Sie müssen Ihren Namen eingeben',
    error_lastname_required: 'Sie müssen Ihren Nachnamen eingeben',
    error_email_address_required: 'Sie müssen eine gültige E-Mail Adresse eingeben',
    error_phone_required: 'Sie müssen eine gültige Rufnummer eingeben',
    error_pass_required: 'Sie müssen ein gültiges Passwort eingeben (mindestens 8 Zeichen)',
    error_confirm_pass_required: 'Muss das gleiche Passwort wie oben sein',
    error_email_used: 'Diese E-Mail existiert bereits. Bitte melden Sie sich an oder erstellen Sie Ihr Konto mit einer anderen E-Mail-Adresse',
    subdomain: 'Subdomain',

    //CustomerDataComponent
    title_create_your_academy: 'Wir sind fast am Ziel...',
    question_goal: 'Was ist Ihr Hauptziel bei Brainspro?',
    select_goal: 'Wählen Sie ein Ziel',
    question_volume: 'Wie viele Personen möchten Sie ausbilden?',
    select_volume: 'Wählen Sie einen ungefähren Bereich',
    question_sector: 'In welchem Bereich sind Sie tätig?',
    select_sector: 'Wählen Sie einen Sektor',
    question_area: 'Was ist Ihr beruflicher Bereich?',
    select_area: 'Wählen Sie einen Bereich',
    back: 'Zurück'
}