export default {
    coupons: "Gutscheine",
    scripts: "Kopf-/Fußzeilen-Skripte",
    integrations: "Integrationen",
    //CouponProductListComponent
    activated: "Aktiviert",
    end_at: "Endet am",
    related_product: "Zugehörige Produkte",
    associate_product: "Assoziiertes Produkt",
    select_product: "Wählen Sie ein Produkt",
    project_name: 'Name des Kurses',
    update_at: 'Aktualisiert am ',
    to_cancel: 'Abbestellen',

    //CouponComponent
    create_coupon: 'Coupon erstellen',
    coupon_data: 'Details zum Coupon',
    code: 'Code',
    quantity: 'Menge',
    offer_type: 'Abzinsungssatz',
    offer_text: 'Rabatttyp auswählen...',
    percent_offer: 'Prozentualer Rabatt',
    fixed_offer: 'Fester Produktrabatt',
    duration: 'Dauer',
    delete_coupon: 'Coupon löschen',
    info_delete_coupon: 'Dieser Coupon wird dauerhaft gelöscht',
    quest_delete_coupon: '¿Sind Sie sicher, dass Sie diesen Coupon löschen wollen??',
    view_receipt: 'Siehe Quittung',
    trial_ends_on: 'Die Probezeit endet am: ',
    error_code_required: 'Einen Gutscheincode eingeben',
    error_amount_required: 'Geben Sie den zu rabattierenden Betrag ein',

    //Integrations
    clientify: 'Clientify',
    clientify_info_text: 'Clientify es un CRM que te ayuda a organizar todos tus clientes, oportunidades, embudos de venta, automatizaciones...',
    whatsapp: 'Whatsapp',

    //GoogleAnalytics
    title_ga: 'Google Analytics',
    description_text: 'Geben Sie den Google Analytics html-Code ein',

    //CustomGlobalHTML
    title_html: 'Benutzerdefiniertes HTML',
    header_script: 'Kopfzeilen-Skripte',
    footer_script: 'Fußzeilen-Skripte',
    alert_message: 'Wenn der Code nicht korrekt ist, funktioniert die Website Ihrer Akademie möglicherweise nicht richtig. Der folgende Code wird in den Kopf- und/oder Fußzeilen-Tag eingefügt',

    //Clientify
    //Whatsapp
    telephone_number: 'Rufnummer',
    //GooglePixel
}