<template>
  <card-with-header-and-footer-component>
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.categories') }}</h3>
    </template>

    <div>

    </div>

    <template v-slot:footer>
      <button type="button" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brainspro-500">
        {{ $t('general.cancel') }}
      </button>

      <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brainspro-600 hover:bg-brainspro-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brainspro-500">
        {{ $t('general.save') }}
      </button>
    </template>
  </card-with-header-and-footer-component>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
export default {
  name: "CustomCategoriesComponent",
  components: {CardWithHeaderAndFooterComponent}
}
</script>

<style scoped>

</style>