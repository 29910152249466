<template>
  <section class="max-w-7xl mx-auto py-6 md:py-6 sm:px-8">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex overflow-hidden">
      <div class="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div class="flex-1 relative z-0 lg:flex lg:overflow-hidden">
          <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
            <!-- Start main area-->
            <div class="inset-0 px-4 sm:px-6 lg:px-8">
              <!-- class border-dashed border-2 border-gray-200 rounded-lg -->
              <div class="h-full">
                <div class="relative xl:flex xl:flex-col flex-shrink-0">
                  <table class="min-w-full">
                    <thead>
                    <tr>
                      <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Usuario
                      </th>
                      <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Fecha
                      </th>
                    </tr>
                    </thead>

                    <tbody class="bg-white">
                    <tr class="hover:bg-gray-50 transition-colors ease-in">
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 font-semibold text-gray-500">
                        emarono@loreka.com
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-400">2023-10-06 11:25:46
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-50 transition-colors ease-in">
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 font-semibold text-gray-500">
                        emarono@loreka.com
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-400">2023-09-22 08:24:03
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-50 transition-colors ease-in">
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 font-semibold text-gray-500">
                        emarono@loreka.com
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-400">2023-09-21 10:03:39
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-50 transition-colors ease-in">
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 font-semibold text-gray-500">
                        emarono@loreka.com
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-400">2023-09-20 21:02:17
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-50 transition-colors ease-in">
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 font-semibold text-gray-500">
                        emarono@loreka.com
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-400">2023-09-13 11:28:56
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LogComponent"
}
</script>

<style scoped>

</style>