<template>
  <div class="mx-6 my-6">
    <div class="relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 pl-3 -mt-7 flex items-center pointer-events-none">
              <span class="text-gray-500 text-xs sm:text-sm">
                https://
              </span>
      </div>
      <input type="url" v-model="videoURL"  class="focus:ring-bpblue focus:border-bpblue block w-full pl-16 pr-12 text-xs sm:text-sm border-gray-300 rounded-md">
      <p class="mt-2 text-sm text-gray-500" id="academy-tagline-description">{{$t('projects.insert_video_url')}}</p>
    </div>
  </div>
  <div v-if="videoURL !== ''" class="m-6">
    <iframe :src="'https://'+ videoURL" width="50%" height="50%"></iframe>
  </div>
</template>

<script>
export default {
  name: "VideoComponent",
  data() {
    return {
      projectTypeSelected: '',
      videoURL: '',
    }
  },
  created() {
    this.getVideoContent()
  },
  methods: {
    getVideoContent()
    {
      //TODO llamar al endpoint para obtener el contenido de la lección
    }
  }
}
</script>

<style scoped>

</style>