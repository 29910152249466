<template>
  <section v-show="navigationMenuStore.showSlideOverUserCreation" class="fixed inset-0 overflow-hidden" style="z-index: 2147483002" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="absolute inset-0 overflow-hidden">
      <!-- Background overlay, show/hide based on slide-over state. -->
      <div class="absolute inset-0 bg-gray-600 opacity-50" aria-hidden="true"></div>

      <div class="absolute inset-y-0 pl-16 max-w-full right-0 flex">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <transition
            name="display-user-creation"
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
        <div v-show="navigationMenuStore.showSlideOverUserCreation" class="w-screen max-w-md">
          <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl" v-on:submit.prevent="createUser">
            <div class="flex-1 h-0 overflow-y-auto">
              <div class="py-6 px-4 bg-bpblue sm:px-6">
                <div class="flex items-center justify-between">
                  <h2 class="text-lg font-medium text-white" id="slide-over-title">
                    {{$t('panels.create_user')}}
                  </h2>
                  <div class="ml-3 h-7 flex items-center">
                    <button type="button" v-on:click="navigationMenuStore.toggleSlideOverUserCreation" class="bg-bpblue rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="mt-1">
                  <p class="text-sm text-white">
                    {{$t('panels.create_info')}}
                  </p>
                </div>
              </div>
              <div class="flex-1 flex flex-col justify-between">
                <div class="px-4 divide-y divide-gray-200 sm:px-6">
                  <div class="space-y-6 pt-6 pb-5">
                      <div>
                        <label for="firstname" class="block text-sm font-medium text-gray-700">{{ $t('panels.firstname') }}</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text" v-model="v$.request.firstname.$model" id="firstname" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                          <div v-if="v$.request.firstname.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                            <!-- Heroicon name: solid/exclamation-circle -->
                            <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                          </div>
                        </div>
                        <p v-if="v$.request.firstname.$error" class="mt-2 text-sm text-red-600" id="error-firstname"> {{$t('panels.error_firstname_required')}}</p>
                      </div>
                    <div class="mt-6">
                      <label for="lastname" class="block text-sm font-medium text-gray-700">{{ $t('general.lastname') }}</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input type="text" v-model="v$.request.lastname.$model" id="lastname" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        <div v-if="v$.request.lastname.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                          <!-- Heroicon name: solid/exclamation-circle -->
                          <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                      <p v-if="v$.request.lastname.$error" class="mt-2 text-sm text-red-600" id="error-lastname"> {{$t('panels.error_lastname_required')}}</p>
                    </div>
                    <div class="">
                      <label for="email-address" class="block text-sm font-medium text-gray-700">{{ $t('panels.email') }}</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input type="text" v-model="v$.request.email.$model" id="email-address" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        <div v-if="v$.request.email.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                          <!-- Heroicon name: solid/exclamation-circle -->
                          <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                      <p v-if="v$.request.email.$error" class="mt-2 text-sm text-red-600" id="error-email-address"> {{$t('panels.error_email_required')}}</p>
                    </div>
                    <div class="mt-6">
                      <label for="password" class="block text-sm font-medium text-gray-700">{{ $t('general.pass') }}</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input v-bind:type="viewPassType" v-model="v$.request.password.$model" id="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        <!-- Icono para mostrar contraseña o no -->
                        <div @click="clickOnEye" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                          <!-- Heroicon name: solid/question-mark-circle -->
                          <svg v-if="!viewPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                          </svg>
                        </div>
                        <div @click="clickOnEye" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                          <svg v-if="viewPass" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                        </div>
                        <!-- fin de icono ojo -->
                      </div>
                      <p v-if="v$.request.password.$error" class="mt-2 text-sm text-red-600" id="error-password"> {{$t('signup.error_pass_required')}}</p>
                    </div>
                    <div class="mt-6">
                      <label for="phone" class="block text-sm font-medium text-gray-700">{{ $t('general.phone') }}</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input type="tel" v-model="v$.request.phone.$model" id="phone" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        <div v-if="v$.request.phone.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                          <!-- Heroicon name: solid/exclamation-circle -->
                          <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                      <p v-if="v$.request.phone.$error" class="mt-2 text-sm text-red-600" id="error-phone"> {{$t('panels.error_phone_required')}}</p>
                    </div>
                    <div class="mt-6">
                      <label for="identity_card" class="block text-sm font-medium text-gray-700">{{ $t('panels.identity_card') }}</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input type="identity_card" v-model="v$.request.identity_card.$model" id="identity_card" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        <div v-if="v$.request.identity_card.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                          <!-- Heroicon name: solid/exclamation-circle -->
                          <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                      <p v-if="v$.request.identity_card.$error" class="mt-2 text-sm text-red-600" id="error-identity-card"> {{$t('panels.error_dni_minLenght')}}</p>
                    </div>
                    <div class="col-span-6 sm:col-span-3 mt-10">
                      <label for="enrol" class="block text-sm font-medium text-gray-700">{{$t('panels.enroll_in')}}</label>
                      <select id="enrol" name="enrol" v-model="request.product_uuid" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-bpblue focus:border-bpblue sm:text-sm">
                        <option value="">{{$t('panels.select_project')}}</option>
                        <option v-for="product in products" v-bind:key="product.uuid" :value="product.uuid">{{ product.title }}</option>
                      </select>
                    </div>
                    <fieldset>
                      <legend class="text-sm font-medium text-gray-900">
                        {{$t('panels.user_role')}}
                      </legend>
                      <div class="mt-2 space-y-5">
                        <div class="relative flex items-start">
                          <div class="absolute flex items-center h-5">
                            <input id="role_student" v-on:change="changeNewUserRol" name="user-role-selector" v-model="request.rol" type="radio" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300" value="student" checked>
                          </div>
                          <div class="pl-7 text-sm">
                            <label for="role_student" class="font-medium text-gray-900">
                              {{$t('panels.student')}}
                            </label>
                            <p id="privacy_public_description" class="text-gray-500">
                              {{$t('panels.user_as_student')}}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div class="relative flex items-start">
                            <div class="absolute flex items-center h-5">
                              <input id="role_editing_teacher" v-on:change="changeNewUserRol" name="user-role-selector" v-model="request.rol" type="radio" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300" value="editing-teacher">
                            </div>
                            <div class="pl-7 text-sm">
                              <label for="role_editing_teacher" class="font-medium text-gray-900">
                                {{$t('panels.teacher')}}
                              </label>
                              <p id="role_editing_teacher_description" class="text-gray-500">
                                {{$t('panels.user_as_teacher')}}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="relative flex items-start">
                            <div class="absolute flex items-center h-5">
                              <input id="role_teacher" v-on:change="changeNewUserRol" name="user-role-selector" v-model="request.rol" type="radio" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300" value="teacher">
                            </div>
                            <div class="pl-7 text-sm">
                              <label for="role_teacher" class="font-medium text-gray-900">
                                {{$t('panels.teacher_no_edition')}}
                              </label>
                              <p id="role_teacher_description" class="text-gray-500">
                                {{$t('panels.user_as_teacher_no_edition')}}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="relative flex items-start">
                            <div class="absolute flex items-center h-5">
                              <input id="role_inspector" v-on:change="changeNewUserRol" name="user-role-selector" v-model="request.rol" type="radio" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300" value="inspector">
                            </div>
                            <div class="pl-7 text-sm">
                              <label for="role_inspector" class="font-medium text-gray-900">
                                {{$t('panels.inspector')}}
                              </label>
                              <p class="text-gray-500">
                                {{$t('panels.user_as_inspector')}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div v-if="request.rol === 'student'" class="mt-2 py-4">
                    <fieldset>
                      <div class="space-y-4">
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input id="notificate-checkbox" v-model="request.notify" name="notificate-checkbox" type="checkbox" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300 rounded" />
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="notificate-checkbox" class="font-medium text-gray-700">{{$t('panels.email_notification')}}</label>
                            <p class="text-gray-500">{{$t('panels.text_email_notification')}}</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <help-center-component :url="this.helpCenter.kn_create_user_url"></help-center-component>
                </div>
              </div>
            </div>
            <div class="flex-shrink-0 px-4 py-4 flex justify-end">
              <button type="button" v-on:click="navigationMenuStore.toggleSlideOverUserCreation" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                {{$t('general.close')}}
              </button>
              <button type="submit" :disabled="loading" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                {{$t('panels.create_user')}}
              </button>
            </div>
          </form>
        </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import productService from "@/services/product/ProductService";
import createUserService from "@/services/user/CreateUserService";
import NotificationService from "@/services/notification/NotificationService";
import useVuelidate from "@vuelidate/core";
import {email, maxLength, minLength, required} from "@vuelidate/validators";
import {useAuthUserStore} from "@/stores/auth";
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import helpcenter from "@/services/helpcenter/links";
import HelpCenterComponent from "@/views/common/HelpCenterComponent";

export default {
  name: "SlideOverComponent",
  components: {HelpCenterComponent},
  data: function () {
    return {
      show: false,
      loading: false,
      viewPass: false,
      request: {
        academy_uuid: null,
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        phone: null,
        identity_card: null,
        rol: "student",
        product_uuid: null,
        notify: false
      },
      products: []
    }
  },
  setup () {

    const authUserStore = useAuthUserStore();
    const navigationMenuStore = useNavigationMenuStore()
    const helpCenter = helpcenter

    return {
      authUserStore,
      navigationMenuStore,
      helpCenter,
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      request: {
        firstname: {
          required,
          maxLengthValue: maxLength(255)
        },
        lastname: {
          required,
          maxLengthValue: maxLength(255)
        },
        email: {
          required,
          email,
          maxLengthValue: maxLength(255)
        },
        phone: {
          maxLengthValue: maxLength(255)
        },
        password: {
          required,
          minLength: minLength(8),
          maxLengthValue: maxLength(255)
        },
        identity_card: {
          maxLengthValue: maxLength(255)
        },
      }
    }
  },
  created() {
    console.log("Se ha creado el listado de productos correctamente")
    console.log(productService);

    productService.index()
        .then((response) => {
          console.log("Productos para el nuevo usuario: ", response.data)
          this.products = response.data
          this.loading = false
        })
  },
  methods: {
    changeNewUserRol()
    {
      if(this.request.rol !== 'student')
      {
        this.request.notify = false
      }
    },
    createUser()
    {
      this.loading = true;
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.request.academy_uuid = academy_uuid

      createUserService.create(academy_uuid, this.request)
          .then((result) => {
            console.log("Resultado creación del usuario", result)
            NotificationService.showNotification("El usuario se ha creado correctamente", "success")
            this.navigationMenuStore.toggleSlideOverUserCreation()
          })
          .catch((error) => {
            console.log("Error en la creación del usuario", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },
    clickOnEye() {
      this.viewPass = !this.viewPass
    },
  },
  computed: {
    viewPassType() {
      return (this.viewPass) ? 'text' : 'password'
    }
  }
}
</script>

<style scoped>

</style>