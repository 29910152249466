export default {
    //ProjectsList
    your_created_projects: "Els teus projectes creats",
    new_project: "Nou Curs",
    search_course_placeholder: "Busca el teu projecte",
    projects_name: "NOM DEL CURS",
    update_at: 'Actualitzat el ',
    coming_soon: 'Properament',
    search_your_project: 'Busca el teu curs...',
    project_name: 'Nom del curs',
    loading_projects: 'Carregant projectes...',

    //ProjectEditComponent
    modify_project: 'Modificant el teu projecte',
    general: 'General',
    layout: 'Plantilla',
    access: 'Accés',
    branding: 'Branding',
    product_page: 'Pàgina de Producte',
    sale: 'Venda',
    seo_text: 'SEO',
    product: 'Producte',

    //CategoriesSlideOverComponent
    categories: 'Categories',
    text_category: 'Classifica els teus productes per categories, així facilitaràs als teus alumnes comprar els teus cursos.',
    select_category: 'Selecciona categoria...',
    ebook: 'E-book',
    online_project: 'Curs en línia',
    blended_learning: 'Curs semipresencial',
    online_program: 'Programa Online',
    webinar_consulting: 'Webinar / Consultoria online',
    remove_category: 'Eliminar categoria',
    href_help: 'Vull saber més sobre les categories',

    //ProjectEdit
        //CustomCategoriesComponent
        //ProjectGeneralComponent
        category: 'Categoria',
        duration: 'Durada',
        featured_product: 'Producte destacat',
        publish_project: 'Publicar Projecte',
        delete_project: 'Esborrar Curs',
        warning_delete: 'Atenció!',
        message_delete_project: 'En eliminar el curs perdràs totes les dades definitivament.',
        question_delete_project: 'Esteu segur que voleu suprimir aquest curs?',
        delete: 'Eliminar',
        confirmation_delete_project: '(*) Aquesta casella és obligatòria per eliminar el curs',
        navigation: 'Navegació',
        navigation_free: 'Lliure',
        navigation_secuential: 'Seqüencial',
        navigation_without_restriction: "Sense restriccions. L'usuari podrà navegar lliurement pel curs (excloent-ne els cursos amb contingut per degoteig)",
        navigation_completed_restriction: "L'alumne ha de completar cada unitat d'aprenentatge abans de continuar la següent. La finalització de les unitats daprenentatge pot establir-se de diferents maneres.",
        quest_lesson_complete: 'Quan serà considerada una lliçó completada?',
        lesson_complete_at_visit: 'En ser visitada',
        lesson_at_visit_info: 'En entrar en una lliçó es completarà automàticament al progrés total del curs. (Per defecte)',
        lesson_complete_at_see: 'En visualitzar el contingut completament',
        lesson_at_see_info: 'Es marcarà com a completat en visualitzar el contingut completament (vídeo, ebook, podcast)',
        lesson_complete_on_time: 'En romandre un temps',
        lesson_on_time_info: 'Es completarà en estar a cada lliçó el temps indicat (HH:mm)',
        automatic_navigation: 'Navegació automàtica',
        automatic_navigation_disabled: 'Desactivat',
        automatic_navigation_enabled: 'Activat',
        automatic_navigation_info: 'Quan completeu el contingut d\'una lliçó passareu a la següent lliçó corresponent.',
        //PageComponent
        principal_page: 'Pàgina principal',
        objects_presentation: 'Presentació i objectes',
        learning_objectives: 'No us oblideu de descriure breument els objectius d\'aprenentatge.',
        teaching_staff: 'Metodologia i professorat',
        public: 'Públic',
        content: 'Contingut',
        sidebar: 'Barra Lateral',
        //ProductBrandingComponent
        banner: 'Banner',
        upload_banner: 'Puja el teu banner',
        banner_format: 'PNG, JPG, GIF fins a 3MBm',
        promotional_video: 'Vídeo Promocional',
        video_url: 'URL del vídeo',
        video_info: 'Si tens un vídeo promocional, col·loca aquí la url del vídeo a Youtube o Vimeo.',
        //SellComponent
        price_management: 'Gestió de preus',
        sell_price: 'Preu de venda',
        tax: 'A €. (-3% taxes)',
        price_offer: "Preu d'oferta",
        text_configuration: 'Configuració de textos',
        free_text_button: 'Títol de botó de compra gratuït',
        text_if_free: 'Aquest títol apareixerà si el teu producte és gratuït',
        on_sale: 'En venda',
        sale_info: 'Si està activat el teu producte estarà a la venda',

        //SeoComponent
        seo_configuration: 'Configuració SEO',
        seo_title: 'Títol SEO',
        slug: 'Slug',
        seo_description: 'Descripció SEO',

    //ProjectListComponent
    featured: 'Destacat',
    classroom: 'Aula',
    slug_text: 'slug',
    project: 'projecte',
    projects: 'projectes',
    preview: 'Previsualitzar',

    //ProjectAccessComponent
    access_project: 'Accés al curs',
    draft: 'Esborrany',
    draft_text: 'Aquest projecte no es mostrarà al catàleg i podrà ser editat',
    published: 'Publicat',
    published_text: 'Aquest projecte està publicat i els usuaris podran accedir-hi',
    private: 'Privat',
    private_text: 'Aquest projecte és privat, els usuaris dʻaquest curs es donaran dʻalta manualment',
    coming_soon_access: 'Properament',
    coming_soon_access_text: 'Aquest projecte es mostrarà al catàleg de cursos però no s\'hi podrà accedir',
    enrollment_closed: 'Matriculació tancada',
    enrollment_closed_text: 'Només els usuaris matriculats podran entrar al curs. El curs apareixerà al catàleg però no es podran matricular nous usuaris',
    course_expiration: 'Caducitat del curs',
    project_expiration_info: 'El teu curs serà accessible en aquest període de temps. En acabar, es denegarà l\'accés al curs als usuaris matriculats.',
    init_date: "Data d'inici",
    project_delete: 'Esborrar',
    init_date_selected: "Data d'inici seleccionada",
    end_date: 'Data de fi',
    date_delete: 'Esborrar',
    end_date_selected: 'Data de finalització seleccionada',

    //ProjectContentComponent
    new_section: 'Nova Secció',
    section_title: 'Títol de la secció: ',
    create_section: 'Crear secció',
    import_section: 'Importar secció',
    lesson_title: 'Títol de la selecció:',
    new_lesson: 'Nova lliçó',

    //AttachProductListComponent
    attached_products: 'Productes Associats',
    attach_product: 'Adjuntar Producte',
    loading_attached_products: 'Carregant productes associats...',
    unattach_product: 'Desassociar producte',
    info_unattach_product: 'En desasociar el producte al projecte, els alumnes que comprin aquest producte no podran accedir al projecte.',
    quest_unattach_product: 'Segur que vols desasociar el producte ',
    no_product_attached: 'NO HI HA PRODUCTES ASSOCIATS',

    //AttachProductSlideOverComponent
    attach_product_slide: 'Associar Producte',
    selected_product: 'Producte seleccionat',
    quest_attach_product: 'Vols adjuntar aquest producte?',

    //ProjectEditContentComponent
    back: 'Tornar',
    select_type_content: 'Seleccioneu el tipus de contingut',
    type_lesson: 'Tipus de lliçó: ',
    html_editor: 'Editor HTML',
    html_info_text: 'Insereix el codi HTML que vulguis mostrar a la teva lliçó',
    insert_video_url: 'Insereix la URL del vídeo',
    content_index: 'Índex de continguts',
    student_list: "Llista d'estudiants",

    //ProjectLayoutContent
    image: 'Imatge',
    classic_design: 'Aspecte Clàssic'
}