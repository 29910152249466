<template>
  <div class="max-w-7xl mx-auto py-2 md:py-6 sm:px-6 lg:px-4">
    <section class="p-4">
      <card-with-header-and-footer-component :loading="subdomain.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.your_domain") }}</h3>
        </template>
        <alert-component>
          {{ $t("customization.info_domain") }}
        </alert-component>

        <div class="my-4">
          <label for="mybrainspro-domain" class="block text-sm font-medium text-gray-700">{{ $t("customization.domain_input") }}</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 text-xs sm:text-sm">
                {{$t('customization.example_url_placeholder')}}
              </span>
            </div>
            <input type="text" v-model="subdomain.request.subdomain" name="mybrainspro-domain" id="mybrainspro-domain" class="focus:ring-bpblue focus:border-bpblue block w-full pl-16 pr-12 text-xs sm:text-sm border-gray-300 rounded-md" :placeholder="$t('customization.example_academy')" aria-describedby="price-currency">
            <div class="flex absolute inset-y-0 right-0 pr-3 items-center pointer-events-none">
              <span class="text-gray-500 text-xs sm:text-sm" id="price-currency">
                {{$t('customization.example_2')}}
              </span>
            </div>
          </div>
        </div>

        <template v-slot:footer>
          <button type="button" v-on:click="getSubdomain" :disabled="subdomain.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setSubdomain" :disabled="subdomain.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>

    <section class="p-4 mt-4">
      <card-with-header-and-footer-component :loading="domain.loading" :blocked="domain.blocked">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.custom_domain") }}</h3>
        </template>

        <alert-component class="mb-5">
          {{ $t("customization.info_domain2") }}
        </alert-component>

        <div hidden class="rounded-md bg-yellow-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: exclamation -->
              <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
            <div hidden class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">
                {{ $t("customization.attention") }}
              </h3>
              <div hidden class="mt-2 text-sm text-yellow-700">
                <p>
                  {{ $t("customization.info_attention") }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="my-4">
          <label for="custom-domain" class="block text-sm font-medium text-gray-700">{{$t('customization.domain_text')}}</label>
          <div class="mt-1">
            <input type="text" v-model="domain.request.domain" name="custom-domain" id="custom-domain" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="$t('customization.example_domain')">
          </div>
        </div>

        <p class="my-4">{{ $t("customization.activate_domain") }}
          <ol class="mt-2 list-decimal pl-6">
            <li>{{ $t("customization.info_activate_1line") }}</li>
            <li>{{ $t("customization.info_activate_2line") }}</li>
            <li>{{ $t("customization.info_activate_3line") }}</li>
            <li>{{ $t("customization.info_activate_4line") }}</li>
          </ol>
        </p>

        <template v-slot:footer>
          <button type="button" v-on:click="getDomain" :disabled="domain.loading || domain.blocked" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setDomain" :disabled="domain.loading || domain.blocked" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>

      <help-center-component :url="this.helpCenter.kn_custom_domain_url"></help-center-component>
    </section>

    <section class="p-4 col-span-2">
      <card-with-header-and-footer-component :loading="lang.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.lang") }}</h3>
        </template>
        <div>
          <label for="academy-name" class="block text-sm font-medium text-gray-700">{{ $t("customization.academy_lang") }}</label>
          <div class="mt-1">
            <select v-on:loadeddata="lang.options" v-model="academiesStore.academy.language_id"
                    class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
              <option v-for="option in lang.options" v-bind:key="option.id" :value="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
          <p class="mt-2 text-sm text-gray-500" id="academy-name-description">{{ $t("customization.academy_lang_selector_text") }}</p>
        </div>
        <template v-slot:footer>
          <button type="button" v-on:click="getAcademyLanguage" :disabled="lang.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setLang" :disabled="lang.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>
  </div>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import AlertComponent from "@/components/feedback/alerts/AlertComponent";
import SubdomainService from "@/services/academy/SubdomainService";
import DomainService from "@/services/academy/DomainService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";
import LanguageService from "@/services/settings/LanguageService";
import AcademyLanguageService from "@/services/academy/AcademyLanguageService";
import HelpCenterComponent from "@/views/common/HelpCenterComponent";
import helpcenter from "@/services/helpcenter/links";

export default {
name: "DomainComponent",
  components: {
    HelpCenterComponent,
    CardWithHeaderAndFooterComponent,
    AlertComponent
  },
  data() {
    return {
      subdomain: {
        loading: true,
        request: {
          subdomain: ''
        }
      },
      domain: {
        blocked: false,
        loading: true,
        request: {
          domain: ''
        }
      },
      lang: {
        loading: true,
        options: null,
        selectedLangId: null, //Select Input
        academyLangId: null,
      },
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();
    const academiesStore = useAcademiesStore()
    const helpCenter = helpcenter

    return {
      authUserStore,
      academiesStore,
      helpCenter
    }
  },
  created() {
    console.log("Entrada domain")
    this.getSubdomain()
    this.getDomain()
    this.getLanguages()
  },
  methods: {
    getSubdomain() {
      let academy_uuid = this.academiesStore.getSelectedAcademyUuid;
      this.subdomain.loading = true;

      SubdomainService.index(academy_uuid)
        .then((response) => {
          console.log("Se ha obtenido el subdominio correctamente", response)
          this.subdomain.request.subdomain = response.data.subdomain
        })
        .catch((response) => {
          console.log("Error obteniendo el subdominio", response)
          NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
        })
        .finally(() => {
          this.subdomain.loading = false
        })
    },
    setSubdomain() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.subdomain.loading = true;

      SubdomainService.update(academy_uuid, this.subdomain.request)
        .then((response) => {
          console.log("Se ha almacenado el subdominio correctamente", response)
          NotificationService.showNotification("Se ha almacenado el subdominio correctamente", "success")

          this.academiesStore.getAcademy()
        })
        .catch((error) => {
          console.log("Error almacenando el subdominio", error.response)
          if (error.response.data.errors)
          {
            error.response.data.errors.forEach( (error) => {
              NotificationService.showNotification(error, "error")
            })
          }
          else
          {
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          }
        })
        .finally(() => {
          this.subdomain.loading = false
        })
    },
    getDomain() {
      let academy_uuid = this.academiesStore.getSelectedAcademyUuid;
      this.domain.blocked =
          this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.key !== "plan_pro" &&
          this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.key !== "plan_avanzado"
      this.domain.loading = true;

      DomainService.index(academy_uuid)
          .then((response) => {
            console.log("Se ha obtenido el dominio correctamente", response)
            this.domain.request.domain = response.data.domain
          })
          .catch((response) => {
            console.log("Error obteniendo el dominio", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.domain.loading = false
          })
    },
    setDomain() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.domain.loading = true;

      DomainService.update(academy_uuid, this.domain.request)
          .then((response) => {
            console.log("Se ha almacenado el dominio correctamente", response)
            NotificationService.showNotification("Se ha almacenado el dominio correctamente", "success")
          })
          .catch((error) => {
            console.log("Error almacenando el dominio", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.domain.loading = false
          })
    },
    getLanguages() {
      let academy_uuid = this.academiesStore.getSelectedAcademyUuid;
      this.lang.loading = true
      //Obtengo los idiomas.
      LanguageService.index(academy_uuid)
          .then((response) => {
            this.lang.options = response.data.data
            this.getAcademyLanguage()
            console.log("Se han obtenido los idiomas correctamente", this.lang.options)
          })
          .catch((response) => {
            console.log("Error obteniendo los idiomas", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.lang.loading = false
          })
    },
    setLang() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.lang.loading = true
      let request = {
        academy_uuid: academy_uuid,
        language_id: this.academiesStore.academy.language_id
      }

        console.log("El idioma seleccionado es: ", this.academiesStore.academy.language_id)
        AcademyLanguageService.update(request)
            .then((response) => {
              console.log("Se ha almacenado el idioma correctamente: ", response)
            })
            .catch((error) => {
              console.log("Error al almacenar el idioma en la academia: ", error)
            })
            .finally(() => {
              this.lang.loading = false
            })
    },
    getAcademyLanguage() {
      this.lang.academyLangId = this.academiesStore.academy.language_id
      this.academiesStore.academy.languageName = this.lang.options.filter(lang => lang.id === this.lang.academyLangId)[0].name
      console.log("El lenguaje de la academia es: ", this.academiesStore.academy.languageName)
    },
  }
}
</script>

<style scoped>

</style>