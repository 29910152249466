import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid) {
        return useAuthUserStore().getApiClient
            .get('/v2/saas/invoices', {
                params: {
                    academy_uuid: academyUuid
                }
            })
    },
}