<template>
  <section v-if="!this.general.loading" class="pt-2 pb-6 md:py-5">

    <div v-on:click="this.$router.go(-1)" class="flex justify-center mb-5">
      <div class="flex flex-wrap justify-center items-center w-2/12 cursor-pointer -mt-8 mb-2 bg-bpblue hover:bg-blue-300 p-2 text-white rounded-2xl">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <label class="cursor-pointer ml-2">{{$t('projects.back')}}</label>
      </div>
    </div>

    <div class="bg-white rounded-lg shadow-inner shadow-stone-100 border border-stone-300 relative py-5 mx-4">
      <div class="flex flex-row justify-between px-10">
        <h3 class="mt-2 text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.general') }}</h3>
      </div>
      <div class="h-px mx-auto my-6 bg-gray-200 border-0 dark:bg-gray-200 mb-8"></div>

      <div class="mt-5 sm:mt-5 px-10">
        <h3 class="hidden text-xl leading-6 font-medium text-gray-900" id="modal-headline">
          Tu archivo: nombre del archivo
        </h3>
        <div class="mb-5">
          <video class="w-full rounded-md shadow-md" controls>
            <source :src="this.video.link">
          </video>
        </div>
      </div>
    </div>


      <!--
      <div class="col-span-6">
        <label for="product_title" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('general.title') }}</label>
        <input id="product_title" v-model="title" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
      </div>
      -->

      <!--
      <div class="col-span-6 mt-5">
        <label for="product_duration" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('products.duration') }}</label>
        <input id="product_duration" v-model="duration" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
      </div>
      -->

  </section>
</template>

<script>

import StorageService from "@/services/academy/StorageService";
import NotificationService from "@/services/notification/NotificationService";

export default {
  name: "GeneralComponent",
  components: {},
  data() {
    return {
      general: {
        loading: false,
      },
      title: '',
      video: null,
    }
  },

  created() {
    this.getVideo()
  },
  methods: {
    getVideo() {
      this.general.loading = true
      let video_uuid = this.$route.params.id;
      console.log("La uuid del video es: ", video_uuid)

      StorageService.getVideo(video_uuid)
          .then((response) => {
            this.video = response.data.data
            console.log("El video obtenido es: ", this.video)
          })
          .catch((error) => {
            console.log("Error obteniendo el producto", error.response)
            if (error.response.data.errors) {
              error.response.data.errors.forEach((error) => {
                NotificationService.showNotification(error, "error")
              })
            } else {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.general.loading = false
          })
    }
  },
}
</script>

<style scoped>

</style>