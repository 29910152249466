<template>
  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-shrink-0">
    <div v-if="this.general.saasSubscriptionStatusIsCanceled"></div>
    <div v-else class="flex flex-col w-64">
      <div class="flex justify-center flex-col h-auto py-2 flex-shrink-0 px-4 bg-gray-900">
        <div class="flex justify-center mb-2">
          <img v-if="authUserStore.user.academy.branding" class="h-24 w-24"
               :src="academiesStore.academy.branding.logo_url" alt="Brandname"/>
          <img v-else class="h-24 w-24" src="/images/avatar-transparent.png" alt="Brandname"/>
        </div>
        <div class="flex justify-center flex-col text-center">
          <h3 class="text-white font-semibold">{{ authUserStore.user.academy.title }}</h3>
          <span class="text-gray-300 text-sm">{{ authUserStore.user.academy.tagline }}</span>
        </div>
      </div>
      <div class="h-0 flex-1 flex flex-col overflow-y-auto">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <nav class="flex-1 px-2 py-4 bg-gray-800">
          <router-link to="/" exact-active-class="bg-gray-900 text-white"
                       class="group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <font-awesome-icon
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                icon="home" size="lg"/>
            {{ $t("general.home") }}
          </router-link>
          <!--<router-link to="/instructors" active-class="bg-gray-900 text-white" class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <font-awesome-icon class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" icon="chalkboard-teacher" size="lg" />
              Equipo Docente

              <span class="ml-auto inline-block py-0.5 px-3 text-xs text-gray-800 leading-4 rounded-full bg-white transition ease-in-out duration-150">
                  5
              </span>
          </router-link>-->
          <router-link to="/projects" active-class="bg-gray-900 text-white"
                       class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                 class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150">
              <path
                  d="M11.25 5.337c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.036 1.007-1.875 2.25-1.875S15 2.34 15 3.375c0 .369-.128.713-.349 1.003-.215.283-.401.604-.401.959 0 .332.278.598.61.578 1.91-.114 3.79-.342 5.632-.676a.75.75 0 0 1 .878.645 49.17 49.17 0 0 1 .376 5.452.657.657 0 0 1-.66.664c-.354 0-.675-.186-.958-.401a1.647 1.647 0 0 0-1.003-.349c-1.035 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401.31 0 .557.262.534.571a48.774 48.774 0 0 1-.595 4.845.75.75 0 0 1-.61.61c-1.82.317-3.673.533-5.555.642a.58.58 0 0 1-.611-.581c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.035-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959a.641.641 0 0 1-.658.643 49.118 49.118 0 0 1-4.708-.36.75.75 0 0 1-.645-.878c.293-1.614.504-3.257.629-4.924A.53.53 0 0 0 5.337 15c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.036 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.369 0 .713.128 1.003.349.283.215.604.401.959.401a.656.656 0 0 0 .659-.663 47.703 47.703 0 0 0-.31-4.82.75.75 0 0 1 .83-.832c1.343.155 2.703.254 4.077.294a.64.64 0 0 0 .657-.642Z"/>
            </svg>
            {{ $t("general.project") }}
          </router-link>
          <router-link to="/products" active-class="bg-gray-900 text-white"
                       class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <svg xmlns="http://www.w3.org/2000/svg"
                 class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                 fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <font-awesome-icon
                  class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                  icon="chalkboard" size="lg"/>
            </svg>
            {{ $t("general.products") }}
          </router-link>
          <router-link to="/records" active-class="bg-gray-900 text-white"
                       class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                 class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150">
              <path
                  d="M4.5 4.5a3 3 0 00-3 3v9a3 3 0 003 3h8.25a3 3 0 003-3v-9a3 3 0 00-3-3H4.5zM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06z"/>
            </svg>
            {{ $t("general.records") }}
          </router-link>
          <router-link to="/videos" active-class="bg-gray-900 text-white"
                       class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                 class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150">
              <path fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                    clip-rule="evenodd"/>
            </svg>
            <span>{{ $t("general.videos") }}</span>
            <span class=" ml-2 inline-flex items-center rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">Beta</span>
          </router-link>

          <!-- Container de Comunidad -->
          <div class="divide-x-2 divide-gray-600 ">
            <div
                class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
              <font-awesome-icon
                  class="mr-3 h-6 w-6 text-gray-400 group-focus:text-gray-300 transition ease-in-out duration-150"
                  icon="users" size="lg"/>
              {{ $t("general.community") }}
            </div>
            <div class="ml-5 pl-2">
              <router-link to="/users/1" active-class="bg-gray-900 text-white"
                           class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor"
                     class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg>
                Usuarios
              </router-link>
              <router-link to="/groups" active-class="bg-gray-900 text-white"
                           class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor"
                     class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"/>
                </svg>
                Grupos
                <span class=" ml-2 inline-flex items-center rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">Beta</span>
              </router-link>
            </div>
          </div>

          <router-link to="/academy" active-class="bg-gray-900 text-white"
                       class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <font-awesome-icon
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                icon="school" size="lg"/>
            {{ $t("general.customization") }}
          </router-link>
          <router-link to="/reports" active-class="bg-gray-900 text-white"
                       class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <font-awesome-icon
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                icon="chart-bar" size="lg"/>
            {{ $t("general.sales") }}
          </router-link>
          <router-link to="/marketing" active-class="bg-gray-900 text-white"
                       class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <font-awesome-icon
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                icon="bullhorn" size="lg"/>
            {{ $t("general.marketing") }}
          </router-link>
          <!--
            <router-link to="" active-class="hidden bg-gray-900 text-white" class="hidden mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
              <font-awesome-icon class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" icon="cogs" size="lg" />
              {{ $t("general.settings") }}
            </router-link>
          -->
          <router-link to="/myaccount/" active-class=" bg-gray-900 text-white"
                       class=" mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
            <svg xmlns="http://www.w3.org/2000/svg"
                 class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                 viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clip-rule="evenodd"/>
            </svg>
            {{ $t('general.my_account') }}
          </router-link>
        </nav>
        <div class="p-4 bg-gray-800 flex flex-col justify-center items-center ">
          <div class="mb-3">
            <img class=" w-8 h-6" src="/images/logo-negativo-cara.png">
          </div>
          <div class="text-xs text-blue-100">
            <span class="font-light">Powered by</span>
            <a class="text-xs text-blue-100 font-medium"> Brainspro</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";

export default {
  name: "NavigationDesktop.vue",
  data: () => {
    return {
      general: {
        saasSubscriptionStatusIsCanceled: false
      }
    }
  },
  setup() {
    const authUserStore = useAuthUserStore()
    const academiesStore = useAcademiesStore()

    return {
      authUserStore,
      academiesStore
    }
  },
  updated() {
    this.getSaasSubscriptionStatus()
  },
  methods: {
    getSaasSubscriptionStatus() {
      let saasSubscriptionStatus = this.authUserStore.user.academy.saas_subscriptions[0].status
      if (saasSubscriptionStatus === 'canceled' || saasSubscriptionStatus === 'past_due') {
        console.log("Restringido el acceso a canceled: ", saasSubscriptionStatus)
        this.general.saasSubscriptionStatusIsCanceled = true
      }
    }
  }
}
</script>

<style scoped>

</style>