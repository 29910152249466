export default {
    coupons: "Cupons",
    scripts: "Scripts encapçalat/peu de pàgina",
    integrations: "Integracions",
    //CouponProductListComponent
    activated: "Activat",
    end_at: "Finalitza el",
    related_product: "Productes associats",
    associate_product: "Associar producte",
    select_product: "Selecciona un producte",
    project_name: 'Nom del curs',
    update_at: 'Actualitzat el ',
    to_cancel: 'Donar de baixa',

    //CouponComponent
    create_coupon: 'Crear Cupó',
    coupon_data: 'Dades del cupó',
    code: 'Codi',
    quantity: 'Quantitat',
    offer_type: 'Tipus de descompte',
    offer_text: 'Selecciona tipus de descompte...',
    percent_offer: 'Descompte en percentatge',
    fixed_offer: 'Descompte fix de producte',
    duration: 'Durada',
    delete_coupon: 'Esborrar Cupó',
    info_delete_coupon: "Aquest cupó s'esborrarà definitivament",
    quest_delete_coupon: 'Segur que vols esborrar aquest cupó?',
    view_receipt: 'Veure rebut',
    trial_ends_on: 'El període de prova finalitza el: ',
    error_code_required: 'Introdueix un codi de cupó',
    error_amount_required: 'Introdueix la quantitat a descomptar',

    //Integrations
    clientify: 'Clientify',
    clientify_info_text: "Clientify és un CRM que t'ajuda a organitzar tots els clients, oportunitats, embuts de venda, automatitzacions...",
    whatsapp: 'Whatsapp',

    //GoogleAnalytics
    title_ga: 'Google Analytics',
    description_text: 'Introduïu el codi html de Google Analytics',

    //CustomGlobalHTML
    title_html: 'HTML Personalitzat',
    header_script: 'Scripts de capçalera',
    footer_script: 'Scripts de peu de pàgina',
    alert_message: "Si el codi és incorrecte pot ser que la web de la teva acadèmia no funcioni correctament. El codi següent s'introduirà a l'etiqueta header i/o footer",

    //Clientify
    //Whatsapp
    telephone_number: 'Número de Telèfon',
    //GooglePixel
}