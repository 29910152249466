export default {
    hello: "Hola",
    create_a_project: "Crea un curs",
    customize_your_academy: "Personalització",
    check_your_sales: "Consulta els teus ingressos",
    create_your_project: "Crea el teu curs",
    placeholder_name: "El meu curs a Brainspro",
    text_describe: "El títol descriu en una sola frase allò que els seus alumnes aprendran.",
    bttn_close: "Tancar",
    bttn_create: "Crear curs!",
    contacts: "Més dubtes?, Contacta'ns!",
    client_attention: 'Atenció al client: ',
    mail_client_attention: 'hola@brainspro.com',
    support: 'Suport tècnic: ',
    email_support: 'soporte@brainspro.com',
    phone_whatsapp: 'Telèfon i Whatsapp: ',
    phone_number: '+34 626 201 897',
    text_support: 'Reserva una cita online amb un dels nostres experts',
    href_intercom: 'Anar a la base de coneixement',
    href_custom_domain: 'Més sobre la configuració de dominis personalitzats.',
    no_annual_data: "No hi ha dades anuals",
    no_monthly_data: "No hi ha dades mensuals",
    no_students_data: "No hi ha dades d'estudiants registrats",
    no_projects_data: "No hi ha dades de projectes creats",

}