import {useAuthUserStore} from "@/stores/auth";

export default {
    index() {
        return useAuthUserStore().getApiClient.get('/academies', {})
    },

    show(academyUuid) {
        return useAuthUserStore().getApiClient.get('/academies/' + academyUuid, {})
    },

    updateAcademy(academyUuid, data)
    {
        return useAuthUserStore().getApiClient.put('/academies/' + academyUuid, data)
    },

    getUserEnrolList(academyUuid, projectUuid, page) {
        return useAuthUserStore().getApiClient
            .get('/v2/academy/users', {
                params: {
                    academy_uuid: academyUuid,
                    project_uuid: projectUuid,
                    page: page,
                }
            })
    },

    getSelectedAcademyUuid(state) {
        return state;
    },

    getLangSelected(academyUuid)
    {
        return useAuthUserStore().getApiClient.get('/academy/language', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },
    update(academyUuid, data)
    {
        return useAuthUserStore().getApiClient.put('/academy/language', data, {
            params: {
                academy_uuid: academyUuid
            }
        })
    },

    balance:
        {
            fetch(academyUuid)
            {
                return useAuthUserStore().getApiClient.get('/academy/' + academyUuid + '/balance')
            }
        }
}