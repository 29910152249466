import {useAuthUserStore} from "@/stores/auth";

export default {
    index() {
        return useAuthUserStore().getApiClient.get('/user/basic')
    },

    update(academyUuid, data)
    {
        return useAuthUserStore().getApiClient.put('/user/basic', data)
    }
}