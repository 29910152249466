export default {
    coupons: "Cupões",
    scripts: "Guias de cabeçalho/roteiros",
    integrations: "Integrações",
    //Coupons
    activated: "Activado",
    end_at: "Termina em",
    related_product: "Produtos associados",
    associate_product: "Produto associado",
    select_product: "Seleccione um produto",
    project_name: 'Nome do curso',
    update_at: 'Actualizado em ',
    to_cancel: 'Anular a subscrição',

    //CouponComponent
    create_coupon: 'Criar cupão',
    coupon_data: 'Detalhes do cupão',
    code: 'Código',
    quantity: 'Quantidade',
    offer_type: 'Taxa de Desconto',
    offer_text: 'Seleccione o tipo de desconto...',
    percent_offer: 'Desconto percentual',
    fixed_offer: 'Desconto de produto fixo',
    duration: 'Duração',
    delete_coupon: 'Eliminar cupão',
    info_delete_coupon: 'Este cupão será eliminado permanentemente',
    quest_delete_coupon: 'Tem a certeza de que quer eliminar este cupão?',
    view_receipt: 'Ver recibo',
    trial_ends_on: 'O período probatório termina: ',
    error_code_required: 'Introduzir um código de cupão',
    error_amount_required: 'Introduza o montante a descontar',

    //Integrations
    clientify: 'Clientify',
    clientify_info_text: 'Clientify es un CRM que te ayuda a organizar todos tus clientes, oportunidades, embudos de venta, automatizaciones...',
    whatsapp: 'Whatsapp',

    //GoogleAnalytics
    title_ga: 'Google Analytics',
    description_text: 'Introduza o código html do Google Analytics',

    //CustomGlobalHTML
    title_html: 'HTML personalizado',
    header_script: 'Guias de cabeçalho',
    footer_script: 'Guias de rodapé',
    alert_message: 'Se o código estiver incorrecto, o website da sua academia pode não funcionar correctamente. O seguinte código será inserido no cabeçalho e/ou no rodapé da etiqueta',

    //Clientify
    //Whatsapp
    telephone_number: 'Número de Telefone',
    //GooglePixel
}