import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid, groupUuid) {
        return useAuthUserStore().getApiClient.get('/v2/projects', {
            params: {
                academy_uuid: academyUuid,
                group_uuid: groupUuid
            }
        })
    },
    getMoodleCourseURL(projectUUID)
    {
        return useAuthUserStore().getApiClient
            .get('/project/' + projectUUID + '/lms/auth')
            .then(({ data }) => {
                console.log("Link de acceso al curso", data)
                return data;
            })
            .catch(error => {
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    //errorReporting.showError(error.response.data.message)
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log("No response", error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }

            })
    },
    get(projectUuid) {
        return useAuthUserStore().getApiClient.post('/project/' + projectUuid)
    },
    put(project) {
        return useAuthUserStore().getApiClient.put('/project', project)
    },
    delete(project) {
        return useAuthUserStore().getApiClient.put('project', project)
    },

    //=============================================================
    //                          SECTIONS
    //=============================================================
    //Creará la sección
    postSection(section) {
        return useAuthUserStore().getApiClient.post('/project/sections', section)
    },
    //Actualizará el nombre de la sección.
    putSection(section) {
        return useAuthUserStore().getApiClient.put('/project/sections', section)
    },

    //=============================================================
    //                          LESSONS
    //=============================================================
    //Obtener la lección
    getLesson(lessonUuid, projectUuid)
    {
        return useAuthUserStore().getApiClient.get('/projects/' + projectUuid + '/lessons/' + lessonUuid)
    },
    //Obtener los tipos de lección
    getLessonTypes()
    {
        return useAuthUserStore().getApiClient.get('/lesson_types')
    },
    //Guardar la lección
    postLesson(sectionUuid, title, lesson_type_key)
    {
        return useAuthUserStore().getApiClient.post('/lessons', {
            section_uuid: sectionUuid,
            title: title,
            lesson_type_key: lesson_type_key
        })
    },

    //=============================================================
    //                          Access
    //=============================================================
    getEnrolConfiguration (project_uuid)
    {
        return useAuthUserStore().getApiClient.get('/v2/projects/' + project_uuid + '/enrol-configuration')
    },
    postEnrolConfiguration(project_uuid, enrolConfiguration)
    {
        return useAuthUserStore().getApiClient.put('/v2/projects/' + project_uuid + '/enrol-configuration', {
            time_start_value: enrolConfiguration.time_start_value,
            time_start_unit: enrolConfiguration.time_start_unit,
            time_end_value: enrolConfiguration.time_end_value,
            time_end_unit: enrolConfiguration.time_end_unit,
        })
    },
    deleteEnrolConfiguration(project_uuid)
    {
        return useAuthUserStore().getApiClient.delete('/v2/projects/' + project_uuid + '/enrol-configuration')
    }

}