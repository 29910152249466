import { createRouter, createWebHistory } from 'vue-router'

import DashboardHelper from "@/views/dashboard/DashboardHelper";
import LoginComponent from "@/views/auth/LoginComponent";
import SubscriptionComponent from "@/views/settings/SubscriptionComponent";
import OrdersComponent from "@/views/sales/OrdersComponent";
import SignUpComponent from "@/views/auth/SignUpComponent";
import AppComponent from "@/views/AppComponent";
import ProfileEditComponent from "@/views/profile/ProfileEditComponent";
import BrandingComponent from "@/views/customization/BrandingComponent";
import StudentsComponent from "@/views/students/StudentsComponent";
import SettingsComponent from "@/views/settings/SettingsComponent";
import DomainComponent from "@/views/customization/DomainComponent";
import AcademyComponent from "@/views/customization/AcademyComponent";
import ContactComponent from "@/views/customization/ContactComponent";
import ReportsComponent from "@/views/sales/ReportsComponent";
import PayoutsComponent from "@/views/sales/PayoutsComponent";
import MarketingComponent from "@/views/marketing/MarketingComponent";
import LegalComponent from "@/views/customization/LegalComponent";
import UserListComponent from "@/views/community/UserListComponent";
import MassiveUserImportComponent from "@/views/community/MassiveUserImportComponent";
import UserProfileComponent from "@/views/community/UserProfileComponent";
import CouponsComponent from "@/views/marketing/CouponsComponent";
import CouponComponent from "@/views/marketing/CouponComponent";
import IntegrationsComponent from "../views/marketing/IntegrationsComponent";
import RememberPasswordComponent from "@/views/auth/RememberPasswordComponent";
import ResetPasswordComponent from "@/views/auth/ResetPasswordComponent";
import ProductSubscriptionListComponent from "../views/sales/ProductSubscriptionListComponent";
import CustomerDataComponent from "@/views/auth/signup/CustomerDataComponent"
import myAccountComponent from "@/views/MyAccount/MyAccountComponent";
import CurrentSubscriptionsComponent from "@/views/MyAccount/Subscription/CurrentSubscriptionsComponent";
import ThankYouPageComponent from "@/views/thankyoupage/ThankYouPageComponent";
import RecordsComponent from "@/views/records/RecordsComponent";
import ProjectsList from "@/views/projects/ProjectsList";
import ProjectEditComponent from "@/views/projects/ProjectEditComponent";
import ProjectGeneralComponent from "@/views/projects/ProjectEdit/ProjectGeneralComponent";
import ProjectContentComponent from "@/views/projects/ProjectEdit/ProjectContentComponent";
import ProjectLayoutComponent from "@/views/projects/ProjectEdit/ProjectLayoutComponent";
import ProjectAccessComponent from "@/views/projects/ProjectEdit/ProjectAccessComponent";
import ProductBrandingComponent from "@/views/products/ProductEdit/ProductBrandingComponent";
import PageComponent from "@/views/products/ProductEdit/PageComponent";
import ProductSellComponent from "@/views/products/ProductEdit/ProductSellComponent";
import ProductSeoComponent from "@/views/products/ProductEdit/ProductSeoComponent";
import ProductList from "@/views/products/ProductList";
import ProductEditComponent from "@/views/products/ProductEditComponent";
import ProductGeneralComponent from "@/views/products/ProductEdit/ProductGeneralComponent";
import ProjectEditContentComponent from "@/views/projects/ProjectEdit/ProjectEditContentComponent";
import ProjectAttachProductComponent from "@/views/projects/ProjectEdit/ProjectAttachProductComponent";
import ProductAttachProjectComponent from "@/views/products/ProductEdit/ProductAttachProjectComponent";
import BalanceComponent from "@/views/sales/BalanceComponent";
import ShowOrdersComponent from "@/views/sales/Orders/ShowOrdersComponent";
import ScriptsComponent from "@/views/marketing/ScriptsComponent"
import {useAcademiesStore} from "@/stores/academies";
import VideoComponent from "@/views/videos/VideoComponent.vue";
import UserEnrolProjectListComponent from "@/views/projects/ProjectEdit/UserEnrolProjectComponent";
import BillingComponent from "@/views/MyAccount/Billing/BillingComponent";
import VideoGeneralComponent from "@/views/videos/VideoEdit/VideoGeneralComponent";
import VideoNavigationComponent from "@/views/videos/VideoNavigationComponent";
import BillingAddressComponent from "@/views/community/user/BillingAddressComponent";
import DataComponent from "@/views/community/user/DataComponent";
import UsersComponent from "@/views/community/UsersComponent";
import InformationComponent from "@/views/MyAccount/Information/InformationComponent";
import LogComponent from "@/views/MyAccount/LogComponent";
import ProjectsComponent from "@/views/projects/ProjectsComponent";
import GroupsComponent from "@/views/community/GroupsComponent";
import GroupsListComponent from "@/views/community/groups/GroupsListComponent";
import GroupEditComponent from "@/views/community/groups/GroupEditComponent";
import GroupGeneralComponent from "@/views/community/groups/group/GroupGeneralComponent";
import GroupAttachProject from "@/views/community/groups/group/GroupAttachProject";
const routes = [
  {
    path: '/signup',
    name: 'signup',
    component: SignUpComponent,
  },
  {
    path: '/customer-data',
    name: 'customer-data',
    component: CustomerDataComponent,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent
  },
  {
    path: '/remember-password',
    name: 'remember-password',
    component: RememberPasswordComponent,
  },
  {
    path: '/reset',
    name: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: '/thankyoupage',
    name: 'thank-you-page',
    component: ThankYouPageComponent,
  },
  {
    path: '/',
    component: AppComponent,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardHelper,
        meta: { requiresAuth: true },
      },
      {
        path: 'users',
        component: UsersComponent,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'massive',
            name: 'community-massive',
            component: MassiveUserImportComponent,
            meta: { requiresAuth: true },
          },
          {
            path: ':page',
            name:'users',
            component: UserListComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'profile/:id',
            name: 'user-profile',
            component: UserProfileComponent,
            meta: { requiresAuth: true },
            redirect: {
              name: 'user-profile.data'
            },
            children: [
              {
                path: '',
                name: 'user-profile.data',
                component: DataComponent,
                meta: {requiresAuth: true}
              },
              {
                path:'billing-address',
                name: 'user-profile.billing-address',
                component: BillingAddressComponent,
                meta: { requiresAuth: true }
              },
            ]
          },
        ]
      },

      {
        path: '',
        name: 'groups',
        component: GroupsComponent,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'groups',
            name: 'groups-list',
            component: GroupsListComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'groups/:id',
            component: GroupEditComponent,
            meta: { requiresAuth: true },
            children: [
              {
                path: '',
                name: 'group-general',
                component: GroupGeneralComponent,
                meta: { requiresAuth: true }
              },
              {
                path: '',
                name: 'group-project',
                component: GroupAttachProject,
                meta: { requiresAuth: true }
              }
            ]
          }
        ]
      },
      {
        path: 'products',
        name: 'products',
        component: ProductList,
        meta: { requiresAuth: true },
      },
      {
        path: 'products/:id',
        component: ProductEditComponent,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'products-edit',
            component: ProductGeneralComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'branding',
            name: 'products-edit-branding',
            component: ProductBrandingComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'page',
            name: 'products-edit-page',
            component: PageComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'sell',
            name: 'products-edit-sell',
            component: ProductSellComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'seo',
            name: 'products-edit-seo',
            component: ProductSeoComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'attach-project',
            name: 'attach-project-to-product',
            component: ProductAttachProjectComponent,
            meta: { requiresAuth: true },
          }
        ]
      },
      {
        path: 'projects',
        name: 'projects',
        component: ProjectsComponent,
        meta: {requiresAuth: true},
        children: [
          {
            path: '',
            name: 'projects-list',
            component: ProjectsList,
            meta: {requiresAuth: true},
          },
          {
            path: 'projects/:id',
            component: ProjectEditComponent,
            meta: {requiresAuth: true},
            children: [
              {
                path: '',
                name: 'projects-edit',
                component: ProjectGeneralComponent,
                meta: {requiresAuth: true},
              },
              {
                path: 'content',
                name: 'projects-content',
                component: ProjectContentComponent,
                meta: {requiresAuth: true},
              },
              {
                path: 'edit-content/:uuid',
                name: 'projects-edit-content',
                component: ProjectEditContentComponent,
                meta: {requiresAuth: true}
              },
              {
                path: 'page',
                name: 'projects-edit-layout',
                component: ProjectLayoutComponent,
                meta: {requiresAuth: true},
              },
              {
                path: 'access',
                name: 'projects-edit-access',
                component: ProjectAccessComponent,
                meta: {requiresAuth: true},
              },
              {
                path: 'attach-product',
                name: 'projects-edit-attach-product',
                component: ProjectAttachProductComponent,
                meta: {requiresAuth: true},
              },
              {
                path: 'community/:page',
                name: 'community-enrol-project-list',
                component: UserEnrolProjectListComponent,
                meta: {requiresAuth: true},
              },
            ]
          },
          /*
        {
          path: '/projects/groups',
          name: 'projects-groups',
          component: GroupsComponent,
          meta: { requiresAuth: true },
          children: [

            {
              path: ':id',
              name: 'projects.group',
              component: GroupComponent,
              meta: { requiresAuth: true }
            },
            {
              path: 'create',
              name: 'projects-group-create',
              component: CreateGroupComponent,
              meta: { requiresAuth: true }
            },

            ]
          },
          */
        ]
      },
      {
        path: 'academy',
        component: AcademyComponent,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name:'academy.domain',
            component: DomainComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'branding',
            name: 'academy.branding',
            component: BrandingComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'contact',
            name: 'academy.contact',
            component: ContactComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'legal',
            name: 'academy.legal',
            component: LegalComponent,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'records',
        name: 'records',
        component: RecordsComponent,
        meta: { requiresAuth: true },
      },
      {
        path: 'videos',
        name: 'videos',
        component: VideoComponent,
        meta: { requiresAuth: true },
      },
      {
        path: 'videos/:id',
        component: VideoNavigationComponent,
        meta: { requiresAuth: true},
        children: [
          {
            path: '',
            name: 'video-edit-general',
            component: VideoGeneralComponent,
            meta: { requiresAuth: true },
          },
        ]
      },
      {
        path: 'students',
        name: 'students',
        component: StudentsComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'marketing',
        name: 'marketing',
        component: MarketingComponent,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name:'marketing.coupons',
            component: CouponsComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'coupon',
            name: 'marketing.coupon.create',
            component: CouponComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'coupon/:id',
            name: 'marketing.coupon',
            component: CouponComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'scripts',
            name: 'marketing.scripts',
            component: ScriptsComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'scripts',
            name: 'marketing.scripts',
            component: ScriptsComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'integrations',
            name: 'marketing.integrations',
            component: IntegrationsComponent,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'subscription',
        name: 'subscription',
        component: SubscriptionComponent,
        meta: { requiresAuth: true },
      },
      {
        path: 'reports',
        component: ReportsComponent,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name:'sales.orders',
            component: OrdersComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'order/:id',
            name:'reports.orders.show_order',
            component: ShowOrdersComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'payouts',
            name: 'sales.payouts',
            component: PayoutsComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'subscriptions',
            name: 'sales.subscriptions',
            component: ProductSubscriptionListComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'balance',
            name: 'reports.balance',
            component: BalanceComponent,
            meta: { requiresAuth: true }
          },
        ]
      },
      {
        path: 'settings',
        name: 'settings',
        component: SettingsComponent,
        meta: { requiresAuth: true },
      },
      {
        path: 'myaccount',
        component: myAccountComponent,
        meta: {requiresAuth: true},
        children: [
          {
            path: '',
            name: 'myaccount.profile',
            component: ProfileEditComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'current-subscription',
            name: 'myaccount.current-subscription',
            component: CurrentSubscriptionsComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'billing/:page',
            name: 'myaccount.billing',
            component: BillingComponent,
            meta: { requiresAuth: true }
          },
          {
            path: 'log',
            name: 'myaccount.log',
            component: LogComponent,
            meta: { requiresAuth: true },
          },
          {
            path: 'information',
            name: 'myaccount.information',
            component: InformationComponent,
            meta: { requiresAuth: true }
          }
        ]
      },

    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('authData')
  const academyStore = useAcademiesStore()

  /*
   * En toda las rutas que necesiten autenticación se usa el siguiente metadata
   * record.meta.requiresAuth
   *
   * Controlaremos si la ruta a la que accedemos existe y si no estamos
   * correctamente autenticados nos redireccione al login
   */

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn)
  {
    next('/login')
  }

  /*
   * Es importante tener en cuenta que si ya estamos logueados vayamos a
   * la página principal de la APP si intentamos acceder al login o al
   * registro
   */

  else if (loggedIn && (to.name=='login' || to.name=='signup'))
  {
    next('/')
  }

  /*
   * Revisamos que exista el store de academia y redirigimos al usuario a
   * renovar su plan si está cancelado, en past-due o si su prueba
   * ha finalizado
   */
  else if
  (
      to.name !== 'myaccount.current-subscription' &&
      academyStore &&
      (
          academyStore.getSaasSubscriptionsStatus === 'canceled' ||
          academyStore.getSaasSubscriptionsStatus === 'past-due' ||
          (
              academyStore.getSaasSubscriptionsStatus === 'trialing' &&
              academyStore.isTrialExpired
          )
      )
  )
  {
    next('/myaccount/current-subscription')
  }

  /*
   * Si no se cumplen ninguna de las condiciones anteriormente dadas
   * redireccionamos a la ruta deseada.
   */

  else
  {
    next()
  }

})

export default router
