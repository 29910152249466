import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid) {
        return useAuthUserStore().getApiClient.get('/recordings', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },
}