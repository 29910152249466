export default {
    your_users: "Os seus utilizadores",
    massive_increase: "Ascensão maciça",
    new_user: "Novo utilizador",
    placeholder_search: "Procure o seu utilizador...",
    all: "Todos",
    project_name: "Nome do curso",
    updated_at: "Actualizado em ",
    view_profile: "Ver perfil",
    write_email: "Escrever correio",
    edit_user: "Editar utilizador",
    call: "Chamada",
    orders: "Encomendas",
    dni: "DNI / CIF / NIE",
    search_user: 'Procura de um utilizador...',
    new_project: 'Novo Curso',
    delete_user: 'Eliminar Utilizador',
    warning: 'Advertência!',
    message_delete: 'Ao apagar o utilizador, perderá permanentemente todos os seus dados.',
    question_delete: 'Tem a certeza que quer eliminar o utilizador ',
    delete: 'Eliminar',
    second_confirmation: '(*) Esta caixa de verificação é necessária para eliminar o utilizador.',
    loading_users: 'Carregamento de utilizadores...',

    //MassUserImportComponent
    beta_version: 'Beta',
    mass_enrollment: "Inscrição em massa",
    functionality: "Funcionalidade para planos",
    advance: "Avançado",
    pro: "Pro",
    limitation_mass_enrollment: "Limitado a registos de até 50 utilizadores. Todos os outros serão ignorados.",
    download_template: "Descarregue o seu formulário de inscrição",
    upload_students_list: "Carregue a sua lista de estudantes",
    drag_drop: "ou arrastá-lo e largá-lo aqui",
    format: "XLSX até 3MB",
    enroll_in: "Inscrever-se:",
    select_product: "Seleccione um curso...",
    upload_users: "Carregar utilizadores",
    import_users: "Importar utilizadores",
    href_massive_enroll: "Mais sobre a importação em massa de utilizadores.",

    //UserEnrollListComponent
    to_cancel: 'Anular a subscrição',
    no_enrolls: 'Este utilizador não está inscrito em nenhum curso.',
    modal_title: 'Desregisto',
    modal_warning: 'Este processo não pode ser invertido, o estudante perderá todo o progresso e não poderá recuperar.',
    modal_question: 'Tem a certeza de que quer cancelar o registo deste utilizador do curso ',
    loading_enrols: 'Carregamento de inscrições...',
    processing: 'Processamento...',
    access_denied: 'Acesso negado ao curso',

    //UserEnrolSlideOverComponent
    select_project: 'Seleccione um projecto',
    loading_projects: 'Carregamento de projectos...',
    project_selected: 'Projecto seleccionado',
    send_users_notification: 'Envia ao utilizador uma notificação de inscrição.',
    back: 'Voltar',
    enrol: 'Registe-se',

    //BillingAddressComponent
    tax_data: 'Dados Fiscais',
    company_name: 'Nome da empresa',
    vat_number_id: 'TIC / DNI',
    address_line_1: 'Linha de endereço 1',
    address_line_2: 'Linha de endereço 2',
    zip_code: 'Código postal',
    city: 'Cidade',
    province: 'Província',
    country: 'País',

    //UserProfileComponent
    save_edition: 'Guardar editar',
    billing_data: 'Dados sobre o volume de negócios',
    groups: 'Grupos',
    in_progress: 'Em curso',
    completed: 'Concluído',

    //Groups
    your_groups: 'Seus grupos',
    new_group: 'Novo grupo',
    loading_groups: 'Loading groups',
    name: 'Nome',
    projects: 'Projectos',
    users: 'Utilizadores',
    last_update: 'Última atualização',

}