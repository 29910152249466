import {useAuthUserStore} from "@/stores/auth";

export default {
    /**
     * @deprecated Changed to /v2/project/massive. Use this instead.
     * @param data
     * @returns {*}
     */
    upload(data)
    {
        return useAuthUserStore().getApiClient.post('/user/massive', data)
    },

    v2_upload(data)
    {
        return useAuthUserStore().getApiClient.post('/v2/project/massive', data)
    }
}