<template>
  <main class="w-full mx-auto pb-10 lg:px-8">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
        <section aria-labelledby="current_subscriptions" class="" v-if="authUserStore.user.academy.saas_subscriptions">
          <div v-if="this.authUserStore.user.academy.saas_subscriptions[0].status === 'past_due'" class="lg:mx-20 my-6 flex justify-center">
            <div class="flex flex-row justify-center bg-yellow-100 border-yellow-200 border rounded-lg p-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 text-yellow-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <label class="text-yellow-700">{{$t('settings.message_subscription_expire')}}</label>
            </div>
          </div>
          <div v-else-if="this.authUserStore.user.academy.saas_subscriptions[0].status === 'canceled'" class="lg:mx-20 my-6 flex justify-center">
            <div class="flex flex-row justify-center bg-red-700 border-red-400 border rounded-lg p-4 mx-6">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <label class="text-white">{{$t('myaccount.canceled_message')}}</label>
            </div>
          </div>

          <section class="mb-16 ">
            <change-subscription-component></change-subscription-component>
          </section>

          <section class="mb-8 ">
            <stripe-number-component></stripe-number-component>
          </section>

          <div class="mx-6 lg:mx-24 bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden ">
            <div class="px-4 sm:px-6 lg:px-8">
              <h2 id="billing_history_heading" class="text-lg leading-6 font-medium text-gray-900">
                {{ $t('settings.actual_subscriptions') }}</h2>
            </div>
            <div class="mt-6 flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden border-t border-gray-200">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                      <tr>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{ $t('settings.ends_on') }}
                        </th>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Último acceso
                        </th>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{ $t('general.plan') }}
                        </th>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{ $t('general.state') }}
                        </th>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{ $t('general.total') }}
                        </th>
                        <!--
                            `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                          -->
                        <th scope="col"
                            class="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <span class="sr-only">{{ $t('settings.view_receipt') }}</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="subscription in authUserStore.user.academy.saas_subscriptions" v-bind:key="subscription.id">
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ $moment(subscription.ends_at).format('DD-MM-YYYY HH:m') }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          2024-02-08 15:32:44
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold">
                          {{ subscription.title }}<br>
                          <span v-if="subscription.status == 'trialing'" class="font-normal">
                            {{$t('settings.trial_ends_on') }} {{ $moment(subscription.trial_ends_at).format('DD-MM-YYYY HH:m') }}
                          </span>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ subscription.status }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ subscription.total }} €
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a href="#" class="hidden text-bpblue hover:text-bpbluedeep">{{
                              $t('settings.view_receipt')
                            }}</a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- COMING SOON -->
    <div class="hidden lg:mx-20 my-6 flex justify-end" v-on:click="cancelSubscription">
      <div class="flex flex-row p-2 text-red-600 hover:text-red-400 cursor-pointer rounded-md">
        <input type="button">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-0.5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <label class="cursor-pointer lg:mr-2 mr-4">{{$t('settings.cancel_subscription')}}</label>
      </div>
      <!-- Modal Component -->
      <Teleport to="body">
        <modal-component id="modal-unregister" v-if="general.showModalUnsubscribe" :onProcess="confirmUnsubscribe" :onCancel="closeUnsubscribe" :loading="false">
          <template v-slot:header>{{$t('settings.cancel_subscription')}}</template>
          <p>{{$t('settings.modal_cancel_sub_text')}}</p>
          <p class="font-bold mt-2">{{$t('settings.modal_cancel_sub_question')}}</p>
        </modal-component>
      </Teleport>
    </div>
  </main>
</template>

<script>

import ChangeSubscriptionComponent from "./ChangeSubscriptionComponent";
import {useAuthUserStore} from "@/stores/auth";
import ModalComponent from "@/components/modals/ModalComponent";
import StripeNumberComponent from "@/views/MyAccount/Subscription/StripeNumberComponent"

export default {
  name: "CurrentSubscriptionsComponent",
  components: {ChangeSubscriptionComponent, ModalComponent, StripeNumberComponent},
  data: function() {
      return {
        general: {
          showModalUnsubscribe: false,
      }
    }
  },
  setup(){
    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  methods: {
    cancelSubscription() {
      this.general.showModalUnsubscribe = true
      console.log("Se pulsó el botón cancelar suscripción")
    },
    confirmUnsubscribe() {
      this.general.showModalUnsubscribe = false

    },
    closeUnsubscribe() {
      this.general.showModalUnsubscribe = false

    }
  }
}
</script>

<style scoped>

</style>