export default {
    //AcademyComponent
    customization: 'Passen Sie Ihre Akademie an',
    domain: "Domain",
    brand_logo: "Logo und Marke",
    rrss_contact: "Kontakt und soziale Netzwerke",
    legal: "Rechtliches",
    options: 'Optionen',

    //DomainComponent
    your_domain: "Ihre mybrainspro.com-Domäne",
    example_url_placeholder: 'https://',
    example_2: '.mybrainspro.com',
    info_domain: "Geben Sie die Domain OHNE Symbole (.,-¿?!-$%&/()...), Akzente oder Leerzeichen ein. Sie brauchen www. nicht anzugeben.",
    domain_input: "Domäne mybrainspro.com",
    custom_domain: "Personalisierte Domäne",
    domain_text: 'Domäne',
    info_domain2: "Geben Sie Ihre Domain (www.yourdomain.com) ein, um sie als Ihre Homepage oder als sekundäre Seite (ihredomain.ihredomain.com) zu verwenden.",
    attention: "Achtung",
    info_attention: "Nur für ADVANCED- und PRO-Tarif verfügbar.",
    activate_domain: "Um Ihre Domain bei BrainsPro zu aktivieren: ",
    info_activate_1line: "Besuchen Sie die Plattform, auf der Sie Ihre Domain erworben haben, und suchen Sie nach den DNS-Einstellungen.",
    info_activate_2line: "Fügt Datensatztyp A zu IP: 76.223.11.227 hinzu",
    info_activate_3line: "Bitte beachten Sie, dass es bis zu 48 Stunden dauern kann, bis diese Änderungen wirksam werden und sich in Ihrem DNS verbreiten. Sobald Sie fertig sind, können Sie die neue Adresse Ihrer BrainsPro Online-Akademie verwenden.",
    info_activate_4line: "Das brainspro-Team wird sich mit Ihnen in Verbindung setzen, um Ihnen einige zusätzliche Einträge zu schicken, die Sie in Ihrem DNS platzieren müssen. Mit ihnen erstellen wir Ihre sichere Website (https://).",
    example_academy: 'beispiel-akademie',
    example_domain: 'meineakademie.de',
    //LanguageSelector
    lang: 'Sprache',
    academy_lang: 'Akademie Sprache',
    academy_lang_selector_text: 'Wählen Sie die Sprache Ihrer Akademie',

    //BrandingComponent
    banner: 'Banner',
    brand_academy: "Branding Ihrer Akademie",
    academy_name: "Name der Akademie",
    full_name: "Wählen Sie den vollständigen Namen Ihrer Akademie",
    tagline: "Akademie TagLine",
    slogan: "Wählen Sie einen Slogan für Ihre Akademie",
    search: 'Suche',
    my_academy: 'Meine Akademie bei BrainsPro',
    change: 'Ändern Sie',
    insert: 'Einfügen',
    href_custom_academy: 'Mehr über die Personalisierung Ihrer Akademie.',

    //ContactComponent
    description: "Beschreibung",
    web_rrss: "Web und soziale Netzwerke",
    web_blog: "Web oder Blog",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    linkedin: "Linkedin",
    youtube: "Youtube",
    location: "Standort und steuerliche Daten",
    address_line_1: "Anschrift, Zeile 1",
    address_line_2: "Anschrift, Zeile 2",
    postalcode: "Postleitzahl",
    city: "Stadt",
    province: "Provinz",
    country: "Land",
    company_name: "Name des Unternehmens",
    nif_cif: "MWST.-NUMMER",

    //LegalComponent
    privacy_policy: "Datenschutzbestimmungen",
    text_register: "Zusammenfassender Text für die Benutzerregistrierung",
    user_view_text: "Dieser Text wird bei der Benutzerregistrierung als Zusammenfassung der längeren Version angezeigt, die auf der Seite mit den rechtlichen Hinweisen Ihrer Schule erscheinen wird.",
    privacy_policy_page: "Seite zum Datenschutz",
    privacy_policy_academy_page: "Akademie-Datenschutzerklärung Seite",
    legal_notice: "Rechtlicher Hinweis",
    href_legal: 'Mehr über die rechtlichen Hinweise und die Datenschutzrichtlinie.',

    //Contact
    contact_bloq: 'Kontakt Block',
    html_bloq: 'Fügt einen Html-Block ein, der die Kontaktinformationen begleitet. Wie ein Google Maps Iframe.',

    //Branding
    //BannerBrandingComponent
    upload_banner: 'Laden Sie Ihr Banner hoch',
    image_format: 'PNG, JPG, GIF bis zu 3 MB',

    //ColorsComponent
    desing_colors: 'Design und Farben',
    header_type: 'Typ der Kopfzeile',
    banner_complete: 'Banner in voller Größe',
    banner_complete_no_text: 'Banner in voller Größe ohne Text',
    columns_text_image: '2-spaltig, Text + Bild',
    banner_info: 'Dadurch wird die Art des Titelbildes geändert, das die Nutzer in Ihrer Akademie sehen.',
    enterprise_color: 'Unternehmensfarbe',
    text_color: 'Textfarbe',

    //FaviconComponent
    favicon: 'Favicon',
    upload_favicon: 'Laden Sie Ihr Favicon hoch',
    favicon_format: 'PNG, JPG, GIF bis zu 3 MB',

    //LogoBrandingComponent
    logo: 'Logo',
    upload_logo: 'Laden Sie Ihr Logo hoch',

    //OptionsComponent
    register_config: 'Registrierung Konfiguration',
    info_button_register: 'Wenn Sie diese Schaltfläche aktivieren, wird die Option zur Registrierung in der Akademie deaktiviert. Dazu müssen sich alle Nutzer über die Brainspro App registrieren.',
    restricted_register: 'Einschränkung der Registrierung',
}