<template>
  <navigation-projects-component></navigation-projects-component>
</template>

<script>
import NavigationProjectsComponent from "./NavigationProjectsComponent";

export default {
  name: "ProjectsComponent",
  components: { NavigationProjectsComponent }
}
</script>

<style scoped>

</style>