export default {
    //BankAccountComponent
    payments_receipt: 'Recepción de pagos',
    beneficiary: 'Beneficiario',
    bic: 'BIC',
    account_number: 'Número de cuenta',
    enter_beneficiary: 'Introduce beneficiario',
    enter_bic: 'Introduce BIC',
    enter_account_number: 'Introduce número de cuenta',
    info_billing_msg: 'Introduce estos datos para recibir los ingresos en tu cuenta.',
    info_billing_send: 'Envía por mail a ',
    info_billing_dni_msg: ' copia escaneada en color del DNI / NIE del representante legal de tu empresa y/o del CIF de tu empresa.',


    //BasicDataComponent
    profile_info: 'Información de perfil',
    enter_name: 'Introduce tu nombre',
    enter_lastname: 'Introduce tu apellido',
    error_name: 'Debe introducir un nombre',
    error_lastname: 'Debes introducir tus apellidos',

    //ChangePasswordComponent
    change_pass: 'Cambia tu contraseña',
    current_pass: 'Contraseña actual',
    new_pass: 'Nueva contraseña',
    confirm_pass: 'Repite tu nueva contraseña',
    enter_pass: 'Introduce contraseña actual',
    enter_new_pass: 'Introduce tu nueva contraseña',
    repeat_new_pass: 'Repite tu nueva contraseña',
    password_not_match: 'Las contraseñas no coinciden',

    //UserAvatarComponent
    user_image: 'Imagen de usuario',
    upload_avatar: 'Sube tu avatar',
    change: 'Cambiar',
    insert: 'Insertar'
}