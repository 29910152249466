export default {
    your_users: "Community",
    massive_increase: "Massive increase",
    new_user: "New user",
    placeholder_search: "Search your user...",
    all: "All",
    project_name: "Project name",
    updated_at: "Updated on ",
    view_profile: "View profile",
    write_email: "Write Mail",
    edit_user: "Edit user",
    call: "Call",
    orders: "Orders",
    dni: "ID / CIF / NIE",
    search_user: 'Search a user...',
    new_project: 'New Project',
    delete_user: 'Delete User',
    warning: 'Warning!',
    message_delete: 'By deleting the user you will lose all his data permanently.',
    question_delete: 'Are you sure you want to delete the user ',
    delete: 'Delete',
    second_confirmation: '(*) This checkbox is required to delete the user.',
    loading_users: 'Loading community...',

    //MassUserImportComponent
    beta_version: 'Beta',
    mass_enrollment: "Mass Enrollment",
    functionality: "Functionality for plans",
    advance: "Advanced",
    pro: "Pro",
    limitation_mass_enrollment: "Limited to enrollments of up to 50 community. All others will be ignored.",
    download_template: "Download your registration form",
    upload_students_list: "Upload your student list",
    drag_drop: "or drag and drop it here",
    format: "XLSX up to 3MB",
    enroll_in: "Enroll in:",
    select_product: "Select a projects...",
    upload_users: "Load community",
    import_users: "Import community",
    href_massive_enroll: "More about the massive import of community.",

    //UserEnrollListComponent
    to_cancel: 'Unsubscribe',
    no_enrolls: 'This user is not enrolled in any course.',
    modal_title: 'Unregister',
    modal_warning: 'This process cannot be reversed, the student will lose all progress and will not be able to recover.',
    modal_question: 'Are you sure you want to unregister this user from the course ',
    loading_enrols: 'Loading registrations...',
    processing: 'Processing...',
    access_denied: 'Access denied to the course',

    //UserEnrolSlideOverComponent
    select_project: 'Select a project',
    loading_projects: 'Loading projects...',
    project_selected: 'Selected project',
    send_users_notification: 'Sends the user an enrollment notification.',
    back: 'Return',
    enrol: 'Enrol',

    //BillingAddressComponent
    tax_data: 'Tax Data',
    company_name: 'Company name',
    vat_number_id: 'VAT NUMBER / ID',
    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    zip_code: 'Zip Code',
    city: 'City',
    province: 'Province',
    country: 'Country',

    //UserProfileComponent
    save_edition: 'Save edit',
    billing_data: 'User Data',
    groups: 'Groups',
    in_progress: 'In progress',
    completed: 'Completed',

    //Groups
    your_groups: 'Your groups',
    new_group: 'New group',
    loading_groups: 'Loading groups',
    name: 'Name',
    projects: 'Proyects',
    users: 'Users',
    last_update: 'Last update',
}