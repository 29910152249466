<template>
    <div class="pt-2 pb-6 md:py-6">
        <header-component :title="$t('products.modify_project')"></header-component>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div class="py-4">
            <nav class="lg:px-3">
              <div class="block">
                <div class="">
                  <nav class="-mb-px flex justify-center lg:justify-start lg:space-x-8 flex-col md:flex md:flex-row" aria-label="Tabs">
                    <router-link :to="{ name: 'products-edit', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="border-transparent text-gray-500 hover:text-gray-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs lg:text-sm">
                      <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                      <span>{{ $t('products.general') }}</span>
                    </router-link>
                    <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                    <router-link :to="{ name: 'products-edit-branding', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" active-class="text-bpblue" class="border-transparent text-gray-500 hover:text-gray-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs lg:text-sm" aria-current="page">
                      <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                      </svg>
                      <span>{{ $t('products.branding') }}</span>
                    </router-link>
                    <router-link :to="{ name: 'products-edit-page', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="border-transparent text-gray-500 hover:text-gray-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs lg:text-sm">
                      <!-- Heroicon name: credit-card -->
                      <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
                      </svg>
                      <span>{{ $t('products.product_page') }}</span>
                    </router-link>
                    <router-link :to="{ name: 'products-edit-sell', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="border-transparent text-gray-500 hover:text-gray-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs lg:text-sm">
                      <!-- Heroicon name: credit-card -->
                      <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                        <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd" />
                      </svg>
                      <span>{{ $t('products.sale') }}</span>
                    </router-link>
                    <router-link :to="{ name: 'products-edit-seo', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="border-transparent text-gray-500 hover:text-gray-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs lg:text-sm">
                      <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                      </svg>
                      <span>{{ $t('products.seo_text') }}</span>
                    </router-link>
                    <router-link :to="{ name: 'attach-project-to-product', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="border-transparent text-gray-500 hover:text-gray-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs lg:text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                      </svg>
                      <span>{{ $t('products.projects') }}</span>
                    </router-link>
                  </nav>
                </div>
              </div>
            </nav>
          </div>

          <section class="mx-auto max-w-7xl">
            <div class="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-6">
              <router-view class="md:col-span-3 lg:col-span-2"></router-view>
              <aside>
                <product-card-preview></product-card-preview>
                <custom-categories-component class="mt-10 hidden"></custom-categories-component>
              </aside>
            </div>
          </section>
        </div>
    </div>
</template>

<script>
    import HeaderComponent from "@/views/common/HeaderComponent";
    import ProductCardPreview from "@/views/products/ProductEdit/ProductCardPreview";
    import CustomCategoriesComponent from "@/views/products/ProductEdit/CustomCategoriesComponent";
    import {useProductsStore} from "@/stores/products";

    export default {
        name: "ProjectEditComponent",
        components: {CustomCategoriesComponent, ProductCardPreview, HeaderComponent},
        setup(){

          const productsStore = useProductsStore()

          return {
           productsStore
          }
        },
        mounted() {
          this.productsStore.getProduct(this.$route.params.id)
        }
    }
</script>

<style scoped>

</style>