export default {
    //BankAccountComponent
    payments_receipt: 'Recepció de pagaments',
    beneficiary: 'Beneficiari',
    bic: 'BIC',
    account_number: 'Número de cuenta',
    enter_beneficiary: 'Introdueix beneficiari',
    enter_bic: 'Introdueix BIC',
    enter_account_number: 'Introdueix el número de compte',
    info_billing_msg: 'Introduïu aquestes dades per rebre els ingressos al vostre compte.',
    info_billing_send: 'Envia per mail a ',
    info_billing_dni_msg: ' còpia escanejada en color del DNI/NIE del representant legal de la teva empresa i/o del CIF de la teva empresa.',

    //BasicDataComponent
    profile_info: 'Informació de perfil',
    enter_name: 'Introdueix el teu nom',
    enter_lastname: 'Introdueix el teu cognom',
    error_name: "Heu d'introduir un nom",
    error_lastname: "Has d'introduir els teus cognoms",

    //ChangePasswordComponent
    change_pass: 'Canvia la teva contrasenya',
    current_pass: 'Contrasenya actual',
    new_pass: 'Nova contrasenya',
    confirm_pass: 'Repeteix la teva contrasenya nova',
    enter_pass: 'Introduïu la contrasenya actual',
    enter_new_pass: 'Introdueix la teva nova contrasenya',
    repeat_new_pass: 'Repeteix la teva contrasenya nova',
    password_not_match: 'Les contrasenyes no coincideixen',

    //UserAvatarComponent
    user_image: "Imatge d'usuari",
    upload_avatar: 'Puja el teu avatar',
    change: 'Canviar',
    insert: 'Inserir'
}