<template>
  <textarea ref="arx" :name="name" :placeholder="placeholder" :value="modelValue"/>
</template>

<script>
import ArticleEditor from '@/../src/assets/article-editor-2-4-3/article-editor.min'
import '@/../src/assets/article-editor-2-4-3/css/article-editor.min.css'

export default {
  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    config: {
      default: function () {
        return {
          editor: {
            minHeight: '300px',
          }
        }
      },
      type: Object,
    },
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {
    this.destroy()
  },
  methods: {
    init() {
      var me = this
      var subscribe = {
        "editor.change": function (event) {
          var html = event.get("html")
          me.handleInput(html)
          return html
        },
      }

      // extend config
      if (typeof this.config.subscribe === "undefined") {
        this.$props.config.subscribe = subscribe
      } else {
        this. $props.config.subscribe["editor.change"] = subscribe["editor.change"]
      }

      // call Article Editor
      var app = ArticleEditor(this.$refs.arx, this.$props.config)

      // set instance
      this.arx = app
      this.$parent.arx = app
    },
    destroy() {
      // Call destroy on redactor to cleanup event handlers
      ArticleEditor(this.$refs.arx, "destroy")

      // unset instance for garbage collection
      this.arx = null
      this.$parent.arx = null
    },
    handleInput(val) {
      this.$emit("update:modelValue", val)
    },
  },
}
</script>