export default {
    log_account: 'Bei Ihrem Konto anmelden',
    create_account: 'ein kostenloses Konto erstellen',
    trial_info: 'Starten Sie Ihre 14-tägige Testphase',
    log_with: 'Zugang mit',
    continue_with: 'Oder fahren Sie fort mit ',
    attention: 'Nur für Administratoren',
    welcome_text1: 'Willkommen bei Brainspro v2. ',
    admin_access: 'Zugang nur für Administratoren',
    welcome_text2: 'Wenn Sie Schüler oder Lehrer sind, rufen Sie Ihren Kurs über die Website Ihrer Schule auf.',
    email_address: 'Postanschrift',
    remember_me: 'Erinnern Sie sich an mich',
    forgot_pass: 'Haben Sie Ihr Passwort vergessen?',
    access: 'Zugang',
    ad_text1: 'Verwandeln Sie Ihre Online-Kurse in',
    ad_text2: 'Lernerfahrungen',
    ad_text3: 'hohe Auswirkung',
    ad_text4: 'Ihre schüler werden die qualität Ihrer Dienstleistungen zu schätzen wissen, und Sie haben alles auf einfache weise im griff.',
    //RememberPasswordComponent
    recovery_password: 'Ihr Passwort wiederherstellen',
    email_input_placeholder: 'tu email einführen',
    information_email_send: 'Sie erhalten eine Bestätigungs-E-Mail, um Ihr Passwort abzurufen.',
    information_go_email_inbox: 'Bitte loggen Sie sich in Ihre E-Mail ein und klicken Sie auf den Link, um Ihr Passwort zurückzusetzen. Wenn Sie die E-Mail nicht finden können, überprüfen Sie bitte Ihren SPAM-Ordner.',
    error_email_invalid: 'Sie müssen eine gültige E-Mail Adresse eingeben',
    button_text: 'Passwort-Wiederherstellung',
    //ResetPasswordComponent
    restore_password: 'Ihr Passwort zurücksetzen',
    new_password: 'Neues Passwort',
    password_placeholder: 'Geben Sie Ihr neues Passwort ein',
    error_password: 'Das Passwort muss mindestens 8 Zeichen lang sein',
    confirm_password: 'Bestätigen Sie das Passwort',
    password_confirm_placeholder: 'Geben Sie Ihr neues Passwort ein',
    error_password_confirm: 'Passwörter stimmen nicht überein',
    button_reset_password: 'Passwort zurücksetzen',
    success_password_change: "Passwort aktualisiert.",
}