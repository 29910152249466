<template>
  <div class="flex items-center justify-center flex-col">
    <font-awesome-icon class="my-1 h-10 w-10 text-3xl text-gray-400 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin" icon="spinner" size="lg" />
    <div class="text-gray-600">
      <slot v-if="hasDefaultSlot"></slot>
      <div v-else>{{ $t("common.loading_content") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    }
  }
}
</script>

<style scoped>

</style>