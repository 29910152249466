export default {
    reports: "Sales",
    //Orders view
    orders: "Orders",
    payments: "Payments",
    pvp_sales: "Sales",
    net_sales: "Income",
    pvp_returns: "Returns PVP",
    net_returns: "Returns Net",
    buyer: "Buyer",
    view_order: "View Order",
    loading_orders: "We are loading your orders",
    balance: 'Balance',
    //Payments view
    description: "Concept/Description",
    from: "From",
    to: "To",
    payment_date: "Payment Date",
    quantity: "Quantity",
    loading_payments: "We are uploading your payments",
    //OrdersComponent
    loading_products: 'We are loading your orders',
    complete: 'Completed',
    reimbursed: 'Refunded',
    obtain_text_1: 'The following have been obtained',
    obtain_text_2: 'orders.',
    //ProductSubscriptionListComponent
    loading_subscriptions: 'We are loading your subscriptions',
    subscriptions: "Subscriptions",
    activated: "Activated",
    canceled: "Canceled",

    //BalanceComponent
    state: 'State',
    gross: 'Gross',
    net: 'Net',
    commission: 'Commission',
    created_at: 'Created in',
    available_on: 'Available on'
}