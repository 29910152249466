<template>
  <section v-show="navigationMenuStore.showSlideOverGroups" class="fixed z-199 inset-0 md:mt-20 overflow-y-auto" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!-- Background overlay, show/hide based on slide-over state. -->
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
      <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-200"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
      >
        <div v-on:click="navigationMenuStore.toggleSlideOverGroups" class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </transition>

        <transition
            name="display-user-creation"
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button type="button" v-on:click="navigationMenuStore.toggleSlideOverGroups" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                <span class="sr-only">{{$t('general.close')}}</span>
                <!-- Heroicon name: x -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div>
              <div class="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-blue-100">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-12 w-12 text-blue-400">
                  <path d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clip-rule="evenodd" />
                  <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                </svg>
              </div>
              <div class="mt-5 sm:mt-5">
                <h3 class="text-xl leading-6 font-medium text-gray-900" id="modal-headline">
                  Nuevo grupo
                </h3>
                <div class="mt-5">
                  <div>
                    <label for="title" class="block text-sm font-medium text-gray-700">{{ $t('general.title') }}</label>
                    <div class="mt-1">
                      <input type="text" name="title" v-model="group.title" required id="title" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
                    </div>
                    <p class="mt-2 text-xs text-gray-500" id="email-description">Agrupa a tus usuarios con respecto a los proyectos en los que están matriculados. Esto te ayudará a organizar tu comunidad.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" v-on:click="createGroup" :disabled="loading" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bpblue text-base font-medium text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue sm:col-start-2 sm:text-sm">
                Crear
              </button>
              <button type="button" v-if="!creatingGroup" v-on:click="navigationMenuStore.toggleSlideOverGroups" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue sm:mt-0 sm:col-start-1 sm:text-sm">
                {{ $t('dashboard.bttn_close') }}
              </button>
            </div>
          </div>
        </transition>
      </div>
  </section>
</template>

<script>
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import GroupsService from "@/services/community/GroupsService";
import {useAcademiesStore} from "@/stores/academies";
import NotificationService from "@/services/notification/NotificationService";

export default {
  name: "GroupsSlideOverComponent",
  data: function () {
    return {
      loading: false,
      creatingGroup: false,
      group: {
        uuid: "",
        academy_uuid: "",
        title: ""
      },
      groups: []
    }
  },
  setup() {
    const navigationMenuStore = useNavigationMenuStore()
    const academyStore = useAcademiesStore()
    return {
      navigationMenuStore,
      academyStore
    }
  },
  mounted() {
    this.loadGroupsData()
  },

  methods: {
    loadGroupsData()
    {
      let academy_uuid = this.academyStore.getSelectedAcademyUuid

      GroupsService.index(academy_uuid)
          .then((response) => {
            console.log("Los GRUPOS recibidos son: ", response.data.data)
            this.groups = response.data.data
          })
          .catch((error) => {
            console.log("Error al obtener grupos: ", error)

          })
          .finally(() => {
            this.loading = false
          })
    },
     createGroup()
     {
       this.loading = true
       console.log("Se ha pulsado el botón guardar")
       this.group.academy_uuid = this.academyStore.getSelectedAcademyUuid

       GroupsService.store(this.group)
           .then((response) => {
              console.log("Respuesta al guardar grupo: ", response)
             NotificationService.showNotification("La operación se ha realizado correctamente", "success")
           })
           .catch((error) =>{
             console.log("Error al guardar grupo: ", error)
             NotificationService.showNotification(error, "error")
           })
           .finally(() => {
             this.loading = false
             this.navigationMenuStore.showSlideOverGroups = false
             //Evento de actualización al componente padre: GroupsListComponent
             this.$emit('group-created', 'Update the GroupListComponent.')
           })
     },
  }
}
</script>

<style scoped>

</style>