<template>
  <main class="">
    <div class="lg:mx-8 mx-4">
      <div class="">
        <div class="">
          <div class="w-full relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex items-center justify-between">
          <span class="pr-3 bg-gray-100 text-xl font-medium text-gray-900">
            Proyectos asociados
          </span>
              <button type="button" v-on:click="this.navigationMenuStore.toggleSlideOverGroupAttachProject" class="inline-flex justify-center px-4 py-2 cursor-pointer border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-2 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="">Asociar proyecto</span>
              </button>
              <teleport to="body">
                <slide-over-attach-project v-on:reload-projects-attached="getProjectsAttached"></slide-over-attach-project>
              </teleport>
            </div>
          </div>

          <div v-show="loading" class="flex justify-center mt-8 p-4 text-sm bg-white rounded-lg">
            <div class=" text-yellow-700">
              <svg class="inline mr-3 w-4 h-4 text-blue-700 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
              </svg>
            </div>
            <span class="ml-1 text-lg text-gray-600">
              Cargando proyectos...
            </span>
          </div>

          <div v-if="this.projects && this.projects.length > 0" class="mt-8 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table class="min-w-full">
              <thead>
              <tr>
                <!-- No mostraremos nada en la tabla -->
              </tr>
              </thead>
              <tbody class="bg-white">
              <tr v-for="project in this.projects" :key="project.uuid" class="hover:bg-gray-50 transition-colors ease-in">
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">{{project.title}}</div>
                      <div class="text-sm leading-5 text-gray-500">{{ $t('community.updated_at') }} {{$moment(project.updated_at).format("LLLL")}}</div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <div class="flex items-center justify-end">
                    <button title="Disassociate Projects" id="disassociate_projects" @click="detachProject(project.uuid)" class="group mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" :aria-label="$t('community.to_cancel')">
                      <svg v-if="!loadingDelete" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="group-hover:text-red-600 transition-colors" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                      <font-awesome-icon v-else class="h-7 w-7 text-gray-400 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin" icon="spinner" size="lg" />
                    </button>
                  </div>
                  <!-- Modal para mostrar al desasociar el proyecto -->
                  <!--
                  <Teleport to="body">
                    <modal-component id="modal-unregister" v-if="showModalDelete" :onProcess="confirmModalDelete" :onCancel="closeModalDelete" :loading="loadingDelete">
                      <template v-slot:header>{{ $t('projects.unattach_product') }}</template>
                      <p>{{ $t('projects.info_unattach_product') }}</p>
                      <p class="font-bold mt-2">{{ $t('projects.quest_unattach_product') }}"{{product.title}}" ?</p>
                    </modal-component>
                  </Teleport>
                  -->
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="flex justify-center mt-8 font-bold text-gray-400 text-sm border border-2 border-gray-300 p-8 rounded-lg">
            <div>No existen proyectos adjuntos al grupo</div>
          </div>
        </div>

      </div>
    </div>
  </main>
</template>

<script>
import {useAcademiesStore} from "@/stores/academies";
import ProjectService from "@/services/project/ProjectService";
import SlideOverAttachProject from "@/views/community/groups/group/SlideOverAttachProject";
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import GroupsService from "@/services/community/GroupsService";
import NotificationService from "@/services/notification/NotificationService";
import notificationService from "@/services/notification/NotificationService";

export default {
  name: "GroupAttachProject",
  components: {SlideOverAttachProject},
  data: () =>
  {
    return {
      loading: false,
      projects: [],
      projectsToAttach: [],
      loadingDelete: false,
    }
  },

  setup () {
    const academyStore = useAcademiesStore()
    const navigationMenuStore = useNavigationMenuStore()

    return {
      academyStore,
      navigationMenuStore
    }
  },
  created()
  {
    this.getProjectsAttached()
  },
  methods:
  {
    getProjectsAttached()
    {
      let academy_uuid = this.academyStore.getSelectedAcademyUuid
      let groupUuid = this.$route.params.id

      ProjectService.index(academy_uuid, groupUuid)
          .then((response) => {
            console.log("Obteniendo proyectos asociados: ", response.data)
            this.projects = response.data
          })
          .catch((error) => {
            console.log("Error al obtener el grupo: ", error)
          })
          .finally(() => {
            //Silence is golden
          })
    },
    detachProject(projectUuid)
    {
      let groupUuid = this.$route.params.id
      GroupsService.detachProject(groupUuid, projectUuid)
          .then((response) => {
            console.log("La respuesta al desasociar proyecto es: ", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
            this.getProjectsAttached()
          })
          .catch((error) => {
            console.log("Error al desasociar proyecto: ", error)
            notificationService.showNotification(error, "Error")
          })
    },
  }
}
</script>

<style scoped>

</style>