<template>
  <section v-if="!general.loading" class="py-6 mt-4 md:col-span-2 mx-8">
    <div class="flex justify-center">
      <!--
      <div v-on:click="goBack" class="flex flex-wrap justify-center items-center w-2/12 cursor-pointer mb-8 bg-bpblue hover:bg-blue-100 hover:text-bpblue p-2 text-white rounded-xl">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <label class="cursor-pointer ml-2">{{$t('projects.back')}}</label>
      </div>
      -->
    </div>

    <div v-if="this.general.lesson" class="">
      <div class="mb-8 py-6 bg-white shadow rounded-md">
        <header class="px-6 flex flex-row justify-between">
          <label class="text-xl leading-6 font-bold text-stone-900 ">{{this.general.lesson.title}}</label>
          <div class="text-md text-gray-400">{{this.general.lesson.lesson_type.title}}</div>
        </header>

        <!-- Dependiendo del tipo de lección se abrirá uno u otro componente -->
        <div v-if="!this.general.loading && this.general.lesson.lesson_type.key === 'html'" :lesson="this.general.lesson">
          <h-t-m-l-component></h-t-m-l-component>
        </div>
        <div v-else-if="!this.general.loading && this.general.lesson.lesson_type.key === 'video'" :lesson="this.general.lesson">
          <video-lesson-component></video-lesson-component>
        </div>
        <div v-else-if="!this.general.loading && this.general.lesson.lesson_type.key === 'questionary'" :lesson="this.general.lesson">
          <questionnaire-component></questionnaire-component>
        </div>
      </div>
    </div>
  </section>


  <!-- índice de contenidos
  <aside class="w-full py-6 bg-white shadow rounded-md my-6">
      <label class="ml-6 text-lg font-bold">{{$t('projects.content_index')}}</label>
    <div id="sections" class="ml-8 mt-4 mb-6" v-for="section in this.projectsStore.project.sections" v-bind:key="section.uuid">
      <div class="bg-white rounded-md">
        <div class="">
          <label class="text-lg text-gray-600">{{section.title}}</label>
        </div>

        <div class="mb-4 pb-2">
          <div class="lessons" v-for="lesson in section.lessons" v-bind:key="lesson.uuid">
            <div class="mt-1 shadow-inner-lg flex flex-row items-center" @mouseover="general.showMenuItems = true" @mouseleave="general.showMenuItems = false">
              <router-link :to="{ name: 'projects-edit-content', params: { uuid: lesson.uuid }}" aria-current="page">
                <label class="w-full text-blue-600 px-3 py-2 rounded-md cursor-pointer focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  {{lesson.title}}
                </label>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
  -->
</template>

<script>
import {useProjectsStore} from "@/stores/projects";
import ProjectService from "@/services/project/ProjectService";
import NotificationService from "@/services/notification/NotificationService";
import HTMLComponent from "@/views/projects/ProjectEdit/LessonTypes/HTMLComponent";
import QuestionnaireComponent from "@/views/projects/ProjectEdit/LessonTypes/QuestionnaireComponent";
import VideoLessonComponent from "@/views/projects/ProjectEdit/LessonTypes/VideoLessonComponent";

export default {
  name: "ProjectEditContentComponent",
  components: {QuestionnaireComponent, VideoLessonComponent, HTMLComponent},
  data() {
    return {
      general: {
        loading: false,
        lesson: null,
      },
      lessonType: 'questionnaire',
      htmlContent: '',
      videoURL:'',
      list: {
        sections: [
          {
            title: "",
            uuid: "",
            lessons: [
              {
                title: "",
                uuid: "",
                type: null,
                content: ""
              },
            ],
          },
        ],
      }
    }
  },
  setup(){

    const projectsStore = useProjectsStore()

    return {
      projectsStore
    }
  },
  created() {
    this.general.loading = true
    this.getLesson()
  },
  methods: {
    getLesson() {
      let projectUuid = this.projectsStore.project.uuid
      let lessonUuid = this.$route.params.uuid

      ProjectService.getLesson(lessonUuid, projectUuid)
          .then((response) => {
            this.general.lesson = response.data
            console.log("La lección obtenida es: ", response.data)
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando la sección", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            //this.projectsStore.getProject(this.$route.params.id)
            this.general.loading = false
          })

    },

    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>