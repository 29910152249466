
import { defineStore } from 'pinia'
import {useProductsStore} from "./products";

export const useNavigationMenuStore = defineStore('navigationMenu', {
    state: () => ({
        showMobileNavigationMenu: false,
        showSlideOverUserCreation: false,
        showSlideOverUserEdit: false,
        showSlideOverCategories: false,
        showSlideOverAttachProject: false,
        showSlideOverAttachProduct: false,
        showSlideOverEnrolUser: false,
        showSlideOverFilePreview: false,
        showSlideOverGroups: false,
        showSlideOverGroupAttachProject: false,
    }),
    actions: {
        closeMobileNavigationMenu (){
            this.showMobileNavigationMenu = false
        },
        toggleSlideOverUserCreation(){
            this.showSlideOverUserCreation = !this.showSlideOverUserCreation
        },
        toggleSlideOverUserEdit(){
            this.showSlideOverUserEdit = !this.showSlideOverUserEdit
        },
        toggleSlideOverCategories(){
            this.showSlideOverCategories = !this.showSlideOverCategories
            //TODO: Change to PiniaJS action
            //store.dispatch('getCategoriesAction')
            useProductsStore().getCategoriesAction()
        },
        toggleSlideOverEnrolUser() {
            this.showSlideOverEnrolUser = !this.showSlideOverEnrolUser
        },
        toggleSlideOverAttachProject() {
            this.showSlideOverAttachProject = !this.showSlideOverAttachProject
        },
        toggleSlideOverAttachProduct() {
            this.showSlideOverAttachProduct = !this.showSlideOverAttachProduct
        },
        toggleSlideOverFilePreview() {
            this.showSlideOverFilePreview = !this.showSlideOverFilePreview
        },
        toggleSlideOverGroups() {
            this.showSlideOverGroups = !this.showSlideOverGroups
        },
        toggleSlideOverGroupAttachProject() {
            this.showSlideOverGroupAttachProject = !this.showSlideOverGroupAttachProject
        }
        //Mutations


    }
})