//English
import general_en from '@/lang/en/general'
import common_en from '@/lang/en/general'
import customization_en from '@/lang/en/customization'
import dashboard_en from '@/lang/en/dashboard'
import empty_state_en from '@/lang/en/empty_state'
import instructors_en from '@/lang/en/instructors'
import login_en from '@/lang/en/login'
import marketing_en from '@/lang/en/marketing'
import panels_en from '@/lang/en/panels'
import profile_en from '@/lang/en/profile'
import projects_en from '@/lang/en/projects'
import settings_en from '@/lang/en/settings'
import signup_en from '@/lang/en/signup'
import community_en from '@/lang/en/community'
import modal_en from '@/lang/en/modal'
import products_en from '@/lang/en/products'
import records_en from '@/lang/en/records'
import sales_en from '@/lang/en/sales'
import trial_en from '@/lang/en/trial'
import myaccount_en from '@/lang/en/myaccount'
import video_en from '@/lang/en/video'
//German
import  general_de from '@/lang/de/general'
import  common_de from '@/lang/de/common'
import  customization_de from '@/lang/de/customization'
import  dashboard_de from '@/lang/de/dashboard'
import  empty_state_de from '@/lang/de/empty_state'
import  instructors_de from '@/lang/de/instructors'
import  login_de from '@/lang/de/login'
import  marketing_de from '@/lang/de/marketing'
import  panel_de from '@/lang/de/panel'
import  profile_de from '@/lang/de/profile'
import  projects_de from '@/lang/de/projects'
import  sales_de from '@/lang/de/sales'
import  settings_de from '@/lang/de/settings'
import  signup_de from '@/lang/de/signup'
import  students_de from '@/lang/de/students'
import  community_de from '@/lang/de/community'
import modal_de from '@/lang/de/modal'
import products_de from "@/lang/de/products"
import records_de from '@/lang/de/records'
import trial_de from '@/lang/de/trial'
import myaccount_de from '@/lang/de/myaccount'
import video_de from '@/lang/de/video'
//Spanish
import general_es from '@/lang/es/general'
import projects_es from '@/lang/es/projects'
import community_es from '@/lang/es/community'
import dashboard_es from '@/lang/es/dashboard'
import sales_es from '@/lang/es/sales'
import marketing_es from '@/lang/es/marketing'
import customization_es from '@/lang/es/customization'
import common_es from '@/lang/es/common'
import panels_es from '@/lang/es/panels'
import settings_es from '@/lang/es/settings'
import empty_state_es from '@/lang/es/empty_state'
import profile_es from '@/lang/es/profile'
import instructors_es from '@/lang/es/instructors'
import students_es from '@/lang/es/students'
import login_es from '@/lang/es/login'
import signup_es from '@/lang/es/signup'
import modal_es from '@/lang/es/modal'
import products_es from '@/lang/es/products'
import records_es from '@/lang/es/records'
import trial_es from '@/lang/es/trial'
import myaccount_es from '@/lang/es/myaccount'
import video_es from '@/lang/es/video'
//Portugues
import general_pt from '@/lang/pt/general'
import dashboard_pt from '@/lang/pt/dashboard'
import projects_pt from '@/lang/pt/projects'
import community_pt from '@/lang/pt/community'
import sales_pt from '@/lang/pt/sales'
import marketing_pt from '@/lang/pt/marketing'
import customization_pt from '@/lang/pt/customization'
import common_pt from '@/lang/pt/common'
import settings_pt from '@/lang/pt/settings'
import signup_pt from '@/lang/pt/signup'
import empty_state_pt from '@/lang/pt/empty_state'
import panels_pt from '@/lang/pt/panels'
import profile_pt from '@/lang/pt/profile'
import login_pt from '@/lang/pt/login'
import instructors_pt from '@/lang/pt/instructors'
import students_pt from '@/lang/pt/students'
import modal_pt from '@/lang/pt/modal'
import products_pt from '@/lang/pt/products'
import records_pt from '@/lang/pt/records'
import trial_pt from '@/lang/pt/trial'
import myaccount_pt from '@/lang/pt/myaccount'
import video_pt from '@/lang/pt/video'
//Catalán
import general_ca from '@/lang/ca/general'
import dashboard_ca from '@/lang/ca/dashboard'
import projects_ca from '@/lang/ca/projects'
import community_ca from '@/lang/ca/community'
import sales_ca from '@/lang/ca/sales'
import marketing_ca from '@/lang/ca/marketing'
import customization_ca from '@/lang/ca/customization'
import common_ca from '@/lang/ca/common'
import settings_ca from '@/lang/ca/settings'
import signup_ca from '@/lang/ca/signup'
import empty_state_ca from '@/lang/ca/empty_state'
import panels_ca from '@/lang/ca/panels'
import profile_ca from '@/lang/ca/profile'
import login_ca from '@/lang/ca/login'
import instructors_ca from '@/lang/ca/instructors'
import students_ca from '@/lang/ca/students'
import modal_ca from '@/lang/ca/modal'
import products_ca from '@/lang/ca/products'
import records_ca from '@/lang/ca/records'
import trial_ca from '@/lang/ca/trial'
import myaccount_ca from '@/lang/ca/myaccount'
import video_ca from '@/lang/ca/video'

export default {
    en: {
        general: general_en,
        common: common_en,
        customization: customization_en,
        dashboard: dashboard_en,
        empty_state: empty_state_en,
        instructors: instructors_en,
        login: login_en,
        marketing: marketing_en,
        panels: panels_en,
        profile: profile_en,
        projects: projects_en,
        settings: settings_en,
        signup: signup_en,
        community: community_en,
        modal: modal_en,
        product: products_en,
        records: records_en,
        sales: sales_en,
        trial: trial_en,
        myaccount: myaccount_en,
        video: video_en
    },
    es: {
        general: general_es,
        dashboard: dashboard_es,
        projects: projects_es,
        community: community_es,
        sales: sales_es,
        marketing: marketing_es,
        customization: customization_es,
        common: common_es,
        panels: panels_es,
        settings: settings_es,
        empty_state: empty_state_es,
        profile: profile_es,
        instructors: instructors_es,
        students: students_es,
        login: login_es,
        signup: signup_es,
        modal: modal_es,
        products: products_es,
        records: records_es,
        trial: trial_es,
        myaccount: myaccount_es,
        video: video_es
    },
    pt: {
        general: general_pt,
        dashboard: dashboard_pt,
        projects: projects_pt,
        community: community_pt,
        sales: sales_pt,
        marketing: marketing_pt,
        customization: customization_pt,
        common: common_pt,
        panels: panels_pt,
        settings: settings_pt,
        empty_state: empty_state_pt,
        profile: profile_pt,
        instructors: instructors_pt,
        students: students_pt,
        login: login_pt,
        signup: signup_pt,
        modal: modal_pt,
        products: products_pt,
        records: records_pt,
        trial: trial_pt,
        myaccount: myaccount_pt,
        video: video_pt
    },
    de: {
        general: general_de,
        common: common_de,
        customization: customization_de,
        dashboard: dashboard_de,
        empty_state: empty_state_de,
        instructors: instructors_de,
        login: login_de,
        marketing: marketing_de,
        panel: panel_de,
        projects: projects_de,
        profile: profile_de,
        sales: sales_de,
        settings: settings_de,
        signup: signup_de,
        students: students_de,
        community: community_de,
        modal: modal_de,
        products: products_de,
        records: records_de,
        trial: trial_de,
        myaccount: myaccount_de,
        video: video_de
    },
    ca: {
        general: general_ca,
        common: common_ca,
        customization: customization_ca,
        dashboard: dashboard_ca,
        empty_state: empty_state_ca,
        instructors: instructors_ca,
        login: login_ca,
        marketing: marketing_ca,
        panels: panels_ca,
        projects: projects_ca,
        profile: profile_ca,
        sales: sales_ca,
        settings: settings_ca,
        signup: signup_ca,
        students: students_ca,
        community: community_ca,
        modal: modal_ca,
        products: products_ca,
        records: records_ca,
        trial: trial_ca,
        myaccount: myaccount_ca,
        video: video_ca
    }
}