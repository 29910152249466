import {useAuthUserStore} from "@/stores/auth";

export default {
    index(user_uuid) {
        return useAuthUserStore().getApiClient.get('/v2/academy/user/billing-address', {
            params: {
                academy_user_uuid: user_uuid
            }
        })
    },

    update(userUuid, data)
    {
        return useAuthUserStore().getApiClient.put('/v2/academy/user/billing-address', data, {
            params: {
                academy_user_uuid: userUuid
            }
        })
    }
}