<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex items-center justify-between">
          <span class="pr-3 bg-gray-100 text-3xl font-medium text-gray-900">
            {{ $t('community.your_groups') }}
          </span>

          <div class="flex">
            <button type="button" v-on:click="navigationMenuStore.showSlideOverGroups = true" class="inline-flex items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-brainspro-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue cursor-pointer">
              <!-- Heroicon name: plus-sm -->
              <svg class="-ml-1.5 mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                   aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"/>
              </svg>
              <span>{{ $t('community.new_group') }}</span>
            </button>
          </div>
        </div>
      </div>

      <!-- Loading groups -->
      <div v-if="loading" class="flex justify-center my-24 p-4 text-sm">
        <div class=" text-yellow-700">
          <svg class="inline mr-3 w-6 h-6 text-blue-700 animate-spin" viewBox="0 0 100 101" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"/>
            <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"/>
          </svg>
        </div>
        <span class="ml-1 text-gray-500 text-xl">
          {{ $t('community.loading_groups') }}
        </span>
      </div>

      <!-- Replace with your content -->
      <div v-else-if="groups && groups.length > 0" class="py-4 hidden md:block">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div
                class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {{ $t('community.name') }}
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {{ $t('community.projects') }}
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {{ $t('community.users') }}
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {{ $t('community.last_update') }}
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="group in groups" :key="group.uuid"
                    class="hover:bg-gray-50 transition-colors ease-in cursor-pointer">

                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span class="text-sm leading-5 font-medium text-gray-900">{{ group.title }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <span class="text-sm leading-5 text-gray-500">{{ group.projects_count }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span class="text-sm leading-5 text-gray-500">{{ group.academy_users_count }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <span class="text-sm leading-5 text-gray-500">{{
                        $moment(group.updated_at).format('DD/MM/YYYY H:mm:ss')
                      }}</span>
                  </td>
                  <td class="border-b border-gray-200">
                    <router-link :to="{ name: 'group-general', params: { id : group.uuid }}"
                                 class="inline-block mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                      </svg>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!loading" class="mt-8">
        <div class="flex justify-center ">
          <div class="text-gray-500 font-semibold">
            No hay grupos creados
          </div>
        </div>

      </div>
      <!-- Reemplazar GroupsListComponent por la vista de móvil. -->
      <!-- <mobile-user-list-component class="block md:hidden" :users="users"></mobile-user-list-component> -->
      <!-- /End replace -->
    </div>
  </div>
  <GroupsSlideOverComponent @group-created="this.loadGroups"></GroupsSlideOverComponent>
</template>

<script>
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import {useAcademiesStore} from "@/stores/academies";
import GroupsService from "@/services/community/GroupsService";
import GroupsSlideOverComponent from "@/views/community/GroupsSlideOverComponent";

export default {
  name: "GroupsListComponent",
  components: { GroupsSlideOverComponent },
  data: function () {
    return {
      loading: false,
      creatingGroup: false,
      group: {
        uuid: "",
        academy_uuid: "",
        title: "",
        attachedProjects: 0,
        attachedUsers: 0,
        updated_at: ''
      },
      groups: []
    }
  },
  setup() {
    const navigationMenuStore = useNavigationMenuStore()
    const academyStore = useAcademiesStore()
    return {
      navigationMenuStore,
      academyStore
    }
  },
  mounted() {
    this.loadGroups()
  },
  methods: {
    loadGroups()
    {
      let academy_uuid = this.academyStore.getSelectedAcademyUuid

      GroupsService.index(academy_uuid)
          .then((response) => {
            this.groups = response.data.data
          })
          .catch((error) => {
            console.log("Error al obtener grupos: ", error)
          })
          .finally(() => {
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>