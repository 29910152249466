export default {
    permits: 'Permits',
    notifications: 'Notifications',
    plans_billing: 'Plans and User',
    integrations: 'Integraciones',
    update_info: 'Please update your billing details. Please note that your location settings may affect tax rates.',
    payment_details: 'Payment details',
    expiration_date: 'Expiry date',
    country: 'Country / Region',
    postal: 'Postcode / ZIP code',
    current_plan: 'Current plan',
    business: 'Business',
    postings_job_25: 'Up to 25 active job offers',
    enterprise: 'Company',
    unlimited_postings_job: 'Unlimited active job offers',
    pricing_plans: 'Pricing the plans',
    startup: 'Startup',
    postings_job_5: 'Up to 5 active job offers',
    user_settings: 'User settings',
    annual_billing: 'Annual Turnover',
    save_10: '(Save 10%)',
    billing_history: 'User history',
    save: 'Save',
    date: 'Date',
    description: 'Description',
    amount: 'Amount',
    format_date: 'MM / YY',
    spain: 'Spain',
    canada: 'Canada',
    mexico: 'Mexico',

    //SubscriptionComponent
    subscription_plan: 'Subscription plan',
    basic: 'Basic',
    basic_price: '26€',
    per_month: '/month',
    basic_info: 'The perfect option to get started in the world of online teaching.',
    unlimited_projects: 'Unlimited Projects',
    unlimited_students: 'Unlimited Students',
    to_3_teachers: 'Up to 3 teachers',
    subdomain: 'Subdomain',
    take_plan: 'I want this plan',
    advance: 'Advanced',
    advance_price: '89€',
    advance_info: 'Boost your online academy, everything from the Basic Plan and more.',
    own_domain: 'Own domain',
    private_label: 'White Label',
    to_10_teachers: 'Up to 10 teachers',
    report_gen: 'Report generation',
    pro: 'Pro',
    pro_price: '152€',
    pro_info: 'Escala your online academy. All the advanced and more.',
    no_comissions: '0% sales commission',
    api_access: 'API Access',
    app_integrations: 'Integration with APPs',
    support_prior: 'Priority support',
    //CurrentSubscriptionsComponent
    actual_subscriptions: 'Current subscriptions',
    attention: 'Attention',
    att_info1: 'If you wish to change your plan, please contact ',
    contact_email: 'billing@brainspro.com',
    att_info2: 'If you wish to purchase a subscription, please fill in the available information ',
    here: 'here',
    ends_on: 'Ends on ',
    trial_ends_on: 'The trial period ends on: ',
    view_receipt: 'View Receipt',
    cancel_subscription: 'Unsubscribe',
    modal_cancel_sub_text: 'This account will go into a suspended state. Functionality will be restricted for academy users, teachers and owners.',
    modal_cancel_sub_question: 'Are you sure you want to do it?',

    //ChangeSubscriptionComponent
    message_subscription_expire: 'Your BrainsPro account has expired. Activate a plan to regain access.',
    update_your_plan: 'Upgrade your plan',
    monthly_billing: 'Monthly',
    yearly_billing: 'Annual',
    text_all_the_basics: 'All the basics for starting a new business',
    button_activate: 'Activate',
    button_current_plan: 'Current Plan',
    what_include: 'Including:',
    text_tax_not_include: 'VAT not included',
    month: 'Month',
    year: 'Year',
}