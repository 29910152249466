<template>
  <div class="py-4">
    <div class="flex flex-col">
          <div v-for="video in videos" :key="video.uuid" class="bg-white border border-gray-200 shadow mb-4 transition-colors ease-in">
            <div class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <div class="whitespace-no-wrap">
                  <div class="text-sm leading-5 font-medium text-gray-900">Enlace: </div>
                  <div class="truncate text-sm leading-5 text-gray-500">{{video.path}}</div>
                  <div class="flex flex-row justify-between">
                    <label class="inline-flex mt-3">
                      <p class="text-bpblue">
                        {{ (video.size_bytes /1024/1024).toFixed(2)}} Mb
                      </p>
                    </label>
                  </div>
                </div>
            </div>
              <div class="flex justify-center">
                <div class="py-3">
                  <td class="lg:px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <router-link :to="{ name: 'video-edit-general', params: { id : video.uuid }}">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-3 h-7 w-7 text-bpblue hover:text-blue-300 transition rounded ease-in-out duration-150">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </router-link>
                  </td>
                </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import {useNavigationMenuStore} from "@/stores/navigationMenu";

export default {
  name: "VideoListComponent",
  props: {
    videos: Array
  },

  setup(){
    const navigationMenuStore = useNavigationMenuStore()

    return {
      navigationMenuStore,
    }
  },
}
</script>

<style scoped>

</style>