import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid) {
        return useAuthUserStore().getApiClient.get('/academy/contact/html', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },

    update(academyUuid, data)
    {
        return useAuthUserStore().getApiClient.put('/academy/contact/html', data, {
            params: {
                academy_uuid: academyUuid
            }
        })
    }
}