export default {
    loading_content: "Estamos cargando tu contenido",
    wellcome: "¡Bienvenido a BrainsPro!",
    redirect_academy: "En unos momentos serás redirigido a tu academia.",
    first: "Primera",
    last: "Última",
    next: "Siguiente",
    page: "Página",
    previous: "Anterior",

}