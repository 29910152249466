<template>
  <div class="max-w-7xl mx-auto py-3 md:py-6 sm:px-8 lg:pt-8">
    <card-with-header-and-footer-component :loading="loading">
      <template v-slot:header>

      </template>

      <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="">
          <label for="company_name"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t('community.company_name')}}</label>
          <input id="company_name" v-model="this.request.name"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="vat_id"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t('community.vat_number_id')}}</label>
          <input id="vat_id" v-model="this.request.vat_id"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="address_line_1"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t('community.address_line_1')}}</label>
          <input id="address_line_1" v-model="this.request.address_line_1"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="address_line_2"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t('community.address_line_2')}}</label>
          <input id="address_line_2" v-model="this.request.address_line_2"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="zip_code"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t('community.zip_code')}}</label>
          <input id="zip_code" v-model="this.request.postal_code"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="city"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t('community.city')}}</label>
          <input id="city" v-model="this.request.city"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="state"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t('community.province')}}</label>
          <input id="state" v-model="this.request.state"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>

        <div class="">
          <label for="country"
                 class="block text-sm font-medium leading-5 text-gray-700">{{ $t('community.country')}}</label>
          <input id="country" v-model="this.request.country"
                 class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
      </div>

      <template v-slot:footer>

        <button type="button" v-on:click="getBillingAddress" :disabled="loading"
                class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="editBillingAddress" :disabled="loading"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </div>
</template>

<script>
import BillingAddressService from "@/services/user/BillingAddressService";
import {useAuthUserStore} from "@/stores/auth";
import notificationService from "@/services/notification/NotificationService";
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";

export default {
  name: "BillingAddressComponent",
  components: {
    CardWithHeaderAndFooterComponent
  },
  data: () => {
    return {
      loading: true,
      request: {
        name: '',
        vat_id: '',
        address_line_1: '',
        address_line_2: '',
        postal_code: '',
        city: '',
        state: '',
        country: ''
      }
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();

    return {
      authUserStore,
    }
  },
  mounted() {
    this.getBillingAddress()
  },
  methods: {
    getBillingAddress() {
      this.loading = true
      //let academy_uuid = this.authUserStore.user.academy.uuid;
      let user_uuid = this.$route.params.id;

      BillingAddressService.index(user_uuid)
          .then((response) => {
            console.log("El uuid del usuario es: ", user_uuid)
            console.log("Los datos de facturación son: ", response.data)
            this.request = response.data
          })
          .catch((error) => {
            console.log("Error al obtener los datos de facturación: ", error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    editBillingAddress() {
      this.loading = true
      let user_uuid = this.$route.params.id;

      console.log("Los datos a guardar son: ", this.request)
      BillingAddressService.update(user_uuid, this.request)
          .then((response) => {
            this.request = response.data
            console.log("La respuesta en la edición de los datos de facturación es: ", response.data)
            notificationService.showNotification("Edición realizada correctamente", "success")
          })
          .catch((error) => {
            console.log("Se ha producido un error al cambiar los datos de facturación: ", error)
            notificationService.showNotification(error, "error")
          })
          .finally(() => {
            this.loading = false
            this.getBillingAddress()
          })
    }
  }
}
</script>

<style scoped>

</style>