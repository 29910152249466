<template>
  <div class="pt-2 pb-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <google-analytic-component></google-analytic-component>
      <!-- Introducción de campos para header y footer custom -->
      <CustomGlobalHTML class="mt-8"></CustomGlobalHTML>
    </div>
  </div>
</template>

<script>

import GoogleAnalyticComponent from "@/views/marketing/scripts/GoogleAnalyticComponent";
import CustomGlobalHTML from "@/views/marketing/scripts/CustomGlobalHTML"

export default {
  name: "ScriptsComponent",
  components: {
    GoogleAnalyticComponent,
    CustomGlobalHTML,
  },

  data() {
    return {
      request: ''
    }
  },
}
</script>

<style scoped>

</style>