<template>
  <div class="pt-2 pb-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <section class="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-4">
        <default-card-component :quantity="stats.SalesPVPCurrentMonth + '€'" :loading="stats.loading">
          {{ $t("sales.pvp_sales") }} {{ $moment(request.month, 'M').format('MMMM') }}
        </default-card-component>

        <default-card-component :quantity="stats.SalesNetCurrentMonth + '€'" :loading="stats.loading">
          {{ $t("sales.net_sales") }} {{ $moment(request.month, 'M').format('MMMM') }}
        </default-card-component>

        <default-card-component :quantity="stats.RefundsPVPCurrentMonth + '€'" text-color="text-red-600" :loading="stats.loading">
          {{ $t("sales.pvp_returns") }} {{ $moment(request.month, 'M').format('MMMM') }}
        </default-card-component>

        <default-card-component :quantity="stats.RefundsNetCurrentMonth + '€'" text-color="text-red-600" :loading="stats.loading">
          {{ $t("sales.net_returns") }} {{ $moment(request.month, 'M').format('MMMM') }}
        </default-card-component>
      </section>

      <section class="my-4 bg-white overflow-hidden shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6 grid grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <label for="months" class="block text-sm leading-5 font-medium text-gray-700">{{$t('general.month')}}</label>
            <select id="months" v-model="request.month" v-on:change="getOrders" class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 capitalize">
              <option v-for="i in 12" :key="i" :value="i">{{ $moment(i, "M").format('MMMM') }}</option>
            </select>
          </div>
          <div>
            <label for="years" class="block text-sm leading-5 font-medium text-gray-700">{{$t('general.year')}}</label>
            <select id="years" v-model="request.year" v-on:change="getOrders" class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
              <option v-for="i in range(2015, currentYear() - 1)" :key="i" :value="i">{{i}}</option>
              <option selected :key="currentYear()" :value="currentYear()">{{currentYear()}}</option>
            </select>
          </div>
          <div v-if="orders" class="col-span-4 mt-1">
            <p class="text-bppick">{{$t('sales.obtain_text_1')}} {{orders.length}} {{$t('sales.obtain_text_2')}}</p>
          </div>
        </div>
      </section>

      <!-- Replace with your content -->
      <div class="py-4 hidden md:block">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div v-if="!loading" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    #
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.date") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("sales.buyer") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.products") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.pvp") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.net") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Comisión
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t("general.state") }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                  <th class="hidden px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="order in orders" :key="order.uuid" v-show="order.customer.firstname !== null">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 font-medium text-gray-900">{{ order.number }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 font-medium text-gray-900">{{ $moment(order.completed_at).format('DD/MM/YYYY H:mm:ss') }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div v-if="order.customer" class="text-sm leading-5 font-medium text-gray-900">
                      {{ order.customer.firstname + ' ' + order.customer.lastname }}
                    </div>
                    <div v-if="order.customer.email" class="text-sm leading-3 font-light text-gray-900">
                      {{ order.customer.email }}
                    </div>
                    <div v-if="order.customer.phone" class="text-sm leading-3 font-light text-gray-900">
                      {{ order.customer.phone }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <span v-for="item in order.items" :key="item.uuid">
                      {{item.title}}
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ order.total }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ order.net }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ order.metadata._stripe_fee }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <span v-if="order.status == 'completed'" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800">{{$t('sales.complete')}}</span>
                    <span v-if="order.status == 'refunded'" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-red-100 text-red-800">{{$t('sales.reimbursed')}}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <a href="#" class="hidden text-blue-600 hover:text-blue-900 hover:underline">{{ $t("sales.view_order") }}</a>
                  </td>
                  <td class="hidden lg:px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <router-link :to="{ name: 'reports.orders.show_order', params: { id : order.uuid }}" class="cursor-pointer hover:underline">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-3 h-5 w-5 text-bpblue hover:text-blue-300 transition rounded ease-in-out duration-150">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <loading-component v-else>{{ $t("sales.loading_orders") }}</loading-component>
          </div>
        </div>
      </div>
      <mobile-orders-component class="md:hidden" :loading="loading" :orders="orders"></mobile-orders-component>
      <!--<PaginationComponent></PaginationComponent>-->
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/views/common/LoadingComponent";
import OrdersService from "@/services/report/OrdersService";
import DefaultCardComponent from "@/components/cards/DefaultCardComponent";
import MobileOrdersComponent from "@/views/sales/Mobile/OrdersComponent";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "OrdersComponent",
  components: {DefaultCardComponent, LoadingComponent, MobileOrdersComponent},
  data() {
    return {
      loading: true,
      orders: [],
      request: {
        month: null,
        year: null
      },
      stats: {
        loading: true,
        SalesPVPCurrentMonth: 0,
        SalesNetCurrentMonth: 0,
        RefundsPVPCurrentMonth: 0,
        RefundsNetCurrentMonth: 0
      }
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  created() {
    this.request.month = this.$moment().format('M')
    this.request.year = this.$moment().year()

    this.getOrders(this.request.year, this.request.month)
  },
  methods: {
    range (start, end, step = 1) {
      const len = Math.floor((end - start) / step) + 1
      return Array(len).fill().map((_, idx) => start + (idx * step))
    },
    currentYear() {
      return parseInt(this.$moment().format('YYYY'));
    },
    getOrders() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      OrdersService.index(academy_uuid, this.request.year, this.request.month).then((response) => {
        console.log("getOrders OK", response)
        this.orders = response.data.orders
        this.getStatsCurrentMonth()
      })
      .catch((response) => {
        console.log("Ha ocurrido un error en getOrders", response)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getStatsCurrentMonth() {
      this.stats.loading = true
      this.stats.SalesPVPCurrentMonth = 0
      this.stats.SalesNetCurrentMonth = 0
      this.stats.RefundsPVPCurrentMonth = 0
      this.stats.RefundsNetCurrentMonth = 0

      this.orders.forEach( (order) => {
        if (order.status == 'completed')
        {
          this.stats.SalesPVPCurrentMonth += order.total * 100;
          this.stats.SalesNetCurrentMonth += order.net * 100;
        }

        if (order.status == 'refunded')
        {
          this.stats.RefundsPVPCurrentMonth += order.total * 100;
          this.stats.RefundsNetCurrentMonth += order.net * 100;
        }
      })

      this.stats.SalesPVPCurrentMonth = (this.stats.SalesPVPCurrentMonth / 100).toFixed(2)
      this.stats.SalesNetCurrentMonth = (this.stats.SalesNetCurrentMonth / 100).toFixed(2)
      this.stats.RefundsPVPCurrentMonth =  (this.stats.RefundsPVPCurrentMonth / 100).toFixed(2)
      this.stats.RefundsNetCurrentMonth = (this.stats.RefundsNetCurrentMonth / 100).toFixed(2)
      this.stats.loading = false
    }
  }
}
</script>

<style scoped>

</style>