<template>
  <section class="pt-2 py-5 md:mx-8 mx-4">
    <!-- Mostrar todas las preguntas del cuestionario sin edición. -->
    <div id="questions_list" v-for="(question, questionIndex) in this.questionnaire" v-bind:key="questionIndex">
      <div class="mt-3 p-3 border border-1 text-gray-600">
        <div class="flex justify-between">
          <label class="text-gray-700">Pregunta {{ questionIndex + 1 }}</label>
          <div v-on:click="editQuestion(question, questionIndex)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>
        <label id="question" disabled class="mt-3">{{ question.title }}</label>
        <div v-show="question.messageCorrect" class="mt-3">
          <div class="flex flex-row">
            <label class="text-gray-500">Respuesta correcta</label>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-2 w-5 h-5 text-green-600">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </div>
          <p class="text-sm">{{question.messageCorrect}}</p>
        </div>
        <div v-show="question.messageFail" class="mt-2">
          <div class="flex flex-row">
            <label class="text-gray-500">Respuesta incorrecta</label>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-1 mt-0.5 w-5 h-5 text-red-600">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <p class="text-sm">{{question.messageFail}}</p>
        </div>
      </div>

      <!-- Mostrar las respuestas una vez añadidas. -->
      <div v-for="(answer, answerIndex) in question.answers" v-bind:key="answerIndex" class="p-3 rounded my-3" v-bind:class="{'bg-green-200': answer.isCorrect, 'bg-stone-100' : !answer.isCorrect}">
        <div v-if="question.answers">
          <label class="text-gray-700">Respuesta {{answerIndex + 1}}</label>
          <p id="answer">{{answer.content}}</p>
        </div>
      </div>

        <!-- Creación de Respuestas -->
        <div v-if="this.createAnswer" class="bg-stone-100 p-3 rounded"
             v-bind:class="{'bg-green-200': this.newAnswer.isCorrect}">
          <div class="flex flex-row justify-between">
            <label class="text-gray-700">Nueva respuesta</label>
            <div>
              <label class="text-sm text-gray-500 mr-1">¿Es correcta esta respuesta?</label>
              <input id="notificate-checkbox" v-model="this.newAnswer.isCorrect" name="notificate-checkbox"
                     type="checkbox" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300 rounded"/>
            </div>
          </div>
          <div>
            <input id="question-editor"
                   class="mt-3 py-4 appearance-none w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-bpblue transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                   v-model="this.newAnswer.content">
          </div>

          <!-- Nueva respuesta -->
          <div v-show="createAnswer" class="mt-4">
            <div class="flex justify-end">
              <button type="button" v-on:click="cancelAddAnswer" :disabled="loading"
                      class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                {{ $t('general.cancel') }}
              </button>
              <button type="button" v-on:click="saveAnswer(question)"
                      :disabled="loading && this.newAnswer.content == ''"
                      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                {{ $t('general.save') }}
              </button>
            </div>
          </div>
        </div>

        <!-- Botón para añadir preguntas-->
        <div v-if="!createAnswer && !this.createQuestion" class="flex justify-end mt-4 mb-6">
          <button v-on:click="this.createAnswer = !this.createAnswer"
                  class=" text-bpbluedeep border border-bpblue font-medium px-2 rounded-full hover:bg-bpblue hover:text-white transition duration-150 ease-in-out">
            + Añadir respuesta
          </button>
        </div>
      </div>

    <!-- Creando una nueva pregunta -->
    <div v-show="createQuestion" class="pb-8 mt-6">
      <div class="my-3">
        <label class="text-gray-700">Pregunta {{ this.questionnaire.length + 1 }}</label>
        <vue-editor id="question-editor" class="mt-3" v-model="this.newQuestion.title"></vue-editor>
      </div>

      <!-- Opciones de Configuración de la pregunta -->
      <div class="border-gray-300">
        <label class="text-gray-800 font-semibold">Opciones de configuración</label>
        <div class="flex flex-row">
          <label class="py-2 mr-9 text-gray-500 text-sm mt-0.5">Si es correcta la respuesta</label>
          <div class="space-y-4 py-3">
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="notificate-checkbox" v-model="this.newQuestion.showMsgCorrect" name="notificate-checkbox"
                       type="checkbox" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300 rounded"/>
              </div>
              <div class="ml-3 text-sm">
                <label for="notificate-checkbox" class="font-medium text-gray-700">Mensaje respuesta correcta</label>
                <p class="text-gray-500">Notifica a tu alumno cuando marca una respuesta correcta.</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="newQuestion.showMsgCorrect">
          <input v-model="this.newQuestion.messageCorrect" type="text" placeholder="Introduce un mensaje que se mostrará al usuario si la respuesta es correcta."
                 class="appearance-none w-full my-2 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-bpblue transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
        <div class="flex flex-row">
          <label class="py-2 mr-6 text-gray-500 text-sm mt-0.5">Si es incorrecta la respuesta</label>
          <div class="space-y-4 py-3">
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="notificate-checkbox" v-model="this.newQuestion.showMsgFail" name="notificate-checkbox"
                       type="checkbox" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300 rounded"/>
              </div>
              <div class="ml-3 text-sm">
                <label for="notificate-checkbox" class="font-medium text-gray-700">Mensaje respuesta incorrecta</label>
                <p class="text-gray-500">Notifica a tu alumno cuando marca una respuesta incorrecta.</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="newQuestion.showMsgFail">
          <input v-model="this.newQuestion.messageFail" type="text" placeholder="Introduce un mensaje que se mostrará al usuario si la respuesta es incorrecta."
                 class="appearance-none w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-bpblue transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
        <div class="flex justify-end mt-3">
          <button type="button" v-on:click="cancelAddQuestion" :disabled="loading"
                  class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.cancel') }}
          </button>
          <button type="button" v-on:click="saveQuestion(this.newQuestion)" :disabled="loading"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </div>

    <!-- Editando una pregunta -->
    <div v-show="this.showEditQuestion" class="pb-8 mt-6">
      <div class="my-3">
        <label class="text-gray-700">Pregunta {{ this.editIndexQuestion }}</label>
        <vue-editor id="question-editor" class="mt-3" v-model="this.newQuestion.title"></vue-editor>
      </div>

      <!-- Opciones de Configuración de la pregunta -->
      <div class="border-gray-300">
        <label class="text-gray-800 font-semibold">Opciones de configuración</label>
        <div class="flex flex-row">
          <label class="py-2 mr-9 text-gray-500 text-sm mt-0.5">Si es correcta la respuesta</label>
          <div class="space-y-4 py-3">
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="notificate-checkbox" v-model="this.newQuestion.showMsgCorrect" name="notificate-checkbox"
                       type="checkbox" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300 rounded"/>
              </div>
              <div class="ml-3 text-sm">
                <label for="notificate-checkbox" class="font-medium text-gray-700">Mensaje respuesta correcta</label>
                <p class="text-gray-500">Notifica a tu alumno cuando marca una respuesta correcta.</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="newQuestion.showMsgCorrect">
          <input v-model="this.newQuestion.messageCorrect" type="text" placeholder="Introduce un mensaje que se mostrará al usuario si la respuesta es correcta."
                 class="appearance-none w-full my-2 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-bpblue transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
        <div class="flex flex-row">
          <label class="py-2 mr-6 text-gray-500 text-sm mt-0.5">Si es incorrecta la respuesta</label>
          <div class="space-y-4 py-3">
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="notificate-checkbox" v-model="this.newQuestion.showMsgFail" name="notificate-checkbox"
                       type="checkbox" class="focus:ring-bpblue h-4 w-4 text-bpblue border-gray-300 rounded"/>
              </div>
              <div class="ml-3 text-sm">
                <label for="notificate-checkbox" class="font-medium text-gray-700">Mensaje respuesta incorrecta</label>
                <p class="text-gray-500">Notifica a tu alumno cuando marca una respuesta incorrecta.</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="newQuestion.showMsgFail">
          <input v-model="this.newQuestion.messageFail" type="text" placeholder="Introduce un mensaje que se mostrará al usuario si la respuesta es incorrecta."
                 class="appearance-none w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-bpblue transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
        <div class="flex justify-end mt-3">
          <button type="button" v-on:click="cancelAddQuestion" :disabled="loading"
                  class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.cancel') }}
          </button>
          <button type="button" v-on:click="saveEditQuestion(this.newQuestion)" :disabled="loading"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </div>

    <!-- Botón añadir nueva pregunta -->
    <div v-if="!this.createQuestion" class="flex flex-row items-center justify-center ">
      <button type="button" v-on:click="this.createQuestion = !this.createQuestion" :disabled="loading"
              class="inline-flex mt-5 items-center px-3 py-2 font-medium shadow-sm hover:shadow-inner text-white bg-bpblue rounded-xl hover:bg-bpbluedeep transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <label class="mx-2 cursor-pointer">Añadir nueva pregunta</label>
      </button>
    </div>

  </section>
</template>

<script>
import {VueEditor} from "vue3-editor";

export default {
  name: "QuestionaryComponent",
  components: {
    VueEditor
  },
  props: {
    lesson: {
      uuid: String,
      title: String,
    }
  },
  mounted() {
    console.log("El objeto lección es: ", this.lesson)
  },
  data() {
    return {
      loading: false,
      createQuestion: false,
      createAnswer: false,
      questionnaire: [],
      newQuestion: {
        uuid: '',
        title: '',
        answers: [],
        showMsgCorrect: false,
        messageCorrect: '',
        showMsgFail: false,
        messageFail: '',
        position: 0,
        score: 0,
      },
      newAnswer: {
        content: '',
        uuid: '',
        isCorrect: false,
      },
      showEditQuestion: false,
      editIndexQuestion: 0,

      error: {
        questionError: false,
      }
    }
  },
  methods: {
    cancelAddQuestion() {
      this.createQuestion = false
      this.showEditQuestion = true
      this.editIndexQuestion = 0
      this.newQuestion = [{
        uuid: '',
        title: '',
        answers: null,
        score: '',
      }],
          console.log("La pregunta se borrará")
    },
    saveQuestion() {
      this.questionnaire.push(this.newQuestion)
      this.createQuestion = false
      console.log("Añadiendo pregunta al cuestionario principal: ", this.questionnaire)
      this.newQuestion = {
        uuid: '',
        title: '',
        answers: [],
        showMsgCorrect: false,
        messageCorrect: '',
        showMsgFail: false,
        messageFail: '',
        position: 0,
        score: 0,
      }
    },
    saveEditQuestion() {
      let index = this.questionnaire.findIndex(question => question.uuid === this.newQuestion.uuid)
      this.questionnaire[index] = this.newQuestion
      console.log("Se ha reemplazado la pregunta en el cuestionario: ", this.questionnaire)
      this.showEditQuestion = false
      this.newQuestion = {
        uuid: '',
        title: '',
        answers: [],
        showMsgCorrect: false,
        messageCorrect: '',
        showMsgFail: false,
        messageFail: '',
        position: 0,
        score: 0,
      }
    },
    editQuestion(question, indexQuestion) {
      this.editIndexQuestion = indexQuestion+1
      this.newQuestion = question
      this.showEditQuestion = true
    },

    cancelAddAnswer() {
      this.newAnswer = null
      this.createAnswer = false
    },
    saveAnswer(question) {
      let indexQuestion = this.questionnaire.indexOf(question)
      this.questionnaire[indexQuestion].answers.push(this.newAnswer)
      console.log("La pregunta tiene estas respuestas", this.questionnaire)
      this.createAnswer = false
      this.newAnswer = {
        content: '',
        uuid: '',
        isCorrect: false,
      }
    }
  }
}
</script>

<style scoped>

</style>