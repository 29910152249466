<template>
  <div class="mx-auto sm:flex sm:flex-col">
      <!-- <header-component :title="$t('settings.update_your_plan')"></header-component> -->
      <p class="hidden mt-4 text-xl text-gray-500 sm:text-center"></p>
      <div class="relative mt-6 bg-gray-100 rounded-lg p-0.5 flex self-center sm:mt-2 sm:px-6 px-6">
        <button v-on:click="changeInterval('month')" type="button" :class="{'bg-white shadow' : interval == 'month'}" class="relative border-gray-200 rounded-md shadow-sm py-2 w-1/2 text-sm font-medium text-gray-900 whitespace-nowrap  focus:z-10 sm:w-auto sm:px-8">{{$t('settings.monthly_billing')}}</button>
        <button v-on:click="changeInterval('year')" type="button" :class="{'bg-white shadow' : interval == 'year'}" class="ml-1 relative border shadow-inner border-transparent rounded-md py-2 w-1/2 text-sm font-medium text-gray-700 whitespace-nowrap focus:z-10 sm:w-auto sm:px-8">{{$t('settings.yearly_billing')}}</button>
      </div>
      <div class="lg:mx-40 w-auto flex flex-1 justify-center p-2 rounded-lg mt-5 items-center mx-20 mb-5 text-gray-600">
        <span class="flex p-2 rounded-lg">
            <!-- Heroicon name: outline/speakerphone -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
            </svg>
          </span>
        <p class="ml-3 font-medium">
          <span class="">{{$t('myaccount.info_promotional_coupon')}}</span>
        </p>
        </div>
    </div>
    <div v-on:click="interval = 'year'" class="cursor-pointer mx-40 w-auto flex flex-1 justify-center p-2 rounded-lg bg-bpblue items-center mx-20 border-2 text-white">
      <font-awesome-icon class="cursor-pointer m-1 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="piggy-bank" size="lg" />
      <label class="cursor-pointer ml-3 font-medium">
        ¡Ahorra 2 meses al activar la suscripción anual!
      </label>

    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="mt-10 space-y-4 sm:mt-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
        <div v-for="plan in plans" :key="plan.uuid" class="border border-gray-200 rounded-lg divide-y divide-gray-200 bg-white shadow-lg">
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">{{ plan.title }}</h2>
            <p class="hidden mt-4 text-sm text-gray-500">{{$t('settings.text_all_the_basics')}}</p>
            <p class="mt-8">
              <span class="text-2xl font-bold text-gray-900">{{ getPlanPrice(plan.prices).total }} €</span>
              <span v-if="interval=='month'" class="text-base font-medium text-gray-500"> / {{$t('settings.month')}}</span>
              <span v-if="interval=='year'" class="text-base font-medium text-gray-500"> / {{$t('settings.year')}}</span>
            </p>
            <button v-if="plan.key != this.userStateBlockButton" v-on:click="changeSubscription(getPlanPrice(plan.prices).uuid)" class="mt-8 block w-full bg-bpblue border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-pink-600">{{$t('settings.button_activate')}}</button>
            <button v-else class="mt-8 block w-full bg-gray-300 border border-transparent rounded-md py-2 text-sm font-semibold text-gray-500 text-center">{{$t('settings.button_current_plan')}}</button>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">{{$t('settings.what_include')}}</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li v-for="feature in plan.features" :key="feature.uuid" class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">{{getTranslatedFeatureTitle(feature.translations)}}</span>
              </li>
            </ul>
          </div>
          <div v-if="plan.title === 'Plan Starter' && interval === 'year'" class="flex flex-col pt-6 pb-8 mx-auto items-center text-gray-600">
            <div>Pagado anualmente · Precio sin IVA</div>
          </div>
          <div v-if="plan.title === 'Plan Pro Trainer' && interval === 'year'" class="flex flex-col pt-6 pb-8 mx-auto items-center text-gray-600">
            <div>Starter + Pro Trainer</div>
            <div>Pagado anualmente · Precio sin IVA</div>
          </div>
          <div v-if="plan.title === 'Plan Learning Center' && interval === 'year'" class="flex flex-col pt-6 pb-8 mx-auto items-center text-gray-600">
            <div>Pro Trainer + Learning Center</div>
            <div>Pagado anualmente · Precio sin IVA</div>
          </div>
        </div>
      </div>

      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-bpblue rounded-md shadow-xlm">
        <div class="mt-10 py-4 text-white text-xl font-bold">BrainsPro para la formación corporativa</div>
        <div class="text-yellow-300 font-semibold my-2">¿Necesitas más?</div>
        <label class="text-white font-semibold">
          BrainsPro es una herramienta para la gestión del aprendizaje poderosa, escalable y fácil de usar para todo tu equipo y grupos de interés, ideal para la formación en entornos corporativos.
        </label>

        <div class="mt-10 space-y-4 sm:mt-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3 pb-8">
          <div class="">
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Todas las características de Elearning Center</label>
            </div>
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Productos ilimitados</label>
            </div>
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Administradores ilimitados</label>
            </div>
          </div>
          <div class=" my-8">
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Integración de multi-academia</label>
            </div>
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Nivel de servicio 99,9%</label>
            </div>
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Acceso a la API empresarial</label>
            </div>
          </div>
          <div class=" my-8">
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Equipo dedicado al éxito del cliente</label>
            </div>
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Inicio de sesión único (SSO)</label>
            </div>
            <div class="flex flex-row text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <label class="ml-2">Marca blanca de correo electrónico</label>
            </div>
          </div>
        </div>
        <div class="flex justify-center pb-8">
          <a type="button" href="https://brainspro.com/contacto/" target="_blank" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            Solicita presupuesto
          </a>
        </div>
      </div>


    </div>
</template>

<script>
import SaasPlanService from "../../../services/saas/SaasPlanService";
import saasSubscriptionService from "../../../services/saas/SaasSubscriptionService";
//import HeaderComponent from "../../common/HeaderComponent";
import {useAuthUserStore} from "../../../stores/auth";

export default {
  name: "ChangeSubscriptionComponent",
  components: {
    //HeaderComponent,
  },
  data() {
    return {
      loading: false,
      interval: "year",
      plans: [],
      userSaasPlan: '',
      statusSaasPlan: '',
      userStateBlockButton: ''
    }
  },
  setup()
  {
    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  mounted() {
    this.getSubscriptionsPlans()
  },
  methods: {
    getSubscriptionsPlans() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      SaasPlanService.index(academy_uuid)
        .then((response) => {
          this.plans = response.data
        })
        .finally(() => {
          this.statusSaasPlan = this.authUserStore.user.academy.saas_subscriptions[0].status
          this.planKey = this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.key
          //Si el estado del plan del usuario está en modo trialing, dejará que marque cualquier botón y no marcará ningún plan como activo.
          switch (this.statusSaasPlan)
          {
            case 'trialing':
              this.userSaasPlan = 'trialing'
              break
            case 'manual':
              this.userSaasPlan = 'manual'
              break
            case 'active':
              this.userStateBlockButton = this.planKey
              break
            case 'incomplete':
              this.userSaasPlan = 'incomplete'
              break
            case 'past_due':
              this.userSaasPlan = 'past_due'
              break
            case 'canceled':
              this.userSaasPlan = 'canceled'
              break
            case 'unpaid':
              this.userSaasPlan = 'unpaid'
              break
            case 'incomplete_expired':
              this.userSaasPlan = 'incomplete_expired'
              break
          }
        })
    },
    getPlanPrice(prices){
      let result = prices.find((price) => {
        return price.interval == this.interval
      })

      return result
    },
    changeInterval(interval) {
      this.interval = interval
    },
    changeSubscription(price_id) {
      console.log("I want to change my subscription with", price_id)
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      saasSubscriptionService.post(academy_uuid, price_id)
      .then((response) => {
        console.log("Response from saas subscription service:", response)
        window.location = response.data.stripe.url
      })
      .catch((result) => {
        console.log("Error", result)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getTranslatedFeatureTitle(translations) {
      let locale = this.$i18n.locale
      if(typeof translations[locale] == "undefined") {
        return ''
      } else {
        return translations[locale]
      }
    }
  }
}
</script>

<style scoped>

</style>