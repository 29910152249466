<template>
  <div class="pt-2 pb-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div> COMPONENTE SHOW ORDER. Prueba 2</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowOrdersComponent"
}
</script>

<style scoped>

</style>