
import { defineStore } from 'pinia'
import {useAuthUserStore} from "@/stores/auth";
import ProductService from "@/services/product/ProductService";
import customCategoryService from "@/services/product/CustomCategoryService";

export const useProductsStore = defineStore('products', {
    state: () => ({
        newProjectModal: {
            isShow: false,
            request: {
                title: "",
                category: ""
            }
        },
        product: {
            uuid: "",
            title: "",
            slug: "",
            category: "",
            featured: true,
            status: '',
            content: {
                aside: "",
                duration: "",
                header: "",
                toc: "",
                what: "",
                why: ""
            },
            projects: null,
            is_selling: false,
            regular_price: "",
            sale_price: "",
            media: {
                thumbnail: "",
                thumbnail_handle: "",
                video: ""
            },
            seo: {
                seo_description: "",
                seo_title: ""
            },
            metadata: {
                button_checkout_text: "Gratuito",
            }
        },
        loading: false,
        categories: []
    }),
    actions:{

        toggleModal()
        {
            this.newProjectModal.isShow = !this.newProjectModal.isShow
        },
        getProduct(productUuid)
        {
            this.loading = true
            useAuthUserStore().getApiClient.get('/product/' + productUuid, {})
                .then(response => {
                    console.log("El producto obtenido es", response)
                    this.setProducts(response.data)
                })
                .catch(error => {
                    console.log("Ha ocurrido un error obteniendo el producto", error)
                })
                .finally(() => {
                    //console.log(useProductsStore())
                    useProductsStore().loading = false
                })
        },

        setProductGeneral() {
            return ProductService.put({
                uuid: this.product.uuid,
                title: this.product.title,
                category: this.product.category,
                duration: this.product.content.duration,
                featured: this.product.featured,
                status: this.product.status
            }).then((response) => {
                console.log("Almacenando seccion general del producto", response)
            })
                .catch((error) => {
                    console.log("Ha ocurrido un error almacenando el producto", error)
                })
                .finally(() => {

                })
        },
        setProductBanner() {
            this.loading = true
            return ProductService.put({
                uuid: this.product.uuid,
                thumbnail: this.product.media.thumbnail,
                thumbnail_handle: this.product.media.thumbnail_handle
            })
        },
        setProductVideo() {
            this.loading = true
            return ProductService.put({
                uuid: this.product.uuid,
                video: this.product.media.video,
            })
        },
        setProductPage() {
            this.loading = true
            return ProductService.put({
                uuid: this.product.uuid,
                header: this.product.content.header,
                why: this.product.content.why,
                what: this.product.content.what,
                toc: this.product.content.toc,
                aside: this.product.content.aside,
            })
        },
        setProductPrice() {
            this.loading = true
            return ProductService.put({
                uuid: this.product.uuid,
                regular_price: this.product.regular_price,
                sale_price: this.product.sale_price,
                button_checkout_text: this.product.metadata.button_checkout_text,
                is_selling: this.product.is_selling
            })
        },
        setProductSEO() {
            this.loading = true
            return ProductService.put({
                uuid: this.product.uuid,
                seo_title: this.product.seo.seo_title,
                slug: this.product.slug,
                seo_description: this.product.seo.seo_description,
            })
        },
        getCategoriesAction() {
            return customCategoryService.index(useAuthUserStore().user.academy.uuid)
                .then((response) => {
                    console.log("Custom Categories1", response.data)
                    this.categories = response.data.data
                })
                .finally(() => {
                    this.loading = false
                })
        },
        //Mutations
        setProducts(product) {
            this.product = {
                uuid: product.uuid,
                title: product.title,
                slug: (product.slug) ? product.slug : "",
                category: product.category,
                featured: product.featured,
                status: product.status,
                content: {
                    aside: (product.content && product.content.aside)? product.content.aside : "",
                    duration: (product.content && product.content.duration)? product.content.duration : "",
                    header: (product.content && product.content.header)? product.content.header : "",
                    toc: (product.content && product.content.toc)? product.content.toc: "",
                    what: (product.content && product.content.what)? product.content.what: "",
                    why: (product.content && product.content.why)? product.content.why: ""
                },
                projects: product.projects,
                is_selling: product.is_selling,
                regular_price: (product.regular_price)? product.regular_price : "",
                sale_price: (product.sale_price)? product.sale_price : "",
                media: {
                    thumbnail: (product.media && product.media.thumbnail)? product.media.thumbnail : "",
                    thumbnail_handle: (product.media && product.media.thumbnail_handle)? product.media.thumbnail_handle : "",
                    video: (product.media && product.media.video)? product.media.video : ""
                },
                seo: {
                    seo_description: (product.seo && product.seo.seo_description)? product.seo.seo_description : "",
                    seo_title: (product.seo && product.seo.seo_title)? product.seo.seo_title : ""
                },
                metadata: {
                    button_checkout_text: (product.metadata && product.metadata.button_checkout_text)? product.metadata.button_checkout_text : "",
                }
            }
        },

    }
})