<template>
  <div class="pt-6 pb-6 md:py-12">
    <div class="max-w-5xl mx-auto px-4 sm:px-6 md:px-8 mb-10">
      <div class="relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex items-center justify-between">
          <span class="pr-3 bg-gray-100 text-xl font-medium text-gray-900">
            {{ $t("marketing.related_product") }}
          </span>
        </div>
      </div>

      <div class="max-w-5xl mx-auto px-4">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <!--
          Custom select controls like this require a considerable amount of JS to implement from scratch. We're planning
          to build some low-level libraries to make this easier with popular frameworks like React, Vue, and even Alpine.js
          in the near future, but in the mean time we recommend these reference guides when building your implementation:

          https://www.w3.org/TR/wai-aria-practices/#Listbox
          https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
        -->
        <div class="w-100 ">
          <label id="listbox-label" class="block text-sm font-medium text-gray-700">
            {{ $t("marketing.associate_product") }}
          </label>
          <div class="mt-1 relative" tabindex="0" @click="select.show = !select.show" @focusout="select.show = false">
            <button type="button" class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-bpblue focus:border-bpblue sm:text-sm" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
              <div class="flex items-center">
                <span v-if="data.productToAttach" aria-label="Online" :class="{'bg-green-400' : data.productToAttach.status == 'publish'}" class="bg-gray-200 flex-shrink-0 inline-block h-2 w-2 rounded-full"></span>
                <span v-if="data.productToAttach" class="ml-3 block truncate">{{ data.productToAttach.title }}</span>
                <span v-else class="ml-3 block truncate">{{ $t("marketing.select_product") }}</span>
              </div>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <!-- Heroicon name: solid/selector -->
              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </span>
            </button>

            <!--
              Select popover, show/hide based on select state.

              Entering: ""
                From: ""
                To: ""
              Leaving: "transition ease-in duration-100"
                From: "opacity-100"
                To: "opacity-0"
            -->
            <ul v-show="select.show" class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
              <!--
                Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

                Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
              -->
              <li v-for="product in data.productsToAttach" v-bind:key="product.uuid" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option">
                <div class="flex items-center" v-on:click="attachProduct(product)">
                  <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                  <span :class="{'bg-green-400' : product.status == 'publish'}" class="bg-gray-200 flex-shrink-0 inline-block h-2 w-2 rounded-full" aria-hidden="true" :title="product.status == 'publish' ? 'Publicado' : 'No publicado'"></span>
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="font-normal ml-3 block truncate">
                    {{ product.title }}
            <span class="sr-only"> is online</span>
          </span>
                </div>

                <!--
                  Checkmark, only display for selected option.

                  Highlighted: "text-white", Not Highlighted: "text-indigo-600"
                -->
                <span class="hidden text-bpbluedeep absolute inset-y-0 right-0 flex items-center pr-4">
          <!-- Heroicon name: solid/check -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
          </svg>
        </span>
              </li>

              <!-- More items... -->
            </ul>
          </div>
        </div>

      </div>
      <!-- Replace with your content -->
      <div class="py-4">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full" :key="data.attached_products">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{$t('marketing.project_name')}}
                  </th>
                  <th class="hidden px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{$t('general.teachers')}}
                  </th>
                  <th class="hidden px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    # {{$t('general.students')}}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="product in data.products" :key="product.uuid" class="hover:bg-gray-50 transition-colors ease-in">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-14">
                        <img v-if="product.thumbnail" class="h-14 rounded" :src="product.thumbnail" alt="">

                        <div v-else class="h-14 w-28 bg-gray-400 rounded"></div>
                      </div>
                      <div class="ml-4">
                        <div class="text-sm leading-5 font-medium text-gray-900">{{product.title}}</div>
                        <div class="text-sm leading-5 text-gray-500">{{$t('marketing.update_at')}} {{$moment(product.updated_at).format("LLLL")}}</div>
                      </div>
                    </div>
                  </td>
                  <td class="hidden px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex overflow-hidden hidden">
                      <img class="inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                      <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                      <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80" alt="">
                      <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                    </div>
                    <span class="text-sm leading-5 text-gray-500">{{$t('general.coming_soon')}}</span>
                  </td>
                  <td class="hidden px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{$t('general.coming_soon')}}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <div class="flex items-center justify-end">
                      <button title="Dar de baja" v-on:click="detachFromCoupon(product.uuid)" :disabled="loadingLMS" class="group mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" :aria-label="$t('marketing.to_cancel')">
                        <svg v-if="!loadingLMS" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="group-hover:text-red-600 transition-colors" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        <font-awesome-icon v-else class="h-7 w-7 text-gray-400 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin" icon="spinner" size="lg" />
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <PaginationComponent class="hidden"></PaginationComponent>
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import PaginationComponent from "@/views/common/PaginationComponent";
import CouponService from "@/services/marketing/CouponService";
import productService from "@/services/product/ProductService";

export default {
  name: "UserEnrolListComponent",
  components: {PaginationComponent},
  props: {
    parentLoading: {
      type: Boolean,
      default: true
    },
    products: null
  },
  data: () => {
    return {
      loading: false,
      loadingLMS: false,
      data: {
        products: [],
        productsToAttach: [],
        productToAttach: null,
        attached_products: 0,
      },
      select: {
        show: false
      }
    }
  },
  created() {
    console.log("Se ha creado el listado de cursos para el cupon", this.$props.products)
    this.data.products = this.$props.products
    this.data.attached_products = this.$props.products.length

    productService.index()
        .then((response) => {
          console.log("Productos", response.data)
          this.productsToAttach = response.data
          this.loading = false
        })

  },
  methods: {
    attachProduct(productSelected) {
      console.log("Asociando producto", productSelected)
      this.data.productToAttach = productSelected
      let coupon_uuid = this.$route.params.id;
      console.log("El cupon seleccionado", coupon_uuid)
      CouponService.attachProduct(coupon_uuid, productSelected.uuid)
          .then((response) => {
            console.log(response)
            if(response.data.coupon)
            {
              this.data.products = response.data.coupon.products
              this.data.attached_products = response.data.coupon.products.length
            }
          })
          .finally(() => {
              this.data.productToAttach = null;
          })
    },
    detachFromCoupon(product_uuid) {
        let coupon_uuid = this.$route.params.id;
        CouponService.detachProduct(coupon_uuid, product_uuid)
            .then((response) => {
              console.log(response)
              if(response.data.coupon)
              {
                this.data.products = response.data.coupon.products
                this.data.attached_products = response.data.coupon.products.length
              }
            })
            .finally(() => {

            })
    },

  }
}
</script>

<style scoped>

</style>