<template>
  <card-with-header-and-footer-component class="bg-white" :loading="bank.loading">
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('profile.payments_receipt') }}</h3>
    </template>

    <alert-component>
      {{ $t('profile.info_billing_msg') }}<br>
      {{ $t('profile.info_billing_send') }}<a class="font-bold" href="mailto:billing@brainspro.com">billing@brainspro.com</a>{{ $t('profile.info_billing_dni_msg') }}
    </alert-component>

    <div class="my-4">
      <label for="user-bank-account-owner" class="block text-sm font-medium text-gray-700">{{ $t('profile.beneficiary') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input type="text" name="user-bank-account-owner" id="user-bank-account-owner" v-model="bank.request.owner" class="focus:ring-bpblue focus:border-bpblue block w-full pr-12 sm:text-sm border-gray-300 rounded-md" :placeholder="$t('profile.enter_beneficiary')" aria-describedby="price-currency">
      </div>
    </div>

    <div class="my-4">
      <label for="user-bank-account-bic" class="block text-sm font-medium text-gray-700">{{ $t('profile.bic') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input type="text" name="user-bank-account-owner" id="user-bank-account-bic" v-model="bank.request.bic" class="focus:ring-bpblue focus:border-bpblue block w-full pr-12 sm:text-sm border-gray-300 rounded-md" :placeholder="$t('profile.enter_bic')" aria-describedby="price-currency">
      </div>
    </div>

    <div class="my-4">
      <label for="user-bank-account-number" class="block text-sm font-medium text-gray-700">{{ $t('profile.account_number') }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input type="text" name="user-bank-account-owner" id="user-bank-account-number" v-model="bank.request.account" class="focus:ring-bpblue focus:border-bpblue block w-full pr-12 sm:text-sm border-gray-300 rounded-md" :placeholder="$t('profile.enter_account_number')" aria-describedby="price-currency">
      </div>
    </div>

    <template v-slot:footer>
      <button type="button" v-on:click="getBankAccount" :disabled="bank.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.cancel') }}
      </button>

      <button type="button" v-on:click="setBankAccount" :disabled="bank.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.save') }}
      </button>
    </template>
  </card-with-header-and-footer-component>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import BankAccountService from "@/services/user/BankService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";
import AlertComponent from "@/components/feedback/alerts/AlertComponent";

export default {
name: "BankAccountComponent",
components: {CardWithHeaderAndFooterComponent, AlertComponent},
data() {
  return {
    bank: {
      loading: true,
      request: {
        owner: '',
        bic: '',
        account: ''
      }
    }
  }
},
setup(){

  const authUserStore = useAuthUserStore();
  const academiesStore = useAcademiesStore()

  return {
    authUserStore,
    academiesStore
  }
},
created() {
  this.getBankAccount()
},
methods: {
  getBankAccount() {
    let academy_uuid = this.authUserStore.user.academy.uuid;
    this.bank.loading = true;

    BankAccountService.index(academy_uuid)
        .then((response) => {
          console.log("Se ha obtenido el número de cuenta correctamente", response)
          this.bank.request = response.data
        })
        .catch((response) => {
          console.log("Error obteniendo el número de cuenta", response)
          NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
        })
        .finally(() => {
          this.bank.loading = false
        })
  },
  setBankAccount() {
    let academy_uuid = this.authUserStore.user.academy.uuid;
    this.bank.loading = true;

    BankAccountService.update(academy_uuid, this.bank.request)
        .then((response) => {
          console.log("Se ha almacenado el número de cuenta correctamente", response)
          NotificationService.showNotification("Se ha almacenado el número de cuenta correctamente", "success")
          this.academiesStore.getAcademy()
        })
        .catch((error) => {
          console.log("Error almacenando el número de cuenta", error.response)
          if (error.response.data.errors) {
            error.response.data.errors.forEach((error) => {
              NotificationService.showNotification(error, "error")
            })
          } else {
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          }
        })
        .finally(() => {
          this.bank.loading = false
        })
  }
}

}
</script>

<style scoped>

</style>