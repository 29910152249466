<template>
    <div class="pt-2 pb-6 md:py-6">

      <div class="max-w-7xl mx-auto px-4 sm:px-4 md:px-8">
        <div v-show="$route.name !== 'projects-edit-content'" class="py-4">
          <nav class="py-4">
            <div class="">
              <div class="">
                <nav class="flex flex-col lg:flex-row justify-start" aria-label="Tabs">
                  <router-link :to="{ name: 'video-edit-general', params: { id : $route.params.id }}" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="lg:mr-4 text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                    <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <span>General</span>
                  </router-link>
                </nav>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <section class="mx-auto max-w-7xl">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <router-view class="md:col-span-2"></router-view>
        </div>
      </section>
    </div>
</template>

<script>
export default {
  name: "VideoEditComponent"
}
</script>

<style scoped>

</style>