<template>

  <section class="">
    <product-list-component></product-list-component>
  </section>

</template>

<script>
import ProductListComponent from "@/views/projects/ProjectEdit/AttachProduct/AttachProductListComponent";

export default {
  name: "ProjectAttachProductComponent",
  components: {ProductListComponent},
  data: () => {
    return {

    }
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style scoped>

</style>
