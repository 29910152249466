<template>
  <div class="max-w-7xl mx-auto py-2 md:py-6 sm:px-6 lg:px-4">
    <section class="p-4">
      <card-with-header-and-footer-component :loading="description.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.description") }}</h3>
        </template>

        <vue-editor v-model="description.request.description"></vue-editor>

        <template v-slot:footer>
          <button type="button" v-on:click="getAcademyDescription" :disabled="description.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setAcademyDescription" :disabled="description.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>

    <section class="p-4 mt-6">
      <card-with-header-and-footer-component :loading="rrss.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.web_rrss") }}</h3>
        </template>

      <div class="grid grid-cols-2 gap-6">
        <div class="col-span-1">
          <label for="website_url" class="block text-sm font-medium text-gray-700">{{ $t("customization.web_blog") }}</label>
          <div class="mt-1">
            <input type="text" v-model="rrss.request.website_url" id="website_url" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
          </div>
        </div>
        <div class="col-span-1">
          <label for="public_phone" class="block text-sm font-medium text-gray-700">{{ $t("general.phone") }}</label>
          <div class="mt-1">
            <input type="text" v-model="rrss.request.phone" id="public_phone" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
          </div>
        </div>
        <div class="col-span-1">
          <label for="public_email" class="block text-sm font-medium text-gray-700">{{ $t("general.email") }}</label>
          <div class="mt-1">
            <input type="text" v-model="rrss.request.email" id="public_email" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
          </div>
        </div>
        <div class="col-span-1">
          <label for="facebook_url" class="block text-sm font-medium text-gray-700">{{ $t("customization.facebook") }}</label>
          <div class="mt-1">
            <input type="text" v-model="rrss.request.facebook_url" id="facebook_url" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
          </div>
        </div>
        <div class="col-span-1">
          <label for="twitter_url" class="block text-sm font-medium text-gray-700">{{ $t("customization.twitter") }}</label>
          <div class="mt-1">
            <input type="text" v-model="rrss.request.twitter_url" id="twitter_url" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
          </div>
        </div>
        <div class="col-span-1">
          <label for="instagram_url" class="block text-sm font-medium text-gray-700">{{ $t("customization.instagram") }}</label>
          <div class="mt-1">
            <input type="text" v-model="rrss.request.instagram_url" id="instagram_url" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
          </div>
        </div>
        <div class="col-span-1">
          <label for="linkedin_url" class="block text-sm font-medium text-gray-700">{{ $t("customization.linkedin") }}</label>
          <div class="mt-1">
            <input type="text" v-model="rrss.request.linkedin_url" id="linkedin_url" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
          </div>
        </div>
        <div class="col-span-1">
          <label for="youtube_url" class="block text-sm font-medium text-gray-700">{{ $t("customization.youtube") }}</label>
          <div class="mt-1">
            <input type="text" v-model="rrss.request.youtube_url" id="youtube_url" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
          </div>
        </div>
      </div>

        <template v-slot:footer>
          <button type="button" v-on:click="getAcademyRRSS" :disabled="rrss.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setAcademyRRSS" :disabled="rrss.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>

    <section class="p-4 mt-6">
      <contact-html-component></contact-html-component>
    </section>

    <section class="p-4 mt-6">
      <card-with-header-and-footer-component :loading="address.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.location") }}</h3>
        </template>

        <div class="grid grid-cols-4 gap-6">
          <div class="col-span-4">
            <label for="address_line_1" class="block text-sm font-medium text-gray-700">{{ $t("customization.address_line_1") }}</label>
            <div class="mt-1">
              <input type="text" v-model="address.request.address_line_1" id="address_line_1" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
            </div>
          </div>
          <div class="col-span-4">
            <label for="address_line_2" class="block text-sm font-medium text-gray-700">{{ $t("customization.address_line_2") }}</label>
            <div class="mt-1">
              <input type="text" v-model="address.request.address_line_2" id="address_line_2" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
            </div>
          </div>
          <div class="col-span-2">
            <label for="address_postal_code" class="block text-sm font-medium text-gray-700">{{ $t("customization.postalcode") }}</label>
            <div class="mt-1">
              <input type="text" v-model="address.request.postal_code" id="address_postal_code" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
            </div>
          </div>
          <div class="col-span-2">
            <label for="address_city" class="block text-sm font-medium text-gray-700">{{ $t("customization.city") }}</label>
            <div class="mt-1">
              <input type="text" v-model="address.request.city" id="address_city" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
            </div>
          </div>
          <div class="col-span-2">
            <label for="address_state" class="block text-sm font-medium text-gray-700">{{ $t("customization.province") }}</label>
            <div class="mt-1">
              <input type="text" v-model="address.request.state" id="address_state" class="shadow-sm focus:ring-bpblue focus:border-ibpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
            </div>
          </div>
          <div class="col-span-2">
            <label for="address_country" class="block text-sm font-medium text-gray-700">{{ $t("customization.country") }}</label>
            <div class="mt-1">
              <input type="text" v-model="address.request.country" id="address_country" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
            </div>
          </div>
          <div class="col-span-2">
            <label for="fiscal_fullname" class="block text-sm font-medium text-gray-700">{{ $t("customization.company_name") }}</label>
            <div class="mt-1">
              <input type="text" v-model="address.request.fiscal_fullname" id="fiscal_fullname" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
            </div>
          </div>
          <div class="col-span-2">
            <label for="vat_id" class="block text-sm font-medium text-gray-700">{{ $t("customization.nif_cif") }}</label>
            <div class="mt-1">
              <input type="text" v-model="address.request.vat_id" id="vat_id" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-description">
            </div>
          </div>
        </div>

        <template v-slot:footer>
          <button type="button" v-on:click="getAcademyContact" :disabled="address.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setAcademyContact" :disabled="address.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>

  </div>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import { VueEditor } from "vue3-editor";
import RRSSService from "@/services/academy/RRSSService";
import DescriptionService from "@/services/academy/DescriptionService";
import ContactService from "@/services/academy/ContactService";
import ContactHtmlComponent from "@/views/customization/contact/ContactHtmlComponent";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "ContactComponent",
  components: {
    ContactHtmlComponent,
    CardWithHeaderAndFooterComponent,
    VueEditor
  },
  data() {
    return {
      description: {
        loading: true,
        request: {
          description: ''
        }
      },
      rrss: {
        loading: true,
        request: {
          website_url: '',
          phone: '',
          email: '',
          facebook_url: '',
          twitter_url: '',
          instagram_url: '',
          linkedin_url: '',
          youtube_url: ''
        }
      },
      address: {
        loading: false,
        request: {
          address_line_1: '',
          address_line_2: '',
          postal_code: '',
          city: '',
          state: '',
          country: '',
          fiscal_fullname: '',
          vat_id: ''
        }
      },
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  },
  mounted() {
    this.getAcademyDescription();
    this.getAcademyRRSS();
    this.getAcademyContact();
    console.log("Listado de componentes", this.components)
  },
  methods: {
    getAcademyDescription() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.description.loading = true;

      DescriptionService.index(academy_uuid)
          .then((response) => {
            console.log("Se ha almacenado la descripción correctamente", response)
            this.description.request.description = response.data.description
          })
          .catch((response) => {
            console.log("Error obteniendo la descripción", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.description.loading = false
          })
    },
    setAcademyDescription() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.description.loading = true;

      DescriptionService.update(academy_uuid, this.description.request)
          .then((response) => {
            console.log("Se ha almacenado la descripción correctamente", response)
            NotificationService.showNotification("Se ha almacenado la descripción correctamente", "success")
          })
          .catch((error) => {
            console.log("Error guardando la descripción", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.description.loading = false
          })
    },
    getAcademyRRSS() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.rrss.loading = true;

      RRSSService.index(academy_uuid)
          .then((response) => {
            console.log("Se han obtenido las rrss correctamente", response)
            this.rrss.request = response.data.rrss
          })
          .catch((response) => {
            console.log("Error obteniendo las rrss", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.rrss.loading = false
          })
    },
    setAcademyRRSS() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.rrss.loading = true;

      RRSSService.update(academy_uuid, this.rrss.request)
          .then((response) => {
            console.log("Se han almacenado las RRSS correctamente", response)
            NotificationService.showNotification("Se ha almacenado la petición correctamente", "success")
          })
          .catch((error) => {
            console.log("Error guardando las RRSS", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.rrss.loading = false
          })
    },
    getAcademyContact() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.address.loading = true;

      ContactService.index(academy_uuid)
          .then((response) => {
            console.log("Se han obtenido los datos de contacto correctamente", response)
            this.address.request = response.data.address
          })
          .catch((response) => {
            console.log("Error obteniendo la dirección", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.address.loading = false
          })
    },
    setAcademyContact() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.address.loading = true;

      ContactService.update(academy_uuid, this.address.request)
          .then((response) => {
            console.log("Se han obtenido los datos de contacto correctamente", response)
            NotificationService.showNotification("Se ha almacenado la petición correctamente", "success")
          })
          .catch((error) => {
            console.log("Error guardando las RRSS", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.address.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>