<template>
  <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
    <div class="px-4 py-5 sm:px-6">
      <!-- Content goes here -->
      <!-- We use less vertical padding on card headers on desktop than on body sections -->
      <slot name="header"></slot>
    </div>
    <div class="relative px-4 py-5 sm:p-6">
      <div v-if="blocked" class="flex items-center justify-center flex-col bg-red-100 bg-opacity-95 blur-3xl absolute top-0 left-0 right-0 bottom-0 z-50">
        <font-awesome-icon class="my-1 h-10 w-10 text-3xl text-red-900 group-hover:text-gray-600 group-focus:text-gray-600" icon="ban" size="lg" />
        <div class="text-red-900">
          <div>{{$t('panels.text_plan1')}} <router-link to="/settings" class="underline font-extrabold cursor-pointer">{{$t('panels.text_plan2')}}</router-link>.</div>
        </div>
      </div>
      <div v-if="loading" class="flex items-center justify-center flex-col bg-blue-50 bg-opacity-95 absolute top-0 left-0 right-0 bottom-0 z-50">
        <font-awesome-icon class="my-1 h-10 w-10 text-3xl text-blue-400 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin" icon="spinner" size="lg" />
        <div class="text-blue-600">
          <div>{{$t('panels.loading_content')}}</div>
        </div>
      </div>
      <!-- Content goes here -->
      <slot></slot>
    </div>
    <div class="px-4 py-4 sm:px-6 text-right">
      <!-- Content goes here -->
      <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
      <slot name="footer" v-if="! blocked"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWithHeaderAndFooterComponent",
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    blocked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>