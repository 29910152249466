import {useAuthUserStore} from "@/stores/auth";
export default {
    index(academyUuid)
    {
        return useAuthUserStore().getApiClient.get('/academy/language', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },
    update(data)
    {
        return useAuthUserStore().getApiClient.put('/academy/language', data)
    },

}