<template>
  <div v-show="productsStore.newProjectModal.isShow" class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="pacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-on:click="productsStore.toggleModal" class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button type="button" v-on:click="productsStore.toggleModal" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
              <span class="sr-only">{{$t('general.close')}}</span>
              <!-- Heroicon name: x -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div>
            <div class="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-blue-100">
              <!-- Heroicon name: academic-cap -->
              <svg class="h-12 w-12 text-bpblue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                <path fill="#fff" d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
              </svg>
            </div>
            <div class="mt-5 sm:mt-5">
              <h3 class="text-xl leading-6 font-medium text-gray-900" id="modal-headline">
                {{ $t('dashboard.create_your_project') }}
              </h3>
              <div class="mt-5">
                <div>
                  <label for="title" class="block text-sm font-medium text-gray-700">{{ $t('general.title') }}</label>
                  <div class="mt-1">
                    <input type="text" name="title" v-model="request.title" required id="title" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Mi curso en Brainspro" aria-describedby="email-description">
                  </div>
                  <p class="mt-2 text-sm text-gray-500" id="email-description">{{ $t('dashboard.text_describe') }}</p>
                </div>

                <div class="mt-3 hidden">
                  <label for="category" class="block text-sm font-medium text-gray-700">{{ $t('general.category') }}</label>
                  <select id="category" name="category" v-model="request.category" required class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-bpblue focus:border-bpblue sm:text-sm rounded-md">
                    <option value="">{{ $t('products.select_category') }}</option>
                    <option value="1">{{ $t('products.ebook') }}</option>
                    <option value="44">{{ $t('products.online_project') }}</option>
                    <option value="2">{{ $t('products.blended_learning') }}</option>
                    <option value="3">{{ $t('products.online_program') }}</option>
                    <option value="4">{{ $t('products.webinar_consulting') }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button type="button" v-on:click="createProduct()" :disabled="this.loading" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-bpblue text-base font-medium text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue sm:col-start-2 sm:text-sm">
              {{ $t('dashboard.bttn_create') }}
            </button>
            <button type="button" v-on:click="productsStore.toggleModal" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue sm:mt-0 sm:col-start-1 sm:text-sm">
              {{ $t('dashboard.bttn_close') }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ProductService from "@/services/product/ProductService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import {useProductsStore} from "@/stores/products";

export default {
  name: "NewProjectModalComponent",
  data() {
    return {
      loading: false,
      request: {
        title: "",
        category: "44"
      }
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();
    const productsStore = useProductsStore()

    return {
      authUserStore,
      productsStore
    }
  },
  methods: {
    createProduct() {
      this.loading = true;
      let academy_uuid = this.authUserStore.user.academy.uuid;
      ProductService.post(academy_uuid, this.request)
        .then((result) => {
          console.log("Resultado creación de curso", result)
          NotificationService.showNotification("El curso se ha almacenado correctamente", "success")

          this.$router.push({ name: 'products-edit', params: { id : result.data.uuid }})
          this.productsStore.toggleModal()
        })
        .catch((error) => {
          console.log("Error en la creación del curso", error)
          NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
      })
      .finally(() => {
        this.loading =  false;
      })
    }
  }
}
</script>

<style scoped>

</style>