<template>
  <div class="pt-2 pb-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <card-with-header-and-footer-component :loading="this.loading">
      <template v-slot:header>
        <div class="flex flex-row">
          <img src="/images/icons/clientify-logo.png" class="mr-2 w-7 h-7 -mb-1 -mt-1">
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{$t('marketing.clientify')}}</h3>
        </div>
      </template>

      <div class="mb-5">
        <alert-component>
          {{$t('marketing.clientify_info_text')}} <br><a href="https://clientify.com/" target="_blank" class="font-bold">Para más información visita su página web</a>
        </alert-component>
      </div>


      <div class="mt-1 relative rounded-md shadow-sm">
        <label for="product_title" class="block text-sm font-medium leading-5 text-gray-700">API Key</label>
        <input v-bind:type="showPassClientify" v-model="this.clientifyApiKey" id="passwordClientify" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        <!-- Icono para mostrar contraseña o no -->
        <div @click="this.viewPassClientify = !this.viewPassClientify" class="absolute inset-y-0 right-0 mt-4 pr-3 flex items-center text-gray-400">
          <!-- Heroicon name: solid/question-mark-circle -->
          <svg v-if="!viewPassClientify" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
          </svg>
        </div>
        <div @click="this.viewPassClientify = !this.viewPassClientify" class="absolute inset-y-0 right-0 mt-4 pr-3 flex items-center text-gray-400">
          <svg v-if="viewPassClientify" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
        </div>

        <!-- fin de icono ojo -->
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="deleteClientify" :disabled="loading"
                class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="saveClientify" :disabled="loading"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </div>

  <div class="pt-2 pb-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <card-with-header-and-footer-component :loading="this.loading">
      <template v-slot:header>
        <div class="flex flex-row">
          <img src="/images/icons/whatsapp-logo.png" class="mr-2 w-7 h-7 -mb-1 -mt-1">
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{$t('marketing.whatsapp')}}</h3>
        </div>
      </template>

      <div class="mt-1 relative rounded-md shadow-sm">
        <label for="product_title" class="block text-sm font-medium leading-5 text-gray-700">{{$t('marketing.telephone_number')}}</label>
        <input v-model="this.telephone" type="tel" id="telephone" placeholder="+34 611 151 151" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        <!-- fin de icono ojo -->
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="deleteTelephone" :disabled="loading"
                class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="saveWhatsapp" :disabled="loading"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </div>

  <div class="hidden pt-2 pb-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <card-with-header-and-footer-component :loading="this.loading">
      <template v-slot:header>
        <div class="flex flex-row">
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">MailChimp</h3>
          <img src="/images/icons/whatsapp-logo.png" class="ml-2 w-8 h-8 -mt-1.5 -mb-1.5">
        </div>
      </template>

      <div class="mt-1 relative rounded-md shadow-sm">
        <label for="product_title" class="block text-sm font-medium leading-5 text-gray-700">{{$t('marketing.telephone_number')}}</label>
        <input v-model="this.telephone" type="tel" id="telephone" placeholder="+34 611 151 151" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        <!-- fin de icono ojo -->
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="deleteTelephone" :disabled="loading"
                class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="saveWhatsapp" :disabled="loading"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </div>

  <div class="pt-2 pb-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <card-with-header-and-footer-component :loading="this.loading">
      <template v-slot:header>
        <div class="flex flex-row">
          <img src="/images/icons/meta-logo.png" class="-ml-0.5 mr-1 w-9 h-6 -mb-1 -mt-0.5">
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">Verificación de dominio de Meta (Facebook)</h3>
        </div>
      </template>

      <div class="mt-1 relative rounded-md shadow-sm">
        <label for="product_title" class="block text-sm font-medium leading-5 text-gray-700">API Key</label>
        <input v-bind:type="showPassPixelG" v-model="this.pixelGApiKey" id="passwordGP" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        <!-- Icono para mostrar contraseña o no -->
        <div @click="this.viewPasspixelG = !this.viewPasspixelG" class="absolute inset-y-0 right-0 mt-4 pr-3 flex items-center text-gray-400">
          <!-- Heroicon name: solid/question-mark-circle -->
          <svg v-if="!viewPasspixelG" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
          </svg>
        </div>
        <div @click="this.viewPasspixelG = !this.viewPasspixelG" class="absolute inset-y-0 right-0 mt-4 pr-3 flex items-center text-gray-400">
          <svg v-if="viewPasspixelG" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
        </div>
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="deletePixelG" :disabled="loading"
                class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="savePixelG" :disabled="loading"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </div>

  <div class="hidden pt-2 pb-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <card-with-header-and-footer-component :loading="this.loading">
      <template v-slot:header>
        <div class="flex flex-row">
          <img src="/images/icons/" class="mr-2 w-7 h-7 -mb-1 -mt-1">
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">Facebook Pixel</h3>
        </div>
      </template>

      <div class="mt-1 relative rounded-md shadow-sm">
        <label for="product_title" class="block text-sm font-medium leading-5 text-gray-700">API Key</label>
        <input v-bind:type="showPassPixelG" v-model="this.pixelGApiKey" id="passwordGP" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        <!-- Icono para mostrar contraseña o no -->
        <div @click="this.viewPasspixelG = !this.viewPasspixelG" class="absolute inset-y-0 right-0 mt-4 pr-3 flex items-center text-gray-400">
          <!-- Heroicon name: solid/question-mark-circle -->
          <svg v-if="!viewPasspixelG" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
          </svg>
        </div>
        <div @click="this.viewPasspixelG = !this.viewPasspixelG" class="absolute inset-y-0 right-0 mt-4 pr-3 flex items-center text-gray-400">
          <svg v-if="viewPasspixelG" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
        </div>
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="deletePixelG" :disabled="loading"
                class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="savePixelG" :disabled="loading"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </div>

</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import IntegrationService from "@/services/marketing/IntegrationService";
import {useAuthUserStore} from "@/stores/auth";
import AlertComponent from "@/components/feedback/alerts/AlertComponent";

export default {
  name: "IntegrationsComponent",
  components: {CardWithHeaderAndFooterComponent, AlertComponent},
  data() {
    return {
      loading: false,
      clientifyApiKey: '',
      viewPassClientify: false,
      telephone: '',
      pixelGApiKey: '',
      viewPasspixelG: false,
    }
  },
  setup(){
    const authUserStore = useAuthUserStore();

    return {
      authUserStore,
    }
  },
  created() {
    this.getClientify()
    this.getWhatsapp()
  },
  methods: {
    getClientify() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      IntegrationService.indexClientify(academy_uuid)
          .then((response) => {
            console.log("Obteniendo clientify", response)
            this.clientifyApiKey = response.data.value
          })
          .catch((error) => {
            console.log("Error al obtener clientify: ", error)
          })
          .finally(() => {

          })
    },
    getWhatsapp() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      IntegrationService.indexWhatsapp(academy_uuid)
          .then((response) => {
            console.log("Obteniendo whatsapp", response)
            this.telephone = response.data.value
          })
          .catch((error) => {
            console.log("Error al obtener whatsapp: ", error)
          })
          .finally(() => {

          })
    },
    deleteClientify() {
      this.clientifyApiKey = ''
      this.script = ''
    },
    saveClientify() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      IntegrationService.storeClientify(this.clientifyApiKey, academy_uuid)
          .then((response) => {
            console.log("Respuesta al guardar Clientify: ",response)
          })
          .catch((error) => {
            console.log("Error al guardar la api key de Clientify: ", error)
          })
    },
    deleteTelephone() {
      this.telephone = ''
    },
    saveWhatsapp() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      IntegrationService.storeWhatsapp(this.telephone, academy_uuid)
          .then((response) => {
            console.log("Respuesta al guardar el número de whatsapp: ", response)
          })
          .catch((error) => {
            console.log("Error al guardar el número de whatsapp: ", error)
          })
    },
    deletePixelG() {
      this.pixelGApiKey = ''
    },
    savePixelG() {

      //let academy_uuid = this.authUserStore.user.academy.uuid;
      console.log("Google Pixel ApiKey: ", this.pixelGApiKey)
    }
  },
  computed: {
    showPassClientify() {
      return (this.viewPassClientify) ? 'text' : 'password'
    },
    showPassPixelG() {
      return (this.viewPasspixelG) ? 'text' : 'password'
    }
  }
}
</script>

<style scoped>

</style>