import {useAuthUserStore} from "@/stores/auth";

export default {
    indexGA(codeGA) {
        return useAuthUserStore().getApiClient.get('/marketing/integration/ga-tag', {
            params: {
                academy_uuid: codeGA.academy_uuid
            }
        })
    },

    showGA() {

    },
    storeGA(codeGA) {
        return useAuthUserStore().getApiClient.post('/marketing/integration/ga-tag', {
            value: codeGA.value,
            academy_uuid: codeGA.academy_uuid
        })
    },
    deleteGA() {
        //llamar a endpoint de borrado
    },
    indexHTMLHead(codeHTML) {
        return useAuthUserStore().getApiClient.get('/marketing/integration/custom-global-html', {
            params: {
                type: codeHTML.type,
                academy_uuid: codeHTML.academy_uuid
            }
        })
    },
    indexHTMLFooter(codeHTML) {
        return useAuthUserStore().getApiClient.get('/marketing/integration/custom-global-html', {
            params: {
                type: codeHTML.type,
                academy_uuid: codeHTML.academy_uuid
            }
        })
    },
    showHTML() {

    },
    storeHTML(codeHTML) {
        return useAuthUserStore().getApiClient.post('/marketing/integration/custom-global-html', {
            value: codeHTML.value,
            type: codeHTML.type,
            academy_uuid: codeHTML.academy_uuid
        })
    },
    deleteHTML() {

    },

    //Clientify
    indexClientify(academy_uuid) {
        return useAuthUserStore().getApiClient.get('/marketing/integration/crm/clientify', {
            params: {
                academy_uuid: academy_uuid
            }
        })
    },
    storeClientify(apiKey, academy_uuid) {
        return useAuthUserStore().getApiClient.post('/marketing/integration/crm/clientify', {
            value: apiKey,
            academy_uuid: academy_uuid
        })
    },

    //Whatsapp
    indexWhatsapp(academy_uuid) {
        return useAuthUserStore().getApiClient.get('/marketing/integration/whatsapp', {
            params: {
                academy_uuid: academy_uuid
            }
        })
    },
    storeWhatsapp(phone, academy_uuid) {
        return useAuthUserStore().getApiClient.post('/marketing/integration/whatsapp', {
            value: phone,
            academy_uuid: academy_uuid
        })
    },
}