<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  name: "GroupsComponent"
}
</script>

<style scoped>

</style>