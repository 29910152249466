<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm">
        <div class="text-center">
          <img class="h-12 w-auto mx-auto" src="/images/logo-bp.png" alt="Brainspro">
          <h1 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
            {{ $t('login.recovery_password') }}
          </h1>
        </div>
        <div class="mt-6">
          <form @submit.prevent="sendEmail">
            <div class="mt-6" v-if="!request.send">
              <label class="block text-sm font-medium text-gray-700">{{ $t('general.email_address') }}</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input type="email" v-model="v$.request.email.$model" id="password_confirmation"
                       required
                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                       :placeholder="$t('login.email_input_placeholder')">
                <div v-if="v$.request.email.$error"
                     class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500">
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg class="h-5 w-5 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"/>
                  </svg>
                </div>
              </div>
              <p v-if="v$.request.email.$error" class="mt-2 text-sm text-red-600"
                 id="error-password_confirmation"> {{ $t('login.error_email_invalid') }} </p>
            </div>
            <div>
              <span v-if="!request.send" class="mt-4 block text-center text-sm text-gray-400">
                {{ $t('login.information_email_send') }}
              </span>
              <div v-if="request.send"
                   class="flex items-center mt-7 p-2 text-sm text-gray-500 bg-yellow-100 rounded-md shadow-md ">
                <div class="m-3">
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                       aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                  </svg>
                </div>
                <span class="ml-2">
                  {{ $t('login.information_go_email_inbox') }}
                </span>
              </div>

            </div>
            <div class="mt-6">
              <span class="block w-full rounded-md shadow-sm">
                <button type="submit"
                        :disabled="isLoading"
                        class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                        v-if="!request.send">
                  {{ $t('login.button_text') }}
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationService from "@/services/notification/NotificationService";
import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "RememberComponent",
  data() {
    return {
      request: {
        email: '',
      },
      send: false,
      isLoading: false,
    }
  },
  setup () {

    const authUserStore = useAuthUserStore();

    return {
      authUserStore ,
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      request: {
        email: {required, email}
      }
    }
  },
  methods: {
    sendEmail () {
      this.isLoading=true
      console.log("El email del usuario es:", this.request.email)
      //Se envía a la api
      this.authUserStore.rememberPassword(this.request)
          .then(() => {
            //Tras éxito mostrar mensaje de email enviado a su correo
            NotificationService.showNotification("Email de recuperación enviado.", "success")
            //Envío confirmado
            this.request.send = true
          })
          .catch((error) => {
            console.log("Ha fallado el envío de email...", error.response.data)
            if (error.response.data.message) {
              NotificationService.showNotification(error.response.data.message, "error")
            } else {
              NotificationService.showNotification("Upss! Parece que ha ocurrido un error, si pasados unos minutos el problema persiste. Ponte en contacto con el equipo de soporte", "error")
            }
          })
          .finally((result) => {
            this.isLoading=false
            console.log("Result in finally: ", result)
          })
    },
  }

}
</script>

<style scoped>

</style>