<template>

  <div class="pt-2 pb-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="py-4 hidden md:block">

        <div class="flex flex-col">
        <span class="shadow-sm rounded-md mb-5 flex justify-end">
          <button type="button" v-on:click="exportUsers"
                  class=" inline-flex items-center px-4 py-2 transition ease-in border border-gray-300 text-sm font-medium rounded text-gray-700 bg-white hover:bg-bpblue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            <!-- Heroicon name: document-download -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
            <label class="mx-2">{{ $t('projects.student_list') }}</label>
          </button>
        </span>

          <user-enrol-project-list-component :key="$route.fullPath"></user-enrol-project-list-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import UserEnrolProjectListComponent
  from "@/views/projects/ProjectEdit/UserEnrolProjectList/UserEnrolProjectListComponent";
import userService from "@/services/user/UserService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "UserEnrolProjectComponent",
  components: {UserEnrolProjectListComponent},

  data() {
    return {
      pagination: null,
      loading: false,
    }
  },
  setup(){
    const authUserStore = useAuthUserStore();

    return {authUserStore}
  },

  methods:
  {
    exportUsers()
    {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      let project_uuid = this.$route.params.id
      console.log("El project uuid es: ", project_uuid)

      userService.downloadUserOnProject(academy_uuid, project_uuid)
          .then((response) => {
            console.log("Descargando", response)
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users.csv');
            document.body.appendChild(link)
            link.click()
          })
          .catch((error) => {
            console.log("Error en la descarga de usuarios", error.response)
            if (error.response.data.errors) {
              error.response.data.errors.forEach((error) => {
                NotificationService.showNotification(error, "error")
              })
            } else {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
    },
  }

}
</script>

<style scoped>

</style>