<template>
    <div class="flex flex-col md:flex-row">
      <div v-show="this.academiesStore.getSaasSubscriptionsStatus === 'trialing' && this.remainingDaysInTrialSubscription > -1" class="flex items-center px-4 lg:px-4 pt-4 lg:pt-0" @click="goToCurrentSubscriptions">
        <span
           class="inline-fex items-center px-2.5 py-0.5 rounded lg:text-sm text-sm text-xs font-medium bg-yellow-100 text-yellow-800 hover:bg-green-100 hover:text-green-800 cursor-pointer transition-all">
             {{ $t('general.text_current_period') }} {{ $t('general.text_end_at') }}
        <span class="font-bold underline">{{ this.remainingDaysInTrialSubscription }} {{ $t('trial.days') }}</span>
          {{ $t('trial.count_active') }}
        </span>
      </div>
      <div v-if="this.academiesStore.getSaasSubscriptionsStatus === 'past_due' && this.remainingDaysInTrialSubscription < 0" class="flex items-center px-4" @click="goToCurrentSubscriptions">
        <!-- Se mostrará un mensaje de que ha terminado el período de prueba -->
        <span class="inline-fex items-center px-2.5 py-0.5 rounded lg:text-sm text-xs font-medium bg-red-600 text-white hover:bg-red-700 cursor-pointer transition-all">
          {{ $t('trial.expired') }}
        </span>
      </div>

      <!-- Se mostrará si no está en modo tableta/móvil -->
      <div v-show="this.remainingDaysInFreeSession > 0" class="flex justify-center invisible lg:visible">
        <a href="https://reuniones.clientify.com/#/silvia-montoya/soporte" target="_blank" class="w-auto flex inline-flex justify-center p-1 rounded-md hover:bg-stone-400 hover:text-white bg-stone-100 text-stone-800 my-2 mx-20 items-center shadow-inner cursor-pointer">
          <div class="flex p-1 rounded-lg">
            <!-- Heroicon name: outline/speakerphone -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
            </svg>
          </div>
          <p class="ml-2 text-xs lg:text-sm">
            <span class="">{{ $t('trial.you_have') }}</span><span class="font-bold underline">{{ this.remainingDaysInFreeSession }} {{ $t('trial.days') }}</span>
            {{ $t('trial.take_session') }}<span class="font-bold">{{ $t('trial.take_now') }}</span>
          </p>
        </a>
      </div>
    </div>
</template>

<script>
import {useAcademiesStore} from "@/stores/academies";

export default {
  name: "TrialingComponent",

  setup() {
    const academiesStore = useAcademiesStore()

    return {
      academiesStore
    }
  },
  data: () => {
    return {

    }
  },
  updated() {

  },
  methods: {
    goToCurrentSubscriptions() {
      this.$router.push({ name: 'myaccount.current-subscription' })
    },
  },
  computed:
  {
    remainingDaysInFreeSession()
    {
      if (!this.academiesStore.academy.main_saas_subscription)
      {
        return -1
      }
      if (this.academiesStore.academy.main_saas_subscription && this.academiesStore.academy.main_saas_subscription.status !== 'trialing')
      {
        return -1
      }

      let now = this.$moment();
      let trialEndsAt = this.$moment(this.academiesStore.academy.main_saas_subscription.trial_ends_at)
      let result = trialEndsAt.diff(now, 'days')
      // --------------
      // La suscripción trialing son 14 días, se le restarán 7 días para la sesión gratuita con nuestro equipo de expertos.
      // --------------
      return result - 7
    },
    remainingDaysInTrialSubscription()
    {
      if(!this.academiesStore.academy.main_saas_subscription)
      {
        return -1
      }
      if(!this.academiesStore.academy.main_saas_subscription.trial_ends_at)
      {
        return -1
      }

      let now = this.$moment();
      let ends_at = this.$moment(this.academiesStore.academy.main_saas_subscription.trial_ends_at);
      let result = ends_at.diff(now, 'days')
      if (result < 0) result = 0;
      return result
    },
  }
}
</script>

<style scoped>

</style>