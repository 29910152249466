export default {
    your_users: "Gemeinschaft",
    massive_increase: "Massiver Anstieg",
    new_user: "Neuer Benutzer",
    placeholder_search: "Suchen Sie nach Ihrem Benutzer...",
    all: "Alle",
    project_name: "Name des Kurses",
    updated_at: "Aktualisiert am ",
    view_profile: "Profil ansehen",
    write_email: "Mail schreiben",
    edit_user: "Benutzer bearbeiten",
    call: "aufrufen",
    orders: "Bestellungen",
    dni: "DNI / CIF / NIE",
    search_user: 'Suche nach einem Benutzer...',
    new_project: 'Neuer Kurs',
    delete_user: 'Benutzer löschen',
    warning: 'Warnung!',
    message_delete: 'Durch das Löschen des Benutzers verlieren Sie alle seine Daten dauerhaft.',
    question_delete: 'Sind Sie sicher, dass Sie den Benutzer löschen wollen ',
    delete: 'Löschen',
    second_confirmation: '(*) Dieses Kontrollkästchen ist erforderlich, um den Benutzer zu löschen.',
    loading_users: 'Benutzer laden...',

    //MassUserImportComponent
    beta_version: 'Beta',
    mass_enrollment: "Masseneinschreibung",
    functionality: "Funktionalität für Pläne",
    advance: "Fortgeschrittene",
    pro: "Pro",
    limitation_mass_enrollment: "Begrenzt auf Registrierungen von bis zu 50 Benutzern. Alle anderen werden ignoriert.",
    download_template: "Laden Sie Ihr Anmeldeformular herunter",
    upload_students_list: "Laden Sie Ihre Liste der Schüler hoch",
    drag_drop: "oder hierher ziehen und ablegen",
    format: "XLSX bis zu 3MB",
    enroll_in: "Einschreiben:",
    select_product: "Wählen Sie einen Kurs...",
    upload_users: "Benutzer laden",
    import_users: "Benutzer importieren",
    href_massive_enroll: "Mehr über den Massenimport von Nutzern.",

    //UserEnrollListComponent
    to_cancel: 'Abbestellen',
    no_enrolls: 'Dieser Benutzer ist in keinem Kurs eingeschrieben.',
    modal_title: 'Unregister',
    modal_warning: 'Dieser Prozess kann nicht rückgängig gemacht werden, der Schüler verliert alle Fortschritte und kann sie nicht mehr aufholen.',
    modal_question: 'Sind Sie sicher, dass Sie diesen Benutzer aus dem Kurs abmelden möchten ',
    loading_enrols: 'Einschreibungen laden...',
    processing: 'Verarbeitung...',
    access_denied: 'Zugang zum Kurs verweigert',

    //UserEnrolSlideOverComponent
    select_project: 'Wählen Sie ein Projekt',
    loading_projects: 'Projekte laden...',
    project_selected: 'Ausgewähltes Projekt',
    send_users_notification: 'Sendet dem Benutzer eine Anmeldebestätigung.',
    back: 'Zurück',
    enrol: 'Register',

    //BillingAddressComponent
    tax_data: 'Steuerliche Daten',
    company_name: 'Name des Unternehmens',
    vat_number_id: 'UMSATZSTEUERNUMMER / IDENTIFIKATIONSNUMMER',
    address_line_1: 'Anschrift Zeile 1',
    address_line_2: 'Anschrift Zeile 2',
    zip_code: 'Postleitzahl',
    city: 'Stadt',
    province: 'Provinz',
    country: 'Land',

    //UserProfileComponent
    save_edition: 'Bearbeitung speichern',
    billing_data: 'Umsatzdaten',
    groups: 'Gruppen',
    in_progress: 'In Arbeit',
    completed: 'Abgeschlossen',

    //Groups
    your_groups: 'Ihre Gruppen',
    new_group: 'Neue Gruppe',
    loading_groups: 'Gruppen laden',
    name: 'Name',
    projects: 'Projekte',
    users: 'Benutzer',
    last_update: 'Letzte Aktualisierung',

}