<template>
  <div class="py-4">
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg">
            <div v-for="product in products" :key="product.uuid" class="bg-white border border-gray-200 shadow mb-4 transition-colors ease-in">
              <div class="px-6 py-4 whitespace-no-wrap  border-b border-gray-200">
                <div class="flex items-center">
                  <div class="flex-shrink-0 h-14 relative">
                    <div v-if="product.featured" class="text-yellow-400 absolute -top-3 -left-3 bg-white rounded-full shadow-lg" :title="$t('products.featured')">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>
                    <img v-if="product.thumbnail" class="h-14 rounded" :src="product.thumbnail" alt="">
                    <div v-else class="h-14 w-28 bg-gray-400 rounded text-center">
                      <img src="/images/avatar-transparent.png" class="h-14 inline">
                    </div>
                  </div>
                  <div class="ml-4">
                    <div class="text-sm leading-5 font-medium text-gray-900">{{product.title}}</div>
                    <div class="hidden text-sm leading-5 text-gray-500">{{ $t('products.update_at') }} {{$moment(product.updated_at).format("LLLL")}}</div>
                    <ul class="mt-1">
                      <li class="text-sm leading-5 text-brainspro-800 rounded mr-2 inline bg-brainspro-100 py-1 px-3" v-for="custom_category in product.custom_categories" :key="custom_category.uuid">{{custom_category.title}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="hidden px-6 py-4 whitespace-no-wrap">
                <div class="flex overflow-hidden hidden">
                  <img class="inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                  <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                  <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80" alt="">
                  <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                </div>
                <span class="text-sm leading-5 text-gray-500">{{ $t('general.coming_soon') }}</span>
              </div>
              <div class="px-6 py-4 flex whitespace-no-wrap">
                <h3 class="w-1/2 text-gray-400">{{ $t('general.published') }}</h3>
                <div class="flex w-1/2 justify-end">
                  <ToggleComponent v-bind:status="(product.status == 'publish')" :callback="changeProductStatus" :product="product.uuid"></ToggleComponent>
                </div>
              </div>
              <div class="hidden px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {{ $t('products.coming_soon') }}
              </div>
              <div class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                <div class="flex items-center justify-center">
                  <router-link :to="{ name: 'products-edit', params: { id : product.uuid }}" class="inline-block mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </router-link>
                  <a title="Preview" :href="'https://' + product.link + '?preview=true'" target="_blank" class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" :aria-label="$t('products.new_project')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                  </a>
                  <button :title="$t('products.classroom')" v-on:click="goToMoodleCourse(product.projects.uuid)" :disabled="loadingLMS" class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" :aria-label="$t('products.new_project')">
                    <svg v-if="!loadingLMS" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                      <path fill="#fff" d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                    </svg>
                    <font-awesome-icon v-else class="h-7 w-7 text-gray-400 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin" icon="spinner" size="lg" />
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productService from "@/services/product/ProductService";
import ProjectService from "@/services/project/ProjectService";
import ToggleComponent from "@/components/forms/ToggleComponent";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "ProjectsListComponent",
  components: {ToggleComponent},
  props: {
    products: Array
  },
  data: () => {
    return {
      loadingLMS: false
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();

    return {
      authUserStore
    }
  },
  methods:{
    goToMoodleCourse(uuid) {
      console.log("Redirección a moodle course")
      this.loadingLMS = true
      ProjectService.getMoodleCourseURL(uuid)
          .then((response) => {
            if(response.data.link)
            {
              console.log("Abriendo el curso " + uuid, response.data.link)

              window.open(response.data.link);
            }
          })
          .finally(() => {
            this.loadingLMS = false
          })
    },
    changeProductStatus(product_uuid, status) {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      console.log("Cambiando el estado del producto", product_uuid, status)
      productService.changeProductStatus(academy_uuid, product_uuid, status)
          .then((response) => {
            console.log("Se ha cambiado  correctamente", response)
            var message = (status) ? "Se ha publicado el curso" : "Se ha despublicado el curso"
            NotificationService.showNotification(message, "success")
          })
          .catch((response) => {
            console.log("Error solicitando el cambio de estado del producto", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            //Silence is golden
          })

    }
  }
}
</script>

<style scoped>

</style>