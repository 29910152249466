<template>
  <div class="page-wrapper">
    <notifications-component></notifications-component>

    <teleport to="body">
      <notification-component></notification-component>
    </teleport>

    <router-view class="page"></router-view>
  </div>
</template>

<script>

import NotificationsComponent from "@/views/common/NotificationsComponent";
import NotificationComponent from "@/components/notifications/NotificationComponent";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: 'App',
  components: {
    NotificationsComponent,
    NotificationComponent
  },
  data() {
    return {};
  },
  setup(){

    const authUserStore = useAuthUserStore();
    return {
      authUserStore
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    if (typeof Android !== "undefined" && typeof Android.getAuthData() !== "undefined")
    {
      // eslint-disable-next-line no-undef
      console.log("Dispositivo Android detectado", Android)
      // eslint-disable-next-line no-undef
      console.log(Android.getAuthData())

      let tokenData = {
        token_type: null,
        expires_in: null,
        access_token: null,
        refresh_token: null
      }

      // eslint-disable-next-line no-undef
      tokenData.access_token = Android.getAuthData();

      this.authUserStore.setTokenData(tokenData);
    }

    const authString = localStorage.getItem('authData')

    if (authString) {
      const authData = JSON.parse(authString)
      this.authUserStore.setTokenData(authData)
    } else {
      console.log("Current route", this.$route.name)
    }
  }
}
</script>

<style src="./assets/tailwind.css"></style>
