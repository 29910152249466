export default {
    hello: "Olá",
    create_a_project: "Criar um curso",
    customize_your_academy: "Personalização",
    check_your_sales: "Verifique os seus rendimentos",
    create_your_project: "Crie o seu curso",
    placeholder_name: "O meu curso em Brainspro",
    text_describe: "O título descreve numa frase o que os seus alunos irão aprender.",
    bttn_close: "Fechar",
    bttn_create: "Criar curso!",
    contacts: 'Mais perguntas? Contacte-nos!',
    client_attention: 'Serviço ao cliente: ',
    mail_client_attention: 'hola@brainspro.com',
    support: 'Soporte técnico: ',
    email_support: 'soporte@brainspro.com',
    phone_whatsapp: 'Telefone e Whatsapp: ',
    phone_number: '+34 626 201 897',
    text_support: 'Marcar uma consulta online com um dos nossos peritos',
    href_intercom: 'Ir para a base de conhecimentos',
    href_custom_domain: 'Mais sobre a configuração de domínios personalizados.',
    no_annual_data: "Não existem dados anuais",
    no_monthly_data: "Sem dados mensais",
    no_students_data: "Não há dados sobre estudantes registados",
    no_projects_data: "Não há dados sobre projectos criados",
}