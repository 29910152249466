export default {
    //AcademyComponent
    customization: 'Personalitza la teva acadèmia',
    domain: "Domini",
    brand_logo: "Logo i Marca",
    rrss_contact: "Contacte i RRSS",
    legal: "Legal",
    options: 'Opcions',

    //DomainComponent
    your_domain: "El teu domini mybrainspro.com",
    example_url_placeholder: 'https://',
    example_2: '.mybrainspro.com',
    info_domain: "Escriu el domini SENSE símbols (.--?!·$%&/()...), ni accents ni espais en blanc. No cal que posis www.",
    domain_input: "Domini mybrainspro.com",
    custom_domain: "Domini personalitzat",
    domain_text: 'Domini',
    info_domain2: "Introdueix el teu domini (www.tudominio.com) per utilitzar-lo com a pàgina principal o com a pàgina secundària (tuescuela.tudominio.com)",
    attention: "Atenció",
    info_attention: "Només disponible per a pla AVANÇAT i pla PRO",
    activate_domain: "Per activar el teu domini a BrainsPro: ",
    info_activate_1line: "Visita la plataforma on vas comprar el teu domini i cerca la configuració de DNS.",
    info_activate_2line: "Afegeix el registre de tipus A a la IP: 76.223.11.227",
    info_activate_3line: "Tingues en compte que poden passar 48 hores fins que aquests canvis es facin efectius i es propaguin al teu DNS. Un cop llest, ja pots començar a utilitzar la nova adreça de la teva acadèmia en línia a BrainsPro.",
    info_activate_4line: "L'equip de brainspro es posarà en contacte amb tu per enviar-te uns registres addicionals que hauràs de col·locar als teus DNS. Amb ells generarem la teva web segura (https://)",
    example_academy: 'acadèmia-d-exemple',
    example_domain: 'mevaacadèmia.com',
    //LanguageSelector
    lang: 'Idioma',
    academy_lang: 'Idioma de l\'Acadèmia',
    academy_lang_selector_text: 'Trieu l\'idioma de la vostra acadèmia',

    //BrandingComponent
    banner: 'Banner',
    brand_academy: "Marca de la teva acadèmia",
    academy_name: "Nom d'Acadèmia",
    full_name: "Trieu el nom complet de la vostra acadèmia",
    tagline: "TagLine d'Acadèmia",
    slogan: "Trieu l'eslògan de la vostra acadèmia",
    search: 'Cerca',
    my_academy: 'La meva Acadèmia a BrainsPro',
    change: 'Canviar',
    insert: 'Inserir',
    href_custom_academy: 'Més sobre personalitzar la teva acadèmia.',

    //ContactComponent
    description: "Descripció",
    web_rrss: "Web i Xarxes Socials",
    web_blog: "Web o Blog",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    linkedin: "Linkedin",
    youtube: "Youtube",
    location: "Ubicació i dades fiscals",
    address_line_1: "Adreça, línia 1",
    address_line_2: "Adreça, línia 2",
    postalcode: "Codi postal",
    city: "Ciutat",
    province: "Província",
    country: "País",
    company_name: "Denominació Social",
    nif_cif: "NIF/CIF",

    //LegalComponent
    privacy_policy: "Política de Privadesa",
    text_register: "Text resum per a registre d'usuari",
    user_view_text: "Aquest text es mostrarà al registre d'usuari, com a resum de la versió més extensa que es mostrarà a la pàgina d'avís legal de la vostra acadèmia.",
    privacy_policy_page: "Pàgina de Política de Privadesa",
    privacy_policy_academy_page: "Pàgina de Política de Privadesa de l'acadèmia",
    legal_notice: "Avís Legal",
    href_legal: 'Més sobre Avís Legal i Política de Privadesa.',

    //Contact
    contact_bloq: 'Bloc de contacte',
    html_bloq: 'Insereix un bloc html per acompanyar la informació de contacte. Com un iframe de google maps.',

    //Branding
    //BannerBrandingComponent
    upload_banner: 'Puja el teu banner',
    image_format: 'PNG, JPG, GIF fins a 3MB',

    //ColorsComponent
    desing_colors: 'Disseny i Colors',
    header_type: 'Tipus de capçalera',
    banner_complete: 'Bàner a mida completa',
    banner_complete_no_text: 'Bàner a mida completa sense text',
    columns_text_image: '2 Columnes, Text + Imatge',
    banner_info: 'Amb això canviaràs el tipus d\'imatge de portada que veuran els usuaris a la teva acadèmia.',
    enterprise_color: 'Color corporatiu',
    text_color: 'Color de text',

    //FaviconComponent
    favicon: 'Favicon',
    upload_favicon: 'Puja el teu favicon',
    favicon_format: 'PNG, JPG, GIF fins a 3MB',

    //LogoBrandingComponent
    logo: 'Logo',
    upload_logo: 'Puja el teu logo',

    //OptionsComponent
    register_config: 'Configuració de Registre',
    info_button_register: "En activar aquest botó es desactivarà l'opció de registrar-vos a l'acadèmia. Per això tots els usuaris hauran de registrar-se des de l'App de Brainspro.",
    restricted_register: 'Restricció de registre',

}