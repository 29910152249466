<template>

  <section v-show="navigationMenuStore.showSlideOverAttachProduct" class="fixed inset-0 overflow-hidden" style="z-index: 2147483002" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="absolute inset-0 overflow-hidden">
      <!-- Background overlay, show/hide based on slide-over state. -->
      <div class="absolute inset-0 bg-gray-600 opacity-50" aria-hidden="true"></div>
      <div class="absolute inset-y-0 pl-16 max-w-full right-0 flex">

        <transition
            name="display-user-creation"
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
        >

          <div class="opacity-100 divide-y divide-gray-200 flex flex-col bg-white shadow-xl rounded-md">
            <!-- Si no se ha seleccionado ningún curso/producto -->
            <div v-if="this.productSelected === null" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
              <div class="flex flex-col overflow-y-auto">
                <div class="flex justify-between py-6 bg-bpblue">
                  <span class="px-4 text-white font-bold text-2xl">{{ $t('projects.attach_product_slide') }}</span>
                  <div class="mt-1 mr-8">
                    <button type="button" v-on:click="closeSlideOver" class=" rounded-md text-brainspro-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>

                <div v-show="loading" class="flex justify-center mx-20 p-4 text-sm">
                  <div class=" text-yellow-700">
                    <svg class="inline mr-3 w-4 h-4 text-blue-700 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                    </svg>
                  </div>
                  <span class="ml-1 text-xl text-gray-600">
                    {{ $t('projects.loading_attached_products') }}
                  </span>
                </div>

                <div class="w-full border-t border-gray-200"></div>
                <div class="overflow-y-auto grow">
                  <div v-for="product in this.products" :key="product.uuid" class="hover:bg-blue-100 transition-colors ease-in">
                    <div v-on:click="getProductSelected(product)"
                         class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 cursor-pointer">
                      <div class="flex items-center ">
                        <div class="flex-shrink-0 h-14">
                          <!--
                          <img v-if="project.products[0].media" class="h-14 rounded"
                               :src="project.products[0].media.thumbnail" alt="">
                          <div v-else class="h-14 w-28 bg-gray-400 rounded"></div>
                          -->
                        </div>
                        <div class="ml-4">
                          <div class="text-sm leading-5 font-medium text-gray-900">{{ product.title }}</div>
                          <div class="text-sm leading-5 text-gray-500">{{ $t('community.updated_at') }}
                            {{ $moment(product.updated_at).format("LLLL") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- si se ha seleccionado un producto -->
            <div v-if="this.productSelected" class="flex flex-col overflow-y-auto">
              <div class="flex justify-between py-6 bg-bpblue">
                <span class="px-4 text-white font-bold text-2xl">{{ $t('projects.selected_product') }}</span>
                <div class="mt-1 mr-8">
                  <button type="button" v-on:click="closeSlideOver" class=" rounded-md text-brainspro-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                    <span class="sr-only">Close panel</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="p-4 text-lg font-bold text-gray-500">
                <span>¿Quieres adjuntar este producto?</span>
              </div>

              <!-- Mostrar el proyecto a adjuntar. -->
              <div class="bg-blue-100 transition-colors ease-in">
                <div class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 cursor-pointer">
                  <div class="flex items-center ">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">{{this.productSelected.title}}</div>
                      <div class="text-sm leading-5 text-gray-500">{{ $t('community.updated_at') }}
                        {{ $moment(this.productSelected.updated_at).format("LLLL") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Botones -->
            <div v-show="this.productSelected" class="flex-shrink-0 px-4 py-8 flex justify-end">
              <button type="button" v-on:click="this.productSelected = null"
                      class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                {{ $t('projects.back') }}
              </button>
              <button type="button" v-on:click="attachProduct(this.productSelected)" :disabled="this.loading"
                      class="inline-flex items-center ml-6 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-blue-400">
                {{ $t('projects.attach_product') }}
              </button>
            </div>
          </div>

        </transition>
      </div>
    </div>
  </section>

</template>

<script>
import {useAuthUserStore} from "@/stores/auth";
import {useProjectsStore} from "@/stores/projects";
import {useProductsStore} from "@/stores/products";
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import projectAttachService from "@/services/project/ProjectAttachProductService";
import productService from "@/services/product/ProductService";

export default {
  name: "AttachProductSlideOverComponent",
  data: () => {
    return {
      loading: true,
      products: null,
      productSelected: null,
      user: {
        academy_uuid: null,
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        phone: null,
        identity_card: null,
        rol: "student",
        product_uuid: null,
        notify: false
      },
    }
  },
  setup(){
    const authUserStore = useAuthUserStore();
    const projectStore = useProjectsStore();
    const navigationMenuStore = useNavigationMenuStore();
    const productStore = useProductsStore();

    return {
      authUserStore,
      projectStore,
      productStore,
      navigationMenuStore
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    //Obtiene la lista de proyectos a adjuntar.
    getProducts() {
      this.loading = true

      productService.index(this.authUserStore.user.academy.uuid)
          .then((response) => {
            this.products = response.data.data
            console.log("Productos obtenidos para asociar: ", response.data)
          })
          .finally(() => {
            this.loading = false
          })
    },
    getProductSelected(product) {
      this.productSelected = product
      console.log("El producto seleccionado es: ", this.productSelected)
    },
    attachProduct(product) {
      this.loading = true
      //Llamar a servicio de attach.
      let project_uuid = this.projectStore.project.uuid
      let product_uuid = product.uuid
      projectAttachService.post(project_uuid, product_uuid)
          .then((response) => {
            console.log("Respuesta de producto asociado: ", response)
          })
          .catch((error) => {
            console.log("Error al asociar el producto a proyecto: ", error)
          })
          .finally(() => {
            this.loading = false
            //Recargaremos la lista de productos asociados
            this.projectStore.getProject(project_uuid)
            //Cerramos la ventana
            this.navigationMenuStore.showSlideOverAttachProduct = !this.navigationMenuStore.showSlideOverAttachProduct
            this.productSelected = null
          })
    },
    closeSlideOver() {
      this.navigationMenuStore.toggleSlideOverAttachProduct()
      this.productSelected = null
    }
  }
}
</script>

<style scoped>

</style>
