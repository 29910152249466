export default {
    sales: "Vendes",

    //Orders view
    orders: "Comandes",
    payments: "Pagaments",
    pvp_sales: "Vendes",
    net_sales: "Ingressos",
    pvp_returns: "Devolucions PVP",
    net_returns: "Devolucions Net",
    buyer: "Comprador",
    view_order: "Veure Comanda",
    loading_orders: "Estem carregant les teves comandes",
    balance: 'Balanç',

    //Payments view
    description: "Concepte/Descripció",
    from: "Des de",
    to: "Fins",
    payment_date: "Data Pagament",
    quantity: "Quantitat",
    loading_payments: "Estem carregant els pagaments",

    //OrdersComponent
    loading_products: 'Estem carregant les teves comandes',
    complete: 'Completat',
    reimbursed: 'Reemborsat',
    obtain_text_1: "S'han obtingut",
    obtain_text_2: 'comandes.',

    //ProductSubscriptionListComponent
    loading_subscriptions: 'Estem carregant les vostres subscripcions',
    subscriptions: "Subscripcions",
    activated: "Activada",
    canceled: "Cancel·lada",

    //BalanceComponent
    state: 'Estat',
    gross: 'Brut',
    net: 'Net',
    commission: 'Comissió',
    created_at: 'Creat a',
    available_on: 'Disponible el'
}