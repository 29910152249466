<template>
  <main class="max-w-7xl mx-auto py-3 md:py-6 sm:px-8 lg:pt-8">
    <nav class="lg:mx-8 mx-4 pb-6">
      <div class="block">
        <div class="">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <router-link :to="{ name: 'group-general' }" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="border-transparent text-gray-500 hover:text-gray-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs lg:text-sm">
                <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                <span>{{ $t('products.general') }}</span>
              </router-link>
              <router-link :to="{ name: 'group-project' }" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="border-transparent text-gray-500 hover:text-gray-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs lg:text-sm">
                <svg class="-ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                <span>Proyectos</span>
              </router-link>
              <div class="hidden">
                Usuarios
              </div>
            </nav>
        </div>
      </div>
    </nav>
    <router-view></router-view>
  </main>
</template>

<script>

export default {
  name: "GroupEditComponent",
  components: {  },
  data: function()
  {
    return {
      loading: false,
      group: {
        uuid: "",
        academy_uuid: "",
        title: "",
        attachedProjects: 0,
        attachedUsers: 0,
        updated_at: ''
      },
    }
  }
}
</script>

<style scoped>

</style>