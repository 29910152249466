
import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore('notifications', {
    state: () => ({
        notificationMessages: []
    }),
    actions: {
        sendNotification(notificationMessages ) {
            this.notificationMessages = notificationMessages
        },
        removeNotificationFromTimeout(timeout= 4000){
            for(let i=0; i<this.notificationMessages.length; i++){
                setTimeout(() => {
                    this.notificationMessages.shift()
                }, timeout);
            }
        }
    }
})