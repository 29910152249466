export default {
    hello: "Hello",
    create_a_project: "Create a course",
    customize_your_academy: "Customization",
    check_your_sales: "Check your income",
    create_your_project: "Create your course",
    placeholder_name: "My course at Brainspro",
    text_describe: "The title describes in one sentence what your students will learn.",
    bttn_close: "Close",
    bttn_create: "Create course!",
    contacts: 'More questions, contact us!',
    client_attention: 'Customer service: ',
    mail_client_attention: 'hello@brainspro.com',
    support: 'Technical support: ',
    email_support: 'soporte@brainspro.com',
    phone_whatsapp: 'Telephone and Whatsapp: ',
    phone_number: '+34 626 201 897',
    text_support: 'Book an appointment online with one of our experts',
    href_intercom: 'Go to the knowledge base',
    href_custom_domain: 'More about configuring custom domains.',
    no_annual_data: "No annual data",
    no_monthly_data: "No monthly data",
    no_students_data: "No data on registered students",
    no_projects_data: "No data on projects created",

}