import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid, filters = {} ) {
        console.log(academyUuid, filters);
        return useAuthUserStore().getApiClient.get('/saas/plans', {
            params: {
                academy_uuid: academyUuid
            }
        })
    }
}