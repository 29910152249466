import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid) {
        return useAuthUserStore().getApiClient.get('/coupons', {
            params: {
                academy_uuid: academyUuid,
                //page: page,
            }
        })
    },

    show(uuid) {
        return useAuthUserStore().getApiClient.get('/coupons/' + uuid)
    },

    store(coupon) {
        return useAuthUserStore().getApiClient.post('/coupons', coupon)
    },

    update(coupon) {
        return useAuthUserStore().getApiClient.put('/coupons/' + coupon.uuid, coupon)
    },

    delete(coupon) {
        return useAuthUserStore().getApiClient.delete('/coupons/' + coupon.uuid, {
            params: {
                academy_uuid: coupon.academy_uuid,
                coupon_uuid: coupon.uuid
            }
        })
    },

    attachProduct(coupon_uuid, product_uuid) {
        return useAuthUserStore().getApiClient.post('/coupons/' + coupon_uuid + '/product/' + product_uuid)
    },

    detachProduct(coupon_uuid, product_uuid) {
        return useAuthUserStore().getApiClient.delete('/coupons/' + coupon_uuid + '/product/' + product_uuid)
    }
}