export default {
    //ProductList
    your_created_projects: "Ihre erstellten Kurse",
    new_project: "Neuer Kurs",
    projects_name: "KURS-NAME",
    update_at: 'Aktualisiert am ',
    coming_soon: 'Demnächst verfügbar',
    search_your_project: 'Suchen Sie nach Ihrem Kurs...',
    project_name: 'Name des Kurses',
    loading_products: 'Produkte laden...',

    //ProductEditComponent
    modify_project: 'Ändern Sie Ihren Kurs',
    general: 'Allgemein',
    branding: 'Branding',
    product_page: 'Produkt Seite',
    sale: 'Verkauf',
    seo_text: 'SEO',
    projects: 'Projekte',

    //CategoriesSlideOverComponent
    categories: 'Kategorien',
    text_category: 'Klassifizieren Sie Ihre Produkte nach Kategorien, um Ihren Studenten den Kauf Ihrer Kurse zu erleichtern.',
    select_category: 'Kategorie auswählen...',
    ebook: 'E-book',
    online_project: 'Online-Kurs',
    blended_learning: 'Blended Learning-Kurs',
    online_program: 'Online-Programm',
    webinar_consulting: 'Webinar / Online-Beratung',
    remove_category: 'Kategorie entfernen',
    href_help: 'Ich würde gerne mehr über die Kategorien erfahren',

    //ProductEdit
    //CustomCategoriesComponent
    //GeneralComponent
    category: 'Kategorie',
    duration: 'Dauer',
    featured_product: 'Produkt-Spotlight',
    delete_project: 'Projekt löschen',
    warning_delete: 'Warnung!',
    message_delete_project: 'Wenn Sie das Projekt löschen, gehen alle Daten unwiderruflich verloren.',
    question_delete_project: 'Sind Sie sicher, dass Sie dieses Projekt löschen wollen?',
    delete: 'Löschen',
    confirmation_delete_project: '(*) Dieses Feld ist erforderlich, um das Projekt zu löschen.',
    //PageComponent
    principal_page: 'Hauptseite',
    objectives_presentation: 'Präsentation und Ziele',
    learning_objectives: 'Vergessen Sie nicht, die Lernziele kurz zu beschreiben.',
    teaching_staff: 'Methodik und Lehrpersonal',
    public: 'Öffentlich',
    content: 'Inhalt',
    sidebar: 'Seitenleiste',
    //ProductBrandingComponent
    banner: 'Banner',
    upload_banner: 'Laden Sie Ihr Banner hoch',
    banner_format: 'PNG, JPG, GIF bis zu 3 MB',
    promotional_video: 'Werbevideo',
    video_url: 'Video-URL',
    video_info: 'Wenn Sie ein Werbevideo haben, geben Sie hier die Url des Videos auf Youtube oder Vimeo an.',
    //SellComponent
    price_management: 'Preis-Management',
    sell_price: 'Verkaufspreis',
    tax: 'In €. (-3% Steuer)',
    price_offer: 'Angebotspreis',
    text_configuration: 'Text-Konfiguration',
    free_text_button: 'Freier Buy Button Titel',
    text_if_free: 'Dieser Titel erscheint, wenn Ihr Produkt kostenlos ist',
    on_sale: 'Zu verkaufen',
    sale_info: 'Wenn Ihr Produkt aktiviert ist, wird es zum Verkauf angeboten',

    //SeoComponent
    seo_configuration: 'SEO-Konfiguration',
    seo_title: 'SEO-Titel',
    slug: 'Slug',
    seo_description: 'SEO Beschreibung',

    //ProjectListComponent
    featured: 'Ausgewählt',
    classroom: 'Klassenzimmer',
    slug_text: 'slug',
    product: 'Produkt',
    products: 'Produkte',
    preview: 'Vorschau',

    //AttachProjectSlideOverComponent
    attach_project: 'Assoziiertes Projekt',
    loading_projects: 'Zugehörige Projekte laden...',
    project_selected: 'Ausgewähltes Projekt',
    quest_attach_project: 'Möchten Sie dieses Projekt anhängen?',
    back: 'Zurück zu',
    add_project: 'Projekt anhängen',
    close_panel: 'Paneel schließen',

    //ProjectAttachedListComponent
    attached_projects: 'Assoziierte Projekte',
    loading_products_attached: 'Laden von zugehörigen Produkten...',
    unattach_project: 'Projekt trennen',
    info_unattach_project: 'Wenn das Projekt vom Produkt getrennt wird, können Schüler, die das Produkt kaufen, nicht mehr auf das Projekt zugreifen.',
    quest_unattach_project: 'Sind Sie sicher, dass Sie das Projekt abkoppeln wollen? ',
}