<template>
  <section class="mx-6 mt-4">
    <label class="font-bold text-md ">{{$t('projects.html_editor')}}</label>
    <div class="">
      <vue-editor id="question-editor" class="mt-3" v-model="this.htmlContent"></vue-editor>
    </div>
  </section>
</template>

<script>
import {VueEditor} from "vue3-editor";

export default {
  name: "HTMLComponent",
  components: {
    VueEditor
  },
  data() {
    return {
      htmlContent: '',
    }
  },
  created() {
    this.getLesson()
  },
  methods: {
    getLesson()
    {
      //TODO llamar al endpoint para obtener el contenido de la lección
    }
  }
}
</script>

<style scoped>

</style>