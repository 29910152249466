export default {
    sales: "Ventas",

    //Orders view
    orders: "Pedidos",
    payments: "Pagos",
    pvp_sales: "Ventas",
    net_sales: "Ingresos",
    pvp_returns: "Devoluciones PVP",
    net_returns: "Devoluciones Neto",
    buyer: "Comprador",
    view_order: "Ver Pedido",
    loading_orders: "Estamos cargando tus pedidos",
    balance: 'Balance',

    //Payments view
    description: "Concepto/Descripción",
    from: "Desde",
    to: "Hasta",
    payment_date: "Fecha Pago",
    quantity: "Cantidad",
    loading_payments: "Estamos cargando tus pagos",

    //OrdersComponent
    loading_products: 'Estamos cargando tus pedidos',
    complete: 'Completado',
    reimbursed: 'Reembolsado',
    obtain_text_1: 'Se han obtenido',
    obtain_text_2: 'pedidos.',

    //ProductSubscriptionListComponent
    loading_subscriptions: 'Estamos cargando tus suscripciones',
    subscriptions: "Suscripciones",
    activated: "Activada",
    canceled: "Cancelada",

    //BalanceComponent
    state: 'Estado',
    gross: 'Bruto',
    net: 'Neto',
    commission: 'Comisión',
    created_at: 'Creado en',
    available_on: 'Disponible el'
}