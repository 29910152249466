export default {
    //ProductList
    your_created_projects: "Tus cursos creados",
    new_project: "Nuevo Curso",
    projects_name: "NOMBRE DEL CURSO",
    update_at: 'Actualizado el ',
    coming_soon: 'Próximamente',
    search_your_project: 'Busca tu curso...',
    project_name: 'Nombre del curso',
    loading_products: 'Cargando productos...',

    //ProductEditComponent
    modify_project: 'Modificando tu producto',
    general: 'General',
    branding: 'Branding',
    product_page: 'Página de Producto',
    sale: 'Venta',
    seo_text: 'SEO',
    projects: 'Proyectos',

    //CategoriesSlideOverComponent
    categories: 'Categorías',
    text_category: 'Clasifica tus productos por categorías, así facilitarás a tus alumnos comprar tus cursos.',
    select_category: 'Selecciona categoría...',
    ebook: 'E-book',
    online_project: 'Curso online',
    blended_learning: 'Curso semi-presencial',
    online_program: 'Programa Online',
    webinar_consulting: 'Webinar / Consultoría online',
    remove_category: 'Eliminar categoría',
    href_help: 'Deseo saber más sobre las categorías',

    //ProductEdit
    //CustomCategoriesComponent
    //GeneralComponent
    category: 'Categoría',
    duration: 'Duración',
    featured_product: 'Producto destacado',
    delete_project: 'Borrar Curso',
    warning_delete: '¡Atención!',
    message_delete_project: 'Al eliminar el curso perderás todos sus datos definitivamente.',
    question_delete_project: '¿Seguro que quieres eliminar este curso?',
    delete: 'Eliminar',
    confirmation_delete_project: '(*) Esta casilla es obligatoria para eliminar el curso',
    //PageComponent
    principal_page: 'Página principal',
    objectives_presentation: 'Presentación y objetivos',
    learning_objectives: 'No te olvides de describir brevemente los objetivos de aprendizaje.',
    teaching_staff: 'Metodología y profesorado',
    public: 'Público',
    content: 'Contenido',
    sidebar: 'Barra Lateral',
    //ProductBrandingComponent
    banner: 'Banner',
    upload_banner: 'Sube tu banner',
    banner_format: 'PNG, JPG, GIF hasta 3MB',
    promotional_video: 'Video Promocional',
    video_url: 'URL del video',
    video_info: 'Si tienes un video promocional, coloca aquí la url del video en Youtube o Vimeo.',
    //SellComponent
    price_management: 'Gestión de Precios',
    sell_price: 'Precio de venta',
    tax: 'En €. (-3% tasas)',
    price_offer: 'Precio de Oferta',
    text_configuration: 'Configuración de textos',
    free_text_button: 'Título de botón de compra gratuito',
    text_if_free: 'Este título aparecerá si tu producto es gratuito',
    on_sale: 'En venta',
    sale_info: 'Si está activado tu producto estará a la venta',

    //SeoComponent
    seo_configuration: 'Configuración SEO',
    seo_title: 'Título SEO',
    slug: 'Slug',
    seo_description: 'Descripción SEO',

    //ProductListComponent
    featured: 'Destacado',
    classroom: 'Aula',
    slug_text: 'slug',
    product: 'producto',
    products: 'productos',
    preview: 'Previsualizar',

    //AttachProjectSlideOverComponent
    attach_project: 'Asociar Proyecto',
    loading_projects: 'Cargando proyectos asociados...',
    project_selected: 'Proyecto seleccionado',
    quest_attach_project: '¿Quieres adjuntar este proyecto?',
    back: 'Volver',
    add_project: 'Adjuntar Proyecto',
    close_panel: 'Cerrar panel',

    //ProjectAttachedListComponent
    attached_projects: 'Proyectos Asociados',
    loading_products_attached: 'Cargando productos asociados...',
    unattach_project: 'Desasociar proyecto',
    info_unattach_project: 'Al desasociar el proyecto al producto, los alumnos que compren dicho producto no podrán acceder al proyecto.',
    quest_unattach_project: '¿Seguro que quieres desasociar el proyecto ',
}