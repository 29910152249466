export default {
    permits: 'Erlaubt',
    notifications: 'Benachrichtigungen',
    plans_billing: 'Planes y Facturación',
    integrations: 'Integrationen',
    update_info: 'Bitte aktualisieren Sie Ihre Rechnungsdaten. Bitte beachten Sie, dass die Einstellungen Ihres Standorts die Steuersätze beeinflussen können.',
    payment_details: 'Einzelheiten zur Zahlung',
    expiration_date: 'Verfallsdatum',
    country: 'Land / Region',
    postal: 'Postleitzahl / PLZ',
    current_plan: 'Aktueller Plan',
    business: 'Business',
    postings_job_25: 'Bis zu 25 aktive Jobangebote',
    enterprise: 'Unternehmen',
    unlimited_postings_job: 'Unbegrenzte Anzahl aktiver Jobangebote',
    pricing_plans: 'Preisgestaltung der Pläne',
    startup: 'Startup',
    postings_job_5: 'Bis zu 5 aktive Jobangebote',
    user_settings: 'Benutzereinstellungen',
    annual_billing: 'Jährlicher Umsatz',
    save_10: '(10% sparen)',
    billing_history: 'Geschichte der Rechnungsstellung',
    save: 'Speichern Sie',
    date: 'Datum',
    description: 'Beschreibung',
    amount: 'Menge',
    format_date: 'MM / YY',
    spain: 'Spanien',
    canada: 'Kanada',
    mexico: 'Mexiko',

    //SubscriptionComponent
    subscription_plan: 'Abonnement-Plan',
    basic: 'Grundlegend',
    basic_price: '26€',
    per_month: '/Monat',
    basic_info: 'Die perfekte Option für den Einstieg in die Welt des Online-Unterrichts.',
    unlimited_projects: 'Unbegrenzte Kurse',
    unlimited_students: 'Unbegrenzt viele Studenten',
    to_3_teachers: 'Bis zu 3 Lehrer',
    subdomain: 'Subdomain',
    take_plan: 'Ich möchte diesen Plan',
    advance: 'Fortgeschrittene',
    advance_price: '89€',
    advance_info: 'Stärken Sie Ihre Online-Akademie, alles aus dem Basic Plan und mehr.',
    own_domain: 'Eigene Domain',
    private_label: 'Weißes Etikett',
    to_10_teachers: 'Bis zu 10 Lehrkräfte',
    report_gen: 'Generación de informes',
    pro: 'Pro',
    pro_price: '152€',
    pro_info: 'Escala Ihre Online-Akademie. Alles von Fortgeschrittenen und mehr.',
    no_comissions: '0% Verkaufsprovision',
    api_access: 'API-Zugang',
    app_integrations: 'Integration mit APPs',
    support_prior: 'Vorrangige Unterstützung',
    //CurrentSubscriptionsComponent
    actual_subscriptions: 'Aktuelle Abonnements',
    attention: 'Achtung',
    att_info1: 'Wenn Sie Ihren Plan ändern möchten, wenden Sie sich bitte an ',
    contact_email: 'billing@brainspro.com',
    att_info2: 'Wenn Sie sich anmelden möchten, geben Sie bitte die folgenden Informationen an ',
    here: 'hier',
    ends_on: 'Endet am ',
    trial_ends_on: 'Die Probezeit endet am: ',
    view_receipt: 'Quittung ansehen',
    cancel_subscription: 'Abbestellen',
    modal_cancel_sub_text: 'Dieses Konto wird in einen suspendierten Zustand versetzt. Die Funktionalität wird für Nutzer der Akademie, Lehrer und Eigentümer eingeschränkt.',
    modal_cancel_sub_question: 'Sind Sie sicher, dass Sie es tun wollen?',

    //ChangeSubscriptionComponent
    message_subscription_expire: 'Ihr BrainsPro-Konto ist abgelaufen. Aktivieren Sie einen Plan, um wieder Zugang zu erhalten.',
    update_your_plan: 'Aktualisieren Sie Ihren Plan',
    monthly_billing: 'Monatlich',
    yearly_billing: 'Jährlich',
    text_all_the_basics: 'Alle Grundlagen für die Gründung eines neuen Unternehmens',
    button_activate: 'Aktivieren Sie',
    button_current_plan: 'Aktueller Plan',
    what_include: 'Einschließlich:',
    text_tax_not_include: 'Mehrwertsteuer nicht inbegriffen',
    month: 'Monat',
    year: 'Jahr',
}