<template>
  <section aria-labelledby="current_subscriptions" class="" v-if="authUserStore.user.academy.saas_subscriptions">
    <div class="rounded-md bg-yellow-50 p-4 mb-5">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: exclamation -->
          <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">
            {{ $t('settings.attention') }}
          </h3>
          <div class="mt-2 text-sm text-yellow-700">
            <p>
              {{ $t('settings.att_info1') }}<b><a class="underline" href="mailto:billing@brainspro.com">billing@brainspro.com</a></b>.<br />
              {{ $t('settings.att_info2') }}<a href="https://pay.gocardless.com/flow/RE001GZMQ43530DXFP5YB352RTJBTK2S" target="_blank" class="font-extrabold underline cursor-pointer">{{ $t('settings.here') }}</a>.
            </p>
          </div>
        </div>
      </div>
    </div>

    <section class="py-10 mb-16">
      <change-subscription-component></change-subscription-component>
    </section>

    <div class="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
      <div class="px-4 sm:px-6">
        <h2 id="billing_history_heading" class="text-lg leading-6 font-medium text-gray-900">{{$t('settings.actual_subscriptions')}}</h2>
      </div>
      <div class="mt-6 flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden border-t border-gray-200">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('settings.ends_on') }}
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.plan') }}
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.state') }}
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.total') }}
                  </th>
                  <!--
                      `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                    -->
                  <th scope="col" class="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <span class="sr-only">{{ $t('settings.view_receipt') }}</span>
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="subscription in authUserStore.user.academy.saas_subscriptions" v-bind:key="subscription.id">
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {{ $moment(subscription.ends_at).format('DD-MM-YYYY HH:m')  }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold">
                    {{ subscription.title }}<br>
                    <span v-if="subscription.status == 'trialing'" class="font-normal">
                      {{ $t('settings.trial_ends_on') }} {{$moment(subscription.trial_ends_at).format('DD-MM-YYYY HH:m')}}
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ subscription.status }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ subscription.total }} €
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a href="#" class="hidden text-bpblue hover:text-bpbluedeep">{{ $t('settings.view_receipt') }}</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ChangeSubscriptionComponent from "./ChangeSubscriptionComponent";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "CurrentSubscriptionsComponent",
  components: {ChangeSubscriptionComponent},
  setup(){
    const authUserStore = useAuthUserStore()

    return {
      authUserStore
    }
  }
}
</script>

<style scoped>

</style>