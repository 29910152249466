<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex items-center justify-between">
    <span class="pr-3 bg-gray-100 text-3xl font-medium text-gray-900">
      Tus usuarios
    </span>
          <router-link to="/users/massive"
                       v-if="massiveEnroll"
                       class="inline-flex items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-brainspro-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            <!-- Heroicon name: plus-sm -->
            <svg class="-ml-1.5 mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clip-rule="evenodd"/>
            </svg>
            <span>{{ $t('community.massive_increase') }}</span>
          </router-link>
          <button v-else-if="!massiveEnroll" type="button" v-on:click="navigationMenuStore.toggleSlideOverUserCreation()"
                  class="inline-flex items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-300 bg-gray-50">
            <!-- Heroicon name: plus-sm -->
            <svg class="-ml-1.5 mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clip-rule="evenodd"/>
            </svg>
            <span>{{ $t('community.massive_increase') }}</span>
          </button>

          <div class="flex">
            <button type="button" v-on:click="navigationMenuStore.toggleSlideOverUserCreation" class="inline-flex items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-brainspro-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue cursor-pointer">
              <!-- Heroicon name: plus-sm -->
              <svg class="-ml-1.5 mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                   aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"/>
              </svg>
              <span>{{ $t('community.new_user') }}</span>
            </button>
          </div>
        </div>
      </div>

      <div class="mt-5 relative z-0 inline-flex">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="mr-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <input type="text" v-model="userRequest.search" v-on:change="getUsers" name="search" id="search" class="focus:ring-bpblue focus:border-bpblue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" :placeholder="$t('community.search_user')" />
          </div>
        </div>

        <span class="shadow-sm rounded-md">
    <button type="button" :class="{'bg-brainspro-100 text-brainspro-800' : userRequest.type == 'all'}"
            v-on:click="changeUserType('all')"
            class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none">
      {{ $t('community.all') }}
    </button>
    <button type="button" v-on:click="changeUserType('students')"
            :class="{'bg-brainspro-100 text-brainspro-800' : userRequest.type == 'students'}"
            class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none">
      {{ $t('general.students') }}
    </button>
    <button type="button" v-on:click="changeUserType('teachers')"
            :class="{'bg-brainspro-100 text-brainspro-800' : userRequest.type == 'teachers'}"
            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none">
      {{ $t('general.teachers') }}
    </button>
  </span>

        <span class="shadow-sm rounded-md ml-2">
        <button type="button" v-on:click="exportUsers"
                class=" inline-flex items-center px-4 py-2 transition ease-in border border-gray-300 text-sm font-medium rounded text-gray-700 bg-white hover:bg-bpblue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          <!-- Heroicon name: document-download -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
          </svg>
        </button>
      </span>
      </div>

      <div v-if="loading" class="flex justify-center my-24 p-4 text-sm">
        <div class=" text-yellow-700">
          <svg class="inline mr-3 w-6 h-6 text-blue-700 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
          </svg>
        </div>
        <span class="ml-1 text-gray-500 text-xl">
          {{ $t('community.loading_users') }}
        </span>
      </div>

      <!-- Replace with your content -->
      <div v-else class="py-4 hidden md:block">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div
                class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">

                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.name') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.email') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.phone') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Última conexión
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.date_creation') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="user in users" :key="user.uuid" v-on:click="goToUserProfile(user.uuid)"
                    class="hover:bg-gray-50 transition-colors ease-in cursor-pointer">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-12">
                        <img v-if="user.avatar" class="h-12 rounded-full shadow" :src="user.avatar" alt="">
                        <div v-else class="h-12 w-12 bg-brainspro-400 rounded-full shadow">
                          <img src="/images/avatar-transparent.png">
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex items-center">
                      <div class="flex-shrink-0">
                        <div class="text-sm leading-5 font-medium text-gray-900">{{ user.firstname }}
                          {{ user.lastname }}
                        </div>
                        <span
                            class="text-sm leading-5 text-brainspro-800 rounded mr-2 inline bg-brainspro-100 py-1 px-3">{{ user.role }}</span>
                        <div class="text-sm leading-5 text-gray-500 hidden">{{ $t('community.updated_at') }}
                          {{ $moment(user.updated_at).format("LLLL") }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span class="text-sm leading-5 text-gray-500">{{ user.email }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <span class="text-sm leading-5 text-gray-500">{{ user.phone }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <span v-if="user.last_login" class="text-sm leading-5 text-gray-500">{{$moment(user.last_login).format('DD/MM/YYYY H:mm:ss') }}</span>
                    <span v-else></span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    <span class="text-sm leading-5 text-gray-500">{{ $moment(user.created_at).format('DD/MM/YYYY H:mm:ss') }}</span>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <div class="flex items-center justify-end">
                      <router-link :to="{ name: 'user-profile', params: { id : user.uuid }}" title="Preview"
                                   class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
                                   :aria-label="$t('community.new_project')">
                        <!-- Heroicon name: solid/chevron-right -->
                        <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-700" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"/>
                        </svg>
                      </router-link>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <mobile-user-list-component class="block md:hidden" :users="users"></mobile-user-list-component>
      <pagination-component :pagination="pagination"></pagination-component>
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import userService from "@/services/user/UserService";
import PaginationComponent from "@/views/common/PaginationComponent";
import MobileUserListComponent from "@/views/community/Mobile/UserListComponent";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import {useNavigationMenuStore} from "@/stores/navigationMenu";

export default {
  name: "UsersListComponent",
  components: {PaginationComponent, MobileUserListComponent},
  data: function () {
    return {
      loading: true,
      request: {
        files: [],
        project: "",
      },
      users: [],
      userRequest: {
        search: null,
        type: 'all'
      },
      pagination: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null
      },
      massiveEnroll: false,
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();
    const navigationMenuStore = useNavigationMenuStore()

    return {
      authUserStore,
      navigationMenuStore
    }
  },
  mounted() {
    this.getUsers()
    this.getSaasPlan()
  },
  computed: {},
  methods: {
    getUsers() {
      console.log("Obteniendo el listado de usuarios")
      let academy_uuid = this.authUserStore.user.academy.uuid;
      let page = 1;

      if (this.$route.params.page) {
        page = this.$route.params.page;
      }

      userService.index(academy_uuid, page, this.userRequest.type, this.userRequest.search)
          .then((response) => {
            console.log("Usuarios", response)
            this.users = response.data.data
            this.pagination = response.data.meta

            //Obtener los productos o proyectos asociados a cada usuario y mostrarlos en la lista de usuarios.
            /*
            for (let i = 0; i < this.community.length; i++)
            {
              let userSelected = this.community[i]
              console.log("El usuario seleccionado es: ", userSelected)
              Obtener los proyectos en los que está matriculado el usuario.

            }
             */
            this.loading = false
          })
    },
    changeUserType(type) {
      this.userRequest.type = type;
      this.getUsers();
    },
    goToUserProfile(user_uuid) {
      this.$router.push({name: 'user-profile', params: {id: user_uuid}})
    },

    exportUsers() {
      let academy_uuid = this.authUserStore.user.academy.uuid;

      userService.download(academy_uuid)
          .then((response) => {
            console.log("Descargando", response)
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'community.csv');
            document.body.appendChild(link)
            link.click()
          })
          .catch((error) => {
            console.log("Error en la descarga de usuarios", error.response)
            if (error.response.data.errors) {
              error.response.data.errors.forEach((error) => {
                NotificationService.showNotification(error, "error")
              })
            } else {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
    },
    getSaasPlan() {
      //Obtiene el plan del usuario
      let saasFeatures = this.authUserStore.user.academy.saas_subscriptions[0].saas_plan.saas_features
      console.log("Obtenidas las funcionalidades del plan del usuario:", saasFeatures)

      this.massiveEnroll = (saasFeatures.find(object => object.key === 'massive-enrol'))
    }
  }
}
</script>

<style scoped>

</style>