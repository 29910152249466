<template>
  <div class="max-w-7xl mx-auto py-2 md:py-6 sm:px-6 lg:px-4">
    <section class="p-4">
      <card-with-header-and-footer-component :loading="privacy.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.privacy_policy") }}</h3>
        </template>

        <h4 class="font-semibold leading-5 text-gray-800 sm:truncate mb-2">{{ $t("customization.text_register") }}</h4>
        <vue-editor v-model="privacy.request.summary"></vue-editor>
        <p class="text-gray-600">{{ $t("customization.user_view_text") }}</p>

        <h4 class="font-semibold leading-5 text-gray-800 sm:truncate mt-5 mb-2">{{ $t("customization.privacy_policy_page") }}</h4>
        <vue-editor v-model="privacy.request.page"></vue-editor>
        <p class="text-gray-600">{{ $t("customization.privacy_policy_academy_page") }}</p>

        <template v-slot:footer>
          <button type="button" v-on:click="getAcademyLegalPrivacy" :disabled="privacy.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setAcademyLegalPrivacy" :disabled="privacy.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>

    <section class="p-4 mt-6">
      <card-with-header-and-footer-component :loading="legal.loading">
        <template v-slot:header>
          <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t("customization.legal_notice") }}</h3>
        </template>

        <vue-editor v-model="legal.request.page"></vue-editor>

        <template v-slot:footer>
          <button type="button" v-on:click="getAcademyLegal" :disabled="legal.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.cancel") }}
          </button>

          <button type="button" v-on:click="setAcademyLegal" :disabled="legal.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t("general.save") }}
          </button>
        </template>
      </card-with-header-and-footer-component>
    </section>

    <help-center-component :url="this.helpCenter.kn_legal_url" class="pl-3"></help-center-component>

  </div>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import { VueEditor } from "vue3-editor";
import PrivacyLegalService from "@/services/academy/PrivacyLegalService";
import LegalPageService from "@/services/academy/LegalPageService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import HelpCenterComponent from "@/views/common/HelpCenterComponent";
import helpcenter from "@/services/helpcenter/links";


export default {
  name: "LegalComponent",
  components: {
    HelpCenterComponent,
    CardWithHeaderAndFooterComponent,
    VueEditor
  },
  data() {
    return {
      privacy: {
        loading: true,
        request: {
          summary: "",
          page: ""
        }
      },
      legal: {
        loading: true,
        request: {
          page: ""
        }
      }
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();
    const helpCenter = helpcenter

    return {
      authUserStore,
      helpCenter
    }
  },
  mounted() {
    this.getAcademyLegalPrivacy();
    this.getAcademyLegal();
  },
  methods: {
    getAcademyLegalPrivacy() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.privacy.loading = true;

      PrivacyLegalService.index(academy_uuid)
          .then((response) => {
            console.log("Se ha obtenido la política de privacidad correctamente", response)
            this.privacy.request.summary = response.data.summary
            this.privacy.request.page = response.data.page
          })
          .catch((response) => {
            console.log("Error obteniendo la descripción", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.privacy.loading = false
          })
    },
    setAcademyLegalPrivacy() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.privacy.loading = true;

      PrivacyLegalService.update(academy_uuid, this.privacy.request)
          .then((response) => {
            console.log("Se ha almacenado la política de privacidad correctamente", response)
            NotificationService.showNotification("Se ha almacenado la política de privacidad correctamente", "success")

          })
          .catch((error) => {
            console.log("Error guardando la política de privacidad", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.privacy.loading = false
          })
    },
    getAcademyLegal() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.legal.loading = true;

      LegalPageService.index(academy_uuid)
          .then((response) => {
            console.log("Se ha obtenido la política de privacidad correctamente", response)
            this.legal.request.page = response.data.page
          })
          .catch((response) => {
            console.log("Error obteniendo la descripción", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.legal.loading = false
          })
    },
    setAcademyLegal() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.legal.loading = true;

      LegalPageService.update(academy_uuid, this.legal.request)
          .then((response) => {
            console.log("Se ha almacenado la política de privacidad correctamente", response)
            NotificationService.showNotification("Se ha almacenado la política de privacidad correctamente", "success")

          })
          .catch((error) => {
            console.log("Error guardando la política de privacidad", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.legal.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>