
import { defineStore } from 'pinia'
import {useAuthUserStore} from "@/stores/auth";

export const useRecordingsStore = defineStore('recordings', {
    state: () => ({
        loading: false,
        recordings: null
    }),
    actions:{
        getRecordings() {
            this.loading = true
            console.log("ESTOY EN RECORDINGS!!!!")
            let academy_uuid = useAuthUserStore().user.academy.uuid
            useAuthUserStore().getApiClient.get('/recordings/' + academy_uuid)
                .then(response => {
                    console.log("Las grabaciones son: ", response)
                    this.setRecordings(response.data)
                })
                .catch(error => {
                    console.log("Ha ocurrido un error obteniendo el producto", error)
                })
                .finally(() => {
                    this.loading = false
                })

        },
        //mutations
        setRecordings(recordings) {
            this.recordings = recordings
        }
    }
})