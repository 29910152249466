export default {
    permits: 'Permisos',
    notifications: 'Notificacions',
    plans_billing: 'Plans i Facturació',
    integrations: 'Integracions',
    update_info: 'Actualitzeu les vostres dades de facturació. Tingueu en compte que la configuració de la vostra ubicació podria afectar els tipus impositius.',
    payment_details: 'Dades de pagament',
    expiration_date: "Data d'expiració",
    country: 'País / Regió',
    postal: 'Codi Postal / ZIP',
    current_plan: 'Pla actual',
    business: 'Negoci',
    postings_job_25: 'Fins a 25 ofertes de feina actives',
    enterprise: 'Empresa',
    unlimited_postings_job: "Ofertes actives il·limitades d'ocupació",
    pricing_plans: 'Posar preu als plans',
    startup: 'Startup',
    postings_job_5: 'Fins a 5 ofertes de feina actives',
    user_settings: "Configuració de l'usuari",
    annual_billing: 'Facturació Anual',
    save_10: '(Save 10%)',
    billing_history: 'Historial de facturació',
    save: 'Desa',
    date: 'Data',
    description: 'Descripció',
    amount: 'Quantitat',
    format_date: 'MM / YY',
    spain: 'Espanya',
    canada: 'Canadà',
    mexico: 'Mèxic',

    //SubscriptionComponent
    subscription_plan: 'Pla de subscripció',
    basic: 'Bàsic',
    basic_price: '26€',
    per_month: '/mes',
    basic_info: "L'opció perfecta per començar al món de l'ensenyament en línia.",
    unlimited_projects: 'Cursos Il·limitats',
    unlimited_students: 'Alumnes Il·limitats',
    to_3_teachers: 'Fins a 3 docents',
    subdomain: 'Subdomini',
    take_plan: 'Vull aquest pla',
    advance: 'Avançat',
    advance_price: '89€',
    advance_info: 'Impulsa la teva acadèmia en línia, tot això del Pla Bàsic i més.',
    own_domain: 'Domini propi',
    private_label: 'Marca Blanca',
    to_10_teachers: 'Fins a 10 docents',
    report_gen: "Generació d'informes",
    pro: 'Pro',
    pro_price: '152€',
    pro_info: "Escala la teva acadèmia en línia. Tot això de l'avançat i més.",
    no_comissions: '0% de comissió de venda',
    api_access: 'Accés a API',
    app_integrations: 'Integració amb APPs',
    support_prior: 'Suport prioritari',
    //CurrentSubscriptionsComponent
    actual_subscriptions: 'Subscripcions actuals',
    attention: 'Atenció',
    att_info1: 'Si voleu canviar el vostre pla contacteu amb ',
    contact_email: 'billing@brainspro.com',
    att_info2: 'Si voleu contractar una subscripció, completeu la informació disponible ',
    here: 'aquí',
    ends_on: 'Finalitza el ',
    trial_ends_on: 'El període de prova finalitza el: ',
    view_receipt: 'Veure rebut',
    cancel_subscription: 'Cancel·la Subscripció',
    modal_cancel_sub_text: "Aquest compte entrarà en un estat de suspensió. Les funcionalitats seran restringides per als usuaris de l'acadèmia, els docents i els amos.",
    modal_cancel_sub_question: 'Segur que vols fer-ho?',

    //ChangeSubscriptionComponent
    message_subscription_expire: "El teu compte a BrainsPro ha expirat. Activeu un pla per recuperar l'accés.",
    update_your_plan: 'Actualitza el teu pla',
    monthly_billing: 'Mensual',
    yearly_billing: 'Anual',
    text_all_the_basics: "Tot el bàsic per iniciar un nou negoci'",
    button_activate: 'Activa',
    button_current_plan: 'Pla Actual',
    what_include: 'Que inclou:',
    text_tax_not_include: 'IVA no inclòs',
    month: 'Mes',
    year: 'Any',
}