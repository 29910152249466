<template>
  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
    <div v-if="!loading" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
      <table class="min-w-full">
        <thead>
        <tr>
          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
            {{ $t('general.name') }}
          </th>
          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
            {{ $t('general.email') }}
          </th>
          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
            {{ $t('general.date_creation') }}
          </th>
          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
        </tr>
        </thead>
        <tbody class="bg-white">
        <tr v-for="user in users" :key="user.uuid"
            class="hover:bg-gray-50 transition-colors ease-in">

          <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <div class="text-sm leading-5 font-medium text-gray-900">{{ user.firstname }} {{ user.lastname }}
                </div>
                <span
                    class="text-sm leading-5 text-brainspro-800 rounded mr-2 inline bg-brainspro-100 py-1 px-3">{{ user.role }}</span>
                <div class="text-sm leading-5 text-gray-500 hidden">{{ $t('community.updated_at') }}
                  {{ $moment(user.updated_at).format("LLLL") }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <span class="text-sm leading-5 text-gray-500">{{ user.email }}</span>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <span class="text-sm leading-5 text-gray-500">{{ $moment(user.created_at).format('DD/MM/YYYY H:mm:ss') }}</span>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
            <div class="flex items-center justify-end">
              <router-link :to="{ name: 'user-profile', params: { id : user.uuid }}" title="Preview"
                           class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
                           :aria-label="$t('community.new_project')">
                <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-700" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"/>
                </svg>
              </router-link>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!--
    <loading-component v-else>{{ $t("sales.loading_orders") }}</loading-component>
    -->
    <pagination-user-enrol-component :pagination="pagination"></pagination-user-enrol-component>


  </div>
</template>

<script>
import {useAcademiesStore} from "@/stores/academies";
import AcademyService from "@/services/academy/AcademyService";
import PaginationUserEnrolComponent from "@/views/common/PaginationUserEnrolComponent";

export default {
  name: "UserEnrolProjectListComponent",
  components: {PaginationUserEnrolComponent},
  data() {
    return {
      users: [],
      userRequest: {
        search: null,
        type: 'all'
      },
      pagination: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null
      },
      loading: false,
    }
  },
  setup() {
    const academyStore = useAcademiesStore()

    return {
      academyStore
    }
  },
  mounted() {
    this.getUsers()
  },

  methods: {
    getUsers() {
      this.loading = true

      let academyUuid = this.academyStore.getSelectedAcademyUuid
      let projectUuid = this.$route.params.id
      let page = 1;

      if (this.$route.params.page)
      {
        page = this.$route.params.page

      }
      console.log("La página es: ", page)

      AcademyService.getUserEnrolList(academyUuid, projectUuid, page)
          .then((response) => {
            this.users = response.data.data
            this.pagination = response.data.meta
            console.log("Se ha creado el listado de usuarios correctamente")
            console.log("Usuarios matriculados: ", response.data)

          })
          .catch((error) => {
            console.log("Error al obtener los usuarios: ", error)
          })
          .finally(() => {
            this.loading = false
          })
    },
  },
}
</script>

<style scoped>

</style>