import { createApp } from 'vue'
import App from './App.vue'
import {createPinia} from 'pinia'
import store from './store'
import router from './router'

var moment = require('moment');
moment.locale('es_ES')

/*
|--------------------------------------------------------------------------
| 2. Axios for the Win & Glory
|--------------------------------------------------------------------------
|
| Todas las llamadas a la app de brainspro serán realizadas con axios.
| Será mejor que lo importemos si no pocas llamadas podrán hacerse.
| Absténgase de usar el cliente http de vue-resource si lo tienes en mente
| los tipos que usan vue-resource no nos gustan ¬¬
|
| La api responderá siempre con JSON, así que mejor será decirlo en cada
| petición.
|
*/

import axios from 'axios'
import {useAuthUserStore} from "@/stores/auth";
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            //TODO PINIA AUTH
            useAuthUserStore().logout().then(() => {
                router.push('/login')
            })
        }

        return Promise.reject(error)
    }
)

/*
|--------------------------------------------------------------------------
| 3. Font Awesome
|--------------------------------------------------------------------------
|
| Usaremos estos iconos en toda la aplicación, quizás cuando se haga este
| código un poco monstruoso lo sacaremos a parte.
|
| No se usará más librerías que esta (se permiten iconos en formato svg).
|
*/

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faHeart,
    faGlobe,
    faCogs,
    faHome,
    faChartBar,
    faSchool,
    faChalkboardTeacher,
    faChalkboard,
    faGraduationCap,
    faBullhorn,
    faSpinner,
    faShoppingCart,
    faWallet,
    faSwatchbook,
    faIdCardAlt,
    faFileContract,
    faUsers, faBan, faBolt, faPiggyBank
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
    faBolt, // Utilizado para las integraciones en la pestaña marketing
    faGlobe, // Mundo para configuración de dominio
    faSwatchbook, // Paleta de colores para zona de personalización de academia
    faIdCardAlt, // Tarjeta identificación para contacto y rrss de academia
    faBan, // Para bloquear permisos o si no se tiene un funcionalidad en concreto
    faCogs,
    faHome,
    faChartBar,
    faHeart, //Para made with love in málaga
    faPiggyBank, // Para suscripciones en sales
    faUsers, // Icono de usuarios para el menú
    faFileContract, // Icono para zona de textos legales en la academia
    faSchool, faChalkboardTeacher, faChalkboard, faGraduationCap, faBullhorn, faSpinner, faShoppingCart, faWallet)

const app = createApp(App).use(store)

import i18n_messages from '@/lang/messages';
import { createI18n } from 'vue-i18n'
import './assets/tailwind.css'
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css';

const i18n = createI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages: i18n_messages
})

app.use(i18n)
app.use(createPinia())
app.use(router)
app.use(VCalendar, {})
app.component('font-awesome-icon', FontAwesomeIcon)

app.config.productionTip = false

app.mount('#app')

app.config.globalProperties.$moment = moment;

process.env.VUE_APP_VERSION = require('@/../package.json').version

