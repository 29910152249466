<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex items-center justify-between">
          <span class="pr-3 bg-gray-100 text-3xl font-medium text-gray-900">
            {{ $t('records.your_records') }}
          </span>
        </div>
      </div>

      <div class="mt-5 relative z-0 inline-flex">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="mr-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative rounded-md shadow-sm">
          </div>
        </div>
      </div>

      <div v-if="loading" class="flex justify-center my-24 p-4 text-sm">
        <div class=" text-yellow-700">
          <svg class="inline mr-3 w-6 h-6 text-blue-700 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
          </svg>
        </div>
        <span class="ml-1 text-gray-500 text-xl">
          {{ $t('records.loading_records') }}
        </span>
      </div>

      <div v-else class="py-4 md:block">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('records.project_name') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('records.class_name') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('records.participants') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('records.total_size') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="recording in this.recordings" :key="recording.id"
                    class="hover:bg-gray-50 transition-colors ease-in">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="">
                      <div class="text-sm leading-5 font-medium text-gray-900">{{ recording.project_name }}</div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex items-center">
                      <div class="">
                        <div class="text-sm leading-5 font-medium text-gray-500">{{ recording.name }}</div>
                        <div class="hidden text-sm leading-5 text-gray-500">{{ $t('projects.update_at') }}
                          {{ $moment(recording.updated_at).format("LLLL") }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 ">
                    <div class="flex justify-center">
                      <div class="text-sm leading-5 font-medium text-gray-500">{{recording.participants}}</div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="">
                      <div class="text-sm leading-5 font-medium text-gray-900"> {{( (recording.size + recording.raw_size) /1024/1024).toFixed(2)}} <label class="text-gray-500">Mb</label></div>
                    </div>
                  </td>
                  <td class="lg:px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <a class="cursor-pointer hover:underline" target="_blank" :href="recording.meeting_url">
                      <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-7 w-7 text-bpblue hover:text-blue-300 transition rounded ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useAcademiesStore} from "@/stores/academies";
import {useRecordingsStore} from "@/stores/recordings";
import {useAuthUserStore} from "@/stores/auth";
import RecordingService from "@/services/recording/RecordingService";

export default {
  name: "RecordsComponent",
  components: {},
  data() {
    return {
      loading: true,
      recordings: [],
      pagination: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null
      },
    }
  },
  created() {
    this.getRecordings()
  },
  setup(){
    const recordingsStore = useRecordingsStore()
    const academiesStore = useAcademiesStore()
    const authUserStore = useAuthUserStore()

    return {
      recordingsStore,
      academiesStore,
      authUserStore
    }
  },
  methods: {
    getRecordings() {
      let academy_uuid = this.academiesStore.getSelectedAcademyUuid;

      RecordingService.index(academy_uuid)
          .then((result) => {
            this.recordings = JSON.parse(result.data)
            console.log("Recording service", this.recordings)
            this.loading = false
          })
    }
  }
}
</script>