export default {
    log_account: 'Inicie sessão na sua conta',
    create_account: 'criar uma conta gratuita',
    trial_info: 'comece o seu julgamento de 14 dias',
    log_with: 'Acesso com',
    continue_with: 'Ou continuar com ',
    attention: 'Atenção apenas aos administradores',
    welcome_text1: 'Bem-vindo a Brainspro v2. ',
    admin_access: 'Acesso apenas para administradores',
    welcome_text2: 'Se é estudante ou professor, aceda ao seu curso a partir do sítio web da sua escola.',
    email_address: 'Endereço postal',
    remember_me: 'Lembra-te de mim',
    forgot_pass: 'Esqueceu-se da sua palavra-passe?',
    access: 'Acesso',
    ad_text1: 'Transforme os seus cursos online em',
    ad_text2: 'experiências de aprendizagem',
    ad_text3: 'alto impacto',
    ad_text4: 'Os seus alunos apreciarão a qualidade dos seus serviços e terá tudo sob controlo de uma forma simples.',
    //RememberPasswordComponent
    recovery_password: 'Recupere a sua palavra-passe',
    email_input_placeholder: 'Introduce tu email',
    information_email_send: 'Será enviado um e-mail de confirmação para recuperar a sua palavra-passe',
    information_go_email_inbox: 'Por favor, inicie sessão no seu e-mail e clique no link para redefinir a sua palavra-passe. Se não conseguir encontrar o e-mail, verifique a sua pasta SPAM.',
    error_email_invalid: 'Deve introduzir um endereço de correio electrónico válido',
    button_text: 'Recuperação de senha',
    //ResetPasswordComponent
    restore_password: 'Redefinir a sua palavra-passe',
    new_password: 'Nova palavra-passe',
    password_placeholder: 'Introduza a sua nova palavra-passe',
    error_password: 'A senha deve ter pelo menos 8 caracteres',
    confirm_password: 'Confirmar palavra-passe',
    password_confirm_placeholder: 'Introduza a sua nova palavra-passe',
    error_password_confirm: 'As palavras-passe não coincidem',
    button_reset_password: 'Redefinir palavra-passe',
    success_password_change: "Senha actualizada.",
}