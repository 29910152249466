<template>
  <card-with-header-and-footer-component :loading="gaTag.loading">
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{$t('marketing.title_ga')}}</h3>
    </template>

    <div class="">
      <textarea v-model="gaTag.value" class="mt-2 h-52 w-full bg-gray-600 text-white"></textarea>
      <p class="mt-2 text-sm text-gray-500" id="integrations-ga">{{$t('marketing.description_text')}}</p>
    </div>

    <template v-slot:footer>
      <button type="button" v-on:click="getGASnippet" :disabled="gaTag.loading"
              class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.cancel') }}
      </button>

      <button type="button" v-on:click="setGASnippet" :disabled="gaTag.loading"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.save') }}
      </button>
    </template>
  </card-with-header-and-footer-component>
</template>
<script>

import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import IntegrationService from "@/services/marketing/IntegrationService";
import {useRecordingsStore} from "@/stores/recordings";
import {useAcademiesStore} from "@/stores/academies";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "GoogleAnalyticComponent",
  components: {
    CardWithHeaderAndFooterComponent,
  },
  data() {
    return {
      gaTag: {
        loading: false,
        value: '',
      },
      clickOnSave: false,
      clickOnCancel: false,
      responseOk: true,
    }
  },
  mounted() {
    this.gaTag.loading = true
    this.getGASnippet()
    this.gaTag.loading = false
  },
  setup(){
    const recordingsStore = useRecordingsStore()
    const academiesStore = useAcademiesStore()
    const authUserStore = useAuthUserStore()

    return {
      recordingsStore,
      academiesStore,
      authUserStore
    }
  },
  methods: {
    getGASnippet() {
      this.gaTag.loading = true
      let academyUuid = this.academiesStore.academy.uuid
      let codeGA = {
        academy_uuid: academyUuid,
      }
      IntegrationService.indexGA(codeGA)
          .then((response) => {
            console.log("Se ha obtenido el header", response)
            this.gaTag.value = response.data.code
          })
          .catch((error) => {
            console.log("Error al obtener el código header", error)
          })
          .finally(() => {
            this.gaTag.loading = false
          })
    },
    setGASnippet() {
      let codeGA = {
        academy_uuid: this.academiesStore.academy.uuid,
        value: this.gaTag.value
      }
        IntegrationService.storeGA(codeGA)
            .then((response) => {
              console.log("Se han obtenido los datos del coupon", response)
            })
            .catch((error) => {
              console.log("Errores: ", error)
            })
            .finally(() => {
              //do something
            })
    }
  }
}
</script>

<style scoped>

</style>