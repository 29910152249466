export default {

    //EditUserSlideOverComponent
    edit_user: 'Editar usuario',
    name: 'Nombre',
    lastname: 'Apellidos',
    email: 'Email',
    password: 'Password',
    close: 'Cerrar',

    //SlideOverComponent
    enroll_in: 'Enrol in:',
    select_project: 'Select a course...',
    user_role: 'User role',
    student: 'Student',
    user_as_student: 'Register your user as a student',
    teacher: 'Teacher',
    user_as_teacher: 'Register your user as a teacher on the course',
    teacher_no_edition: 'Teacher without editing permissions',
    user_as_teacher_no_edition: 'Enrol your user as a teacher without editing permissions on the course',
    inspector: 'Inspector',
    user_as_inspector: 'Register your user as a FUNDAE inspector',
    email_notification: 'Notify by email',
    text_email_notification: 'Notify your student that he/she has been enrolled.',
    help_manual_user: 'I want to know more about manual user creation',
    create_user: 'Create user',
    create_info: 'Start by filling in the following information to create your username.',

    //CardWithHeaderAndFooterComponent
    text_plan1: 'This functionality belongs to a higher plan, please',
    text_plan2: 'update your plan',
    loading_content: 'We are uploading your content',

}