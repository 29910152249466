<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex items-center justify-between">
          <span class="pr-3 bg-gray-100 md:text-3xl text-xl font-medium text-gray-900">
            {{ $t('products.your_created_projects') }}
          </span>
          <div class="flex">
            <button type="button" v-on:click="navigationMenuStore.toggleSlideOverCategories"
                    class="inline-flex mr-5 items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-brainspro-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
              <!-- Heroicon name: plus-sm -->
              <svg class="-ml-1.5 mr-1 h-5 w-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" class="hidden"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"/>
              </svg>
              <span>{{ $t('general.categories') }}</span>
            </button>

            <button type="button" v-on:click="productsStore.toggleModal"
                    class="inline-flex items-center shadow-sm px-4 py-1.5 transition ease-in border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-brainspro-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
              <!-- Heroicon name: plus-sm -->
              <svg class="-ml-1.5 mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"/>
              </svg>
              <span>{{ $t('products.new_project') }}</span>
            </button>
          </div>
        </div>
      </div>

      <div class="mt-5 relative z-0 inline-flex">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="mr-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                   fill="currentColor">
                <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <input type="text" v-model="productRequest.search" v-on:change="getProducts" name="search" id="search"
                   class="focus:ring-bpblue focus:border-bpblue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                   placeholder="Busca tu curso..."/>
          </div>
        </div>
      </div>

      <div v-if="loading" class="flex justify-center my-24 p-4 text-sm">
        <div class=" text-yellow-700">
          <svg class="inline mr-3 w-6 h-6 text-blue-700 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
          </svg>
        </div>
        <span class="ml-1 text-gray-500 text-xl">
          {{ $t('products.loading_products') }}
        </span>
      </div>

      <div v-else class="py-4 hidden md:block">
        <div class="flex flex-col">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div v-if="!this.products && !this.loading">
              <div class="flex flex-row justify-center">
                <div class="border-b-bpblue border-t-bpblue border-4 w-8 h-8 cursor-pointer hover:border-blue-300" v-on:click="productsStore.toggleModal">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-1 text-bpblue hover:text-blue-200" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="mt-1">
                  <label class="font-bold ml-2 cursor-pointer text-bpblue hover:text-blue-200" v-on:click="productsStore.toggleModal">Crear nuevo producto</label>
                </div>
              </div>
            </div>
            <div v-else class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('products.projects_name') }}
                  </th>
                  <th class="hidden px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.teachers') }}
                  </th>
                  <th class=" px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.published') }}
                  </th>
                  <th class="hidden px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('general.students') }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="product in products" :key="product.uuid"
                    class="hover:bg-gray-50 transition-colors ease-in">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-14 relative">
                        <div v-if="product.featured"
                             class="text-yellow-400 absolute -top-3 -left-3 bg-white rounded-full shadow-lg"
                             :title="$t('products.featured')">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20"
                               fill="currentColor">
                            <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
                          </svg>
                        </div>
                        <img v-if="product.thumbnail" class="h-14 rounded" :src="product.thumbnail" alt="">
                        <div v-else class="h-14 w-28 bg-gray-400 rounded text-center">
                          <img src="/images/avatar-transparent.png" class="h-14 inline">
                        </div>
                      </div>
                      <div class="ml-4">
                        <div class="text-sm leading-5 font-medium text-gray-900">{{ product.title }}</div>
                        <div class="hidden text-sm leading-5 text-gray-500">{{ $t('products.update_at') }}
                          {{ $moment(product.updated_at).format("LLLL") }}
                        </div>
                        <ul class="mt-1">
                          <li class="text-sm leading-5 text-brainspro-800 rounded mr-2 inline bg-brainspro-100 py-1 px-3"
                              v-for="custom_category in product.custom_categories" :key="custom_category.uuid">
                            {{ custom_category.title }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td class="hidden px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex overflow-hidden hidden">
                      <img class="inline-block h-8 w-8 rounded-full text-white shadow-solid"
                           src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                           alt="">
                      <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid"
                           src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                           alt="">
                      <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid"
                           src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                           alt="">
                      <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid"
                           src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                           alt="">
                    </div>
                    <span class="text-sm leading-5 text-gray-500">{{ $t('products.coming_soon') }}</span>
                  </td>
                  <td class=" px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <ToggleComponent v-bind:status="(product.status == 'publish')" :callback="changeProductStatus"
                                     :product="product.uuid"></ToggleComponent>
                  </td>
                  <td class="hidden px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {{ $t('products.coming_soon') }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <div class="flex items-center justify-end">
                      <router-link :to="{ name: 'products-edit', params: { id : product.uuid }}"
                                   class="inline-block mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                        </svg>
                      </router-link>
                      <a title="Preview" :href="'https://' + product.link + '?preview=true'" target="_blank"
                         class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
                         :aria-label="$t('products.new_project')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                        </svg>
                      </a>
                      <button title="Aula" v-on:click="goToMoodleCourse(product.projects.uuid)" :disabled="loadingLMS"
                              class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
                              :aria-label="$t('products.new_project')">
                        <svg v-if="!loadingLMS" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z"/>
                          <path fill="#fff"
                                d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"/>
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"/>
                        </svg>
                        <font-awesome-icon v-else
                                           class="h-7 w-7 text-gray-400 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin"
                                           icon="spinner" size="lg"/>
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <mobile-projects-list-component class="block md:hidden" :products="products"></mobile-projects-list-component>
      <PaginationComponent class="hidden"></PaginationComponent>
      <!-- /End replace -->

    </div>
  </div>
</template>

<script>
import PaginationComponent from "@/views/common/PaginationComponent";
import ToggleComponent from "@/components/forms/ToggleComponent";
import productService from "@/services/product/ProductService";
import ProjectService from "@/services/project/ProjectService";
import MobileProjectsListComponent from "@/views/products/Mobile/ProductsListComponent";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import {useProductsStore} from "@/stores/products";
import {useNavigationMenuStore} from "@/stores/navigationMenu";

export default {
  name: "ProjectsList",
  components: {ToggleComponent, PaginationComponent, MobileProjectsListComponent},
  data: () => {
    return {
      loading: true,
      products: [],
      loadingLMS: false,
      productRequest: {
        search: ""
      }
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()
    const productsStore = useProductsStore()
    const navigationMenuStore = useNavigationMenuStore()

    return {
      authUserStore,
      productsStore,
      navigationMenuStore
    }
  },
  created() {
    console.log("Se ha creado el listado de productos correctamente")
    this.getProducts()
  },
  methods: {
    getProducts() {
      productService.index(this.productRequest)
          .then((response) => {
            console.log("Productos", response.data)
            this.products = response.data
            this.loading = false
          })
    },

    goToMoodleCourse(uuid) {
      console.log("Redirección a moodle course")
      this.loadingLMS = true
      ProjectService.getMoodleCourseURL(uuid)
          .then((response) => {
            if (response.data.link) {
              console.log("Abriendo el curso " + uuid, response.data.link)

              window.open(response.data.link);
            }
          })
          .finally(() => {
            this.loadingLMS = false
          })
    },

    changeProductStatus(product_uuid, status) {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      console.log("Cambiando el estado del producto", product_uuid, status)
      productService.changeProductStatus(academy_uuid, product_uuid, status)
          .then((response) => {
            console.log("Se ha cambiado  correctamente", response)
            var message = (status) ? "Se ha publicado el curso" : "Se ha despublicado el curso"
            NotificationService.showNotification(message, "success")

          })
          .catch((response) => {
            console.log("Error solicitando el cambio de estado del producto", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")

          })
          .finally(() => {
            //Silence is golden
          })

    }
  }
}
</script>

<style scoped>

</style>