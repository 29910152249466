<template>
  <card-with-header-and-footer-component :loading="logo.loading">
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('customization.logo') }}</h3>
    </template>

    <div class="sm:col-span-6">
      <div v-if="logo.request.url == ''" v-on:click="setLogo" class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer">
        <div class="space-y-1 text-center">
          <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div class="flex text-sm text-gray-600">
            <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-bpblue hover:text-bpbluedeep focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-bpblue">
              <span>{{ $t('customization.upload_logo') }}</span>
            </label>
            <!--<p class="pl-1">o arrástralo aquí</p>-->
          </div>
          <p class="text-xs text-gray-500">
            {{$t('general.image_format')}}
          </p>
        </div>
      </div>
      <div v-else class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div class="space-y-1 text-center">
          <img :src="logo.request.url" />
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <button v-if="logo.request.url != ''" v-on:click="deleteLogo" type="button" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{$t('general.remove')}}
      </button>

      <button type="button" v-on:click="setLogo" :disabled="logo.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ (logo.request.url)? $t('customization.change') : $t('customization.insert') }}
      </button>
    </template>
  </card-with-header-and-footer-component>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import LogoService from "@/services/academy/LogoService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";

export default {
  name: "LogoBrandingComponent",
  components: {
    CardWithHeaderAndFooterComponent,
  },
  mounted() {
    this.getLogo()
  },
  data() {
    return {
      logo : {
        loading: true,
        request: {
          url: '',
          handle: ''
        }
      }
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();
    const academiesStore = useAcademiesStore()

    return {
      authUserStore,
      academiesStore
    }
  },
  methods: {
    getLogo() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.logo.loading = true;

      LogoService.index(academy_uuid)
          .then((response) => {
            console.log("Se ha obtenido el logo correctamente", response)
            this.logo.request.url = response.data.url
          })
          .catch((response) => {
            console.log("Error obteniendo el logo", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.logo.loading = false
          })
    },
    setLogo() {
      LogoService.openPicker(this.successFilestackUpload)
    },
    successFilestackUpload(response) {
      console.log("Subida realizada con éxito", response)
      this.logo.request.url = response.filesUploaded[0].url;
      this.logo.request.handle = response.filesUploaded[0].handle;

      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.logo.loading = true;

      LogoService.update(academy_uuid, this.logo.request)
          .then((response) => {
            console.log("El logo se ha almacenado correctamente", response)
            NotificationService.showNotification("El logo se ha almacenado correctamente", "success")

            this.academiesStore.getAcademy()
          })
          .catch((error) => {
            console.log("Error almacenando el logo", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.logo.loading = false
          })
    },
    deleteLogo() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.logo.loading = true;

      LogoService.delete(academy_uuid).then((response) => {
        console.log("Se ha borrado el logo correctamente", response)
        NotificationService.showNotification("El logo se ha borrado correctamente", "success")

        this.getLogo()
        this.academiesStore.getAcademy()
      })
      .catch((response) => {
        console.log("Error borrando el logo", response)
        NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
      })
      .finally(() => {
        this.logo.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>