<template>
 <div class="pt-2 pb-6 md:py-5">
   <card-with-header-and-footer-component :loading="productsStore.loading">
     <template v-slot:header>
       <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.banner') }}</h3>
     </template>

     <div class="sm:col-span-6">
       <div v-if="productsStore.product.media.thumbnail == '' || productsStore.product.media.thumbnail == null" v-on:click="setBanner" class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer">
         <div class="space-y-1 text-center">
           <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
             <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
           </svg>
           <div class="flex text-sm text-gray-600">
             <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-bpblue hover:text-bpbluedeep focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-bpblue">
               <span>{{ $t('products.upload_banner') }}</span>
             </label>
             <!--<p class="pl-1">o arrástralo aquí</p>-->
           </div>
           <p class="text-xs text-gray-500">
             {{ $t('products.banner_format') }}
           </p>
         </div>
       </div>
       <div v-else class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
         <div class="space-y-1 text-center">
           <img :src="productsStore.product.media.thumbnail" />
         </div>
       </div>
     </div>

     <template v-slot:footer>
       <button v-if="productsStore.product.media.thumbnail" v-on:click="deleteBanner" :disabled="productsStore.loading" type="button" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
         {{ $t('general.remove') }}
       </button>

       <button type="button" v-on:click="setBanner" :disabled="productsStore.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
         {{ (productsStore.product.media.thumbnail)? 'Cambiar' : 'Insertar' }}
       </button>
     </template>
   </card-with-header-and-footer-component>

   <section class="py-4">
     <card-with-header-and-footer-component :loading="productsStore.loading">
       <template v-slot:header>
         <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.promotional_video') }}</h3>
       </template>

       <div>
         <label for="video-promo" class="block text-sm font-medium text-gray-700">{{ $t('products.video_url') }}</label>
         <div class="mt-1">
           <input type="text" v-model="video" name="academy-name" id="video-promo" class="shadow-sm focus:ring-bpblue focus:border-bpblue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Video Promocional" aria-describedby="email-description">
         </div>
         <p class="mt-2 text-sm text-gray-500" id="academy-name-description">{{ $t('products.video_info') }}</p>
       </div>

       <template v-slot:footer>
         <button type="button" v-on:click="reloadProduct" :disabled="productsStore.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
           {{ $t('general.cancel') }}
         </button>

         <button type="button" v-on:click="setVideo" :disabled="productsStore.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
           {{ $t('general.save') }}
         </button>
       </template>
     </card-with-header-and-footer-component>
   </section>
 </div>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import ProductService from "@/services/product/ProductService";
import NotificationService from "@/services/notification/NotificationService";
import {useProductsStore} from "@/stores/products";

export default {
  name: "BrandingComponent",
  components: {
    CardWithHeaderAndFooterComponent,
  },
  setup(){

    const productsStore = useProductsStore()

    return{
     productsStore
    }
  },
  computed: {
    video: {
      get() {
        return this.productsStore.product.media.video
      },
      set(value) {
        this.productsStore.product.media.video = value
      }
    },
  },
  methods: {
    reloadProduct() {
      this.productsStore.getProduct(this.$route.params.id)
    },
    setBanner() {
      ProductService.openPicker(this.successFilestackUpload)
    },
    successFilestackUpload(response) {
      console.log("Subida realizada con éxito", response)
      this.productsStore.product.media.thumbnail = response.filesUploaded[0].url;
      this.productsStore.product.media.thumbnail_handle = response.filesUploaded[0].handle;
      this.productsStore.setProductBanner()
          .then((response) => {
            console.log("Almacenando seccion general del producto", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el producto", error)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.productsStore.loading = false
          })
    },
    deleteBanner() {
      this.productsStore.product.media.thumbnail = "";
      this.productsStore.product.media.thumbnail_handle = "";

      this.productsStore.setProductBanner()
          .then((response) => {
            console.log("Almacenando seccion general del producto", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el producto", error)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.productsStore.loading = false
          })
    },
    setVideo() {
      this.productsStore.setProductVideo()
          .then((response) => {
            console.log("Almacenando seccion branding del producto", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el producto", error)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.productsStore.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>