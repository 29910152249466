export default {
    //EditUserSlideOverComponent
    edit_user: 'Editar usuari',
    firstname: 'Nom',
    lastname: 'Cognoms',
    email: 'Email',
    identity_card: 'DNI / CIF / NIF',
    password: 'Password',
    close: 'Tancar',

    //SlideOverComponent
    enroll_in: 'Matricular a:',
    select_project: 'Selecciona un curs...',
    user_role: "Rol d'usuari",
    student: 'Alumne',
    user_as_student: 'Matricula el teu usuari com a estudiant',
    teacher: 'Docent',
    user_as_teacher: 'Matricula el teu usuari com a docent al curs',
    teacher_no_edition: 'Docent sense permisos dedició',
    user_as_teacher_no_edition: "Matricula el teu usuari com a docent sense permisos d'edició al curs",
    inspector: 'Inspector',
    user_as_inspector: 'Matricula el teu usuari com a inspector FUNDAE',
    email_notification: 'Notificar per email',
    text_email_notification: "Notifica al teu alumne que se l'ha matriculat",
    help_manual_user: "Vull saber més sobre la creació manual d'usuaris",
    create_user: 'Crear usuari',
    create_info: 'Comença emplenant la informació següent per crear el teu usuari.',
        //Vuelidate strings error
        error_firstname_required: "Heu d'introduir un nom",
        error_lastname_required: "Has d'introduir els cognoms",
        error_email_required: "Has d'introduir un correu electrònic vàlid",
        error_password_required: "Heu d'introduir una contrasenya amb 8 caràcters",
        error_dni_minLenght: 'Ha de tenir almenys 9 caràcters',

    //CardWithHeaderAndFooterComponent
    text_plan1: 'Aquesta funcionalitat pertany a un pla superior, si us plau',
    text_plan2: 'actualitza el teu pla',
    loading_content: 'Estem carregant el teu contingut',
}