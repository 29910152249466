export default {
    your_users: "Comunidad",
    massive_increase: "Subida masiva",
    new_user: "Nuevo usuario",
    placeholder_search: "Busca tu usuario...",
    all: "Todos",
    project_name: "Nombre del curso",
    updated_at: "Actualizado el ",
    view_profile: "Ver perfil",
    write_email: "Escribir Mail",
    edit_user: "Editar usuario",
    call: "Llamar",
    orders: "Pedidos",
    dni: "DNI / CIF / NIE",
    search_user: 'Busca un usuario...',
    new_project: 'Nuevo Curso',
    delete_user: 'Eliminar Usuario',
    warning: '¡Atención!',
    message_delete: 'Al eliminar el usuario perderás todos sus datos definitivamente.',
    question_delete: '¿Seguro que quieres eliminar al usuario ',
    delete: 'Eliminar',
    second_confirmation: '(*) Esta casilla es obligatoria para eliminar al usuario',
    loading_users: 'Cargando usuarios...',

    //MassUserImportComponent
    beta_version: 'Beta',
    mass_enrollment: "Matriculación Masiva",
    functionality: "Funcionalidad para planes",
    advance: "Avanzado",
    pro: "Pro",
    limitation_mass_enrollment: "Limitado a matriculaciones de hasta 50 usuarios. El resto se ignorarán.",
    download_template: "Descarga tu plantilla de matriculación",
    upload_students_list: "Sube tu lista de alumnos",
    drag_drop: "o arrástrala y suéltala aquí",
    format: "XLSX hasta 3MB",
    enroll_in: "Matricular en:",
    select_product: "Selecciona un curso...",
    upload_users: "Cargar usuarios",
    import_users: "Importar usuarios",
    href_massive_enroll: "Más sobre la importación masiva de usuarios.",

    //UserEnrollListComponent
    to_cancel: 'Dar de baja',
    no_enrolls: 'Este usuario no está matriculado en ningún curso',
    modal_title: 'Desmatricular',
    modal_warning: 'Este proceso no se puede revertir, el alumno perderá todo su progreso y no se podrá recuperar.',
    modal_question: '¿Seguro que quieres desmatricular este usuario del curso ',
    loading_enrols: 'Cargando matriculaciones...',
    processing: 'Procesando...',
    access_denied: 'Acceso denegado al curso',

    //UserEnrolSlideOverComponent
    select_project: 'Selecciona un proyecto',
    loading_projects: 'Cargando proyectos...',
    project_selected: 'Proyecto seleccionado',
    send_users_notification: 'Envía al usuario una notificación de matriculación.',
    back: 'Volver',
    enrol: 'Matricular',

    //BillingAddressComponent
    tax_data: 'Datos Fiscales',
    company_name: 'Razón Social',
    vat_number_id: 'CIF / DNI',
    address_line_1: 'Dirección Línea 1',
    address_line_2: 'Dirección Línea 2',
    zip_code: 'Código Postal',
    city: 'Ciudad',
    province: 'Provincia',
    country: 'País',

    //UserProfileComponent
    save_edition: 'Guardar edición',
    billing_data: 'Datos de Facturación',
    groups: 'Grupos',
    in_progress: 'En progreso',
    completed: 'Completado',

    //Groups
    your_groups: 'Tus grupos',
    new_group: 'Nuevo grupo',
    loading_groups: 'Cargando grupos',
    name: 'Nombre',
    projects: 'Proyectos',
    users: 'Usuarios',
    last_update: 'Última actualización',
}