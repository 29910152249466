<template>
  <card-with-header-and-footer-component :loading="loading">
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('customization.contact_bloq') }}</h3>
    </template>

    <textarea v-model="request.contact_html" class="h-52 w-full bg-gray-700 text-white"></textarea>
    <p class="mt-2 text-sm text-gray-500" id="academy-tagline-description">{{ $t('customization.html_bloq') }}</p>

    <template v-slot:footer>
      <button type="button" v-on:click="getAcademyContactHTML" :disabled="loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.cancel') }}
      </button>

      <button type="button" v-on:click="setAcademyContactHTML" :disabled="loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
        {{ $t('general.save') }}
      </button>
    </template>
  </card-with-header-and-footer-component>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import ContactHTMLService from "@/services/academy/ContactHTMLService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";

export default {
name: "ContactHtmlComponent",
  components: {
    CardWithHeaderAndFooterComponent
  },
  data() {
    return {
      loading: true,
      request: {
        contact_html: ''
      }
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();

    return {
      authUserStore
    }
  },
  created() {
    this.getAcademyContactHTML()
  },
  methods: {
    getAcademyContactHTML() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      ContactHTMLService.index(academy_uuid)
          .then((response) => {
            console.log("Se ha almacenado el bloque de contacto correctamente", response)
            this.request.contact_html = response.data.contact_html
          })
          .catch((response) => {
            console.log("Error obteniendo el bloque de contacto", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.loading = false
          })
    },
    setAcademyContactHTML() {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.loading = true;

      ContactHTMLService.update(academy_uuid, this.request)
          .then((response) => {
            console.log("Se ha almacenado el bloque de contacto correctamente", response)
            NotificationService.showNotification("Se ha almacenado el bloque de contacto correctamente", "success")

          })
          .catch((error) => {
            console.log("Error guardando el bloque de contacto", error.response)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>