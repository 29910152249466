<template>
  <main class="max-w-7xl mx-auto py-3 md:py-6 sm:px-8 lg:pt-8">
    <!-- <div class=" lg:grid lg:grid-cols-12 lg:gap-x-5"> -->
    <div>
      <header-component :title="$t('general.my_account')"></header-component>
      <nav class="mt-4 lg:ml-8 ml-4">
        <div class="block">
          <div class="">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <router-link to="/myaccount/"
                           exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm"
                           class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                <!-- Heroicon name: user-circle -->
                <svg class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span class="truncate">
                  {{ $t('general.my_profile') }}
                </span>
              </router-link>

              <router-link to="/myaccount/current-subscription"
                           active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm"
                           class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                           aria-current="page">
                <!-- Heroicon name: credit-card -->
                <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
                </svg>
                <span class="truncate">
                  {{ $t('general.plans') }}
                </span>
              </router-link>

              <router-link to="/myaccount/billing/1"
                           active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm"
                           class="hidden text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                           aria-current="page">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <span class="truncate">
                  Facturación
                </span>
              </router-link>

              <router-link to="/myaccount/log"
                           active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm"
                           class="hidden text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                           aria-current="page">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>
                <span class="truncate">
                  Últimos accesos
                </span>
              </router-link>

              <router-link to="/myaccount/information"
                           active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm"
                           class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                           aria-current="page">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>
                <span class="truncate">
                  Acerca de
                </span>
              </router-link>

            </nav>
          </div>
        </div>
      </nav>
    </div>
  </main>
  <router-view></router-view>
</template>

<script>

import {useAuthUserStore} from "../../stores/auth";
import {useAcademiesStore} from "../../stores/academies";
import HeaderComponent from "@/views/common/HeaderComponent";

export default {
  name: "NavigationMyAccountComponent.vue",
  components: {
    HeaderComponent,
  },
  data: () => {
    return {
      general: {
        saasSubscriptionStatus: false
      }
    }
  },
  setup() {
    const authUserStore = useAuthUserStore();
    const academiesStore = useAcademiesStore()

    return {
      authUserStore,
      academiesStore,
    }
  },
  mounted() {
    this.getSaasSubscriptionStatus()
  },
  methods: {
    getSaasSubscriptionStatus() {
      let saasSubscriptionStatus = this.authUserStore.user.academy.saas_subscriptions[0].status
      if (saasSubscriptionStatus === 'past_due') {
        this.general.saasSubscriptionStatus = true
      }
    }
  }
}
</script>

<style scoped>

</style>