export default {
    coupons: "Coupons",
    scripts: "Header/footer scripts",
    integrations: "Integrations",
    //CouponProductListComponent
    activated: "Activated",
    end_at: "Ends on",
    related_product: "Associated products",
    associate_product: "Associate product",
    select_product: "Select a product",
    project_name: 'Course name',
    update_at: 'Updated on ',
    to_cancel: 'Unsubscribe',

    //CouponComponent
    create_coupon: 'Create Coupon',
    coupon_data: 'Coupon details',
    code: 'Code',
    quantity: 'Quantity',
    offer_type: 'Discount rate',
    offer_text: 'Select discount type...',
    percent_offer: 'Percentage discount',
    fixed_offer: 'Fixed product discount',
    duration: 'Duration',
    delete_coupon: 'Delete Coupon',
    info_delete_coupon: 'This coupon will be permanently deleted',
    quest_delete_coupon: 'Are you sure you want to delete this coupon?',
    view_receipt: 'See receipt',
    trial_ends_on: 'The probationary period ends on: ',
    error_code_required: 'Enter a coupon code',
    error_amount_required: 'Enter the amount to discount',

    //Integrations
    clientify: 'Clientify',
    clientify_info_text: 'Clientify es un CRM que te ayuda a organizar todos tus clientes, oportunidades, embudos de venta, automatizaciones...',
    whatsapp: 'Whatsapp',


    //GoogleAnalytics
    title_ga: 'Google Analytics',
    description_text: 'Enter the Google Analytics html code',

    //CustomGlobalHTML
    title_html: 'Custom HTML',
    header_script: 'Header scripts',
    footer_script: 'Footer scripts',
    alert_message: 'If the code is incorrect, your academy\'s website may not work correctly. The following code will be inserted in the header and/or footer tag',

    //Clientify
    //Whatsapp
    telephone_number: 'Telephone Number',
    //GooglePixel
}