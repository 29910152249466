export default {
    home: "Inicio",
    projects: "Cursos",
    community: "Comunidad",
    customization: "Personalización",
    reports: "Informes",
    marketing: "Marketing",
    settings: "Ajustes",
    my_account: 'Mi cuenta',
    annual_sales: "Ventas (Año)",
    sales: "Ventas",
    students: "Estudiantes",
    teachers: "Docentes",
    products: "Productos",
    project: "Proyectos",
    categories: "Categorías",
    category: 'Categoría',
    published: "Publicado",
    name: "Nombre",
    lastname: 'Apellidos',
    email: "Email",
    phone: "Teléfono",
    title: "Título",
    pass: 'Contraseña',
    pass_confirm: 'Confirmación de contraseña',
    coming_soon: "Próximamente",
    month: "Mes",
    year: "Año",
    date: "Fecha",
    plan: 'Plan',
    pvp: "PVP",
    net: "Neto",
    total: 'Total',
    state: "Estado",
    delete: "Eliminar",
    profile: "Perfil",
    enrollments: "Matriculaciones",
    cancel: "Cancelar",
    save: "Guardar",
    remove: 'Borrar',
    email_address: 'Dirección de correo',
    image_format: 'PNG, JPG, GIF hasta 3MB',
    close: 'Cerrar',
    back: 'Volver',
    coming_soon_brainspro: 'Próximamente en Brainspro',
    and: 'y',
    product: "Producto",
    date_creation: 'Fecha de creación',
    type: 'tipo',

    //AppComponent
    my_profile: 'Mi Perfil',
    adjust: 'Ajustes',
    out: 'Salir',
    search: 'Buscar',
    academy_preview: 'Previsualizar academia',
    my_academy: 'Mi Academia',
    new_project: 'Nuevo Curso',
    text_current_period: 'Tu periodo de prueba ',
    text_end_at: ' acaba en ',
    plans: 'Planes de Suscripción',
    billing: 'Facturación',

    //ThankYouPageComponent
    thankyou: '¡Gracias!',
    go_to_academy: 'Ir a la academia',

    //NavigationDesktop - Mobile
    records: 'Grabaciones',
    videos: 'Videos'

}