export default {
    reports: "Vertrieb",
    //Orders view
    orders: "Bestellungen",
    payments: "Zahlungen",
    pvp_sales: "Vertrieb",
    net_sales: "Einnahmen",
    pvp_returns: "Rückgabe PVP",
    net_returns: "Erträge Netto",
    buyer: "Käufer",
    view_order: "Bestellung ansehen",
    loading_orders: "Wir laden Ihre Bestellungen",
    balance: 'Bilanz',
    //Payments view
    description: "Konzept/Beschreibung",
    from: "Von",
    to: "An",
    payment_date: "Datum der Zahlung",
    quantity: "Menge",
    loading_payments: "Wir laden Ihre Zahlungen hoch",
    //OrdersComponent
    loading_products: 'Wir laden Ihre Bestellungen',
    complete: 'Abgeschlossen',
    reimbursed: 'Erstattet',
    obtain_text_1: 'Es wurden folgende Ergebnisse erzielt',
    obtain_text_2: 'bestellungen.',
    //ProductSubscriptionListComponent
    loading_subscriptions: 'Wir laden Ihre Abonnements',
    subscriptions: "Abonnements",
    activated: "Aktivierte",
    canceled: "Gestrichen",

    //BalanceComponent
    state: 'Staat',
    gross: 'Brutto',
    net: 'Netz',
    commission: 'Kommission',
    created_at: 'Erstellt in',
    available_on: 'Verfügbar am'
}