export default {
    log_account: 'Inicia Sesión',
    create_account: 'crea un compte gratuït',
    trial_info: 'comença la teva prova de 14 dies',
    log_with: 'Accedeix amb',
    continue_with: 'O continua amb ',
    attention: 'Atenció només administradors',
    welcome_text1: 'Benvinguts a Brainspro v2. ',
    admin_access: 'Accés només per a administradors',
    welcome_text2: 'Si ets alumne o docent, accedeix al teu curs des de la web de la teva acadèmia.',
    email_address: 'Adreça de correu',
    remember_me: "Recorda'm",
    forgot_pass: 'Has oblidat la contrasenya?',
    access: 'Accedir',
    ad_text1: 'Converteix els teus cursos online a',
    ad_text2: "experiències d'aprenentatge",
    ad_text3: "d'alt impacte",
    ad_text4: 'Els teus estudiants agrairan la qualitat dels teus serveis i ho tindràs tot sota control de forma senzilla.',
    //RememberPasswordComponent
    recovery_password: 'Recupera la contrasenya',
    email_input_placeholder: 'Introdueix el teu email',
    information_email_send: "S'enviarà un email de confirmació per recuperar la contrasenya",
    information_go_email_inbox: "Si us plau accediu al vostre correu i feu clic a l'enllaç per restaurar la contrasenya. Si no trobeu el correu reviseu la vostra carpeta d'SPAM.",
    error_email_invalid: "Heu d'introduir un correu vàlid",
    button_text: 'Recuperar contrasenya',
    //ResetPasswordComponent
    restore_password: 'Restaura la contrasenya',
    new_password: 'Nova contrasenya',
    password_placeholder: 'Introdueix la teva nova contrasenya',
    error_password: 'La contrasenya ha de tenir un mínim de 8 caràcters',
    confirm_password: 'Confirmar contrasenya',
    password_confirm_placeholder: 'Introdueix la teva nova contrasenya',
    error_password_confirm: 'Les contrasenyes no coincideixen',
    button_reset_password: 'Restaurar contrasenya',
    success_password_change: "Contrasenya actualitzada.",



}