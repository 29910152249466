import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid, filters = {} ) {
        console.log(academyUuid, filters);
        return useAuthUserStore().getApiClient.get('/categories', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },

    get(productUuid) {
        return useAuthUserStore().getApiClient.post('/category/' + productUuid)
    },

    post(academy_uuid, category) {
        return useAuthUserStore().getApiClient.post('/categories', {
            'academy_uuid': academy_uuid,
            'title': category.title,
            'slug': category.slug
        })
    },

    put(product) {
        return useAuthUserStore().getApiClient.put('/categories', product)
    },

    delete(categoryUuid) {
        return useAuthUserStore().getApiClient.delete('/categories/' + categoryUuid)
    }
}