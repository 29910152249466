<template>
  <div>
    <section class="px-4">
      <!-- Maquetación de plantillas -->
      <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white">
        <div class="w-full h-48 relative bg-cover" >
          <label class="flex justify-center">{{ $t('projects.image') }}</label>
        </div>
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-4">
            <h4>
              <a href="" class="product-name">{{ $t('projects.classic_design') }}</a>
              <small class="block text-gray-700"></small>
            </h4>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NotificationService from "@/services/notification/NotificationService";
import {useProductsStore} from "@/stores/products";

export default {
  name: "PageComponent",
  components: {
  },
  setup(){

    const productsStore = useProductsStore()

    return {
     productsStore
    }
  },
  computed: {
    header: {
      get() {
        return this.productsStore.product.content.header
      },
      set(value) {
        this.productsStore.product.content.header = value
      }
    },
    why: {
      get() {
        return this.productsStore.product.content.why
      },
      set(value) {
        this.productsStore.product.content.why = value
      }
    },
    what: {
      get() {
        return this.productsStore.product.content.what
      },
      set(value) {
        this.productsStore.product.content.what = value
      }
    },
    toc: {
      get() {
        return this.productsStore.product.content.toc
      },
      set(value) {
        this.productsStore.product.content.toc = value
      }
    },
    aside: {
      get() {
        return this.productsStore.product.content.aside
      },
      set(value) {
        this.productsStore.product.content.aside = value
      }
    },
  },
  methods: {
    reloadProduct() {
      this.productsStore.getProduct(this.$route.params.id)
    },
    setProductPage() {
      this.productsStore.setProductPage()
          .then((response) => {
            console.log("Almacenando seccion page del producto", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el producto", error)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
          })
          .finally(() => {
            this.productsStore.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>