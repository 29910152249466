export default {
    //AcademyComponent
    customization: 'Personalize a sua academia',
    domain: "Domínio",
    brand_logo: "Logótipo e marca",
    rrss_contact: "Contacto e RRSS",
    legal: "Legal",
    options: 'Opções',

    //DomainComponent
    your_domain: "O seu domínio mybrainspro.com",
    info_domain: "Digite o domínio SEM símbolos (.,-¿?!-$%&/()...), sotaques ou espaços. Não é necessário colocar www.",
    domain_input: "Domínio mybrainspro.com",
    custom_domain: "Domínio personalizado",
    info_domain2: "Introduza o seu domínio (www.tudominio.com) para o utilizar como página inicial ou como página secundária (yourdomain.yourdomain.com).",
    attention: "Atenção",
    info_attention: "Apenas disponível para os planos ADVANCED e PRO.",
    activate_domain: "Para activar o seu domínio em BrainsPro: ",
    info_activate_1line: "Visite a plataforma onde comprou o seu domínio e procure as definições DNS..",
    info_activate_2line: "Adiciona tipo de registo A ao IP: 76.223.11.227",
    info_activate_3line: "Note que pode levar até 48 horas para que estas alterações tenham efeito e se propaguem no seu DNS. Uma vez pronto, pode começar a usar o novo endereço da sua academia online BrainsPro.",
    info_activate_4line: "A equipa do brainspro contactá-lo-á para lhe enviar alguns registos adicionais que terá de colocar no seu DNS. Com eles iremos gerar o seu sítio web seguro (https://).",
    //LanguageSelector
    lang: 'Idioma',
    academy_lang: 'Língua da Academia',
    academy_lang_selector_text: 'Escolha a língua da sua academia',

    //BrandingComponent
    brand_academy: "Marcar a sua academia",
    academy_name: "Nome da Academia",
    full_name: "Escolha o nome completo da sua academia",
    tagline: "Academia TagLine",
    slogan: "Escolha o slogan da sua academia",

    //ContactComponent
    description: "Descrição",
    web_rrss: "Web e Redes Sociais",
    web_blog: "Web ou Blog",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    linkedin: "Linkedin",
    youtube: "Youtube",
    location: "Localização e Dados Fiscais",
    address_1line: "Endereço, linha 1",
    address_2line: "Endereço, linha 2",
    postalcode: "Código postal",
    city: "Cidade",
    province: "Província",
    country: "País",
    company_name: "Nome da empresa",
    nif_cif: "NIF/CIF",

    //LegalComponent
    privacy_policy: "Política de Privacidade",
    text_register: "Texto de resumo para registo do utilizador",
    user_view_text: "Este texto será exibido no registo do utilizador, como um resumo da versão mais longa que será exibida na página de aviso legal da sua escola.",
    privacy_policy_page: "Página de Política de Privacidade",
    privacy_policy_academy_page: "Página da Política de Privacidade da Academia",
    legal_notice: "Aviso Legal",

    //Contact
    contact_bloq: 'Bloco de contacto',
    html_bloq: 'Insere um bloco html para acompanhar as informações de contacto. Como um iframe do google maps.',

    //Branding
    //BannerBrandingComponent
    upload_banner: 'Carregue o seu banner',
    image_format: 'PNG, JPG, GIF até 3MB',

    //ColorsComponent
    desing_colors: 'Desenho e Cores',
    header_type: 'Tipo de cabeçalho',
    banner_complete: 'Banner em tamanho real',
    banner_complete_no_text: 'Banner em tamanho real sem texto',
    columns_text_image: '2 Colunas, Texto + Imagem',
    banner_info: 'Isto irá alterar o tipo de imagem de capa que os utilizadores verão na sua academia.',
    enterprise_color: 'Cor corporativa',
    text_color: 'Cor do texto',

    //FaviconComponent
    favicon: 'Favicon',
    upload_favicon: 'Carregue o seu favicon',
    favicon_format: 'PNG, JPG, GIF até 3MB',

    //LogoBrandingComponent
    logo: 'Logo',
    upload_logo: 'Carregue o seu logótipo',

    //OptionsComponent
    register_config: 'Configuração do registo',
    info_button_register: 'A activação deste botão irá desactivar a opção de registo na academia. Para o fazer, todos os utilizadores devem registar-se a partir da aplicação Brainspro.',
    restricted_register: 'Restrição ao registo',

}