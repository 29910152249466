export default {
    //ProductList
    your_created_projects: "Your created courses",
    new_project: "New Course",
    projects_name: "COURSE NAME",
    update_at: 'Updated on ',
    coming_soon: 'Coming soon',
    search_your_project: 'Search for your course...',
    project_name: 'Course name',
    loading_products: 'Loading products...',

    //ProductEditComponent
    modify_project: 'Modifying your projects',
    general: 'General',
    branding: 'Branding',
    product_page: 'Product Page',
    sale: 'Sale',
    seo_text: 'SEO',
    projects: 'Projects',

    //CategoriesSlideOverComponent
    categories: 'Categories',
    text_category: 'Classify your products by category to make it easier for your students to buy your courses.',
    select_category: 'Select category...',
    ebook: 'E-book',
    online_project: 'Online projects',
    blended_learning: 'Blended learning projects',
    online_program: 'Online Programme',
    webinar_consulting: 'Webinar / Online consulting',
    remove_category: 'Remove category',
    href_help: 'More about the categories',

    //ProductEdit
    //CustomCategoriesComponent
    //GeneralComponent
    category: 'Category',
    duration: 'Duration',
    featured_product: 'Product Spotlight',
    delete_project: 'Delete projects',
    warning_delete: 'Warning!',
    message_delete_project: 'By deleting the projects you will permanently lose all your data.',
    question_delete_project: 'Are you sure you want to delete this projects?',
    delete: 'Delete',
    confirmation_delete_project: '(*) This box is required to delete the projects.',
    //PageComponent
    principal_page: 'Home Page',
    objectives_presentation: 'Presentation and objectives',
    learning_objectives: 'Don\'t forget to briefly describe the learning objectives.',
    teaching_staff: 'Methodology and teaching staff',
    public: 'Public',
    content: 'Content',
    sidebar: 'Side Bar',
    //ProductBrandingComponent
    banner: 'Banner',
    upload_banner: 'Upload your banner',
    banner_format: 'PNG, JPG, GIF up to 3MB',
    promotional_video: 'Promotional Video',
    video_url: 'Video URL',
    video_info: 'If you have a promotional video, place here the url of the video on Youtube or Vimeo.',
    //SellComponent
    price_management: 'Price Management',
    sell_price: 'Selling price',
    tax: 'In €. (-3% tax)',
    price_offer: 'Offer Price',
    text_configuration: 'Text configuration',
    free_text_button: 'Free buy button title',
    text_if_free: 'This title will appear if your product is free',
    on_sale: 'For sale',
    sale_info: 'If activated your product will be for sale',

    //SeoComponent
    seo_configuration: 'SEO Configuration',
    seo_title: 'SEO Title',
    slug: 'Slug',
    seo_description: 'SEO Description',

    //ProjectListComponent
    featured: 'Featured',
    classroom: 'Classroom',
    slug_text: 'slug',
    product: 'product',
    products: 'products',
    preview: 'Preview',

    //AttachProjectSlideOverComponent
    attach_project: 'Associate Project',
    loading_projects: 'Loading associated projects...',
    project_selected: 'Selected project',
    quest_attach_project: 'Do you want to attach this project?',
    back: 'Back',
    add_project: 'Attach Project',
    close_panel: 'Close panel',

    //ProjectAttachedListComponent
    attached_projects: 'Associated Projects',
    loading_products_attached: 'Loading associated products...',
    unattach_project: 'Disassociate project',
    info_unattach_project: 'By disassociating the project from the product, students who purchase the product will not be able to access the project.',
    quest_unattach_project: 'Are you sure you want to disassociate the project ',
}