<template>
  <section v-show="navigationMenuStore.showSlideOverCategories" class="fixed inset-0 overflow-hidden" style="z-index: 2147483002" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="absolute inset-0 overflow-hidden">
      <!-- Background overlay, show/hide based on slide-over state. -->
      <div class="absolute inset-0 bg-gray-600 opacity-50" aria-hidden="true"></div>

      <div class="absolute inset-y-0 pl-16 max-w-full right-0 flex">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <transition
            name="display-user-creation"
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
        <div v-show="navigationMenuStore.showSlideOverCategories" class="w-screen max-w-md">
          <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl" v-on:submit.prevent="createUser">
            <div class="flex-1 h-0 overflow-y-auto">
              <div class="py-6 px-4 bg-bpblue sm:px-6">
                <div class="flex items-center justify-between">
                  <h2 class="text-lg font-medium text-white" id="slide-over-title">
                    {{ $t('products.categories') }}
                  </h2>
                  <div class="ml-3 h-7 flex items-center">
                    <button type="button" v-on:click="navigationMenuStore.toggleSlideOverCategories" class=" rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="mt-1">
                  <p class="text-sm text-gray-200">
                    {{ $t('products.text_category') }}
                  </p>
                </div>
              </div>
              <div class="flex-1 flex flex-col justify-between">
                <div class="px-4 divide-y divide-gray-200 sm:px-6">
                  <div class="space-y-6 pt-6 pb-5" v-if="is_edit_mode">
                    <div>
                      <label for="firstname"  class="block text-sm font-medium text-gray-900">
                        {{ $t('general.title') }}
                      </label>
                      <div class="mt-1">
                        <input type="text" name="title" v-model="request.title" id="title" class="block w-full shadow-sm sm:text-sm focus:ring-bpblue focus:border-bpblue border-gray-300 rounded-md">
                      </div>
                    </div>
                    <div>
                      <label for="lastname" class="block text-sm font-medium text-gray-900">
                        {{ $t('products.slug_text') }}
                      </label>
                      <div class="mt-1">
                        <input type="text" name="slug" v-model="slugValidated" id="slug" class="block w-full shadow-sm sm:text-sm focus:ring-bpblue focus:border-bpblue border-gray-300 rounded-md">
                      </div>
                    </div>
                  </div>

                  <section v-if="!is_edit_mode">
                    <div class="flow-root mt-6">
                      <ul class="-my-5 divide-y divide-gray-200">
                        <li class="py-4" v-for="category in productsStore.categories" :key="category.slug">
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p class="text-sm font-medium text-gray-900 truncate">
                                {{ category.title }}
                              </p>
                              <p class="text-sm text-gray-500 truncate">
                                {{ category.stats.products }} {{ (category.stats.products == 1)? $t('products.product') : $t('products.products')}}
                              </p>
                            </div>
                            <div class="flex items-center justify-end">
                              <a title="Preview" :href="'//' + academiesStore.academy.url + 'catalog/' + category.slug" target="_blank" class="inline-block mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" :aria-label="$t('products.preview')">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                              </a>

                              <button :title="$t('general.remove')" v-on:click="removeCategory(category.uuid)" :disabled="loading" class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" :aria-label="$t('products.remove_category')">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-6">
                      <button v-on:click="toggleEditMode" class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-brainspro-50">
                        +
                      </button>
                    </div>
                  </section>

                  <div class="pt-4 pb-6">
                    <div class="mt-4 flex text-sm">
                      <a href="#" class="group inline-flex items-center text-gray-500 hover:text-gray-900">
                        <!-- Heroicon name: solid/question-mark-circle -->
                        <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                        <span class="ml-2">
                          <a href="https://helpcenter.brainspro.com/es/articles/5713342-como-crear-y-asignar-categorias" target="_blank">{{ $t('products.href_help') }}</a>
                      </span>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="flex-shrink-0 px-4 py-4 flex justify-end">
              <button type="button" v-if="!is_edit_mode" v-on:click="navigationMenuStore.toggleSlideOverCategories" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                {{$t('general.close')}}
              </button>
              <button type="button" v-else v-on:click="toggleEditMode" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                {{$t('general.back')}}
              </button>
              <button type="submit" v-if="is_edit_mode" v-on:click="createCategory" :disabled="loading" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
                {{$t('general.save')}}
              </button>
            </div>
          </form>
        </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import customCategoryService from "@/services/product/CustomCategoryService";
import NotificationService from "@/services/notification/NotificationService";
import {useAuthUserStore} from "@/stores/auth";
import {useAcademiesStore} from "@/stores/academies";
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import {useProductsStore} from "@/stores/products";

export default {
  name: "CategoriesSlideOverComponent",
  data: function () {
    return {
      show: false,
      loading: false,
      is_edit_mode: false,
      request: {
        academy_uuid: "",
        title: "",
        slug: "",
      },
      categories: []
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()
    const academiesStore = useAcademiesStore()
    const navigationMenuStore = useNavigationMenuStore()
    const productsStore = useProductsStore()

    return {
      authUserStore,
      academiesStore,
      navigationMenuStore,
      productsStore
    }
  },
  created() {
    console.log("Se ha creado el listado de categorias correctamente")
  },
  computed: {
    slugValidated()
    {
       return this.request.title
          .toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '');

    }
  },
  methods: {
    toggleEditMode()
    {
      this.is_edit_mode = ! this.is_edit_mode
    },
    createCategory()
    {
      this.loading = true;
      let academy_uuid = this.authUserStore.user.academy.uuid;
      this.request.academy_uuid = academy_uuid
      this.request.slug = this.slugValidated

      customCategoryService.post(academy_uuid, this.request)
          .then((result) => {
            console.log("Resultado creación de la categoría", result)
            NotificationService.showNotification("La categoría se ha creado correctamente", "success")

            this.is_edit_mode = false;
            this.productsStore.getCategoriesAction()
          })
          .catch((error) => {
            console.log("Error en la creación de la categoría", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.loading = false;
            this.request.title = ""
            this.request.slug = ""
          })
    },
    removeCategory(categoryUuid)
    {
      this.loading = true
      customCategoryService.delete(categoryUuid)
          .then((result) => {
            console.log("Resultado borrado de la categoría", result)
            NotificationService.showNotification("La categoría se ha borrado correctamente", "success")

            this.productsStore.getCategoriesAction()
          })
          .catch((error) => {
            console.log("Error en el borrado de la categoría", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>