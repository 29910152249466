<template>
  <section class="pt-2 pb-6 md:py-5">
    <card-with-header-and-footer-component :loading="general.loading">
      <template v-slot:header>
        <div class="flex flex-row justify-between">
          <h3 class="mt-3 text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{ $t('products.general') }}</h3>
          <div v-if="!general.loading" class="">
            <label class="block text-sm font-medium leading-5 text-gray-600">Publicar producto</label>
            <ToggleComponent class="flex justify-end mt-1 -mb-1" v-bind:status="this.getProductStatus === 'publish'" :callback="changeProductStatus"
                             :product="$route.params.id" :key="productsStore.product.uuid"></ToggleComponent>
          </div>
        </div>

      </template>
      <div class="col-span-6">
        <label for="product_title" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('general.title') }}</label>
        <input id="product_title" v-model="title" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
      </div>

      <div class="mt-5">
        <label for="category" class="block text-sm font-medium text-gray-700">{{ $t('products.category') }}</label>
        <select id="category" name="category" v-model="category" :key="category" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-bpblue focus:border-bpblue sm:text-sm rounded-md">
          <option :value="''">{{ $t('products.select_category') }}</option>
          <option v-for="category in this.productsStore.categories" :key="category.uuid" :value="category.uuid">{{ category.title }}</option>
        </select>
      </div>

      <div class="col-span-6 mt-5">
        <label for="product_duration" class="block text-sm font-medium leading-5 text-gray-700">{{ $t('products.duration') }}</label>
        <input id="product_duration" v-model="duration" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
      </div>

      <div class="flex flex-row justify-between">
        <div class="col-span-6 mt-5">
          <label for="product_duration" class="block text-sm font-medium leading-5 text-gray-700 mb-2">{{ $t('products.featured_product') }}</label>
          <ToggleComponent v-bind:status="getProductFeatured" :callback="toggleFeaturedProduct" :product="$route.params.id" :key="productsStore.product.uuid"></ToggleComponent>
        </div>
      </div>

      <template v-slot:footer>
        <button type="button" v-on:click="general.showModalDeleteProduct = true" :disabled="general.loading" class="hidden mr-2 inline-flex float-left px-4 py-2 text-sm font-medium rounded-md text-red-600 hover:underline">
          <label class="ml-1 cursor-pointer">{{ $t('products.delete_project') }}</label>
        </button>

        <!-- Modal Component para borrado de cursos -->
        <Teleport to="body">
          <modal-component id="modal-unregister" v-if="general.showModalDeleteProduct" :onProcess="confirmModalDeleteProduct" :onCancel="closeModalDeleteProduct">
            <template v-slot:header>{{ $t('products.warning_delete') }}</template>
            <p>{{ $t('products.message_delete_project') }}</p>
            <p class="font-bold mt-2">{{ $t('products.question_delete_project') }}</p>
            <div class="mt-4">
              <input type="checkbox" id="checkbox-double-confirmation" v-model="general.doubleConfirmationDeleteProduct">
              <label class="ml-2">{{ $t('products.delete') }}</label>
              <div>
                <p class="mt-2 text-sm text-gray-400" id="error-firstname">
                  {{ $t('products.confirmation_delete_project') }}</p>
              </div>
            </div>
          </modal-component>
        </Teleport>

        <button type="button" v-on:click="getProductGeneral" :disabled="general.loading" class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.cancel') }}
        </button>

        <button type="button" v-on:click="setProductGeneral" :disabled="general.loading" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
          {{ $t('general.save') }}
        </button>
      </template>
    </card-with-header-and-footer-component>
  </section>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import ToggleComponent from "@/components/forms/ToggleComponent";
import NotificationService from "@/services/notification/NotificationService";
import {useProductsStore} from "@/stores/products";
import productService from "../../../services/product/ProductService";
import notificationService from "../../../services/notification/NotificationService";
import ModalComponent from "../../../components/modals/ModalComponent";
import {useAuthUserStore} from "@/stores/auth";

export default {
  name: "GeneralComponent",
  components: {CardWithHeaderAndFooterComponent, ToggleComponent, ModalComponent},
  setup(){

    const productsStore = useProductsStore()
    const authUserStore = useAuthUserStore()

    return{
      authUserStore,
      productsStore
    }
  },
  data() {
    return {
      general: {
        loading: true,
        showModalDeleteProduct: false,
        doubleConfirmationDeleteProduct: false,
      },
    }
  },
  created() {
    this.productsStore.getCategoriesAction()
    this.getProductGeneral()
  },
  methods: {
    getProductGeneral() {
      this.productsStore.getProduct(this.$route.params.id)
      this.general.loading = false;
    },
    setProductGeneral() {
      console.log("Guardando producto")
      this.productsStore.setProductGeneral()
          .then((response) => {
            console.log("Almacenando seccion page del producto", response)
            NotificationService.showNotification("La operación se ha realizado correctamente", "success")
          })
          .catch((error) => {
            console.log("Ha ocurrido un error almacenando el producto", error)
            if (error.response.data.errors)
            {
              error.response.data.errors.forEach( (error) => {
                NotificationService.showNotification(error, "error")
              })
            }
            else
            {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
          .finally(() => {
            this.productsStore.loading = false
          })
    },
    toggleFeaturedProduct(uuid, value) {
      this.productsStore.product.featured = value
    },

    closeModalDeleteProduct() {
      this.general.showModalDeleteProduct = false
      this.general.doubleConfirmationDeleteProduct = false
    },

    confirmModalDeleteProduct() {
      if (this.general.doubleConfirmationDeleteProduct)
      {
        console.log("Borrando curso...")
        //this.general.loading = true
        this.general.showModalDeleteProduct = false
        this.general.doubleConfirmationDeleteProduct = false
        //Llamar a servicio
        productService.delete()
            .then((response) =>{
              console.log("Delete product devuelve: ", response)
              //Una vez borrado...
              notificationService.showNotification("El curso se ha eliminado correctamente", "success")
              this.$router.go(-1)
            })
            .catch((error) => {
              console.log("Se ha producido un error: ", error)
            })
            .finally(() => {
              this.general.loading = false
            })
      }
    },

    changeProductStatus(product_uuid, status) {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      console.log("Cambiando el estado del producto", product_uuid, status)
      productService.changeProductStatus(academy_uuid, product_uuid, status)
          .then((response) => {
            console.log("Se ha cambiado  correctamente", response)
            var message = (status) ? "Se ha publicado el curso" : "Se ha despublicado el curso"
            NotificationService.showNotification(message, "success")
            this.getProductGeneral()
          })
          .catch((response) => {
            console.log("Error solicitando el cambio de estado del producto", response)
            NotificationService.showNotification("Ha ocurrido un error inesperado", "error")

          })
          .finally(() => {
            //Silence is golden
          })
    }
  },
  computed: {
    getProductFeatured() {
      return this.productsStore.product.featured === 1 || this.productsStore.product.featured
    },
    getProductStatus() {
      return this.productsStore.product.status
    },
    title: {
      get() {
        return this.productsStore.product.title
      },
      set(value) {
        this.productsStore.product.title = value
      }
    },
    duration: {
      get() {
        return this.productsStore.product.content.duration
      },
      set(value) {
        this.productsStore.product.content.duration = value
      }
    },
    category: {
      get() {
        return this.productsStore.product.category
      },
      set(value) {
        this.productsStore.product.category = value
      }
    }
  }
}
</script>

<style scoped>

</style>