export default {
    permits: 'Permisos',
    notifications: 'Notificaciones',
    plans_billing: 'Planes y Facturación',
    integrations: 'Integraciones',
    update_info: 'Actualice sus datos de facturación. Tenga en cuenta que la configuración de su ubicación podría afectar a los tipos impositivos.',
    payment_details: 'Datos de pago',
    expiration_date: 'Fecha de expiración',
    country: 'País / Región',
    postal: 'Código Postal / ZIP',
    current_plan: 'Plan actual',
    business: 'Negocio',
    postings_job_25: 'Hasta 25 ofertas de empleo activas',
    enterprise: 'Empresa',
    unlimited_postings_job: 'Ofertas activas ilimitadas de empleo',
    pricing_plans: 'Poner precio a los planes',
    startup: 'Startup',
    postings_job_5: 'Hasta 5 ofertas de empleo activas',
    user_settings: 'Configuración del usuario',
    annual_billing: 'Facturación Anual',
    save_10: '(Save 10%)',
    billing_history: 'Historial de facturación',
    save: 'Guardar',
    date: 'Fecha',
    description: 'Descripción',
    amount: 'Cantidad',
    format_date: 'MM / YY',
    spain: 'España',
    canada: 'Canadá',
    mexico: 'México',

    //SubscriptionComponent
    subscription_plan: 'Plan de suscripción',
    basic: 'Básico',
    basic_price: '26€',
    per_month: '/mes',
    basic_info: 'La opción perfecta para comenzar en el mundo de la enseñanza online.',
    unlimited_projects: 'Cursos Ilimitados',
    unlimited_students: 'Alumnos Ilimitados',
    to_3_teachers: 'Hasta 3 docentes',
    subdomain: 'Subdominio',
    take_plan: 'Quiero este plan',
    advance: 'Avanzado',
    advance_price: '89€',
    advance_info: 'Impulsa tu academia online, todo lo del Plan Básico y más.',
    own_domain: 'Dominio propio',
    private_label: 'Marca Blanca',
    to_10_teachers: 'Hasta 10 docentes',
    report_gen: 'Generación de informes',
    pro: 'Pro',
    pro_price: '152€',
    pro_info: 'Escala tu academia online. Todo lo del avanzado y más.',
    no_comissions: '0% de comisión de venta',
    api_access: 'Acceso a API',
    app_integrations: 'Integración con APPs',
    support_prior: 'Soporte prioritario',
    //CurrentSubscriptionsComponent
    actual_subscriptions: 'Suscripciones actuales',
    attention: 'Atención',
    att_info1: 'Si desea cambiar su plan contacte con ',
    contact_email: 'billing@brainspro.com',
    att_info2: 'Si desea contratar una suscripción, complete la información disponible ',
    here: 'aquí',
    ends_on: 'Finaliza el ',
    trial_ends_on: 'El periodo de prueba finaliza el: ',
    view_receipt: 'Ver recibo',
    cancel_subscription: 'Cancelar Suscripción',
    modal_cancel_sub_text: 'Este cuenta entrará en un estado de suspensión. Las funcionalidades serán restringidas para los usuarios de la academia, docentes y dueños.',
    modal_cancel_sub_question: '¿Seguro que quieres hacerlo?',

    //ChangeSubscriptionComponent
    message_subscription_expire: 'Tu cuenta en BrainsPro ha expirado. Activa un plan para recuperar el acceso.',
    update_your_plan: 'Actualiza tu plan',
    monthly_billing: 'Mensual',
    yearly_billing: 'Anual',
    text_all_the_basics: 'Todo lo básico para iniciar un nuevo negocio',
    button_activate: 'Activar',
    button_current_plan: 'Plan Actual',
    what_include: 'Que incluye:',
    text_tax_not_include: 'IVA no incluido',
    month: 'Mes',
    year: 'Año',
}