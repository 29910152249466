import {useAuthUserStore} from "@/stores/auth";

export default {
    index(academyUuid, filters = {} ) {
        console.log(academyUuid, filters);
        return useAuthUserStore().getApiClient.get('/saas/subscriptions', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },

    post(academyUuid, priceUuid) {
        return useAuthUserStore().getApiClient.post('/saas/subscriptions', {
            academy_uuid: academyUuid,
            price_uuid: priceUuid
        })
    }
}