<template>
  <div class="max-w-7xl mx-auto py-3 md:py-6 sm:px-8 lg:pt-8">
    <header-component :title="$t('sales.sales')"></header-component>

    <nav class="mt-4 lg:ml-8 ml-4 mb-8">
      <div class="">
        <div class="">
          <nav class="-mb-px flex md:space-x-8 sm:mx-auto " aria-label="Tabs">
            <router-link to="/reports/" exact-active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="shopping-cart" size="lg" />
              <span>{{ $t("sales.orders") }}</span>
            </router-link>
            <router-link to="/reports/payouts" active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="wallet" size="lg" />
              <span>{{ $t("sales.payments") }}</span>
            </router-link>
            <router-link to="/reports/subscriptions" active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <font-awesome-icon class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6" icon="piggy-bank" size="lg" />
              <span>{{ $t("sales.subscriptions") }}</span>
            </router-link>
            <router-link to="/reports/balance" active-class="bg-white text-bpblue hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium shadow-sm" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 group-hover:text-gray-700 flex-shrink-0 -ml-1 mr-3 h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
              </svg>

              <span>Balance</span>
            </router-link>
          </nav>
        </div>
      </div>
    </nav>

    <router-view></router-view>
  </div>
</template>

<script>
import HeaderComponent from "@/views/common/HeaderComponent";

export default {
  name: "ReportsComponent",
  components: {
    HeaderComponent,
  }
}
</script>

<style scoped>

</style>