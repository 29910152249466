export default {
    create_account: 'Crea una cuenta',
    login: 'inicia sesión',
    access_with: 'Accede con',
    continue_with: 'O contunia con',
    error_text1: 'Upps! Hay',
    error_text2: 'en tu solicitud.',
    privacy_text1: 'He leído y acepto la Política de privacidad. Para más información, consulta nuestra ',
    privacy_text2: 'política de privacidad',
    marketing_text: 'Consiento de manera previa y expresa que BrainsPro me envíe comunicaciones comerciales sobre sus servicios.',
    want_academy: '¡Quiero mi academia!',
    ad_text1: 'Convierte tus cursos online en ',
    ad_text2: 'experiencias de aprendizaje',
    ad_text3: ' de alto impacto',
    ad_text4: 'Tus estudiantes agradecerán la calidad de tus servicios y tendrás todo bajo control de forma sencilla',
    button_next: 'Siguiente',
    start: 'Crear Cuenta',
    error_firstname_required: 'Debes introducir tu nombre',
    error_lastname_required: 'Debes introducir tus Apellidos',
    error_email_address_required: 'Debe introducir un correo electrónico válido',
    error_academy_slug_required: 'Debe introducir un dominio válido',
    error_phone_required: 'Debe introducir un número teléfono válido',
    error_pass_required: 'Debe introducir una contraseña válida (mínimo 8 caracteres)',
    error_confirm_pass_required: 'Debe ser la misma contraseña que la anterior',
    error_domain_used: 'El dominio está siendo usado, introduzca otro.',
    error_email_used: 'Este email ya existe. Por favor inicia sesión o crea tu cuenta con otro email',
    subdomain: 'Subdominio',

    //CustomerDataComponent
    title_create_your_academy: 'Ya casi estamos...',
    question_goal: '¿Cuál es tu principal objetivo en Brainspro?',
    select_goal: 'Selecciona un objetivo',
    question_volume: '¿Cuantas personas quieres formar?',
    select_volume: 'Selecciona un rango aproximado',
    question_sector: '¿Cuál es tu sector de actividad?',
    select_sector: 'Selecciona un sector',
    question_area: '¿Cuál es tu área profesional?',
    select_area: 'Selecciona un área',
    back: 'Volver',

}