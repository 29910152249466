<template>
  <div class="py-4">
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg">
            <div v-for="project in projects" :key="project.uuid" class="bg-white border border-gray-200 shadow mb-4 transition-colors ease-in">
              <div class="px-6 py-4 whitespace-no-wrap  border-b border-gray-200">
                <div class="flex items-center">
                  <div class="ml-4">
                    <div class="text-sm leading-5 font-medium text-gray-900">{{project.title}}</div>
                    <div class="text-sm leading-5 text-gray-500">{{ $t('projects.update_at') }} {{$moment(project.updated_at).format("LLLL")}}</div>
                    <div class="flex flex-row justify-between">
                      <ul class="mt-1">
                        <li class="text-sm leading-5 text-brainspro-800 rounded mr-2 inline bg-brainspro-100 py-1 px-3" > {{project.type.title}} </li>
                      </ul>
                      <label class="inline-flex">
                        <p class="text-gray-600">
                          {{project.students_count}}
                        </p>
                        <span class="shadow-sm rounded-md">
                        <button type="button" v-on:click="exportUsers(project.uuid)" class=" inline-flex items-center ml-3 mt-0.5 transition ease-in text-sm font-medium rounded text-bpblue hover:text-blue-600">
                        <!-- Heroicon name: document-download -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                        </svg>
                        </button>
                      </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                <div class="flex items-center justify-center" v-if="project && project.uuid">
                  <router-link :to="{ name: 'projects-edit', params: { id : project.uuid }}" class=" inline-block mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </router-link>
                  <!-- No se redirige a la edición de proyectos
                  <a title="Preview" :href="'https://' + project.link + '?preview=true'" target="_blank" class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" :aria-label="$t('projects.new_project')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                  </a>
                  -->
                  <div v-if="project.type && project.type.title !== 'LMS'">
                    <button title="Aula" v-on:click="goToMoodleCourse(project.uuid)" :disabled="loadingLMS"
                            class="mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
                            :aria-label="$t('projects.new_project')">
                      <svg v-if="!loadingLMS" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z"/>
                        <path fill="#fff"
                              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"/>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"/>
                      </svg>
                      <font-awesome-icon v-else
                                         class="h-7 w-7 text-gray-400 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin"
                                         icon="spinner" size="lg"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProjectService from "@/services/project/ProjectService";
import {useAuthUserStore} from "@/stores/auth";
import userService from "@/services/user/UserService";
import NotificationService from "@/services/notification/NotificationService";

export default {
  name: "ProjectsListComponent",
  components: {},
  props: {
    projects: Array
  },
  data: () => {
    return {
      loadingLMS: false
    }
  },
  setup(){

    const authUserStore = useAuthUserStore();

    return {
      authUserStore
    }
  },
  methods:{
    goToMoodleCourse(uuid) {
      console.log("Redirección a moodle course")
      this.loadingLMS = true
      ProjectService.getMoodleCourseURL(uuid)
          .then((response) => {
            if(response.data.link)
            {
              console.log("Abriendo el curso " + uuid, response.data.link)

              window.open(response.data.link);
            }
          })
          .finally(() => {
            this.loadingLMS = false
          })
    },

    exportUsers(projectUuid)
    {
      let academy_uuid = this.authUserStore.user.academy.uuid;
      console.log("El project uuid es: ", projectUuid)

      userService.downloadUserOnProject(academy_uuid, projectUuid)
          .then((response) => {
            console.log("Descargando", response)
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users.csv');
            document.body.appendChild(link)
            link.click()
          })
          .catch((error) => {
            console.log("Error en la descarga de usuarios", error.response)
            if (error.response.data.errors) {
              error.response.data.errors.forEach((error) => {
                NotificationService.showNotification(error, "error")
              })
            } else {
              NotificationService.showNotification("Ha ocurrido un error inesperado", "error")
            }
          })
    },
  }
}
</script>

<style scoped>

</style>