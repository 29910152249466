export default {
    create_account: 'Create an account',
    loggin: 'login',
    access_with: 'Access with',
    continue_with: 'Or continue with',
    error_text1: 'Oops! There is',
    error_text2: 'in your request.',
    privacy_text1: 'I have read and accept the Privacy Policy. For more information, please see our ',
    privacy_text2: 'privacy policy',
    marketing_text: 'I previously and expressly consent to BrainsPro sending me commercial communications about its services.',
    want_academy: 'I want my academy!',
    ad_text1: 'Turn your online courses into ',
    ad_text2: 'learning experiences',
    ad_text3: ' high impact',
    ad_text4: 'Your students will appreciate the quality of your services and you will have everything under control in a simple way.',
    button_next: 'Next',
    start: 'Create an Account',
    error_firstname_required: 'You must enter your name',
    error_lastname_required: 'You must enter your lastname',
    error_email_address_required: 'You must enter a valid e-mail address',
    error_phone_required: 'You must enter a valid phone number',
    error_pass_required: 'You must enter a valid password (minimum 8 characters).',
    error_confirm_pass_required: 'Must be the same password as above',
    error_email_used: 'This email already exists. Please login or create your account with another email address',
    subdomain: 'Subdomain',

    //CustomerDataComponent
    title_create_your_academy: 'We are almost there...',
    question_goal: 'What is your main objective at Brainspro?',
    select_goal: 'Select a target',
    question_volume: 'How many people do you want to train?',
    select_volume: 'Select an approximate range',
    question_sector: 'What is your sector of activity?',
    select_sector: 'Select a sector',
    question_area: 'What is your professional area?',
    select_area: 'Select an area',
    back: 'Back'
}