<template>
  <div class="bg-white rounded-lg shadow-inner shadow-stone-200 border border-stone-300 h-32 relative">
    <div v-if="this.$props.loading" class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
      <font-awesome-icon class="my-1 h-10 w-10 text-3xl text-stone-500 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin" icon="spinner" size="lg" />
    </div>
    <div v-else class="flex flex-col p-6 text-center">
      <dt class="order-2 mt-2 text-sm md:text-base lg:text-lg leading-6 font-medium text-stone-500 capitalize">
        <slot></slot>
      </dt>
      <dd class=" order-1 text-xl lg:text-4xl font-extrabold" :class="textColor">
        {{ this.$props.quantity }}
      </dd>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultCardComponent",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    quantity: {
      default: 0
    },
    textColor: {
      type: String,
      default: "text-bpblue"
    }
  }
}
</script>

<style scoped>

</style>