<template>
  <div class="pt-6 pb-6">
    <div class="max-w-5xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="w-full relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex items-center justify-between">
          <span class="pr-3 bg-gray-100 text-xl font-medium text-gray-900">
            {{ $t('projects.attached_products') }}
          </span>
          <button type="button" v-on:click="navigationMenuStore.toggleSlideOverAttachProduct" class="inline-flex justify-center px-4 py-2 cursor-pointer border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-2 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span class="">{{ $t('projects.attach_product') }}</span>
          </button>
        </div>
      </div>

      <div v-show="loading" class="flex justify-center mt-8 p-4 text-sm bg-white rounded-lg">
        <div class=" text-yellow-700">
          <svg class="inline mr-3 w-4 h-4 text-blue-700 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
          </svg>
        </div>
        <span class="ml-1 text-lg text-gray-600">
              {{ $t('projects.loading_attached_products') }}
            </span>
      </div>

      <div v-if="this.projectStore.project.products" class="mt-8 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
        <table class="min-w-full">
          <thead>
          <tr>
            <!-- No mostraremos nada en la tabla -->
          </tr>
          </thead>
          <tbody class="bg-white">
          <tr v-for="product in this.projectStore.project.products" :key="product.uuid" class="hover:bg-gray-50 transition-colors ease-in">
            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
              <div class="flex items-center">
                <div class="flex-shrink-0 h-14">
                  <img v-if="product.thumbnail" class="h-14 rounded" :src="product.thumbnail" alt="">
                  <div v-else class="h-14 w-28 bg-gray-400 rounded"></div>
                </div>
                <div class="ml-4">
                  <div class="text-sm leading-5 font-medium text-gray-900">{{product.title}}</div>
                  <div class="text-sm leading-5 text-gray-500">{{ $t('community.updated_at') }} {{$moment(product.updated_at).format("LLLL")}}</div>
                </div>
              </div>
            </td>
            <td class="hidden px-6 py-4 whitespace-no-wrap border-b border-gray-200">
              <div class="flex overflow-hidden hidden">
                <img class="inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80" alt="">
                <img class="-ml-2 inline-block h-8 w-8 rounded-full text-white shadow-solid" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
              </div>
              <span class="text-sm leading-5 text-gray-500">{{ $t('general.coming_soon') }}</span>
            </td>
            <td class="hidden px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
              {{ $t('general.coming_soon') }}
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
              <div class="flex items-center justify-end">

                <button title="Unbundle_product" id="Unbundle_product" @click="clickOnUnbunble(product.uuid)" class="group mr-2 p-1 h-7 w-7 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" :aria-label="$t('community.to_cancel')">
                  <svg v-if="!loadingDelete" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="group-hover:text-red-600 transition-colors" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  <font-awesome-icon v-else class="h-7 w-7 text-gray-400 group-hover:text-gray-600 group-focus:text-gray-600 animate-spin" icon="spinner" size="lg" />
                </button>
              </div>
              <!-- Modal para mostrar al desasociar el proyecto -->
              <Teleport to="body">
                <modal-component id="modal-unregister" v-if="showModalDelete" :onProcess="confirmModalDelete" :onCancel="closeModalDelete" :loading="loadingDelete">
                  <template v-slot:header>{{ $t('projects.unattach_product') }}</template>
                  <p>{{ $t('projects.info_unattach_product') }}</p>
                  <p class="font-bold mt-2">{{ $t('projects.quest_unattach_product') }}"{{product.title}}" ?</p>
                </modal-component>
              </Teleport>

            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="flex justify-center mt-8 font-bold text-gray-400 text-sm border border-2 border-gray-300 p-8 rounded-lg">
        <div>{{ $t('projects.no_product_attached') }}</div>
      </div>
    </div>

    <!-- Replace with your content -->
    <teleport to="body">
      <attach-product-slide-over-component></attach-product-slide-over-component>
    </teleport>

    <!-- /End replace -->
  </div>
</template>

<script>
import {useAuthUserStore} from "@/stores/auth";
import {useNavigationMenuStore} from "@/stores/navigationMenu";
import {useProjectsStore} from "@/stores/projects"
import AttachProductSlideOverComponent from "@/views/projects/ProjectEdit/AttachProduct/AttachProductSlideOverComponent";
import projectAttachService from "@/services/project/ProjectAttachProductService";
import ModalComponent from "@/components/modals/ModalComponent";

export default {
  name: "ProjectAttachProductComponent",
  components: { AttachProductSlideOverComponent, ModalComponent },
  data: () => {
    return {
      loading: false,
      loadingDelete: false,
      showModalDelete: false,
      productSelected: '',
    }
  },
  setup () {

    const authUserStore = useAuthUserStore()
    const navigationMenuStore = useNavigationMenuStore()
    const projectStore = useProjectsStore()

    return {
      authUserStore,
      navigationMenuStore,
      projectStore
    }
  },
  created() {

  },
  methods: {
    deleteProject() {
      this.loadingDelete = true
      console.log("El uuid del producto a borrar es: ", this.productSelected)
      let project_uuid = this.projectStore.project.uuid
      projectAttachService.delete(project_uuid, this.productSelected)
          .then((response) => {
            console.log("La respuesta del servidor al borrar es: ", response)
          })
          .catch((error) => {
            console.log("Error al desasociar: ", error)
          })
          .finally(() => {
            //Volverá a recargarse los cursos para mostrarlo correctamente.
            this.projectStore.getProject(project_uuid)
            this.loadingDelete = false
            this.showModalDelete = !this.showModalDelete
          })
    },
    clickOnUnbunble (project_uuid) {
      this.showModalDelete = !this.showModalDelete
      this.productSelected = project_uuid
    },
    confirmModalDelete() {
      this.deleteProject()
    },
    closeModalDelete() {
      this.showModalDelete = false
    }
  }
}
</script>

<style scoped>

</style>