export default {
    home: "Startseite",
    projects: "Kurse",
    community: "Gemeinschaft",
    customization: "Personalisierung",
    reports: "Berichte",
    marketing: "Marketing",
    settings: "Anpassungen",
    my_account: 'Mein Konto',
    annual_sales: "Jährliche Verkäufe",
    sales: "Vertrieb",
    students: "Studenten",
    teachers: "Lehrer",
    products: "Produkte",
    project: "Projekte",
    categories: "Kategorien",
    category: 'Kategorie',
    published: "Veröffentlicht",
    name: "Name",
    lastname: 'Nachname',
    email: "Email",
    phone: "Telefon",
    title: "Titel",
    pass: 'Passwort',
    pass_confirm: 'Bestätigung des Passworts',
    coming_soon: "Demnächst verfügbar",
    month: "Monat",
    year: "Jahr",
    date: "Datum",
    plan: 'Plan',
    pvp: "UVP",
    net: "Netz",
    total: 'Insgesamt',
    state: "Staat",
    delete: "Löschen",
    profile: "Profil",
    enrollments: "Einschreibungen",
    cancel: "Abbrechen",
    save: "Speichern Sie",
    remove: 'Löschen',
    email_address: 'Postanschrift',
    image_format: 'PNG, JPG, GIF bis zu 3 MB',
    close: 'Schließen',
    back: 'Zurück zu',
    coming_soon_brainspro: 'Demnächst bei Brainspro',
    and: 'y',
    product: "Produkt",
    date_creation: 'Datum der Erstellung',
    type: 'Typ',

    //AppComponent
    my_profile: 'Mein Profil',
    adjust: 'Anpassungen',
    out: 'Ausfahrt',
    search: 'Suche',
    academy_preview: 'Vorschau Akademie',
    my_academy: 'Meine Akademie',
    new_project: 'Neuer Kurs',
    text_current_period: 'Ihre Probezeit bis ',
    text_end_at: ' endet in ',
    plans: 'Abonnement-Pläne',
    billing: 'Rechnungen',

    //ThankYouPageComponent
    thankyou: 'Dankeschön!',
    go_to_academy: 'Go to the academy',

    //NavigationDesktop - Mobile
    records: 'Aufnahmen',
    videos: 'Videos'

}