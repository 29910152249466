export default {
    coupons: "Cupones",
    scripts: "Scripts encabezado/pie de página",
    integrations: "Integraciones",
    //CouponProductListComponent
    activated: "Activado",
    end_at: "Finaliza el",
    related_product: "Productos asociados",
    associate_product: "Asociar producto",
    select_product: "Selecciona un producto",
    project_name: 'Nombre del curso',
    update_at: 'Actualizado el ',
    to_cancel: 'Dar de baja',

    //CouponComponent
    create_coupon: 'Crear Cupón',
    coupon_data: 'Datos del cupón',
    code: 'Código',
    quantity: 'Cantidad',
    offer_type: 'Tipo de descuento',
    offer_text: 'Selecciona tipo de descuento...',
    percent_offer: 'Descuento en porcentaje',
    fixed_offer: 'Descuento fijo de producto',
    duration: 'Duración',
    delete_coupon: 'Borrar Cupón',
    info_delete_coupon: 'Este cupón se borrará definitivamente',
    quest_delete_coupon: '¿Seguro que quieres borrar este cupón?',
    view_receipt: 'Ver recibo',
    trial_ends_on: 'El periodo de prueba finaliza el: ',
    error_code_required: 'Introduce un código de cupón',
    error_amount_required: 'Introduce la cantidad a descontar',

    //Integrations
    clientify: 'Clientify',
    clientify_info_text: 'Clientify es un CRM que te ayuda a organizar todos tus clientes, oportunidades, embudos de venta, automatizaciones...',
    whatsapp: 'Whatsapp',

    //GoogleAnalytics
    title_ga: 'Google Analytics',
    description_text: 'Introduce el código html de Google Analytics',

    //CustomGlobalHTML
    title_html: 'HTML Personalizado',
    header_script: 'Scripts de encabezado',
    footer_script: 'Scripts de pie de página',
    alert_message: 'Si el código es incorrecto puede que la web de tu academia no funcione correctamente. El siguiente código se introducirá en la etiqueta header y/o footer',

    //Clientify
    //Whatsapp
    telephone_number: 'Número de Teléfono',
    //GooglePixel
}