export default {
    log_account: 'Inicia Sesión',
    create_account: 'crea una cuenta gratis',
    trial_info: 'empieza tu prueba de 14 días',
    log_with: 'Accede con',
    continue_with: 'O continua con ',
    attention: 'Atención solo administradores',
    welcome_text1: 'Bienvenidos a Brainspro v2. ',
    admin_access: 'Acceso solo para administradores',
    welcome_text2: 'Si eres alumno o docente, accede a tu curso desde la web de tu academia.',
    email_address: 'Dirección de correo',
    remember_me: 'Recuérdame',
    forgot_pass: '¿Olvidaste tu contraseña?',
    access: 'Acceder',
    ad_text1: 'Convierte tus cursos online en',
    ad_text2: 'experiencias de aprendizaje',
    ad_text3: 'de alto impacto',
    ad_text4: 'Tus estudiantes agradecerán la calidad de tus servicios y tendrás todo bajo control de forma sencilla.',
    //RememberPasswordComponent
    recovery_password: 'Recupera tu contraseña',
    email_input_placeholder: 'Introduce tu email',
    information_email_send: 'Se enviará un email de confirmación para recuperar su contraseña',
    information_go_email_inbox: 'Por favor acceda a su correo y haga clic en el enlace para restaurar su contraseña. Si no encuentra el correo revise su carpeta de SPAM.',
    error_email_invalid: 'Debe introducir un correo válido',
    button_text: 'Recuperar contraseña',
    //ResetPasswordComponent
    restore_password: 'Restaura tu contraseña',
    new_password: 'Nueva contraseña',
    password_placeholder: 'Introduce tu nueva contraseña',
    error_password: 'La contraseña debe tener un mínimo de 8 caracteres',
    confirm_password: 'Confirmar contraseña',
    password_confirm_placeholder: 'Introduce tu nueva contraseña',
    error_password_confirm: 'Las contraseñas no coinciden',
    button_reset_password: 'Restaurar contraseña',
    success_password_change: "Contraseña actualizada.",



}