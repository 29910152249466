import * as filestack from 'filestack-js';
import {useAuthUserStore} from "@/stores/auth";

let filestackClient = filestack.init('AUawhu5CTLCM9Wjbqlmr9z');

export default {
    index(academyUuid) {
        return useAuthUserStore().getApiClient.get('/academy/logo', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },

    update(academyUuid, data)
    {
        return useAuthUserStore().getApiClient.put('/academy/logo', data, {
            params: {
                academy_uuid: academyUuid
            }
        })
    },

    delete(academyUuid)
    {
        return useAuthUserStore().getApiClient.delete('/academy/logo', {
            params: {
                academy_uuid: academyUuid
            }
        })
    },

    openPicker(callback)
    {
        /*
        * Opciones predefinidas para FileStack Logo
        */
        const options = {
            accept: ['image/*'],
            fromSources: ["local_file_system", "imagesearch", "facebook", "instagram", "googledrive", "dropbox"],
            lang: 'es',
            maxSize: 3*1024*1024,
            maxFiles: 1,
            minFiles: 1,
            imageMax: [256, 256],
            transformations: {
                crop: {
                    aspectRatio: 1 / 1,
                    force: true
                }
            },
            //onOpen: () => console.log('opened!'),
            onUploadDone: callback
        };

        return filestackClient.picker(options).open()
    }
}