<template>
  <card-with-header-and-footer-component :loading="customGlobalHtml.loading">
    <template v-slot:header>
      <h3 class="text-lg font-semibold leading-5 text-gray-900 sm:truncate">{{$t('marketing.title_html')}}</h3>
    </template>

    <div class="sm:flex sm:flex-row md:flex md:flex-row lg:flex ">
      <div class="lg:mr-6 md:mr-3 lg:w-1/2">
        <label class="mt-2 text-md text-gray-600">{{$t('marketing.header_script')}}</label>
        <textarea v-model="customGlobalHtml.header.value" class="mt-2 h-40 w-full bg-gray-600 text-white"></textarea>
        <div class="flex justify-end">
          <button type="button" v-on:click="getHTMLHeader" :disabled="customGlobalHtml.loading"
                  class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.cancel') }}
          </button>

          <button type="button" v-on:click="setHTMLHeader" :disabled="customGlobalHtml.loading"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
      <div class="justify-self-auto xs:mt-6 lg:w-1/2">
        <label class="mt-2 text-md text-gray-600">{{$t('marketing.footer_script')}}</label>
        <textarea v-model="customGlobalHtml.footer.value" class="mt-2 h-40 w-full bg-gray-600 text-white"></textarea>
        <div class="flex justify-end">
          <button type="button" v-on:click="getHTMLFooter" :disabled="customGlobalHtml.loading"
                  class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.cancel') }}
          </button>

          <button type="button" v-on:click="setHTMLFooter" :disabled="customGlobalHtml.loading"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-bpblue hover:bg-bpbluedeep focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bpblue">
            {{ $t('general.save') }}
          </button>
        </div>

      </div>
    </div>


    <template v-slot:footer>
      <alert-component class="">
        {{$t('marketing.alert_message')}}
      </alert-component>
    </template>
  </card-with-header-and-footer-component>
</template>

<script>
import CardWithHeaderAndFooterComponent from "@/components/panels/CardWithHeaderAndFooterComponent";
import AlertComponent from "@/components/feedback/alerts/AlertComponent";
import {useRecordingsStore} from "@/stores/recordings";
import {useAcademiesStore} from "@/stores/academies";
import {useAuthUserStore} from "@/stores/auth";
import IntegrationService from "@/services/marketing/IntegrationService";

export default {
  name: "CustomGlobalHTML",
  components: {
    CardWithHeaderAndFooterComponent,
    AlertComponent
  },
  data() {
    return {
      customGlobalHtml: {
        loading: false,
        header: {
          value: '',
          type: 'head'
        },
        footer: {
          value: '',
          type: 'footer'
        },
      },
      clickOnSave: false,
      clickOnCancel: false,
      responseOk: true,
    }
  },
  mounted() {
    this.getHTMLHeader()
    this.getHTMLFooter()
  },
  setup(){
    const recordingsStore = useRecordingsStore()
    const academiesStore = useAcademiesStore()
    const authUserStore = useAuthUserStore()

    return {
      recordingsStore,
      academiesStore,
      authUserStore
    }
  },
  methods: {
    getHTMLHeader() {
      //TODO Llamar al service
      this.customGlobalHtml.loading = true
      let codeHTML = {
        academy_uuid: this.academiesStore.academy.uuid,
        type: this.customGlobalHtml.header.type
      }
      IntegrationService.indexHTMLHead(codeHTML)
          .then((response) => {
            console.log("Se ha obtenido el header", response)
            this.customGlobalHtml.header.value = response.data.code
          })
          .catch((error) => {
            console.log("Error al obtener el código header", error)
          })
          .finally(() => {
            this.customGlobalHtml.loading = false
          })
    },
    setHTMLHeader() {
      this.customGlobalHtml.loading = true

      let codeHTML = {
        academy_uuid: this.academiesStore.academy.uuid,
        type: this.customGlobalHtml.header.type,
        value: this.customGlobalHtml.header.value
      }
      IntegrationService.storeHTML(codeHTML)
          .then((response) => {
            console.log("Se ha guardado correctamente: ", response)
          })
          .catch((error) => {
            console.log("Error al guardar el código personalizado HTML en el header.", error)
          })
          .finally(() => {
            this.customGlobalHtml.loading = false
          })
    },
    getHTMLFooter() {
      //TODO Llamar al service
      this.customGlobalHtml.loading = true
      let codeHTML = {
        academy_uuid: this.academiesStore.academy.uuid,
        type: this.customGlobalHtml.footer.type
      }
      IntegrationService.indexHTMLHead(codeHTML)
          .then((response) => {
            this.customGlobalHtml.footer.value = response.data.code
          })
          .catch((error) => {
            console.log("Error al obtener el código header", error)
          })
          .finally(() => {
            this.customGlobalHtml.loading = false
          })
    },
    setHTMLFooter() {
      this.customGlobalHtml.loading = true

      let codeHTML = {
        academy_uuid: this.academiesStore.academy.uuid,
        type: this.customGlobalHtml.footer.type,
        value: this.customGlobalHtml.footer.value
      }
      console.log("Academy uuid", codeHTML)
      IntegrationService.storeHTML(codeHTML)
          .then((response) => {
            console.log("Se ha guardado correctamente: ", response)
          })
          .catch((error) => {
            console.log("Error al guardar el código personalizado HTML en el header.", error)
          })
          .finally(() => {
            this.customGlobalHtml.loading = false
          })
    },

  }
}
</script>

<style scoped>

</style>