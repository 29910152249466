<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm">
        <div class="text-center">
          <img style="height: 5rem" class="mx-auto" src="/images/logo-bp-noclaim.png" alt="Brainspro">
          <h2 class="text-3xl leading-9 font-bold text-gray-800">
            {{ $t('signup.title_create_your_academy') }}
          </h2>
        </div>
        <form action="#" method="POST" @submit.prevent="signup">
          <div class="mt-8">
            <label class="block text-sm font-medium text-gray-700">{{ $t('signup.question_goal') }}</label>
            <div class="mt-2 relative rounded-md shadow-sm">
              <select v-on:loadeddata="request.goal.options" required v-model="send.goal_key"
                      class=" form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Selecciona un objetivo">
                <option disabled value="">{{ $t('signup.select_goal') }}</option>
                <option v-for="option in request.goal.options" v-bind:key="option.value" :value="option.key">
                  {{ option.translations[lang] }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-6">
            <label class="block text-sm font-medium text-gray-700">{{ $t('signup.question_volume') }}</label>
            <div class="mt-2 relative rounded-md shadow-sm">
              <select required v-model="send.volume_key"
                      class=" form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Selecciona un objetivo">
                <option disabled value="">{{ $t('signup.select_volume') }}</option>
                <option v-for="option in request.volume.options" v-bind:key="option.id" :value="option.id">
                  {{ option.min_users }} - {{ option.max_users }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-6">
            <label class="block text-sm font-medium text-gray-700">{{ $t('signup.question_sector') }}</label>
            <div class="mt-2 relative rounded-md shadow-sm">
              <select required v-model="send.sector_key"
                      class=" form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Selecciona un objetivo">
                <option disabled value="">{{ $t('signup.select_sector') }}</option>
                <option v-for="option in request.sector.options" v-bind:key="option.value" :value="option.key">
                  {{ option.translations[lang] }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-6">
            <label class="block text-sm font-medium text-gray-700">{{ $t('signup.question_area') }}</label>
            <div class="mt-2 relative rounded-md shadow-sm">
              <select required v-model="send.area_key"
                      v-if="!loading"
                      class=" form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Selecciona un objetivo">
                <option disabled value="">{{ $t('signup.select_area') }}</option>
                <option v-for="option in request.area.options" v-bind:key="option.key" :value="option.key">
                  {{ option.translations[lang] }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-6">
                <span class="block w-full rounded-md shadow-sm">
                  <button type="submit"
                          class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                    {{ $t('signup.start') }}
                  </button>
                </span>
                <span class="block">
                  <label type="back" @click="$router.push('signup')"
                          class="text-blue-700 mt-3 flex justify-center py-2 px-4 text-sm font-medium cursor-pointer rounded-md focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 hover:text-blue-400 transition duration-150 ease-in-out">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                    </svg>
                    {{ $t('signup.back') }}
                  </label>
                </span>
          </div>
        </form>
      </div>
    </div>
    <div
        class="hidden lg:block relative w-0 flex justify-center items-center flex-1 bg-gradient-to-br from-bppick to-bpblue text-white px-10 4xl:px-64 py-20">
      <img class="mb-20" src="/images/banners/banner-login-v2.png"/>
      <img alt=""
           class="hidden absolute inset-0 h-full w-full object-cover object-right"
           src="/images/banners/banner-login.jpeg">
    </div>
  </div>
</template>

<script>
import {useAcademiesStore} from "../../../stores/academies";
import {useAuthUserStore} from "../../../stores/auth";

export default {
  name: "CustomerDataComponent",

  data() {
    return {
      lang: 'en',
      loading: 'true',
      request: {
        goal: {
          options: null
        },
        volume: {
          options: null
        },
        sector: {
          options: null
        },
        area: {
          options: null
        },
      },
      send: {
        goal_key: '',
        volume_key: '',
        sector_key: '',
        area_key: '',
      }
    }
  },
  setup()
  {
    const academiesStore = useAcademiesStore()
    const authUserStore = useAuthUserStore()

    return {
      academiesStore,
      authUserStore
    }
  },

  created() {
    this.lang = this.$i18n.locale
    console.log("Current lang:", this.lang)

    this.getOptionsToGoal()
    this.getOptionsToArea()
    this.getOptionsToSector()
    this.getOptionsToVolume()
  },

  mounted() {
  },

  methods: {
    getOptionsToGoal() {
      this.loading = true
      this.academiesStore.getGoalOptions()
          .then((response) => {
            this.request.goal.options = response.data.data
          })
          .catch((error) =>{
            console.log("Error: ", error)
          })
    },
    getOptionsToArea() {
      this.loading = true
      this.academiesStore.getAreaOptions()
          .then((response) => {
              this.request.area.options = response.data.data
          })
          .catch((error) =>{
            console.log("Error: ", error)
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getOptionsToSector() {
      this.academiesStore.getSectorOptions()
          .then((response) => {
            this.request.sector.options = response.data.data
          })
          .catch((error) =>{
            console.log("Error: ", error)
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getOptionsToVolume() {
      this.loading = true
      this.academiesStore.getVolumeOptions()
          .then((response) => {
            this.request.volume.options = response.data.data
          })
          .catch((error) =>{
            console.log("Error: ", error)
          })
      .finally(() => {
        this.loading = false;
      })
    },
    async signup() {

      let userData = JSON.parse(localStorage.getItem('userDataObject'))
      //Guardar los datos en el formato apropiado para la API
      this.send.firstname = userData.firstname
      this.send.lastname = userData.lastname
      this.send.email = userData.email
      this.send.password = userData.password
      this.send.password_confirmation = userData.password_confirmation
      this.send.phone = userData.phone
      this.send.rgpd_acceptance = userData.rgpd_acceptance
      this.send.subdomain = userData.subdomain
      console.log("Los datos modificados son: ", this.send)

      this.authUserStore.signup(this.send)
          .then(() => {
            this.$router.push({name: 'dashboard'})

            // eslint-disable-next-line no-undef
            this.gtag_report_conversion();

            document.addEventListener('connectif.managed.initialized', function onConnectifInitializedRegister() {
              console.log('Connectif is initialized');
              // now I can start tracking events
              window.connectif.managed.sendEvents([
                {type: "register"},
                {type: "page-visit"}
              ]);

              window.connectif.managed.sendEvents([
                {
                  type: 'custom',
                  eventId: '618f9cc53ecef84394c2c652',
                  payload: {
                    email: this.email,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    phone: this.phone
                  }
                }
              ])

            });
          })
          .catch(({response}) => {
            console.log("Register failed: ", response)
            if (response.data.errors) {
              this.errors = response.data.errors;
            }
          })
    },
    gtag_report_conversion(url) {
      var callback = function () {
        if (typeof (url) != 'undefined') {
          window.location = url;
        }
      };
      // eslint-disable-next-line no-undef
      gtag('event', 'conversion', {
        'send_to': 'AW-703550540/wWWICMXv04kCEMyovc8C',
        'event_callback': callback
      });
      return false;
    },
  }
}
</script>

<style scoped>

</style>