<template>
  <div class="max-w-sm mt-5 rounded overflow-hidden shadow-lg bg-white">
    <div class="w-full h-48 relative bg-cover" :style="'background-image: url('+ productsStore.product.media.thumbnail +')'">
      <div class="bg-bpblue text-white text-lg p-2 absolute bottom-0 right-0">
        <div v-if="productsStore.product.sale_price">
          <span class="line-through mr-2">{{productsStore.product.regular_price}}€</span>
          <span>{{productsStore.product.sale_price}}€</span>
        </div>
        <span v-else>{{productsStore.product.regular_price}}€</span>
      </div>
    </div>
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-4">
        <h4>
          <a href="" class="product-name">{{ productsStore.product.title }}</a>
          <small class="block text-gray-700"></small>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import {useProductsStore} from "@/stores/products";

export default {
  name: "ProductCardPreview",
  setup(){

    const productsStore = useProductsStore()

    return {
      productsStore
    }
  }
}
</script>

<style scoped>

</style>