export default {
    //ProductList
    your_created_projects: "Els teus cursos creats",
    new_project: "Nou Curs",
    projects_name: "NOM DEL CURS",
    update_at: 'Actualitzat el ',
    coming_soon: 'Properament',
    search_your_project: 'Busca el teu curs...',
    project_name: 'Nom del curs',
    loading_products: 'Carregant productes...',

    //ProductEditComponent
    modify_project: 'Modificant el teu producte',
    general: 'General',
    branding: 'Branding',
    product_page: 'Pàgina de Producte',
    sale: 'Venda',
    seo_text: 'SEO',
    projects: 'Projectes',

    //CategoriesSlideOverComponent
    categories: 'Categories',
    text_category: 'Classifica els teus productes per categories, així facilitaràs als teus alumnes comprar els teus cursos.',
    select_category: 'Selecciona categoria...',
    ebook: 'E-book',
    online_project: 'Curs en línia',
    blended_learning: 'Curs semipresencial',
    online_program: 'Programa Online',
    webinar_consulting: 'Webinar / Consultoria online',
    remove_category: 'Eliminar categoria',
    href_help: 'Vull saber més sobre les categories',

    //ProductEdit
    //CustomCategoriesComponent
    //GeneralComponent
    category: 'Categoria',
    duration: 'Durada',
    featured_product: 'Producte destacat',
    delete_project: 'Esborrar Curs',
    warning_delete: 'Atenció!',
    message_delete_project: 'En eliminar el curs perdràs totes les dades definitivament.',
    question_delete_project: 'Esteu segur que voleu suprimir aquest curs?',
    delete: 'Eliminar',
    confirmation_delete_project: '(*) Aquesta casella és obligatòria per eliminar el curs',
    //PageComponent
    principal_page: 'Pàgina principal',
    objectives_presentation: 'Presentació i objectius',
    learning_objectives: 'No us oblideu de descriure breument els objectius d\'aprenentatge.',
    teaching_staff: 'Metodologia i professorat',
    public: 'Públic',
    content: 'Contingut',
    sidebar: 'Barra Lateral',
    //ProductBrandingComponent
    banner: 'Banner',
    upload_banner: 'Puja el teu banner',
    banner_format: 'PNG, JPG, GIF fins a 3MB',
    promotional_video: 'Vídeo Promocional',
    video_url: 'URL del vídeo',
    video_info: 'Si tens un vídeo promocional, col·loca aquí la url del vídeo a Youtube o Vimeo.',
    //SellComponent
    price_management: 'Gestió de preus',
    sell_price: 'Preu de venda',
    tax: 'A €. (-3% taxes)',
    price_offer: "Preu d'oferta",
    text_configuration: 'Configuració de textos',
    free_text_button: 'Títol de botó de compra gratuït',
    text_if_free: 'Aquest títol apareixerà si el teu producte és gratuït',
    on_sale: 'En venda',
    sale_info: 'Si està activat el teu producte estarà a la venda',

    //SeoComponent
    seo_configuration: 'Configuració SEO',
    seo_title: 'Títol SEO',
    slug: 'Slug',
    seo_description: 'Descripció SEO',

    //ProductListComponent
    featured: 'Destacat',
    classroom: 'Aula',
    slug_text: 'slug',
    product: 'producte',
    products: 'productes',
    preview: 'Previsualitzar',

    //AttachProjectSlideOverComponent
    attach_project: 'Associar Projecte',
    loading_projects: 'Carregant projectes associats...',
    project_selected: 'Projecte seleccionat',
    quest_attach_project: 'Vols adjuntar aquest projecte?',
    back: 'Tornar',
    add_project: 'Adjuntar Projecte',
    close_panel: 'Tancar panell',

    //ProjectAttachedListComponent
    attached_projects: 'Projectes Associats',
    loading_products_attached: 'Carregant productes associats...',
    unattach_project: 'Desassociar projecte',
    info_unattach_project: 'En desasociar el projecte al producte, els alumnes que comprin aquest producte no podran accedir al projecte.',
    quest_unattach_project: 'Segur que vols desasociar el projecte ',
}